<div
  class="base-form-field form-field-inputfield"
  [ngClass]="[
    (formFieldData.formfieldEntityType === 'string' &&
      formFieldData.formfieldEntityPrecision != null &&
      formFieldData.formfieldEntityPrecision > 1) ||
    formFieldData.formfieldEntityType === 'image' ||
    formFieldData.formfieldEntityType === 'mailtemplate'
      ? 'multiline'
      : '',
    !formFieldData.formfieldLabelShow ? 'justify-center' : ''
  ]"
>
  <div class="label-container" *ngIf="formFieldData.formfieldLabelShow">
    <ng-container *ngIf="formFieldData.formfieldLabelDisplay">
      <span class="label" showIfTruncated matTooltip="{{ formFieldData.formfieldTextname | translate }}"
        >{{ formFieldData.formfieldTextname | translate }}
      </span>
      <ng-container *ngIf="!isViewMode">
        <span
          *ngIf="
            formFieldData.formfieldRequired === GenericFormFieldRequiredType.required && !formFieldData.isReadonlyField() && !isViewMode
          "
          [ngClass]="formFieldData.isRequiredControlValid ? 'requiredField' : 'requiredFieldInvalid'"
          >*</span
        >
        <span
          *ngIf="
            formFieldData.formfieldRequired === GenericFormFieldRequiredType.requiredSoonAsPossible &&
            !formFieldData.isReadonlyField() &&
            !isViewMode
          "
          class="requiredSoonAsPossibileField"
          >*</span
        >
      </ng-container>
      <span
        class="tooltip"
        *ngIf="formFieldData.formfieldInfo"
        matTooltip="{{ parseEntityTooltip('\n', '&emsp;', formFieldData.formfieldInfo | translate) }}"
        [matTooltipClass]="'fo-mat-tooltip tree-tooltip'"
        ><mat-icon class="entityTooltipIcon">info</mat-icon>
      </span>
    </ng-container>
    <ng-container *ngIf="!formFieldData.formfieldLabelDisplay">
      <span class="label">&nbsp;</span>
    </ng-container>
  </div>
  <div class="value-container">
    <span class="value">
      <span [ngSwitch]="true">
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% STRING %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <!-- SINGLE LINE STRING -->
        <ng-container
          *ngSwitchCase="
            formFieldData.formfieldEntityType === 'string' &&
            (formFieldData.formfieldEntityPrecision == null || formFieldData.formfieldEntityPrecision === 1)
          "
          ><div class="form-field-string-input-container">
            <mat-form-field
              appearance="outline"
              class="form-field-input reset-outline-hint"
              [ngClass]="[
                formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '',
                (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                  ? 'invalid-field'
                  : '',
                isViewMode ? 'view-mode' : '',
                isDirtyEnabled && originalFormFieldData.formfieldEntityText !== formFieldData.formfieldEntityText ? DIRTY_CLASSNAME : ''
              ]"
            >
              <input
                id="form-field-multiline-inputarea"
                type="text"
                matInput
                [disabled]="formFieldData.isReadonlyField() || isViewMode"
                (input)="changeValueEvEm.emit()"
                (blur)="loseFocusEvEm.emit()"
                [(ngModel)]="formFieldData.formfieldEntityText"
              />
              <button
                *ngIf="formFieldData.formfieldEntityText && !formFieldData.isReadonlyField() && !isViewMode"
                mat-icon-button
                (click)="clearInput()"
                class="reset-filter"
                matSuffix
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <ng-container *ngIf="!(formFieldData.isReadonlyField() || isViewMode)">
              <div
                *ngIf="formFieldData.formfieldEntityTranslations != null && formFieldData.formfieldEntityTranslations.length === 1"
                class="form-field-multi-rich-string-buttons"
              >
                <button
                  mat-raised-button
                  color="primary"
                  (click)="onAddMultiTextPlaceholder(button)"
                  style="margin: 0 0 0.5rem 0.5rem; min-width: 120px"
                  *ngFor="let button of formFieldData.formfieldEntityTranslations"
                  matTooltip="{{ parseEntityTooltip('\n', '&emsp;', button.addon | translate) }}"
                  [matTooltipClass]="'fo-mat-tooltip tree-tooltip'"
                >
                  <span>{{ button.value | translate }}</span>
                </button>
              </div>
              <div
                *ngIf="formFieldData.formfieldEntityTranslations != null && formFieldData.formfieldEntityTranslations.length > 1"
                class="form-field-multi-rich-string-buttons"
              >
                <button
                  mat-icon-button
                  color="primary"
                  class="form-field-input-string-button"
                  (click)="isStringInputMenuOpen = !isStringInputMenuOpen"
                  cdkOverlayOrigin
                  #singleStringInput="cdkOverlayOrigin"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <ng-template
                  cdkConnectedOverlay
                  [cdkConnectedOverlayOrigin]="singleStringInput"
                  [cdkConnectedOverlayOpen]="isStringInputMenuOpen"
                  (overlayOutsideClick)="$event.stopPropagation(); isStringInputMenuOpen = false"
                >
                  <div class="form-field-input-string-overlay">
                    <button
                      *ngFor="let button of formFieldData.formfieldEntityTranslations"
                      mat-raised-button
                      color="primary"
                      style="margin-bottom: 0.5rem; min-width: 120px"
                      (click)="onAddSingleTextPlaceholder(button)"
                      matTooltip="{{ parseEntityTooltip('\n', '&emsp;', button.addon | translate) }}"
                      [matTooltipClass]="'fo-mat-tooltip tree-tooltip'"
                    >
                      <span>{{ button.value }}</span>
                    </button>
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <!-- MULTI LINE STRING -->
        <ng-container
          *ngSwitchCase="
            formFieldData.formfieldEntityType === 'string' &&
            formFieldData.formfieldEntityPrecision != null &&
            formFieldData.formfieldEntityPrecision === 2
          "
        >
          <div class="form-field-string-input-container">
            <mat-form-field
              appearance="outline"
              class="form-field-input reset-outline-hint"
              [ngClass]="[
                formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '',
                (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                  ? 'invalid-field'
                  : '',
                isViewMode ? 'view-mode' : '',
                isDirtyEnabled && originalFormFieldData.formfieldEntityText !== formFieldData.formfieldEntityText ? DIRTY_CLASSNAME : ''
              ]"
            >
              <textarea
                id="form-field-multiline-textarea"
                type="text"
                matInput
                [disabled]="formFieldData.isReadonlyField() || isViewMode"
                (input)="changeValueEvEm.emit()"
                (blur)="loseFocusEvEm.emit()"
                [(ngModel)]="formFieldData.formfieldEntityText"
                cdkTextareaAutosize
              ></textarea>
              <button
                *ngIf="formFieldData.formfieldEntityText && !formFieldData.isReadonlyField() && !isViewMode"
                mat-icon-button
                (click)="clearInput()"
                class="reset-filter"
                matSuffix
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <ng-container *ngIf="!(formFieldData.isReadonlyField() || isViewMode)">
              <div
                *ngIf="formFieldData.formfieldEntityTranslations != null && formFieldData.formfieldEntityTranslations.length > 0"
                class="form-field-multi-rich-string-buttons"
              >
                <button
                  mat-raised-button
                  color="primary"
                  (click)="onAddMultiTextPlaceholder(button)"
                  style="margin: 0 0 0.5rem 0.5rem; min-width: 120px"
                  *ngFor="let button of formFieldData.formfieldEntityTranslations"
                  matTooltip="{{ parseEntityTooltip('\n', '&emsp;', button.addon | translate) }}"
                  [matTooltipClass]="'fo-mat-tooltip tree-tooltip'"
                >
                  <span>{{ button.value | translate }}</span>
                </button>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <!-- RICH TEXT STRING -->
        <ng-container
          *ngSwitchCase="
            formFieldData.formfieldEntityType === 'string' &&
            formFieldData.formfieldEntityPrecision != null &&
            formFieldData.formfieldEntityPrecision === 3
          "
        >
          <div class="form-field-string-input-container">
            <ckeditor
              #ckeditor
              class="form-field-ck-editor"
              [ngClass]="[
                formFieldData.isReadonlyField() || isViewMode ? 'viewmode' : 'editmode',
                (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                  ? 'invalid-field'
                  : '',
                isDirtyEnabled && originalFormFieldData.formfieldEntityText !== formFieldData.formfieldEntityText ? DIRTY_CLASSNAME : ''
              ]"
              [editor]="Editor"
              (ready)="onCkeditorReady()"
              (change)="changeValueEvEm.emit()"
              (blur)="loseFocusEvEm.emit()"
              [(ngModel)]="formFieldData.formfieldEntityText"
              [disabled]="formFieldData.isReadonlyField() || isViewMode"
              [config]="config"
            ></ckeditor>
            <ng-container *ngIf="!(formFieldData.isReadonlyField() || isViewMode)">
              <div
                *ngIf="formFieldData.formfieldEntityTranslations != null && formFieldData.formfieldEntityTranslations.length > 0"
                class="form-field-multi-rich-string-buttons"
              >
                <button
                  mat-raised-button
                  color="primary"
                  (click)="onAddCkeditorTextPlaceholder(button)"
                  style="margin: 0 0 0.5rem 0.5rem; min-width: 120px"
                  *ngFor="let button of formFieldData.formfieldEntityTranslations"
                  matTooltip="{{ parseEntityTooltip('\n', '&emsp;', button.addon | translate) }}"
                  [matTooltipClass]="'fo-mat-tooltip tree-tooltip'"
                >
                  <span>{{ button.value | translate }}</span>
                </button>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% INTEGER %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'integer' || formFieldData.formfieldEntityType === 'bigint'">
          <mat-form-field
            appearance="outline"
            class="form-field-input reset-outline-hint"
            [ngClass]="[
              formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '',
              (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                ? 'invalid-field'
                : '',
              isViewMode ? 'view-mode' : '',
              isDirtyEnabled && originalFormFieldData.formfieldEntityText !== formFieldData.formfieldEntityText ? DIRTY_CLASSNAME : ''
            ]"
          >
            <input
              type="number"
              matInput
              [disabled]="formFieldData.isReadonlyField() || isViewMode"
              [(ngModel)]="formFieldData.formfieldEntityText"
              (input)="formFieldData.formfieldEntityValue = castToInt(formFieldData.formfieldEntityText); changeValueEvEm.emit()"
              (blur)="loseFocusEvEm.emit()"
              mat-input-number
            />
            <button
              *ngIf="formFieldData.formfieldEntityText != null && !formFieldData.isReadonlyField() && !isViewMode"
              mat-icon-button
              class="reset-filter"
              matSuffix
              (click)="clearInput()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% DECIMAL %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container
          *ngSwitchCase="
            formFieldData.formfieldEntityType === 'money' ||
            formFieldData.formfieldEntityType === 'cost' ||
            formFieldData.formfieldEntityType === 'factor' ||
            formFieldData.formfieldEntityType === 'percentage'
          "
        >
          <mat-form-field
            appearance="outline"
            class="form-field-input reset-outline-hint"
            [ngClass]="[
              formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '',
              (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                ? 'invalid-field'
                : '',
              isViewMode ? 'view-mode' : '',
              isDirtyEnabled && originalFormFieldData.formfieldEntityText !== formFieldData.formfieldEntityText ? DIRTY_CLASSNAME : ''
            ]"
          >
            <!-- EDIT MODE -->
            <input
              *ngIf="!(formFieldData.isReadonlyField() || isViewMode) && formFieldData.formfieldEntityType === 'money'"
              type="text"
              matInput
              [(ngModel)]="formFieldData.formfieldEntityFloatText"
              (input)="updateMoney($event)"
              (blur)="loseFocusEvEm.emit()"
              localizedNumericInput
            />
            <input
              *ngIf="!(formFieldData.isReadonlyField() || isViewMode) && formFieldData.formfieldEntityType === 'percentage'"
              type="text"
              matInput
              [(ngModel)]="formFieldData.formfieldEntityFloatText"
              (input)="updatePercentage($event)"
              (blur)="loseFocusEvEm.emit()"
              localizedNumericInput
            />
            <input
              *ngIf="!(formFieldData.isReadonlyField() || isViewMode) && formFieldData.formfieldEntityType === 'cost'"
              type="text"
              matInput
              [(ngModel)]="formFieldData.formfieldEntityFloatText"
              (input)="updateCost($event)"
              (blur)="loseFocusEvEm.emit()"
              localizedNumericInput
            />
            <input
              *ngIf="!(formFieldData.isReadonlyField() || isViewMode) && formFieldData.formfieldEntityType === 'factor'"
              type="text"
              matInput
              [(ngModel)]="formFieldData.formfieldEntityFloatText"
              (input)="updateRate($event)"
              (blur)="loseFocusEvEm.emit()"
              localizedNumericInput
            />
            <!-- VIEW MODE FORMATTING -->
            <input
              *ngIf="(formFieldData.isReadonlyField() || isViewMode) && formFieldData.formfieldEntityType === 'money'"
              type="text"
              matInput
              [disabled]="true"
              [value]="getMoney(castToFloat2Decimal(formFieldData.formfieldEntityText))"
            />
            <input
              *ngIf="(formFieldData.isReadonlyField() || isViewMode) && formFieldData.formfieldEntityType === 'percentage'"
              type="text"
              matInput
              [disabled]="true"
              [value]="getPercentage(castToFloat4Decimal(formFieldData.formfieldEntityText))"
            />
            <input
              *ngIf="(formFieldData.isReadonlyField() || isViewMode) && formFieldData.formfieldEntityType === 'cost'"
              type="text"
              matInput
              [disabled]="true"
              [value]="getCost(castToFloat4Decimal(formFieldData.formfieldEntityText))"
            />
            <input
              *ngIf="(formFieldData.isReadonlyField() || isViewMode) && formFieldData.formfieldEntityType === 'factor'"
              type="text"
              matInput
              [disabled]="true"
              [value]="getFactor(castToFloat6Decimal(formFieldData.formfieldEntityText))"
            />
            <button
              *ngIf="formFieldData.formfieldEntityText != null && !formFieldData.isReadonlyField() && !isViewMode"
              mat-icon-button
              class="reset-filter"
              matSuffix
              (click)="clearInput()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% DATE %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'date'">
          <p-calendar
            rPCalendarMask
            #pcalendarDatepicker
            [ngClass]="[
              formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '',
              (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                ? 'invalid-field'
                : '',
              isViewMode ? 'view-mode' : '',
              isDirtyEnabled &&
              formFieldData.formfieldEntityText &&
              (datepickerDirty || !isSameDate(originalFormFieldData.formfieldEntityText, formFieldData.formfieldEntityText))
                ? DIRTY_CLASSNAME
                : ''
            ]"
            [disabled]="formFieldData.isReadonlyField() || isViewMode"
            [(ngModel)]="formFieldData.formfieldEntityDate"
            [selectOtherMonths]="true"
            [showIcon]="!formFieldData.isReadonlyField() && !isViewMode"
            [showClear]="formFieldData.formfieldEntityText && !formFieldData.isReadonlyField() && !isViewMode"
            [placeholder]="isViewMode ? '' : getDatePlaceholder()"
            [dateFormat]="getDateRegionalFormat()"
            (onSelect)="updateDate($event); loseFocusEvEm.emit()"
            (onBlur)="updateDate(formFieldData.formfieldEntityDate); loseFocusEvEm.emit()"
            (onClear)="clearInput()"
            appendTo="body"
            ><p-footer>
              <div class="p-datepicker-buttonbar">
                <button
                  class="p-button-text"
                  type="button"
                  (click)="calendarOnTodayButtonClick($event, pcalendarDatepicker)"
                  pButton
                  pRipple
                >
                  {{ 'label_today' | translate }}
                </button>
                <button class="p-button-text" type="button" (click)="pcalendarDatepicker.hideOverlay()" pButton pRipple>
                  {{ 'label_close' | translate }}
                </button>
              </div>
            </p-footer>
          </p-calendar>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% DATETIME %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'datetime'">
          <p-calendar
            rPCalendarMask
            #pcalendarDatepicker
            [ngClass]="[
              formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '',
              (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                ? 'invalid-field'
                : '',
              isViewMode ? 'view-mode' : '',
              isDirtyEnabled &&
              (datepickerDirty || !isSameDatetime(originalFormFieldData.formfieldEntityText, formFieldData.formfieldEntityText))
                ? DIRTY_CLASSNAME
                : ''
            ]"
            [disabled]="formFieldData.isReadonlyField() || isViewMode"
            [(ngModel)]="formFieldData.formfieldEntityDate"
            [selectOtherMonths]="true"
            [showTime]="true"
            [showIcon]="!formFieldData.isReadonlyField() && !isViewMode"
            [showClear]="formFieldData.formfieldEntityText && !formFieldData.isReadonlyField() && !isViewMode"
            [placeholder]="isViewMode ? '' : getDatetimePlaceholder()"
            [dateFormat]="getDatetimeRegionalFormat()"
            (onSelect)="updateDatetime($event); loseFocusEvEm.emit()"
            (onBlur)="updateDatetime(formFieldData.formfieldEntityDate); loseFocusEvEm.emit()"
            (onClear)="clearInput()"
            appendTo="body"
            ><p-footer>
              <div class="p-datepicker-buttonbar">
                <button
                  class="p-button-text"
                  type="button"
                  (click)="calendarOnTodayButtonClick($event, pcalendarDatepicker)"
                  pButton
                  pRipple
                >
                  {{ 'label_today' | translate }}
                </button>
                <button class="p-button-text" type="button" (click)="pcalendarDatepicker.hideOverlay()" pButton pRipple>
                  {{ 'label_close' | translate }}
                </button>
              </div>
            </p-footer>
          </p-calendar>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% TIME %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <!-- <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'time'">
          <mat-form-field
            appearance="outline"
            class="form-field-input reset-outline-hint timepicker-input"
            [ngClass]="[
              formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '',
              (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                ? 'invalid-field'
                : '',
              isViewMode ? 'view-mode' : '',
              isDirtyEnabled && originalFormFieldData.formfieldEntityText !== formFieldData.formfieldEntityText ? DIRTY_CLASSNAME : ''
            ]"
          >
            <input
              matInput
              [disabled]="formFieldData.isReadonlyField() || isViewMode"
              [disableClick]="formFieldData.isReadonlyField()"
              [ngxTimepicker]="timepicker"
              [format]="24"
              [value]="formFieldData.formfieldEntityText"
            />
            <ngx-material-timepicker-toggle matSuffix *ngIf="!formFieldData.isReadonlyField() && !isViewMode" [for]="timepicker">
              <svg viewBox="0 0 24 24" width="24" height="24" class="mat-datepicker-toggle-default-icon" ngxMaterialTimepickerToggleIcon>
                <path
                  d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003                   6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z"
                />
              </svg>
            </ngx-material-timepicker-toggle>
            <button
              *ngIf="formFieldData.formfieldEntityText && !formFieldData.isReadonlyField() && !isViewMode"
              mat-icon-button
              class="reset-filter"
              matSuffix
              (click)="clearInput()"
            >
              <mat-icon>close</mat-icon>
            </button>
            <ngx-material-timepicker
              #timepicker
              [appendToInput]="true"
              [theme]="bstTheme"
              [cancelBtnTmpl]="cancelBtn"
              [confirmBtnTmpl]="confirmBtn"
              (timeSet)="updateTime($event); loseFocusEvEm.emit()"
            ></ngx-material-timepicker>
            <ng-template #cancelBtn>
              <button mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </ng-template>
            <ng-template #confirmBtn>
              <button mat-icon-button>
                <mat-icon>check</mat-icon>
              </button>
            </ng-template>
          </mat-form-field>
        </ng-container> -->
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% OPTION %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'option'">
          <ng-container *ngIf="formFieldData.formfieldEntityKind !== Entities.TYPES">
            <!-- COMBOBOX -->
            <mat-form-field
              appearance="outline"
              *ngIf="formFieldData.formfieldDisplayType === FormFieldInputfieldOptionDisplayOption.COMBOBOX"
              class="form-field-input reset-outline-hint"
              [ngClass]="[
                formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '',
                (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                  ? 'invalid-field'
                  : '',
                isViewMode ? 'view-mode' : '',
                isDirtyEnabled && originalFormFieldData.formfieldEntityValue !== formFieldData.formfieldEntityValue ? DIRTY_CLASSNAME : ''
              ]"
            >
              <input
                type="text"
                matInput
                #optionSelect
                [disabled]="formFieldData.isReadonlyField() || isViewMode"
                [(ngModel)]="formFieldData.filterStringOption"
                (ngModelChange)="filterOptions(formFieldData.filterStringOption)"
                (click)="$event.target.select()"
                [matAutocomplete]="autoStringOption"
              />
              <mat-autocomplete
                (opened)="matAutocompletPanelOpened()"
                (closed)="matAutocompletPanelClosed()"
                matAutocompleteScrollToOption
                #autoStringOption="matAutocomplete"
                (optionSelected)="onOptionSelectionChanged($event); loseFocusEvEm.emit()"
                (closed)="checkSelectedOption()"
              >
                <mat-option
                  class="form-field-option"
                  *ngFor="let opt of filteredEntityOptions"
                  [value]="opt.value"
                  [class.mat-selected]="formFieldData.filterStringOption === opt.value"
                >
                  {{ opt.addon }}{{ opt.value }}
                </mat-option>
                <mat-option class="form-field-option create-button" *ngIf="formFieldData.formfieldEntityKind === 'entity_types'">
                  <button
                    mat-raised-button
                    color="primary"
                    style="margin: 0.25rem"
                    (click)="createEntityTypeOption(); $event.stopPropagation()"
                  >
                    <mat-icon>add</mat-icon> <span>{{ 'label_create' | translate }}</span>
                  </button>
                </mat-option>
              </mat-autocomplete>
              <button
                #dropDownButton
                *ngIf="!formFieldData.isReadonlyField() && !isViewMode"
                mat-icon-button
                matSuffix
                (click)="optionSelect.select()"
              >
                <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
              </button>
              <button
                *ngIf="formFieldData.formfieldEntityText && !formFieldData.isReadonlyField() && !isViewMode"
                mat-icon-button
                class="reset-filter"
                matSuffix
                (click)="optionSelect.select(); clearInput(); $event.stopPropagation()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <!-- RADIO -->
            <mat-radio-group
              *ngIf="formFieldData.formfieldDisplayType === FormFieldInputfieldOptionDisplayOption.RADIO"
              [(value)]="formFieldData.formfieldEntityValue"
              (change)="onRadioSelectionChanged($event); loseFocusEvEm.emit()"
              [disabled]="formFieldData.isReadonlyField() || isViewMode"
              [ngClass]="
                isDirtyEnabled && originalFormFieldData.formfieldEntityValue !== formFieldData.formfieldEntityValue
                  ? DIRTY_CHECKBOX_CLASSNAME
                  : ''
              "
            >
              <mat-radio-button *ngFor="let opt of formFieldData.formfieldEntityOptions" [value]="castToInt(opt.key)">{{
                opt.value
              }}</mat-radio-button>
            </mat-radio-group>
          </ng-container>
          <ng-container *ngIf="formFieldData.formfieldEntityKind === Entities.TYPES">
            <app-form-field-inputfield-foreign
              [entityId]="entityId"
              [entityKind]="entityKind"
              [formFieldData]="formFieldData"
              [isViewMode]="isViewMode"
              [isDirtyEnabled]="isDirtyEnabled"
              [originalFormFieldData]="originalFormFieldData"
              [isSavePressed]="isSavePressed"
              [isValidationFailed]="isValidationFailed"
              [showForeignDetailsButton]="showForeignDetailsButton"
              [foreignFilterEntityKind]="Entities.TYPES_KIND"
              [foreignFilterEntityId]="castToInt(formFieldData.formfieldEntitySpecification)"
              (changeValueEvEm)="changeValueEvEm.emit()"
              (autoUpdateEvEm)="autoUpdateEvEm.emit()"
              (loseFocusEvEm)="loseFocusEvEm.emit()"
            ></app-form-field-inputfield-foreign>
          </ng-container>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% FOREIGN %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'foreign'">
          <ng-container *ngIf="formFieldData.formfieldEntityKind !== Entities.TYPES">
            <app-form-field-inputfield-foreign
              [entityId]="entityId"
              [entityKind]="entityKind"
              [formFieldData]="formFieldData"
              [isViewMode]="isViewMode"
              [isDirtyEnabled]="isDirtyEnabled"
              [originalFormFieldData]="originalFormFieldData"
              [isSavePressed]="isSavePressed"
              [isValidationFailed]="isValidationFailed"
              [showForeignDetailsButton]="showForeignDetailsButton"
              [foreignFilterEntityKind]="foreignFilterEntityKind"
              [foreignFilterEntityId]="foreignFilterEntityId"
              (changeValueEvEm)="changeValueEvEm.emit()"
              (autoUpdateEvEm)="autoUpdateEvEm.emit()"
              (loseFocusEvEm)="loseFocusEvEm.emit()"
            ></app-form-field-inputfield-foreign>
          </ng-container>
          <ng-container *ngIf="formFieldData.formfieldEntityKind === Entities.TYPES">
            <app-form-field-inputfield-foreign
              [entityId]="entityId"
              [entityKind]="entityKind"
              [formFieldData]="formFieldData"
              [isViewMode]="isViewMode"
              [isDirtyEnabled]="isDirtyEnabled"
              [originalFormFieldData]="originalFormFieldData"
              [isSavePressed]="isSavePressed"
              [isValidationFailed]="isValidationFailed"
              [showForeignDetailsButton]="showForeignDetailsButton"
              [foreignFilterEntityKind]="Entities.TYPES_KIND"
              [foreignFilterEntityId]="castToInt(formFieldData.formfieldEntitySpecification)"
              (changeValueEvEm)="changeValueEvEm.emit()"
              (autoUpdateEvEm)="autoUpdateEvEm.emit()"
              (loseFocusEvEm)="loseFocusEvEm.emit()"
            ></app-form-field-inputfield-foreign>
          </ng-container>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% BOOLEAN %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'boolean'">
          <mat-slide-toggle
            *ngIf="formFieldData.formfieldDisplayType === FormFieldInputfieldBooleanDisplayOption.TOGGLE"
            color="primary"
            [checked]="formFieldData.formfieldEntityValue != null && formFieldData.formfieldEntityValue != 0"
            (change)="onChangeBoolean($event); loseFocusEvEm.emit()"
            [disabled]="formFieldData.isReadonlyField() || isViewMode"
            [ngClass]="
              isDirtyEnabled && originalFormFieldData.formfieldEntityValue !== formFieldData.formfieldEntityValue
                ? DIRTY_CHECKBOX_CLASSNAME
                : ''
            "
          >
          </mat-slide-toggle>
          <mat-checkbox
            *ngIf="formFieldData.formfieldDisplayType === FormFieldInputfieldBooleanDisplayOption.CHECKBOX"
            color="primary"
            [checked]="formFieldData.formfieldEntityValue != null && formFieldData.formfieldEntityValue != 0"
            (change)="onChangeBoolean($event); loseFocusEvEm.emit()"
            [disabled]="formFieldData.isReadonlyField() || isViewMode"
            [ngClass]="
              isDirtyEnabled && originalFormFieldData.formfieldEntityValue !== formFieldData.formfieldEntityValue
                ? DIRTY_CHECKBOX_CLASSNAME
                : ''
            "
          >
          </mat-checkbox>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% IMAGE %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'image'">
          <span
            class="image-input"
            [ngClass]="[
              (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                ? 'invalid-field'
                : '',
              isDirtyEnabled && originalFormFieldData.formfieldEntityText !== formFieldData.formfieldEntityText ? DIRTY_CLASSNAME : ''
            ]"
          >
            <img *ngIf="formFieldData.formfieldEntityText" [src]="transform(formFieldData.formfieldEntityText)" />
            <span class="truncate-text" *ngIf="!formFieldData.formfieldEntityText">{{ 'label_no_image' | translate }}</span>
            <div class="image-buttons">
              <button
                mat-icon-button
                class="small-icon"
                *ngIf="!formFieldData.isReadonlyField() && !isViewMode"
                (click)="fileInput.click()"
              >
                <mat-icon>search</mat-icon>
                <input
                  hidden
                  type="file"
                  #fileInput
                  (change)="convertFile($event.target.files); loseFocusEvEm.emit()"
                  [accept]="formFieldData.formfieldEntityUnit"
                />
              </button>
              <button
                *ngIf="formFieldData.formfieldEntityText && !formFieldData.isReadonlyField() && !isViewMode"
                mat-icon-button
                class="small-icon"
                (click)="clearInput()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </span>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% BITSET %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'bitset'">
          <!-- view mode -->
          <div *ngIf="formFieldData.isReadonlyField() || isViewMode">
            <mat-form-field appearance="outline" class="form-field-input reset-outline-hint view-mode">
              <input type="text" matInput [disabled]="true" [value]="formFieldData.formfieldEntityText" />
            </mat-form-field>
          </div>
          <!-- edit mode -->
          <div *ngIf="!formFieldData.isReadonlyField() && !isViewMode">
            <ng-container *ngIf="formFieldData.formfieldDisplayType === FormFieldInputfieldOptionDisplayOption.COMBOBOX">
              <mat-form-field
                appearance="outline"
                class="form-field-input reset-outline-hint"
                [ngClass]="[
                  (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                    ? 'invalid-field'
                    : '',
                  isDirtyEnabled && originalFormFieldData.formfieldEntityValue !== formFieldData.formfieldEntityValue ? DIRTY_CLASSNAME : ''
                ]"
              >
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(ngModel)]="bitwiseSelection"
                  #selectionDropdownOptions
                  (selectionChange)="checkBitsetSelectionByDropdown($event); loseFocusEvEm.emit()"
                  [compareWith]="bitwiseAndDropdown"
                  multiple
                >
                  <mat-option class="form-field-option" *ngFor="let option of formFieldData.formfieldEntityOptions" [value]="option">
                    {{ option.value }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="formFieldData.formfieldDisplayType === FormFieldInputfieldOptionDisplayOption.RADIO">
              <mat-selection-list
                class="bitset-field horizontal"
                #selectionOptions
                (selectionChange)="checkBitsetSelection(selectionOptions.selectedOptions.selected); loseFocusEvEm.emit()"
                [ngClass]="[
                  isDirtyEnabled && originalFormFieldData.formfieldEntityValue !== formFieldData.formfieldEntityValue ? DIRTY_CLASSNAME : ''
                ]"
              >
                <mat-list-option
                  *ngFor="let option of formFieldData.formfieldEntityOptions"
                  checkboxPosition="before"
                  [selected]="bitwiseAnd(castToInt(option.key), formFieldData.formfieldEntityValue)"
                  [value]="option"
                >
                  {{ option.value }}
                </mat-list-option>
              </mat-selection-list>
            </ng-container>
          </div>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% COLOR %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'color'">
          <div
            *ngIf="!formFieldData.isReadonlyField() && !isViewMode"
            class="color-input"
            [ngClass]="[
              (isSavePressed && !formFieldData.isRequiredControlValid) || (isValidationFailed && formFieldData.formfieldUnique)
                ? 'invalid-field'
                : '',
              isDirtyEnabled && originalFormFieldData.formfieldEntityText !== formFieldData.formfieldEntityText ? DIRTY_CLASSNAME : ''
            ]"
          >
            <input
              class="color-picker"
              [(colorPicker)]="formFieldData.formfieldEntityText"
              [style.background]="formFieldData.formfieldEntityText"
              cpPosition="auto"
              [cpUseRootViewContainer]="true"
              [cpCmykEnabled]="false"
              [cpCancelButton]="true"
              cpCancelButtonText="{{ 'color_picker_cancel' | translate }}"
              [cpCancelButtonClass]="'btn btn-primary btn-xs'"
              [cpOKButton]="true"
              cpOKButtonText="{{ 'color_picker_confirm' | translate }}"
              [cpSaveClickOutside]="false"
              [cpOKButtonClass]="'btn btn-primary btn-xs'"
              (colorPickerSelect)="onColorChange($event); loseFocusEvEm.emit()"
              [cpAlphaChannel]="'disabled'"
              [cpCmykEnabled]="false"
            />
            <!-- <mcc-color-picker
              [selectedColor]="formFieldData.formfieldEntityText"
              [hideHexForms]="false"
              hideEmptyUsedColors
              btnCancelLabel="{{ 'color_picker_cancel' | translate }}"
              btnConfirmLabel="{{ 'color_picker_confirm' | translate }}"
              colorPickerSelectorHeight="200"
              usedSizeColors="15"
              (selected)="onColorChange($event); loseFocusEvEm.emit()"
            >
            </mcc-color-picker> -->
          </div>
          <div
            *ngIf="formFieldData.isReadonlyField() || isViewMode"
            class="color-picker-readonly"
            [ngStyle]="{ 'background-color': formFieldData.formfieldEntityText }"
          ></div>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% TRANSLATIONS %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'translation'">
          <app-translations-input-dialog-control
            [keyvaluesTranslations]="formFieldData.formfieldEntityTranslations"
            [isViewMode]="isViewMode"
            [isDirtyEnabled]="isDirtyEnabled"
            [isDisabled]="formFieldData.isReadonlyField() || isViewMode"
            (changedValueEvEm)="onTranslationsChange($event); loseFocusEvEm.emit()"
          ></app-translations-input-dialog-control>
        </ng-container>
        <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% EMAIL TEMPLATE %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'mailtemplate'">
          <div class="form-field-mailtemplate">
            <div class="mailtemplate-row">
              <div class="base-form-field form-field-inputfield w-onethird">
                <div class="label-container">
                  <span class="label">{{ 'mailbody_languageid' | translate }}</span>
                </div>
                <div class="value-container">
                  <span class="value">
                    <mat-form-field
                      appearance="outline"
                      class="form-field-input reset-outline-hint"
                      [ngClass]="[isViewMode ? 'view-mode' : '']"
                    >
                      <mat-select
                        disableOptionCentering
                        panelClass="finoffice-matselect-panel"
                        #languageSelect
                        [(ngModel)]="selectedLanguage"
                        (selectionChange)="selectedLanguageOptionChanged()"
                        [disabled]="formFieldData.isReadonlyField() || isViewMode"
                      >
                        <mat-option class="form-field-option" *ngFor="let language of staticOptions?.languages" [value]="language">
                          {{ language.entityName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </span>
                </div>
              </div>
              <div class="base-form-field form-field-inputfield w-onethird" style="padding: 0 0.5rem">
                <div class="label-container">
                  <span class="label">{{ 'mailbody_stage' | translate }}</span>
                </div>
                <div class="value-container">
                  <span class="value">
                    <mat-form-field
                      appearance="outline"
                      class="form-field-input reset-outline-hint"
                      [ngClass]="[isViewMode ? 'view-mode' : '']"
                    >
                      <mat-select
                        disableOptionCentering
                        panelClass="finoffice-matselect-panel"
                        #stageSelect
                        [(ngModel)]="selectedStage"
                        (selectionChange)="selectedStageOptionChanged()"
                        [disabled]="formFieldData.isReadonlyField() || isViewMode"
                      >
                        <mat-option class="form-field-option" *ngFor="let stage of staticOptions?.stages" [value]="stage">
                          {{ stage.entityName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </span>
                </div>
              </div>
              <div class="base-form-field form-field-inputfield w-onethird">&nbsp;</div>
              <div class="base-form-field form-field-inputfield w-onethird" style="padding: 0 0.5rem">
                <div class="label-container">
                  <span class="label truncate-text">{{ 'mailbody_fontfamily' | translate }}</span>
                </div>
                <div class="value-container">
                  <span class="value">
                    <mat-form-field
                      appearance="outline"
                      class="form-field-input reset-outline-hint"
                      [ngClass]="[isViewMode ? 'view-mode' : '']"
                    >
                      <mat-select
                        disableOptionCentering
                        panelClass="finoffice-matselect-panel"
                        #templateSelect
                        [(ngModel)]="selectedFontfamily"
                        (selectionChange)="selectedFontfamilyOptionChanged()"
                        [disabled]="formFieldData.isReadonlyField() || isViewMode"
                      >
                        <mat-option class="form-field-option" *ngFor="let fontfamily of staticOptions?.fontfamilies" [value]="fontfamily">
                          {{ fontfamily.entityName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </span>
                </div>
              </div>
              <div class="base-form-field form-field-inputfield w-onethird" style="padding: 0 0.5rem">
                <div class="label-container">
                  <span class="label">{{ 'mailbody_template' | translate }}</span>
                </div>
                <div class="value-container">
                  <span class="value">
                    <mat-form-field
                      appearance="outline"
                      class="form-field-input reset-outline-hint"
                      [ngClass]="[isViewMode ? 'view-mode' : '']"
                    >
                      <mat-select
                        disableOptionCentering
                        panelClass="finoffice-matselect-panel"
                        #templateSelect
                        [(ngModel)]="selectedTemplate"
                        (selectionChange)="selectedTemplateOptionChanged()"
                        [disabled]="formFieldData.isReadonlyField() || isViewMode"
                      >
                        <mat-option class="form-field-option" *ngFor="let template of staticOptions?.templates" [value]="template">
                          {{ template.entityName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </span>
                </div>
              </div>
              <div class="base-form-field form-field-inputfield w-onethird">
                <div class="label-container">
                  <span class="label">{{ 'mailbody_outgoing_account' | translate }}</span>
                </div>
                <div class="value-container">
                  <span class="value">
                    <mat-form-field
                      appearance="outline"
                      class="form-field-input reset-outline-hint"
                      [ngClass]="[isViewMode ? 'view-mode' : '']"
                    >
                      <mat-select
                        disableOptionCentering
                        panelClass="finoffice-matselect-panel"
                        #templateSelect
                        [(ngModel)]="selectedOutgoingAccount"
                        (selectionChange)="selectedOutgoingAccountOptionChanged()"
                        [disabled]="formFieldData.isReadonlyField() || isViewMode"
                      >
                        <mat-option class="form-field-option" *ngFor="let account of staticOptions?.mailoutgoingaccounts" [value]="account">
                          {{ account.entityName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </span>
                </div>
              </div>
            </div>
            <ng-container *ngIf="selectedMailTemplateFrameBody">
              <div class="mailtemplate-row">
                <div class="base-form-field form-field-inputfield">
                  <div class="label-container">
                    <span class="label">{{ 'mailbody_subject' | translate }}</span>
                    <span class="requiredField">*</span>
                  </div>
                  <div class="value-container">
                    <span class="value">
                      <div class="form-field-string-input-container">
                        <mat-form-field
                          appearance="outline"
                          class="form-field-input reset-outline-hint"
                          [ngClass]="[
                            isViewMode ? 'view-mode' : '',
                            isDirtyEnabled && originalSelectedMailTemplateFrameBody.subject !== selectedMailTemplateFrameBody.subject
                              ? DIRTY_CLASSNAME
                              : 'equalclass'
                          ]"
                        >
                          <input
                            id="form-field-multiline-mail-inputarea"
                            type="text"
                            matInput
                            [(ngModel)]="selectedMailTemplateFrameBody.subject"
                            (ngModelChange)="subjectBodyChanged()"
                            [disabled]="formFieldData.isReadonlyField() || isViewMode"
                          />
                        </mat-form-field>
                        <ng-container *ngIf="!(formFieldData.isReadonlyField() || isViewMode)">
                          <div
                            *ngIf="staticOptions?.subjectTags != null && staticOptions?.subjectTags.length === 1"
                            class="form-field-multi-rich-string-buttons"
                          >
                            <button
                              mat-raised-button
                              color="primary"
                              (click)="onAddSingleTextMailPlaceholder(button)"
                              style="margin: 0 0 0.5rem 0.5rem; min-width: 120px"
                              *ngFor="let button of staticOptions?.subjectTags"
                            >
                              <span>{{ button.value | translate }}</span>
                            </button>
                          </div>
                          <div
                            *ngIf="staticOptions?.subjectTags != null && staticOptions?.subjectTags.length > 1"
                            class="form-field-multi-rich-string-buttons"
                          >
                            <button
                              mat-icon-button
                              color="primary"
                              class="form-field-input-string-button"
                              (click)="isStringInputMenuOpen = !isStringInputMenuOpen"
                              cdkOverlayOrigin
                              #singleStringInput="cdkOverlayOrigin"
                            >
                              <mat-icon>more_vert</mat-icon>
                            </button>

                            <ng-template
                              cdkConnectedOverlay
                              [cdkConnectedOverlayOrigin]="singleStringInput"
                              [cdkConnectedOverlayOpen]="isStringInputMenuOpen"
                              (overlayOutsideClick)="$event.stopPropagation(); isStringInputMenuOpen = false"
                            >
                              <div class="form-field-input-string-overlay">
                                <button
                                  *ngFor="let button of staticOptions?.subjectTags"
                                  mat-raised-button
                                  color="primary"
                                  style="margin-bottom: 0.5rem; min-width: 120px"
                                  (click)="onAddSingleTextMailPlaceholder(button)"
                                >
                                  <span>{{ button.value }}</span>
                                </button>
                              </div>
                            </ng-template>
                          </div>
                        </ng-container>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="mailtemplate-editor-row">
                <div class="base-form-field form-field-inputfield multiline">
                  <div class="label-container">
                    <span class="label">{{ 'mailbody_body' | translate }}</span>
                    <span class="requiredField">*</span>
                  </div>
                  <div class="value-container">
                    <span class="value">
                      <div class="form-field-string-input-container">
                        <ckeditor
                          #ckeditor
                          class="form-field-ck-editor"
                          [ngClass]="[
                            formFieldData.isReadonlyField() || isViewMode ? 'viewmode' : 'editmode',
                            isDirtyEnabled && originalSelectedMailTemplateFrameBody.body !== selectedMailTemplateFrameBody.body
                              ? DIRTY_CLASSNAME
                              : ''
                          ]"
                          [editor]="Editor"
                          (ready)="onCkeditorMailReady()"
                          [(ngModel)]="selectedMailTemplateFrameBody.body"
                          (ngModelChange)="subjectBodyChanged()"
                          [disabled]="formFieldData.isReadonlyField() || isViewMode"
                          [config]="config"
                        ></ckeditor>
                        <ng-container *ngIf="!(formFieldData.isReadonlyField() || isViewMode)">
                          <div
                            *ngIf="staticOptions?.bodyTags != null && staticOptions?.bodyTags.length > 0"
                            class="form-field-multi-rich-string-buttons"
                          >
                            <button
                              mat-raised-button
                              color="primary"
                              (click)="onAddCkeditorTextMailPlaceholder(button)"
                              style="margin: 0 0 0.5rem 0.5rem; min-width: 120px"
                              *ngFor="let button of staticOptions?.bodyTags"
                            >
                              <span>{{ button.value | translate }}</span>
                            </button>
                          </div>
                        </ng-container>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <span class="truncate-text" *ngSwitchDefault showIfTruncated [matTooltip]="formFieldData.formfieldEntityText">
          {{ formFieldData.formfieldEntityText }}
        </span>
      </span>
    </span>
  </div>
</div>
