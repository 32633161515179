import { Action, createReducer, on } from '@ngrx/store';
import { RestrictedAuthStoreAction } from '.';
import { State, initialState } from './state';

export const restrictedAuthFeatureKey = 'restrictedAuth';

const authReducer = createReducer(
  initialState,
  // authentication
  on(RestrictedAuthStoreAction.restrictedAutoAuthenticate, (state) => ({
    ...state,
    isRestrictedAuthenticated: false,
    errorKey: null,
    errorMessage: null,
    authUser: null,
  })),
  on(RestrictedAuthStoreAction.restrictedAuthSuccess, (state, { result }) => ({
    ...state,
    isRestrictedAuthenticated: true,
    authUser: result.getAuthUser(),
  })),
  on(RestrictedAuthStoreAction.restrictedAuthFailure, (state, { result }) => ({
    ...state,
    isRestrictedAuthenticated: false,
    error: result.error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
