<div class="dialog-header">
  <span>{{ 'label_send_email_for' | translate }} {{ data.entityKind | translate }} {{ data.entityName }}</span>
  <div class="buttons-container">
    <button mat-flat-button color="primary" matDialogClose>
      <span *ngIf="!isViewMail">{{ 'label_cancel' | translate }}</span>
      <span *ngIf="isViewMail">{{ 'label_close' | translate }}</span>
    </button>
    <ng-container *ngIf="!isViewMail">
      <span style="margin-left: 0.5rem"></span>
      <button mat-flat-button color="primary" (click)="$event.stopPropagation(); sendEmail()" [disabled]="!isSendMailValid()">
        <span>{{ 'label_send_email' | translate }}</span>
      </button>
    </ng-container>
  </div>
</div>
<div mat-dialog-content class="generic-send-email-dialog-content">
  <div class="spinner-container" *ngIf="isLoading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <ng-container *ngIf="!isLoading && (mailTemplates || isViewMail)">
    <div class="particulars-form">
      <div class="particulars-row single-line-chips-row">
        <div class="particulars-col fullwidth">
          <div class="label-container">
            <span class="label truncate-text">{{ 'email_tos' | translate }}</span>
            <span class="requiredField" *ngIf="!isViewMail && !mailTemplates[0].emailTosReadonly">*</span>
          </div>
          <span class="control-value chips">
            <mat-form-field
              appearance="outline"
              class="form-field-input reset-outline-hint chips-input single-line"
              [ngClass]="[
                !isViewMail && !mailTemplates[0].emailTosReadonly ? '' : 'view-mode',
                availableToAddresses.length === 0 ? 'invalid-field' : ''
              ]"
              (click)="!isViewMail && !mailTemplates[0].emailTosReadonly ? openToAddressesDialog(tosChipsMultiselectionDialog) : null"
            >
              <!-- -->
              <mat-chip-grid #chipToAddressesList [disabled]="isViewMail || mailTemplates[0].emailTosReadonly">
                <mat-chip-row
                  *ngFor="let item of availableToAddresses"
                  [removable]="!isViewMail && !mailTemplates[0].emailTosReadonly"
                  (removed)="removeToAddressesChip(item)"
                >
                  {{ item.emailaddressFullname }} ({{ item.emailaddressEmail }})
                  <mat-icon matChipRemove *ngIf="!isViewMail && !mailTemplates[0].emailTosReadonly">cancel</mat-icon>
                </mat-chip-row>
                <input [matChipInputFor]="chipToAddressesList" readonly [disabled]="true" />
              </mat-chip-grid>
              <button
                *ngIf="!isViewMail && !mailTemplates[0].emailTosReadonly"
                mat-icon-button
                matSuffix
                class="small-icon"
                (click)="openToAddressesDialog(tosChipsMultiselectionDialog); $event.stopPropagation()"
              >
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </span>
        </div>
      </div>
      <div class="particulars-row single-line-chips-row" *ngIf="!isViewMail || availableCcAddresses?.length > 0">
        <div class="particulars-col fullwidth">
          <div class="label-container">
            <span class="label truncate-text">{{ 'email_ccs' | translate }}</span>
          </div>
          <span class="control-value chips">
            <mat-form-field
              appearance="outline"
              class="form-field-input reset-outline-hint chips-input single-line"
              [ngClass]="[!isViewMail && !mailTemplates[0].emailCcsReadonly ? '' : 'view-mode']"
              (click)="!isViewMail && !mailTemplates[0].emailCcsReadonly ? openCcAddressesDialog(ccsChipsMultiselectionDialog) : null"
            >
              <mat-chip-grid #chipCcAddressesList [disabled]="isViewMail || mailTemplates[0].emailCcsReadonly">
                <mat-chip-row
                  *ngFor="let item of availableCcAddresses"
                  [removable]="!isViewMail && !mailTemplates[0].emailCcsReadonly"
                  (removed)="removeCcAddressesChip(item)"
                >
                  {{ item.emailaddressFullname }} ({{ item.emailaddressEmail }})
                  <mat-icon matChipRemove *ngIf="!isViewMail && !mailTemplates[0].emailCcsReadonly">cancel</mat-icon>
                </mat-chip-row>
                <input [matChipInputFor]="chipCcAddressesList" readonly [disabled]="true" />
              </mat-chip-grid>
              <button
                *ngIf="!isViewMail && !mailTemplates[0].emailCcsReadonly"
                mat-icon-button
                matSuffix
                class="small-icon"
                (click)="openCcAddressesDialog(ccsChipsMultiselectionDialog); $event.stopPropagation()"
              >
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </span>
        </div>
      </div>
      <div class="particulars-row single-line-chips-row" *ngIf="!isViewMail || availableBccAddresses?.length > 0">
        <div class="particulars-col fullwidth">
          <div class="label-container">
            <span class="label truncate-text">{{ 'email_bccs' | translate }}</span>
          </div>
          <span class="control-value chips">
            <mat-form-field
              appearance="outline"
              class="form-field-input reset-outline-hint chips-input single-line"
              [ngClass]="[!isViewMail && !mailTemplates[0].emailBccsReadonly ? '' : 'view-mode']"
              (click)="!isViewMail && !mailTemplates[0].emailBccsReadonly ? openBccAddressesDialog(bccsChipsMultiselectionDialog) : null"
            >
              <mat-chip-grid #chipBccAddressesList [disabled]="isViewMail || mailTemplates[0].emailBccsReadonly">
                <mat-chip-row
                  *ngFor="let item of availableBccAddresses"
                  [removable]="!isViewMail && !mailTemplates[0].emailBccsReadonly"
                  (removed)="removeBccAddressesChip(item)"
                >
                  {{ item.emailaddressFullname }} ({{ item.emailaddressEmail }})
                  <mat-icon matChipRemove *ngIf="!isViewMail && !mailTemplates[0].emailBccsReadonly">cancel</mat-icon>
                </mat-chip-row>
                <input [matChipInputFor]="chipBccAddressesList" readonly [disabled]="true" />
              </mat-chip-grid>
              <button
                *ngIf="!isViewMail && !mailTemplates[0].emailBccsReadonly"
                mat-icon-button
                matSuffix
                class="small-icon"
                (click)="openBccAddressesDialog(bccsChipsMultiselectionDialog); $event.stopPropagation()"
              >
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </span>
        </div>
      </div>
    </div>
    <div class="particulars-form margin-top">
      <div class="particulars-row" *ngIf="!isViewMail">
        <div class="particulars-col w-20">
          <ng-container *ngIf="Languages?.length > 0">
            <div class="label-container">
              <span class="label truncate-text">{{ 'email_language' | translate }}</span>
            </div>
            <span class="control-value">
              <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  #languageSelect
                  [(ngModel)]="selectedLanguage"
                  (selectionChange)="selectedLanguageOptionChanged()"
                >
                  <mat-option class="form-field-option" *ngFor="let key of Languages" [value]="key">
                    {{ languages.get(key) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </ng-container>
        </div>
        <div class="particulars-col w-20">
          <ng-container *ngIf="Stages?.length > 0">
            <div class="label-container">
              <span class="label truncate-text">{{ 'email_stage' | translate }}</span>
            </div>
            <span class="control-value">
              <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  #languageSelect
                  [(ngModel)]="selectedStage"
                  (selectionChange)="selectedStageOptionChanged()"
                >
                  <mat-option class="form-field-option" *ngFor="let key of Stages" [value]="key">
                    {{ stages.get(key) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </ng-container>
        </div>
        <div class="particulars-col w-20"></div>
        <div class="particulars-col w-20">
          <div class="label-container">
            <span class="label truncate-text">{{ 'email_fontfamily' | translate }}</span>
          </div>
          <span class="control-value">
            <mat-form-field appearance="outline" class="form-field-input reset-outline-hint view-mode">
              <input type="text" matInput [value]="selectedTemplate?.emailFontfamily" [disabled]="true" />
            </mat-form-field>
          </span>
        </div>
        <div class="particulars-col w-20">
          <div class="label-container">
            <span class="label truncate-text">{{ 'email_template' | translate }}</span>
          </div>
          <span class="control-value">
            <mat-form-field appearance="outline" class="form-field-input reset-outline-hint view-mode">
              <input type="text" matInput [value]="selectedTemplate?.emailTemplate" [disabled]="true" />
            </mat-form-field>
          </span>
        </div>
      </div>
      <div class="particulars-row">
        <div class="particulars-col fullwidth">
          <div class="label-container">
            <span class="label truncate-text">{{ 'email_subject' | translate }}</span>
            <span class="requiredField" *ngIf="!isViewMail && !selectedTemplate.emailSubjectReadonly">*</span>
          </div>
          <span class="control-value">
            <mat-form-field appearance="outline" class="form-field-input reset-outline-hint view-mode" *ngIf="selectedTemplate == null">
              <input type="text" matInput value="" [disabled]="true" />
            </mat-form-field>
            <ng-container *ngIf="selectedTemplate != null">
              <mat-form-field
                appearance="outline"
                class="form-field-input reset-outline-hint"
                [ngClass]="[
                  isViewMail || selectedTemplate.emailSubjectReadonly ? 'view-mode' : '',
                  selectedTemplate.emailSubject == null || selectedTemplate.emailSubject === '' || !isRequiredSubjectTagsValid()
                    ? 'invalid-field'
                    : ''
                ]"
              >
                <input
                  id="form-field-multiline-mail-inputarea"
                  type="text"
                  matInput
                  [(ngModel)]="selectedTemplate.emailSubject"
                  [disabled]="isViewMail || selectedTemplate.emailSubjectReadonly"
                />
              </mat-form-field>
              <div
                *ngIf="selectedTemplate.emailSubjectTags != null && selectedTemplate.emailSubjectTags.length === 1"
                class="form-field-multi-rich-string-buttons"
              >
                <button
                  mat-raised-button
                  [color]="button.addon2 === 't' && !isRequiredSubjectTagSetted(button.addon) ? 'warn' : 'primary'"
                  (click)="onAddSingleTextMailPlaceholder(button)"
                  class="tag-button"
                  *ngFor="let button of selectedTemplate.emailSubjectTags"
                  matTooltip="{{ button.addon | translate }}"
                  [matTooltipClass]="'fo-mat-tooltip'"
                >
                  <span>{{ button.value | translate }}{{ button.addon2 === 't' ? '*' : null }}</span>
                </button>
              </div>
              <div
                *ngIf="selectedTemplate.emailSubjectTags != null && selectedTemplate.emailSubjectTags.length > 1"
                class="form-field-multi-rich-string-buttons"
              >
                <button
                  mat-icon-button
                  color="primary"
                  class="form-field-input-string-button"
                  (click)="isStringInputMenuOpen = !isStringInputMenuOpen"
                  cdkOverlayOrigin
                  #singleStringInput="cdkOverlayOrigin"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <ng-template
                  cdkConnectedOverlay
                  [cdkConnectedOverlayOrigin]="singleStringInput"
                  [cdkConnectedOverlayOpen]="isStringInputMenuOpen"
                  (overlayOutsideClick)="$event.stopPropagation(); isStringInputMenuOpen = false"
                >
                  <div class="form-field-input-string-overlay">
                    <button
                      *ngFor="let button of selectedTemplate.emailSubjectTags"
                      mat-raised-button
                      [color]="button.addon2 === 't' && !isRequiredSubjectTagSetted(button.addon) ? 'warn' : 'primary'"
                      class="tag-button"
                      matTooltip="{{ button.addon | translate }}"
                      [matTooltipClass]="'fo-mat-tooltip'"
                      (click)="onAddSingleTextMailPlaceholder(button)"
                    >
                      <span>{{ button.value }}{{ button.addon2 === 't' ? '*' : null }}</span>
                    </button>
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </span>
        </div>
      </div>
      <div class="particulars-row" *ngIf="!isViewMail">
        <div class="particulars-col fullwidth multiline ckeditor">
          <div class="label-container">
            <span class="label truncate-text">{{ 'email_body' | translate }}</span>
            <span class="requiredField" *ngIf="!isViewMail && !selectedTemplate.emailBodyReadonly">*</span>
          </div>
          <span class="control-value">
            <ckeditor
              #ckeditor
              class="form-field-ck-editor"
              [ngClass]="[
                isViewMail || selectedTemplate.emailBodyReadonly ? 'view-mode' : 'edit-mode',

                selectedTemplate.emailBody == null || selectedTemplate.emailBody === '' || !isRequiredBodyTagValid() ? 'invalid-field' : ''
              ]"
              [editor]="Editor"
              (ready)="onCkeditorMailReady()"
              [(ngModel)]="selectedTemplate.emailBody"
              [disabled]="isViewMail || selectedTemplate.emailBodyReadonly"
              [config]="config"
            ></ckeditor>
            <div
              *ngIf="!isViewMail && selectedTemplate.emailBodyTags != null && selectedTemplate.emailBodyTags.length > 0"
              class="form-field-multi-rich-string-buttons"
            >
              <button
                mat-raised-button
                [color]="button.addon2 === 't' && !isRequiredBodyTagSetted(button.addon) ? 'warn' : 'primary'"
                (click)="onAddCkeditorTextMailPlaceholder(button)"
                class="tag-button"
                matTooltip="{{ button.addon | translate }}"
                [matTooltipClass]="'fo-mat-tooltip'"
                *ngFor="let button of selectedTemplate.emailBodyTags"
              >
                <span>{{ button.value | translate }}{{ button.addon2 === 't' ? '*' : null }}</span>
              </button>
            </div>
          </span>
        </div>
      </div>
      <div class="particulars-row octuple-height" *ngIf="isViewMail">
        <div class="particulars-col fullwidth">
          <div class="label-container">
            <span class="label truncate-text">{{ 'email_body' | translate }}</span>
          </div>
          <span class="control-value mail-preview">
            <div class="mail-preview-container" [innerHTML]="sanitizeHtml(selectedTemplate.emailBody)"></div>
          </span>
        </div>
      </div>
      <div class="particulars-row single-line-chips-row">
        <div class="particulars-col fullwidth">
          <div class="label-container">
            <span class="label truncate-text">{{ 'email_attachments' | translate }}</span>
          </div>
          <span class="control-value chips">
            <mat-form-field
              appearance="outline"
              class="form-field-input reset-outline-hint chips-input single-line"
              [ngClass]="[!isViewMail && !selectedTemplate.emailAttachmentsReadonly ? '' : 'view-mode', isViewMail ? 'download' : '']"
            >
              <mat-chip-grid #chipEmailToList [disabled]="selectedTemplate.emailAttachmentsReadonly">
                <mat-chip-row
                  *ngFor="let item of availableFiles"
                  [removable]="!selectedTemplate.emailAttachmentsReadonly"
                  (removed)="removeFile(item)"
                  (click)="isViewMail ? downloadFile(item) : null"
                >
                  <ng-container *ngIf="isViewMail">
                    <a href="javascript:void(0)" (click)="$event.stopPropagation(); downloadFile(item)">{{ item.fileName }}</a>
                  </ng-container>
                  <ng-container *ngIf="!isViewMail">
                    {{ item.fileName }}
                  </ng-container>
                  <mat-icon matChipRemove *ngIf="!selectedTemplate.emailAttachmentsReadonly">cancel</mat-icon>
                </mat-chip-row>
                <input [matChipInputFor]="chipEmailToList" readonly [disabled]="true" />
              </mat-chip-grid>
              <button
                *ngIf="!selectedTemplate.emailAttachmentsReadonly"
                mat-icon-button
                matSuffix
                class="small-icon"
                (click)="fileInput.click()"
              >
                <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" multiple />
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template id="chips-multiselection-dialog" #tosChipsMultiselectionDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div class="dialog-header">
    <span>{{ 'generic_chips_multiselection_dialog_title' | translate }}</span>
    <div class="buttons-container">
      <button mat-raised-button color="primary" class="edit-mode-button" (click)="closeToAddressesDialog()">
        <mat-icon>cancel</mat-icon>
        <span>{{ 'label_cancel' | translate }}</span>
      </button>
      <span style="margin-left: 0.5rem"></span>
      <button mat-raised-button color="primary" (click)="$event.stopPropagation(); saveToAddressesSelection()">
        <mat-icon>check</mat-icon>
        <span>{{ 'label_apply' | translate }}</span>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="chips-multiselection-dialog-content">
    <app-generic-multigrid-multiselection
      [selectedDataSubj]="currentToAddressesSelectionSubj.asObservable()"
      [entityKind]="Entities.CONTACT"
      (addToSelectionEvEm)="addToToAddressesSelection($event)"
      (removeFromSelectionEvEm)="removeFromToAddressesSelection($event)"
      (clearSelectionEvEm)="clearToAddressesSelection()"
    ></app-generic-multigrid-multiselection>
  </div>
</ng-template>

<ng-template id="chips-multiselection-dialog" #ccsChipsMultiselectionDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div class="dialog-header">
    <span>{{ 'generic_chips_multiselection_dialog_title' | translate }}</span>
    <div class="buttons-container">
      <button mat-raised-button color="primary" class="edit-mode-button" (click)="closeCcAddressesDialog()">
        <mat-icon>cancel</mat-icon>
        <span>{{ 'label_cancel' | translate }}</span>
      </button>
      <span style="margin-left: 0.5rem"></span>
      <button mat-raised-button color="primary" (click)="$event.stopPropagation(); saveCcAddressesSelection()">
        <mat-icon>check</mat-icon>
        <span>{{ 'label_apply' | translate }}</span>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="chips-multiselection-dialog-content">
    <app-generic-multigrid-multiselection
      [selectedDataSubj]="currentCcAddressesSelectionSubj.asObservable()"
      [entityKind]="Entities.CONTACT"
      (addToSelectionEvEm)="addToCcAddressesSelection($event)"
      (removeFromSelectionEvEm)="removeFromCcAddressesSelection($event)"
      (clearSelectionEvEm)="clearCcAddressesSelection()"
    ></app-generic-multigrid-multiselection>
  </div>
</ng-template>

<ng-template id="chips-multiselection-dialog" #bccsChipsMultiselectionDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div class="dialog-header">
    <span>{{ 'generic_chips_multiselection_dialog_title' | translate }}</span>
    <div class="buttons-container">
      <button mat-raised-button color="primary" class="edit-mode-button" (click)="closeBccAddressesDialog()">
        <mat-icon>cancel</mat-icon>
        <span>{{ 'label_cancel' | translate }}</span>
      </button>
      <span style="margin-left: 0.5rem"></span>
      <button mat-raised-button color="primary" (click)="$event.stopPropagation(); saveBccAddressesSelection()">
        <mat-icon>check</mat-icon>
        <span>{{ 'label_apply' | translate }}</span>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="chips-multiselection-dialog-content">
    <app-generic-multigrid-multiselection
      [selectedDataSubj]="currentBccAddressesSelectionSubj.asObservable()"
      [entityKind]="Entities.CONTACT"
      (addToSelectionEvEm)="addToBccAddressesSelection($event)"
      (removeFromSelectionEvEm)="removeFromBccAddressesSelection($event)"
      (clearSelectionEvEm)="clearBccAddressesSelection()"
    ></app-generic-multigrid-multiselection>
  </div>
</ng-template>
