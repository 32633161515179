import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { LicenseAssetPeriodAllocation, LicenseAssetPeriodAllocationRequest } from 'src/app/models/asset-allocation';
import { MoveSharedAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-shared-asset-wizard/init-step/init-step';
import { MoveSharedAssetMovingToStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-shared-asset-wizard/move-shared-asset-movingto-step/move-shared-asset-movingto-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Move Shared Asset Information Step',
  props<{ initStep: MoveSharedAssetInitStep }>()
);

export const selectMovingToStep = createAction(
  '[MoveSharedAsset Wizard Page] Move Shared Asset Wizard - Moving to Step',
  props<{ movingTo: MoveSharedAssetMovingToStep }>()
);

export const selectAllocationUpdateStep = createAction(
  '[MoveSharedAsset Wizard Page] Move Shared Asset Wizard - Allocation Update Step',
  props<{ allocationUpdate: LicenseAssetPeriodAllocation[] }>()
);

export const deleteAllocationUpdateStep = createAction('[MoveSharedAsset Wizard Page] Delete Allocation Update Step');

export const exitMoveSharedAssetWizard = createAction('[MoveSharedAsset Wizard Page] Exit Move Shared Asset Wizard');

export const destroyWizard = createAction('[MoveSharedAsset Wizard Page] Destroy Wizard');

export const createNewMoveSharedAsset = createAction(
  '[Wizard Confirmation Step] Create Move Shared Asset Information Step',
  props<{ request: LicenseAssetPeriodAllocationRequest[] }>()
);

export const createNewMoveSharedAssetSuccess = createAction(
  '[Wizard Confirmation Step] Create Move Shared Asset Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewMoveSharedAssetFail = createAction(
  '[Wizard Confirmation Step] Create Move Shared Asset Information Step Fail',
  props<{ errorMessage: any }>()
);
