<div class="base-form-field form-field-mailtemplate" *ngIf="!isLoading">
  <div class="mailtemplate-row">
    <div class="base-form-field form-field-inputfield onethird">
      <div class="label-container">
        <span class="label">{{ 'mailbody_languageid' | translate }}</span>
      </div>
      <div class="value-container">
        <span class="value">
          <mat-form-field appearance="outline" class="form-field-input reset-outline-hint" [ngClass]="[isViewMode ? 'view-mode' : '']">
            <mat-select
              disableOptionCentering
              panelClass="finoffice-matselect-panel"
              #languageSelect
              [(ngModel)]="selectedLanguage"
              (selectionChange)="selectedLanguageOptionChanged()"
              [disabled]="formFieldData.isReadonlyField() || isViewMode"
            >
              <mat-option class="form-field-option" *ngFor="let language of languageOptions" [value]="language">
                {{ language.entityName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
    </div>
    <div class="base-form-field form-field-inputfield onethird">
      <div class="label-container">
        <span class="label">{{ 'mailbody_stage' | translate }}</span>
      </div>
      <div class="value-container">
        <span class="value">
          <mat-form-field appearance="outline" class="form-field-input reset-outline-hint" [ngClass]="[isViewMode ? 'view-mode' : '']">
            <mat-select
              disableOptionCentering
              panelClass="finoffice-matselect-panel"
              #stageSelect
              [(ngModel)]="selectedStage"
              (selectionChange)="selectedStageOptionChanged()"
              [disabled]="formFieldData.isReadonlyField() || isViewMode"
            >
              <mat-option class="form-field-option" *ngFor="let stage of stageOptions" [value]="stage">
                {{ stage.entityName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="mailTemplateItem">
    <div class="mailtemplate-row">
      <div class="base-form-field form-field-inputfield">
        <div class="label-container">
          <span class="label">{{ 'mailbody_subject' | translate }}</span>
          <span class="requiredField">*</span>
        </div>
        <div class="value-container">
          <span class="value">
            <div class="form-field-string-input-container">
              <mat-form-field appearance="outline" class="form-field-input reset-outline-hint" [ngClass]="[isViewMode ? 'view-mode' : '']">
                <input
                  id="form-field-multiline-inputarea"
                  type="text"
                  matInput
                  [(ngModel)]="mailTemplateItem.mailtemplateitemSubject"
                  [disabled]="formFieldData.isReadonlyField() || isViewMode"
                />
              </mat-form-field>
              <ng-container *ngIf="!(formFieldData.isReadonlyField() || isViewMode)">
                <div
                  *ngIf="mailTemplateItem.mailtemplateitemSubjectTags != null && mailTemplateItem.mailtemplateitemSubjectTags.length === 1"
                  class="form-field-multi-rich-string-buttons"
                >
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="onAddSingleTextPlaceholder(button)"
                    style="margin: 0 0 0.5rem 0.5rem; min-width: 120px"
                    *ngFor="let button of mailTemplateItem.mailtemplateitemSubjectTags"
                  >
                    <span>{{ button.value | translate }}</span>
                  </button>
                </div>
                <div
                  *ngIf="mailTemplateItem.mailtemplateitemSubjectTags != null && mailTemplateItem.mailtemplateitemSubjectTags.length > 1"
                  class="form-field-multi-rich-string-buttons"
                >
                  <button
                    mat-icon-button
                    color="primary"
                    class="form-field-input-string-button"
                    (click)="isStringInputMenuOpen = !isStringInputMenuOpen"
                    cdkOverlayOrigin
                    #singleStringInput="cdkOverlayOrigin"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <ng-template
                    cdkConnectedOverlay
                    [cdkConnectedOverlayOrigin]="singleStringInput"
                    [cdkConnectedOverlayOpen]="isStringInputMenuOpen"
                    (overlayOutsideClick)="$event.stopPropagation(); isStringInputMenuOpen = false"
                  >
                    <div class="form-field-input-string-overlay">
                      <button
                        *ngFor="let button of mailTemplateItem.mailtemplateitemSubjectTags"
                        mat-raised-button
                        color="primary"
                        style="margin-bottom: 0.5rem; min-width: 120px"
                        (click)="onAddSingleTextPlaceholder(button)"
                      >
                        <span>{{ button.value }}</span>
                      </button>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="mailtemplate-editor-row">
      <div class="base-form-field form-field-inputfield multiline">
        <div class="label-container">
          <span class="label">{{ 'mailbody_body' | translate }}</span>
          <span class="requiredField">*</span>
        </div>
        <div class="value-container">
          <span class="value">
            <div class="form-field-string-input-container">
              <ckeditor
                #ckeditor
                class="form-field-ck-editor"
                [ngClass]="formFieldData.isReadonlyField() || isViewMode ? 'viewmode' : 'editmode'"
                [editor]="Editor"
                (ready)="onCkeditorReady()"
                [(ngModel)]="mailTemplateItem.mailtemplateitemBody"
                [disabled]="formFieldData.isReadonlyField() || isViewMode"
                [config]="config"
              ></ckeditor>
              <ng-container *ngIf="!(formFieldData.isReadonlyField() || isViewMode)">
                <div
                  *ngIf="mailTemplateItem.mailtemplateitemBodyTags != null && mailTemplateItem.mailtemplateitemBodyTags.length > 0"
                  class="form-field-multi-rich-string-buttons"
                >
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="onAddCkeditorTextPlaceholder(button)"
                    style="margin: 0 0 0.5rem 0.5rem; min-width: 120px"
                    *ngFor="let button of mailTemplateItem.mailtemplateitemBodyTags"
                  >
                    <span>{{ button.value | translate }}</span>
                  </button>
                </div>
              </ng-container>
            </div>
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
