import { createAction, props } from '@ngrx/store';
import { LicenseAssetPeriodAllocationRequest } from 'src/app/models/asset-allocation';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { AddConsumersToAssetConsumerStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-consumers-to-asset-wizard/add-consumers-to-asset-consumers-step/add-consumers-to-asset-consumers.step';
import { AddConsumersToAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-consumers-to-asset-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Wizard Init Step] Select Add Consumers To Asset Init Step',
  props<{ initStep: AddConsumersToAssetInitStep }>()
);

export const selectConsumersStep = createAction(
  '[Wizard Consumerss Step] Select Add Consumers To Asset Consumers Step',
  props<{ consumersStep: AddConsumersToAssetConsumerStep }>()
);

export const deleteConsumersStep = createAction('[Wizard CancelDetails Step] Delete Add Consumers To Asset Consumers Step');

export const exitAddConsumersToAssetWizard = createAction('[AddConsumersToAsset Wizard Page] Exit Add Consumers To Asset Wizard');

export const destroyWizard = createAction('[AddConsumersToAsset Wizard Page] Destroy Wizard');

export const createNewAddConsumersToAsset = createAction(
  '[Wizard Confirmation Step] Create Add Consumers To Asset Information Step',
  props<{ request: LicenseAssetPeriodAllocationRequest[] }>()
);

export const createNewAddConsumersToAssetSuccess = createAction(
  '[Wizard Confirmation Step] Create Add Consumers To Asset Information Step Success',
  props<{ response: EntityWizardResponse }>()
);

export const createNewAddConsumersToAssetFail = createAction(
  '[Wizard Confirmation Step] Create Add Consumers To Asset Information Step Fail',
  props<{ errorMessage: string }>()
);
