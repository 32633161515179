import { LicenseAssetPeriodAllocationRequest } from 'src/app/models/asset-allocation';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { AddConsumersToAssetConsumerStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-consumers-to-asset-wizard/add-consumers-to-asset-consumers-step/add-consumers-to-asset-consumers.step';
import { AddConsumersToAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/add-consumers-to-asset-wizard/init-step/init-step';
export interface State {
  initStep: AddConsumersToAssetInitStep;
  consumersStep: AddConsumersToAssetConsumerStep;
  request: LicenseAssetPeriodAllocationRequest[];
  response: EntityWizardResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  consumersStep: null,
  request: null,
  response: null,
  errorMessage: null,
};
