import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { AssetSubscription } from '../models/asset-subscription';
import { BaseResponse } from '../models/base-response';
import { DeliveryitemParticulars, DeliveryitemParticularsRequest, FeedItem, getFeedItemRequestParams } from '../models/feeditem';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class FeedItemDataService {
  getFeedItems(
    request: PaginatedRequest,
    filterStarts?: Date,
    filterExcludeServiceIds?: number[],
    filterExcludeBillingitemIds?: number[]
  ): Observable<BaseResponse<FeedItem[]>> {
    const m = this.getFeedItems.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = getFeedItemRequestParams(request);
    if (filterStarts != null) {
      params = params.append('filterStarts', moment(filterStarts).utc(true).format('YYYY-MM-DD'));
    }
    if (filterExcludeServiceIds != null && filterExcludeServiceIds.length > 0) {
      params = params.append('filterExcludeServiceIds', filterExcludeServiceIds.join(','));
    }
    if (filterExcludeBillingitemIds != null && filterExcludeBillingitemIds.length > 0) {
      params = params.append('filterExcludeBillingitemIds', filterExcludeBillingitemIds.join(','));
    }
    const retVal = this.http.get<BaseResponse<FeedItem[]>>(path, { params });
    return retVal;
  }

  getFeedItemsSpreadsheet(request: PaginatedRequest, filterStarts?: Date) {
    const m = this.getFeedItemsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = getFeedItemRequestParams(request);
    if (filterStarts != null) {
      params = params.append('filterStarts', moment(filterStarts).utc(true).format('YYYY-MM-DD'));
    }
    return this.http
      .get(path, {
        params: params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  changeSubscription(feeditemId: number, subscribed: boolean): Observable<BaseResponse<ActionResponse>> {
    const m = this.changeSubscription.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS_SUBSCRIPTIONS(feeditemId));
    LogService.info(this, m, LogService.PUT + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    let params = new HttpParams().set('subscribe', subscribed ? 'true' : 'false');
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getDeliveryitemParticulars(deliveryitemId: number): Observable<BaseResponse<DeliveryitemParticulars>> {
    const m = this.getDeliveryitemParticulars.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.DELIVERYITEMS_PARTICULARS_BYID(deliveryitemId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<DeliveryitemParticulars>>(path);
    return retVal;
  }

  putDeliveryitemParticulars(deliveryitemId: number, request: DeliveryitemParticularsRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.putDeliveryitemParticulars.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.DELIVERYITEMS_PARTICULARS_BYID(deliveryitemId));
    LogService.info(this, m, LogService.PUT + path, null);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);
    return retVal;
  }

  getFeeditemAllocations(request: PaginatedRequest, serviceId: number): Observable<BaseResponse<AssetSubscription[]>> {
    const m = this.getFeeditemAllocations.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS_SUBSCRIPTIONS_BYID(serviceId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<AssetSubscription[]>>(path, {
      params,
    });

    return retVal;
  }

  getFeeditemAllocationsSpreadsheet(request: PaginatedRequest, serviceId: number) {
    const m = this.getFeeditemAllocationsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS_SUBSCRIPTIONS_BYID_SPREADSHEET(serviceId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  constructor(private http: HttpClient) {}
}
