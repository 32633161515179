<div class="comments-container">
  <div #commentsContainer class="comments-content" [ngClass]="isEditing ? 'editing-mode' : ''">
    <ng-container *ngIf="comments">
      <div *ngFor="let comment of comments" class="comment" [ngClass]="comment.chatUserId !== AuthUser.uid ? 'other-user' : 'user'">
        <div class="comment-header">
          <span
            >{{ comment.chatUser }} - {{ getDatetime(comment.chatModified)
            }}<ng-container *ngIf="isCommentModified(comment)">
              - <span>{{ 'label_edited' | translate }}</span></ng-container
            >
            <ng-container *ngIf="comment.chatSync">
              &nbsp;<mat-icon
                *ngIf="comment.chatSyncError == null && comment.chatSynced == null"
                fontSet="fas"
                fontIcon="fa-sync-alt"
                matTooltip="{{ 'label_sync_pending' | translate }}"
                [matTooltipClass]="'fo-mat-tooltip'"
                style="color: orange"
              ></mat-icon>
              <mat-icon
                *ngIf="comment.chatSyncError == null && comment.chatSynced != null"
                fontSet="fas"
                fontIcon="fa-sync-alt"
                matTooltip="{{ 'label_synced' | translate }}: {{ getDatetime(comment.chatSynced) }}"
                [matTooltipClass]="'fo-mat-tooltip'"
                style="color: green"
              ></mat-icon>
              <mat-icon
                *ngIf="comment.chatSyncError != null"
                fontSet="fas"
                fontIcon="fa-sync-alt"
                [matTooltip]="comment.chatSyncError"
                [matTooltipClass]="'fo-mat-tooltip'"
                style="color: red"
              ></mat-icon>
              <!-- - <span *ngIf="comment.chatSynced == null">{{ 'label_sync_pending' | translate }}</span>
              <span *ngIf="comment.chatSynced != null">{{ 'label_synced' | translate }}: {{ getDatetime(comment.chatSynced) }}</span> -->
            </ng-container>
          </span>
          <ng-container *ngIf="isEditing && comment.chatUserId === AuthUser.uid && (comment.entityUpdate || comment.entityDelete)">
            <div class="comment-header-buttons">
              <button mat-icon-button *ngIf="comment.entityUpdate" (click)="$event.stopPropagation(); editComment(comment)">
                <mat-icon fontSet="fas" fontIcon="fa-pencil-alt"></mat-icon>
              </button>
              <button mat-icon-button *ngIf="comment.entityDelete" (click)="$event.stopPropagation(); removeSingleRecords(comment)">
                <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
              </button>
            </div>
          </ng-container>
        </div>
        <span class="comment-text">{{ comment.chatMessage }}</span>
      </div>
    </ng-container>

    <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
      <mat-toolbar-row *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="50"></mat-spinner>
      </mat-toolbar-row>
      <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
        <span>{{ 'generic_chat_error_nodata' | translate }}</span>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
  <div class="comment-input-container" *ngIf="isEditing">
    <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
      <textarea
        id="form-field-multiline-textarea"
        type="text"
        matInput
        [(ngModel)]="inputComment"
        cdkTextareaAutosize
        placeholder="{{ 'label_comment_placeholder' | translate }}"
      ></textarea>
      <button
        mat-icon-button
        (click)="$event.stopPropagation(); sendComment()"
        matSuffix
        [disabled]="inputComment == null || inputComment == ''"
      >
        <mat-icon fontSet="fas" fontIcon="fa-play"></mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>

<div
  *ngIf="selectedEntity?.entityUpdate"
  id="generic-comments-overlay-button"
  #flyingButtons
  cdkDragBoundary=".main-content-container"
  (cdkDragEnded)="flyingButtonsDragEnd($event)"
  (cdkDragStarted)="flyingBtnDragging = true"
  [style.pointer-events]="flyingBtnDragging ? 'none' : null"
  class="flying-button-overlay-button"
  cdkDrag
>
  <ng-container *ngIf="!isEditing">
    <button mat-icon-button (click)="goEditMode()" matTooltip="{{ 'label_edit' | translate }}" [matTooltipClass]="'fo-mat-tooltip'">
      <mat-icon fontSet="fas" fontIcon="fa-edit"></mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="isEditing">
    <button mat-icon-button (click)="goViewMode()" matTooltip="{{ 'label_back' | translate }}" [matTooltipClass]="'fo-mat-tooltip'">
      <mat-icon fontSet="fas" fontIcon="fa-long-arrow-alt-left"></mat-icon>
    </button>
  </ng-container>
</div>
