<div class="base-form-field form-field-stepcontrol">
  <div class="label-container" *ngIf="formFieldData.formfieldLabelShow">
    <ng-container *ngIf="formFieldData.formfieldLabelDisplay">
      <span class="label" showIfTruncated matTooltip="{{ formFieldData.formfieldTextname | translate }}"
        >{{ formFieldData.formfieldTextname | translate }}
      </span>
      <ng-container *ngIf="!isViewMode">
        <span
          *ngIf="formFieldData.formfieldRequired === GenericFormFieldRequiredType.required && !formFieldData.isReadonlyField()"
          [ngClass]="formFieldData.isStepControlValid ? 'requiredField' : 'requiredFieldInvalid'"
          >*</span
        >
        <span
          *ngIf="
            formFieldData.formfieldRequired === GenericFormFieldRequiredType.requiredSoonAsPossible && !formFieldData.isReadonlyField()
          "
          class="requiredSoonAsPossibileField"
          >*</span
        >
      </ng-container>
      <span
        class="tooltip"
        *ngIf="formFieldData.formfieldInfo"
        matTooltip="{{ parseEntityTooltip('\n', '&emsp;', formFieldData.formfieldInfo | translate) }}"
        [matTooltipClass]="'fo-mat-tooltip tree-tooltip'"
        ><mat-icon class="entityTooltipIcon">info</mat-icon></span
      ></ng-container
    >
    <ng-container *ngIf="!formFieldData.formfieldLabelDisplay">
      <span class="label">&nbsp;</span>
    </ng-container>
  </div>
  <div class="value-container">
    <span class="value">
      <app-step-value-control
        [isEditing]="formFieldData.isReadonlyField() || isViewMode ? false : true"
        [isSavePressed]="isSavePressed"
        [disableShowDirty]="!isDirtyEnabled"
        [stepValues]="formFieldData.formfieldEntitySteps"
        (changeValueEvEm)="formFieldData.formfieldEntitySteps = $event; changeValueEvEm.emit()"
        (isValidEvEm)="setValid($event)"
        (loseFocusEvEm)="loseFocusEvEm.emit()"
      ></app-step-value-control>
    </span>
  </div>
</div>
