<div class="wizard-communication-step">
  <div class="particulars-form">
    <div class="particulars-row no-label">
      <div class="particulars-col fullwidth">
        <div class="label-container">
          <!-- <span class="particulars-form-title">{{ 'contract_issuer_referencer' | translate }}</span> -->
          <span class="particulars-form-title">{{ 'mailbody_mailcontent' | translate }}</span>
          <ng-container *ngIf="mailTemplateFrame != null && !stepDisabled && !isValid()"
            >&nbsp;-&nbsp;<span class="hint truncate-text" [ngClass]="isSavePressed ? 'error' : ''"
              >{{ 'mailbody_missing_stage_configured' | translate }} {{ invalidStageNames() }}</span
            >
            <ng-container *ngIf="!isSelectedStageValid()">
              <ng-container *ngIf="isMissingRequiredField()">
                &nbsp;-&nbsp;<span class="hint truncate-text" [ngClass]="isSavePressed ? 'error' : ''">{{
                  'mailbody_stage_language_missing_required_field' | translate
                }}</span>
              </ng-container>
              <ng-container *ngIf="isMissingRequiredTag()">
                &nbsp;-&nbsp;<span class="hint truncate-text" [ngClass]="isSavePressed ? 'error' : ''">{{
                  'mailbody_stage_language_missing_required_tag' | translate
                }}</span>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="particulars-row">
      <div class="particulars-col w-onethird">
        <div class="label-container">
          <span class="label truncate-text">{{ 'mailbody_languageid' | translate }}</span>
        </div>
        <span class="control-value">
          <mat-form-field appearance="outline" class="form-field-input reset-outline-hint" [ngClass]="[!isEditMode ? 'view-mode' : '']">
            <mat-select
              disableOptionCentering
              panelClass="finoffice-matselect-panel"
              #languageSelect
              [(ngModel)]="selectedLanguage"
              (selectionChange)="selectedLanguageOptionChanged()"
              [disabled]="stepDisabled || !isSelectedStageValid()"
            >
              <mat-option class="form-field-option" *ngFor="let language of staticOptions?.languages" [value]="language">
                {{ language.entityName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
      <div class="particulars-col w-onethird">
        <div class="label-container">
          <span class="label truncate-text">{{ 'mailbody_stage' | translate }}</span>
        </div>
        <span class="control-value">
          <mat-form-field appearance="outline" class="form-field-input reset-outline-hint" [ngClass]="[!isEditMode ? 'view-mode' : '']">
            <mat-select
              disableOptionCentering
              panelClass="finoffice-matselect-panel"
              #stageSelect
              [(ngModel)]="selectedStage"
              (selectionChange)="selectedStageOptionChanged()"
              [disabled]="stepDisabled || !isSelectedStageValid()"
            >
              <mat-option class="form-field-option" *ngFor="let stage of filteredStages" [value]="stage">
                {{ stage.entityName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
      <div class="particulars-col w-onethird"></div>
      <div class="particulars-col w-onethird">
        <div class="label-container">
          <span class="label truncate-text">{{ 'mailbody_fontfamily' | translate }}</span>
        </div>
        <span class="control-value">
          <mat-form-field appearance="outline" class="form-field-input reset-outline-hint" [ngClass]="[!isEditMode ? 'view-mode' : '']">
            <mat-select
              disableOptionCentering
              panelClass="finoffice-matselect-panel"
              #templateSelect
              [(ngModel)]="selectedFontfamily"
              (selectionChange)="selectedFontfamilyOptionChanged()"
              [disabled]="stepDisabled || !isEditMode"
            >
              <mat-option class="form-field-option" *ngFor="let fontfamily of staticOptions?.fontfamilies" [value]="fontfamily">
                {{ fontfamily.entityName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
      <div class="particulars-col w-onethird">
        <div class="label-container">
          <span class="label truncate-text">{{ 'mailbody_template' | translate }}</span>
        </div>
        <span class="control-value">
          <mat-form-field appearance="outline" class="form-field-input reset-outline-hint" [ngClass]="[!isEditMode ? 'view-mode' : '']">
            <mat-select
              disableOptionCentering
              panelClass="finoffice-matselect-panel"
              #templateSelect
              [(ngModel)]="selectedTemplate"
              (selectionChange)="selectedTemplateOptionChanged()"
              [disabled]="stepDisabled || !isEditMode"
            >
              <mat-option class="form-field-option" *ngFor="let template of staticOptions?.templates" [value]="template">
                {{ template.entityName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
      <div class="particulars-col w-onethird">
        <div class="label-container">
          <span class="label truncate-text">{{ 'mailbody_outgoing_account' | translate }}</span>
        </div>
        <span class="control-value">
          <mat-form-field appearance="outline" class="form-field-input reset-outline-hint" [ngClass]="[!isEditMode ? 'view-mode' : '']">
            <mat-select
              disableOptionCentering
              panelClass="finoffice-matselect-panel"
              #templateSelect
              [(ngModel)]="selectedOutgoingAccount"
              (selectionChange)="selectedOutgoingAccountOptionChanged()"
              [disabled]="stepDisabled || !isEditMode"
            >
              <mat-option class="form-field-option" *ngFor="let account of staticOptions?.mailoutgoingaccounts" [value]="account">
                {{ account.entityName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
    </div>
    <ng-container *ngIf="selectedMailTemplateFrameBody">
      <div class="particulars-row single-line-chips-row" *ngIf="isTosEnabled">
        <div class="particulars-col">
          <div class="label-container">
            <span class="label truncate-text">{{ 'mailbody_tos' | translate }}</span>
            <span class="requiredField" *ngIf="isEditMode && selectedMailTemplateFrameBody.prohibitadditionaltos">*</span>
          </div>
          <span class="control-value chips">
            <mat-form-field
              appearance="outline"
              class="form-field-input reset-outline-hint chips-input single-line"
              [ngClass]="[isEditMode ? '' : 'view-mode']"
              (click)="isEditMode ? openToAddressesDialog(tosChipsMultiselectionDialog) : null"
            >
              <!--
                 -->
              <mat-chip-grid #chipToAddressesList [disabled]="!isEditMode">
                <mat-chip-row *ngFor="let item of availableToAddresses" [removable]="isEditMode" (removed)="removeToAddressesChip(item)">
                  {{ item.emailaddressFullname }} ({{ item.emailaddressEmail }})
                  <mat-icon matChipRemove *ngIf="isEditMode">cancel</mat-icon>
                </mat-chip-row>
                <input [matChipInputFor]="chipToAddressesList" readonly [disabled]="true" />
              </mat-chip-grid>
              <button
                *ngIf="isEditMode"
                mat-icon-button
                matSuffix
                class="small-icon"
                (click)="openToAddressesDialog(tosChipsMultiselectionDialog); $event.stopPropagation()"
              >
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </span>
        </div>
        <div class="particulars-col w-25">
          <div class="label-container">
            <span class="label truncate-text">{{ 'mailbody_prohibitadditionaltos' | translate }}</span>
          </div>
          <span class="control-value">
            <mat-slide-toggle
              color="primary"
              [(ngModel)]="selectedMailTemplateFrameBody.prohibitadditionaltos"
              [disabled]="!isEditMode"
            ></mat-slide-toggle>
          </span>
        </div>
        <div class="particulars-col w-25" *ngIf="isCopyToEnabled">
          <div class="label-container">
            <span class="label truncate-text">{{ 'mailbody_copyto' | translate }}</span>
          </div>
          <span class="control-value">
            <mat-form-field appearance="outline" class="form-field-input reset-outline-hint" [ngClass]="[!isEditMode ? 'view-mode' : '']">
              <mat-select
                disableOptionCentering
                panelClass="finoffice-matselect-panel"
                #languageSelect
                [(ngModel)]="selectedMailTemplateFrameBody.copytoId"
                [disabled]="!isEditMode"
              >
                <mat-option class="form-field-option" *ngFor="let opt of staticOptions?.copyToOptions" [value]="opt.typeId">
                  {{ opt.typeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        </div>
      </div>
      <div class="particulars-row single-line-chips-row" *ngIf="isCcBccsEnabled">
        <div class="particulars-col">
          <div class="label-container">
            <span class="label truncate-text">{{ 'mailbody_ccs' | translate }}</span>
          </div>
          <span class="control-value chips">
            <mat-form-field
              appearance="outline"
              class="form-field-input reset-outline-hint chips-input single-line"
              [ngClass]="[isEditMode ? '' : 'view-mode']"
              (click)="isEditMode ? openCcAddressesDialog(ccsChipsMultiselectionDialog) : null"
            >
              <!--
                 -->
              <mat-chip-grid #chipCcAddressesList [disabled]="!isEditMode">
                <mat-chip-row *ngFor="let item of availableCcAddresses" [removable]="isEditMode" (removed)="removeCcAddressesChip(item)">
                  {{ item.emailaddressFullname }} ({{ item.emailaddressEmail }})
                  <mat-icon matChipRemove *ngIf="isEditMode">cancel</mat-icon>
                </mat-chip-row>
                <input [matChipInputFor]="chipCcAddressesList" readonly [disabled]="true" />
              </mat-chip-grid>
              <button
                *ngIf="isEditMode"
                mat-icon-button
                matSuffix
                class="small-icon"
                (click)="openCcAddressesDialog(ccsChipsMultiselectionDialog); $event.stopPropagation()"
              >
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </span>
        </div>
        <div class="particulars-col">
          <div class="label-container">
            <span class="label truncate-text">{{ 'mailbody_bccs' | translate }}</span>
          </div>
          <span class="control-value chips">
            <mat-form-field
              appearance="outline"
              class="form-field-input reset-outline-hint chips-input single-line"
              [ngClass]="[isEditMode ? '' : 'view-mode']"
              (click)="isEditMode ? openBccAddressesDialog(bccsChipsMultiselectionDialog) : null"
            >
              <!--
                 -->
              <mat-chip-grid #chipBccAddressesList [disabled]="!isEditMode">
                <mat-chip-row *ngFor="let item of availableBccAddresses" [removable]="isEditMode" (removed)="removeBccAddressesChip(item)">
                  {{ item.emailaddressFullname }} ({{ item.emailaddressEmail }})
                  <mat-icon matChipRemove *ngIf="isEditMode">cancel</mat-icon>
                </mat-chip-row>
                <input [matChipInputFor]="chipBccAddressesList" readonly [disabled]="true" />
              </mat-chip-grid>
              <button
                *ngIf="isEditMode"
                mat-icon-button
                matSuffix
                class="small-icon"
                (click)="openBccAddressesDialog(bccsChipsMultiselectionDialog); $event.stopPropagation()"
              >
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </span>
        </div>
      </div>
      <div class="particulars-row">
        <div class="particulars-col fullwidth">
          <div class="label-container">
            <span class="label truncate-text">{{ 'mailbody_subject' | translate }}</span>
            <span class="requiredField" *ngIf="isEditMode">*</span>
          </div>
          <span class="control-value">
            <div class="form-field-string-input-container">
              <mat-form-field
                appearance="outline"
                class="form-field-input reset-outline-hint"
                [ngClass]="[
                  !isEditMode ? 'view-mode' : '',
                  isDirtyEnabled && originalSelectedMailTemplateFrameBody.subject !== selectedMailTemplateFrameBody.subject
                    ? DIRTY_CLASSNAME
                    : 'equalclass',
                  isFilledBody(selectedMailTemplateFrameBody) &&
                  (selectedMailTemplateFrameBody.subject == null ||
                    selectedMailTemplateFrameBody.subject === '' ||
                    !isRequiredSubjectTagsValid(selectedMailTemplateFrameBody))
                    ? 'invalid-field'
                    : ''
                ]"
              >
                <input
                  id="form-field-multiline-mail-inputarea"
                  type="text"
                  matInput
                  [(ngModel)]="selectedMailTemplateFrameBody.subject"
                  (ngModelChange)="subjectBodyChanged()"
                  [disabled]="stepDisabled || !isEditMode"
                />
              </mat-form-field>
              <ng-container *ngIf="isEditMode">
                <div
                  *ngIf="staticOptions?.subjectTags != null && staticOptions?.subjectTags.length === 1"
                  class="form-field-multi-rich-string-buttons"
                >
                  <button
                    mat-raised-button
                    [color]="isSavePressed && button.addon2 === 't' && !isRequiredSubjectTagSetted(button.key) ? 'warn' : 'primary'"
                    (click)="onAddSingleTextMailPlaceholder(button)"
                    style="margin: 0 0 0.5rem 0.5rem; min-width: 120px"
                    *ngFor="let button of staticOptions?.subjectTags"
                    matTooltip="{{ button.addon | translate }}"
                    [matTooltipClass]="'fo-mat-tooltip'"
                  >
                    <span>{{ button.value | translate }}{{ button.addon2 === 't' ? '*' : null }}</span>
                  </button>
                </div>
                <div
                  *ngIf="staticOptions?.subjectTags != null && staticOptions?.subjectTags.length > 1"
                  class="form-field-multi-rich-string-buttons"
                >
                  <button
                    mat-icon-button
                    color="primary"
                    class="form-field-input-string-button"
                    (click)="isStringInputMenuOpen = !isStringInputMenuOpen"
                    cdkOverlayOrigin
                    #singleStringInput="cdkOverlayOrigin"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <ng-template
                    cdkConnectedOverlay
                    [cdkConnectedOverlayOrigin]="singleStringInput"
                    [cdkConnectedOverlayOpen]="isStringInputMenuOpen"
                    (overlayOutsideClick)="$event.stopPropagation(); isStringInputMenuOpen = false"
                  >
                    <div class="form-field-input-string-overlay">
                      <button
                        *ngFor="let button of staticOptions?.subjectTags"
                        mat-raised-button
                        [color]="isSavePressed && button.addon2 === 't' && !isRequiredSubjectTagSetted(button.key) ? 'warn' : 'primary'"
                        style="margin-bottom: 0.5rem; min-width: 120px"
                        matTooltip="{{ button.addon | translate }}"
                        [matTooltipClass]="'fo-mat-tooltip'"
                        (click)="onAddSingleTextMailPlaceholder(button)"
                      >
                        <span>{{ button.value }}{{ button.addon2 === 't' ? '*' : null }}</span>
                      </button>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </span>
        </div>
      </div>
      <div
        class="particulars-row mailtemplate-editor-row"
        [ngClass]="isTosEnabled && isCcBccsEnabled ? 'with-two-extra-rows' : isTosEnabled || isCcBccsEnabled ? 'with-one-extra-rows' : ''"
      >
        <div class="particulars-col fullwidth multiline ckeditor">
          <div class="label-container">
            <span class="label truncate-text">{{ 'mailbody_body' | translate }}</span>
            <span class="requiredField" *ngIf="isEditMode">*</span>
          </div>
          <span class="control-value">
            <div class="form-field-string-input-container">
              <ckeditor
                #ckeditor
                class="form-field-ck-editor"
                [ngClass]="[
                  !isEditMode ? 'viewmode' : 'editmode',
                  isDirtyEnabled && originalSelectedMailTemplateFrameBody.body !== selectedMailTemplateFrameBody.body
                    ? DIRTY_CLASSNAME
                    : '',
                  isFilledBody(selectedMailTemplateFrameBody) &&
                  (selectedMailTemplateFrameBody.body == null ||
                    selectedMailTemplateFrameBody.body === '' ||
                    !isRequiredBodyTagValid(selectedMailTemplateFrameBody))
                    ? 'invalid-field'
                    : ''
                ]"
                [editor]="Editor"
                (ready)="onCkeditorMailReady()"
                [(ngModel)]="selectedMailTemplateFrameBody.body"
                (ngModelChange)="subjectBodyChanged()"
                [disabled]="stepDisabled || !isEditMode"
                [config]="config"
              ></ckeditor>
              <ng-container *ngIf="isEditMode">
                <div
                  *ngIf="staticOptions?.bodyTags != null && staticOptions?.bodyTags.length > 0"
                  class="form-field-multi-rich-string-buttons"
                >
                  <button
                    mat-raised-button
                    [color]="isSavePressed && button.addon2 === 't' && !isRequiredBodyTagSetted(button.key) ? 'warn' : 'primary'"
                    (click)="onAddCkeditorTextMailPlaceholder(button)"
                    style="margin: 0 0 0.5rem 0.5rem; min-width: 120px"
                    matTooltip="{{ button.addon | translate }}"
                    [matTooltipClass]="'fo-mat-tooltip'"
                    *ngFor="let button of staticOptions?.bodyTags"
                  >
                    <span>{{ button.value | translate }}{{ button.addon2 === 't' ? '*' : null }}</span>
                  </button>
                </div>
              </ng-container>
            </div>
          </span>
        </div>
      </div>
      <div class="particulars-row single-line-chips-row">
        <div class="particulars-col fullwidth">
          <div class="label-container">
            <span class="label truncate-text">{{ 'mailbody_attachments' | translate }}</span>
          </div>
          <span class="control-value chips">
            <mat-form-field
              appearance="outline"
              class="form-field-input reset-outline-hint chips-input single-line"
              [ngClass]="[isEditMode ? '' : 'view-mode']"
            >
              <mat-chip-grid #chipEmailToList [disabled]="!isEditMode">
                <mat-chip-row *ngFor="let item of availableFiles" [removable]="isEditMode" (removed)="removeFile(item)">
                  {{ item.fileName }}
                  <mat-icon matChipRemove *ngIf="isEditMode">cancel</mat-icon>
                </mat-chip-row>
                <input [matChipInputFor]="chipEmailToList" readonly [disabled]="true" />
              </mat-chip-grid>
              <button *ngIf="isEditMode" mat-icon-button matSuffix class="small-icon" (click)="fileInput.click()">
                <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" multiple />
                <!--
              (click)="openEmailToDialog(legalEntitiesChipsMultiselectionDialog); $event.stopPropagation()" -->
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!-- <div class="wizard-communication-step" style="display: none">
  <div class="main label-container">
    <span class="label truncate-text">{{ 'mailbody_mailcontent' | translate }}</span>
    <ng-container *ngIf="mailTemplateFrame != null && !stepDisabled && !isValid()"
      >&nbsp;-&nbsp;<span class="hint truncate-text" [ngClass]="isSavePressed ? 'error' : ''"
        >{{ 'mailbody_missing_stage_configured' | translate }} {{ invalidStageNames() }}</span
      >
      <ng-container *ngIf="!isSelectedStageValid()">
        <ng-container *ngIf="isMissingRequiredField()">
          &nbsp;-&nbsp;<span class="hint truncate-text" [ngClass]="isSavePressed ? 'error' : ''">{{
            'mailbody_stage_language_missing_required_field' | translate
          }}</span>
        </ng-container>
        <ng-container *ngIf="isMissingRequiredTag()">
          &nbsp;-&nbsp;<span class="hint truncate-text" [ngClass]="isSavePressed ? 'error' : ''">{{
            'mailbody_stage_language_missing_required_tag' | translate
          }}</span>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="main value-container">
    <span class="value">
      <div class="form-field-mailtemplate">
        <div class="mailtemplate-row">
          <div class="base-form-field form-field-inputfield w-20">
            <div class="label-container">
              <span class="label">{{ 'mailbody_languageid' | translate }}</span>
            </div>
            <div class="value-container">
              <span class="value">
                <mat-form-field
                  appearance="outline"
                  class="form-field-input reset-outline-hint"
                  [ngClass]="[!isEditMode ? 'view-mode' : '']"
                >
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    #languageSelect
                    [(ngModel)]="selectedLanguage"
                    (selectionChange)="selectedLanguageOptionChanged()"
                    [disabled]="stepDisabled || !isSelectedStageValid()"
                  >
                    <mat-option class="form-field-option" *ngFor="let language of staticOptions?.languages" [value]="language">
                      {{ language.entityName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </div>
          <div class="base-form-field form-field-inputfield w-20" style="padding: 0 0.5rem">
            <div class="label-container">
              <span class="label">{{ 'mailbody_stage' | translate }}</span>
            </div>
            <div class="value-container">
              <span class="value">
                <mat-form-field
                  appearance="outline"
                  class="form-field-input reset-outline-hint"
                  [ngClass]="[!isEditMode ? 'view-mode' : '']"
                >
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    #stageSelect
                    [(ngModel)]="selectedStage"
                    (selectionChange)="selectedStageOptionChanged()"
                    [disabled]="stepDisabled || !isSelectedStageValid()"
                  >
                    <mat-option class="form-field-option" *ngFor="let stage of filteredStages" [value]="stage">
                      {{ stage.entityName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </div>
          <div class="base-form-field form-field-inputfield w-20">&nbsp;</div>
          <div class="base-form-field form-field-inputfield w-20" style="padding: 0 0.5rem">
            <div class="label-container">
              <span class="label truncate-text">{{ 'mailbody_fontfamily' | translate }}</span>
            </div>
            <div class="value-container">
              <span class="value">
                <mat-form-field
                  appearance="outline"
                  class="form-field-input reset-outline-hint"
                  [ngClass]="[!isEditMode ? 'view-mode' : '']"
                >
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    #templateSelect
                    [(ngModel)]="selectedFontfamily"
                    (selectionChange)="selectedFontfamilyOptionChanged()"
                    [disabled]="stepDisabled || !isEditMode"
                  >
                    <mat-option class="form-field-option" *ngFor="let fontfamily of staticOptions?.fontfamilies" [value]="fontfamily">
                      {{ fontfamily.entityName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </div>
          <div class="base-form-field form-field-inputfield w-20">
            <div class="label-container">
              <span class="label">{{ 'mailbody_template' | translate }}</span>
            </div>
            <div class="value-container">
              <span class="value">
                <mat-form-field
                  appearance="outline"
                  class="form-field-input reset-outline-hint"
                  [ngClass]="[!isEditMode ? 'view-mode' : '']"
                >
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    #templateSelect
                    [(ngModel)]="selectedTemplate"
                    (selectionChange)="selectedTemplateOptionChanged()"
                    [disabled]="stepDisabled || !isEditMode"
                  >
                    <mat-option class="form-field-option" *ngFor="let template of staticOptions?.templates" [value]="template">
                      {{ template.entityName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="selectedMailTemplateFrameBody">
          <div class="mailtemplate-row">
            <div class="base-form-field form-field-inputfield">
              <div class="label-container">
                <span class="label">{{ 'mailbody_subject' | translate }}</span>
                <span class="requiredField" *ngIf="!stepDisabled">*</span>
              </div>
              <div class="value-container">
                <span class="value">
                  <div class="form-field-string-input-container">
                    <mat-form-field
                      appearance="outline"
                      class="form-field-input reset-outline-hint"
                      [ngClass]="[
                        !isEditMode ? 'view-mode' : '',
                        isDirtyEnabled && originalSelectedMailTemplateFrameBody.subject !== selectedMailTemplateFrameBody.subject
                          ? DIRTY_CLASSNAME
                          : 'equalclass',
                        isFilledBody(selectedMailTemplateFrameBody) &&
                        (selectedMailTemplateFrameBody.subject == null ||
                          selectedMailTemplateFrameBody.subject === '' ||
                          !isRequiredSubjectTagsValid(selectedMailTemplateFrameBody))
                          ? 'invalid-field'
                          : ''
                      ]"
                    >
                      <input
                        id="form-field-multiline-mail-inputarea"
                        type="text"
                        matInput
                        [(ngModel)]="selectedMailTemplateFrameBody.subject"
                        (ngModelChange)="subjectBodyChanged()"
                        [disabled]="stepDisabled || !isEditMode"
                      />
                    </mat-form-field>
                    <ng-container *ngIf="isEditMode">
                      <div
                        *ngIf="staticOptions?.subjectTags != null && staticOptions?.subjectTags.length === 1"
                        class="form-field-multi-rich-string-buttons"
                      >
                        <button
                          mat-raised-button
                          [color]="isSavePressed && button.addon2 === 't' && !isRequiredSubjectTagSetted(button.key) ? 'warn' : 'primary'"
                          (click)="onAddSingleTextMailPlaceholder(button)"
                          style="margin: 0 0 0.5rem 0.5rem; min-width: 120px"
                          *ngFor="let button of staticOptions?.subjectTags"
                          matTooltip="{{ button.addon | translate }}"
                          [matTooltipClass]="'fo-mat-tooltip'"
                        >
                          <span>{{ button.value | translate }}{{ button.addon2 === 't' ? '*' : null }}</span>
                        </button>
                      </div>
                      <div
                        *ngIf="staticOptions?.subjectTags != null && staticOptions?.subjectTags.length > 1"
                        class="form-field-multi-rich-string-buttons"
                      >
                        <button
                          mat-icon-button
                          color="primary"
                          class="form-field-input-string-button"
                          (click)="isStringInputMenuOpen = !isStringInputMenuOpen"
                          cdkOverlayOrigin
                          #singleStringInput="cdkOverlayOrigin"
                        >
                          <mat-icon>more_vert</mat-icon>
                        </button>

                        <ng-template
                          cdkConnectedOverlay
                          [cdkConnectedOverlayOrigin]="singleStringInput"
                          [cdkConnectedOverlayOpen]="isStringInputMenuOpen"
                          (overlayOutsideClick)="$event.stopPropagation(); isStringInputMenuOpen = false"
                        >
                          <div class="form-field-input-string-overlay">
                            <button
                              *ngFor="let button of staticOptions?.subjectTags"
                              mat-raised-button
                              [color]="
                                isSavePressed && button.addon2 === 't' && !isRequiredSubjectTagSetted(button.key) ? 'warn' : 'primary'
                              "
                              style="margin-bottom: 0.5rem; min-width: 120px"
                              matTooltip="{{ button.addon | translate }}"
                              [matTooltipClass]="'fo-mat-tooltip'"
                              (click)="onAddSingleTextMailPlaceholder(button)"
                            >
                              <span>{{ button.value }}{{ button.addon2 === 't' ? '*' : null }}</span>
                            </button>
                          </div>
                        </ng-template>
                      </div>
                    </ng-container>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="mailtemplate-editor-row">
            <div class="base-form-field form-field-inputfield multiline">
              <div class="label-container">
                <span class="label">{{ 'mailbody_body' | translate }}</span>
                <span class="requiredField" *ngIf="!stepDisabled">*</span>
              </div>
              <div class="value-container">
                <span class="value">
                  <div class="form-field-string-input-container">
                    <ckeditor
                      #ckeditor
                      class="form-field-ck-editor"
                      [ngClass]="[
                        !isEditMode ? 'viewmode' : 'editmode',
                        isDirtyEnabled && originalSelectedMailTemplateFrameBody.body !== selectedMailTemplateFrameBody.body
                          ? DIRTY_CLASSNAME
                          : '',
                        isFilledBody(selectedMailTemplateFrameBody) &&
                        (selectedMailTemplateFrameBody.body == null ||
                          selectedMailTemplateFrameBody.body === '' ||
                          !isRequiredBodyTagValid(selectedMailTemplateFrameBody))
                          ? 'invalid-field'
                          : ''
                      ]"
                      [editor]="Editor"
                      (ready)="onCkeditorMailReady()"
                      [(ngModel)]="selectedMailTemplateFrameBody.body"
                      (ngModelChange)="subjectBodyChanged()"
                      [disabled]="stepDisabled || !isEditMode"
                      [config]="config"
                    ></ckeditor>
                    <ng-container *ngIf="!!isEditMode && !stepDisabled">
                      <div
                        *ngIf="staticOptions?.bodyTags != null && staticOptions?.bodyTags.length > 0"
                        class="form-field-multi-rich-string-buttons"
                      >
                        <button
                          mat-raised-button
                          [color]="isSavePressed && button.addon2 === 't' && !isRequiredBodyTagSetted(button.key) ? 'warn' : 'primary'"
                          (click)="onAddCkeditorTextMailPlaceholder(button)"
                          style="margin: 0 0 0.5rem 0.5rem; min-width: 120px"
                          matTooltip="{{ button.addon | translate }}"
                          [matTooltipClass]="'fo-mat-tooltip'"
                          *ngFor="let button of staticOptions?.bodyTags"
                        >
                          <span>{{ button.value | translate }}{{ button.addon2 === 't' ? '*' : null }}</span>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </span>
  </div>
</div> -->

<ng-template id="chips-multiselection-dialog" #ccsChipsMultiselectionDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div class="dialog-header">
    <span>{{ 'generic_chips_multiselection_dialog_title' | translate }}</span>
    <div class="buttons-container">
      <button mat-raised-button color="primary" class="edit-mode-button" (click)="closeCcAddressesDialog()">
        <mat-icon>cancel</mat-icon>
        <span>{{ 'label_cancel' | translate }}</span>
      </button>
      <span style="margin-left: 0.5rem"></span>
      <button mat-raised-button color="primary" (click)="$event.stopPropagation(); saveCcAddressesSelection()">
        <mat-icon>check</mat-icon>
        <span>{{ 'label_apply' | translate }}</span>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="chips-multiselection-dialog-content">
    <app-generic-multigrid-multiselection
      [selectedDataSubj]="currentCcAddressesSelectionSubj.asObservable()"
      [entityKind]="Entities.CONTACT"
      (addToSelectionEvEm)="addToCcAddressesSelection($event)"
      (removeFromSelectionEvEm)="removeFromCcAddressesSelection($event)"
      (clearSelectionEvEm)="clearCcAddressesSelection()"
    ></app-generic-multigrid-multiselection>
  </div>
</ng-template>

<ng-template id="chips-multiselection-dialog" #bccsChipsMultiselectionDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div class="dialog-header">
    <span>{{ 'generic_chips_multiselection_dialog_title' | translate }}</span>
    <div class="buttons-container">
      <button mat-raised-button color="primary" class="edit-mode-button" (click)="closeBccAddressesDialog()">
        <mat-icon>cancel</mat-icon>
        <span>{{ 'label_cancel' | translate }}</span>
      </button>
      <span style="margin-left: 0.5rem"></span>
      <button mat-raised-button color="primary" (click)="$event.stopPropagation(); saveBccAddressesSelection()">
        <mat-icon>check</mat-icon>
        <span>{{ 'label_apply' | translate }}</span>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="chips-multiselection-dialog-content">
    <app-generic-multigrid-multiselection
      [selectedDataSubj]="currentBccAddressesSelectionSubj.asObservable()"
      [entityKind]="Entities.CONTACT"
      (addToSelectionEvEm)="addToBccAddressesSelection($event)"
      (removeFromSelectionEvEm)="removeFromBccAddressesSelection($event)"
      (clearSelectionEvEm)="clearBccAddressesSelection()"
    ></app-generic-multigrid-multiselection>
  </div>
</ng-template>

<ng-template id="chips-multiselection-dialog" #tosChipsMultiselectionDialog>
  <div
    class="dialog-draggable-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
  >
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div class="dialog-header">
    <span>{{ 'generic_chips_multiselection_dialog_title' | translate }}</span>
    <div class="buttons-container">
      <button mat-raised-button color="primary" class="edit-mode-button" (click)="closeToAddressesDialog()">
        <mat-icon>cancel</mat-icon>
        <span>{{ 'label_cancel' | translate }}</span>
      </button>
      <span style="margin-left: 0.5rem"></span>
      <button mat-raised-button color="primary" (click)="$event.stopPropagation(); saveToAddressesSelection()">
        <mat-icon>check</mat-icon>
        <span>{{ 'label_apply' | translate }}</span>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="chips-multiselection-dialog-content">
    <app-generic-multigrid-multiselection
      [selectedDataSubj]="currentToAddressesSelectionSubj.asObservable()"
      [entityKind]="Entities.CONTACT"
      (addToSelectionEvEm)="addToToAddressesSelection($event)"
      (removeFromSelectionEvEm)="removeFromToAddressesSelection($event)"
      (clearSelectionEvEm)="clearToAddressesSelection()"
    ></app-generic-multigrid-multiselection>
  </div>
</ng-template>
