import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { Entity } from 'src/app/models/entity';
import { MailHistory } from 'src/app/models/mailtemplate/mail-template-preview';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { RootStoreState } from 'src/app/root-store';
import { MailTemplatesDataService } from 'src/app/services/mail-template-data.service';
import { GridBaseComponent } from '../../base-components/grid-base-component';
import {
  GenericSendEmailDialogComponent,
  GenericSendEmailDialogData,
} from '../generic-send-email-dialog/generic-send-email-dialog.component';

@Component({
  selector: 'app-generic-history-email',
  templateUrl: './generic-history-email.component.html',
  styleUrls: ['./generic-history-email.component.scss'],
})
export class GenericHistoryEmailComponent extends GridBaseComponent {
  @Input() entitySubj: Observable<Entity>;
  @Input() customApipath: string;
  @Input() customEntity: Entity;
  @Input() isHistoricalParticular: boolean;
  selectedEntity: Entity;
  isMailLoading = false;
  constructor(
    protected mailDataService: MailTemplatesDataService,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef
  ) {
    super(store, cdRef);
  }

  gbGetDisplayColumnTable() {
    return ['status', 'emailhistorySent', 'emailhistorySubject', 'emailhistoryAttachments', 'emailhistoryTos', 'actionButtons'];
  }

  gbGetInitialOrderBy() {
    return 'emailhistorySent';
  }

  gbGetInitialSort() {
    return 'desc';
  }

  async gbAfterViewInitTable() {
    this.dataSourceTable = new MatTableDataSource();
    this.subscribe(this.entitySubj, (entity) => {
      if (entity) {
        this.selectedEntity = entity;
        this.initRequest();
        this.gbLoadEntitiesData();
      }
    });
  }

  gbLoadEntitiesData() {
    this.isLoading = true;
    this.noEntityData = false;
    if (this.customApipath) {
      if (this.gridLoadSubscription != null) {
        this.gridLoadSubscription.unsubscribe();
      }
      this.gridLoadSubscription = this.subscribe(
        this.mailDataService.getMailHistoryByApipath(this.customApipath, this.request),
        (response) => {
          if (response.data) {
            this.isLoading = false;
            this.dataSourceTable.data = response.data;
            this.pageTotalElements = response.data[0].entityCount;
          } else {
            this.dataSourceTable.data = [];
            this.pageTotalElements = 0;
            this.isLoading = false;
            this.noEntityData = true;
          }
        },
        (error) => {
          /* HTTP Errors are managed on ServerErrorInterceptor */
        }
      );
    } else {
      if (this.gridLoadSubscription != null) {
        this.gridLoadSubscription.unsubscribe();
      }
      this.gridLoadSubscription = this.subscribe(
        this.mailDataService.getMailHistoryByEntities(
          [{ entityId: this.selectedEntity.entityId, entityKind: this.selectedEntity.entityKind }],
          this.request
        ),
        (response) => {
          if (response.data) {
            this.isLoading = false;
            this.dataSourceTable.data = response.data;
            this.pageTotalElements = response.data[0].entityCount;
          } else {
            this.dataSourceTable.data = [];
            this.pageTotalElements = 0;
            this.isLoading = false;
            this.noEntityData = true;
          }
        },
        (error) => {
          /* HTTP Errors are managed on ServerErrorInterceptor */
        }
      );
    }
  }

  getTooltip(row: MailHistory): string {
    if (row.emailhistoryError !== null) return row.emailhistoryError;
    else if (row.emailhistoryRead !== null) return this.getDatetime(row.emailhistorySent) + ' / ' + this.getDatetime(row.emailhistoryRead);
    else return this.getDatetime(row.emailhistorySent);
  }

  loadSpreadsheetFile() {
    this.setSpreadsheetLoading(true);
    const pr: PaginatedRequest = { ...this.request, pageSize: null };
    if (this.customApipath) {
      this.subscribe(
        this.mailDataService.getMailHistoryByApipathSpreadsheet(this.customApipath, pr),
        (blob) => {
          this.setSpreadsheetLoading(false);
          FileSaver.saveAs(blob.file, blob.filename); // FileSaver;
        },
        (error) => this.setSpreadsheetLoading(false)
      );
    } else {
      this.subscribe(
        this.mailDataService.getMailHistoryByEntitiesSpreadsheet(
          [{ entityId: this.selectedEntity.entityId, entityKind: this.selectedEntity.entityKind }],
          pr
        ),
        (blob) => {
          this.setSpreadsheetLoading(false);
          FileSaver.saveAs(blob.file, blob.filename); // FileSaver;
        },
        (error) => this.setSpreadsheetLoading(false)
      );
    }
  }

  viewEmail(row: MailHistory) {
    if (this.isHistoricalParticular) {
      this.subscribe(
        this.mailDataService.getMailHistoryParticulars(row.emailhistoryId),
        (response) => {
          if (response.data) {
            this.isMailLoading = false;
            this.dialog.open(GenericSendEmailDialogComponent, {
              width: '90vw',
              maxWidth: '90vw',
              maxHeight: '95vh',
              autoFocus: false,
              disableClose: true,
              panelClass: 'generic-send-email-dialog',
              data: {
                entityKind: this.customEntity.entityKind,
                entityId: this.customEntity.entityId,
                entityName: this.customEntity.entityName,
                viewMail: response.data,
                files: response.data.emailAttachments,
              } as GenericSendEmailDialogData,
            });
          }
        },
        (error) => {
          /* HTTP Errors are managed on ServerErrorInterceptor */
        }
      );
    } else {
      this.subscribe(
        this.mailDataService.getMailHistoryByEntityKindEntityIdParticulars(
          this.selectedEntity.entityKind,
          this.selectedEntity.entityId,
          row.emailhistoryId
        ),
        (response) => {
          if (response.data) {
            this.isMailLoading = false;
            this.dialog.open(GenericSendEmailDialogComponent, {
              width: '90vw',
              maxWidth: '90vw',
              maxHeight: '95vh',
              autoFocus: false,
              disableClose: true,
              panelClass: 'generic-send-email-dialog',
              data: {
                entityKind: this.selectedEntity.entityKind,
                entityId: this.selectedEntity.entityId,
                entityName: this.selectedEntity.entityName,
                viewMail: response.data,
                files: response.data.emailAttachments,
              } as GenericSendEmailDialogData,
            });
          }
        },
        (error) => {
          /* HTTP Errors are managed on ServerErrorInterceptor */
        }
      );
    }
  }
}
