import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { ErrorHandlingStoreAction } from 'src/app/root-store/error-handling-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { LogService } from '../../log-service';
import { ExcludedAPIServices } from './excluded-api-services';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private messageNotifierService: MessageNotifierService,
    private store: Store<RootStoreState.State>,
    private translate: TranslateService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(PreferencesSelectors.selectPreferencesHelp).pipe(
      first(),
      switchMap((helpSetting) => {
        if (!helpSetting || !request.url.match(helpSetting.settingHelpUrl)) {
          return next.handle(request).pipe(
            // retry(1), /* Retry to call back-end service for n times*/
            catchError((response: HttpErrorResponse) => {
              return this.manageError(response);
            })
          );
        } else {
          return next.handle(request);
        }
      })
    );
  }

  private manageError(resp: HttpErrorResponse): Observable<HttpEvent<any>> {
    const m = this.manageError.name;

    const httpStatus = resp.error.status;

    if (!httpStatus) {
      // UNDEFINED ERROR;
      LogService.error(this, m, resp.message, resp.error);
      this.store.dispatch(ErrorHandlingStoreAction.setServerUnreachableError());
      return throwError(() => resp);
      // Add your st uff here;
    } else if (httpStatus === 400) {
      // 400 - BAD_REQUEST
      LogService.warning(this, m, 'Error 400', resp.error);
      // Add your stuff here;
    } else if (httpStatus === 401) {
      // 401 - UNAUTHORIZED
      LogService.warning(this, m, 'Error 401', resp.error);
      // Add your stuff here;
    } else if (httpStatus === 404) {
      // 404 - NOT_FOUND
      LogService.warning(this, m, 'Error 404', resp.error);
      // Add your stuff here;
    } else if (httpStatus === 500) {
      // 500 - INTERNAL_SERVER_ERROR
      LogService.error(this, m, 'Error 500', resp.error);
      this.store.dispatch(ErrorHandlingStoreAction.setError());
      // combineLatest([
      //   this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticated)),
      //   this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)),
      // ])
      //   .pipe(take(1))
      //   .subscribe(([isAuth, isTenantAuth]) => {
      //     if (isAuth && !isTenantAuth) {
      //       this.store.dispatch(NotificationsStoreAction.refreshNotificationCount());
      //     }
      //   });
      // Add your stuff here;
    } else {
      // NOT MANAGED ERRORS;
      const msg = 'NOT MANAGED Error (' + httpStatus + ')';
      LogService.warning(this, m, msg, resp.error);
      // Add your stuff here;
    }

    const retVal = this.showErrorWithOrWithoutToastr(resp);

    return retVal;
  }

  private showErrorWithOrWithoutToastr(resp: HttpErrorResponse) {
    const m = this.showErrorWithOrWithoutToastr.name;

    const responsePath = resp.error.path;
    const responseMethod = resp.error.httpMethod;
    const responseErrorKey = resp.error.errorKey;
    const responseErrorId = resp.error.errorId;

    if (responsePath && responseMethod && responseErrorKey) {
      const fePath = responsePath.substr(1); /* remove first slash */
      const excludedMethod = ExcludedAPIServices.map.get(fePath);
      if (responseMethod === excludedMethod) {
        const msg = 'Toastr Alert omitted because this API service belongs to list of excludeds';
        LogService.info(this, m, msg, null);
      } else {
        LogService.error(this, m, this.translate.instant(responseErrorKey), null);
        this.messageNotifierService.showSystemErrorMessage(responseErrorId);
      }
    } else if (resp.url.includes('assets')) {
      const msg = 'Toastr Alert omitted because this API service belongs to assets';
      LogService.info(this, m, msg, null);
    } else {
      LogService.error(this, m, this.translate.instant(resp.message), null);
      this.messageNotifierService.showSystemErrorMessage(responseErrorId ? responseErrorId : -1);
    }

    return throwError(() => resp);
  }
}
