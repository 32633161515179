import { ActionResponse } from 'src/app/models/action-response';
import { LicenseAssetPeriodAllocation, LicenseAssetPeriodAllocationRequest } from 'src/app/models/asset-allocation';
import { MoveSharedAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-shared-asset-wizard/init-step/init-step';
import { MoveSharedAssetMovingToStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-shared-asset-wizard/move-shared-asset-movingto-step/move-shared-asset-movingto-step';

export interface State {
  initStep: MoveSharedAssetInitStep;
  movingTo: MoveSharedAssetMovingToStep;
  allocationUpdate: LicenseAssetPeriodAllocation[];
  request: LicenseAssetPeriodAllocationRequest[];
  response: ActionResponse;
  errorMessage: any;
}

export const initialState: State = {
  initStep: null,
  movingTo: null,
  allocationUpdate: null,
  request: null,
  response: null,
  errorMessage: null,
};
