<div
  class="base-form-field form-field-entityfield"
  [ngClass]="[
    (formFieldData.formfieldEntityType === 'string' &&
      formFieldData.formfieldEntityPrecision != null &&
      formFieldData.formfieldEntityPrecision > 1) ||
    formFieldData.formfieldEntityType === 'mailtemplate'
      ? 'multiline'
      : '',
    !formFieldData.formfieldLabelShow ? 'justify-center' : ''
  ]"
  (click)="onItemClick()"
>
  <div class="label-container" *ngIf="formFieldData.formfieldLabelShow">
    <ng-container *ngIf="formFieldData.formfieldLabelDisplay">
      <span class="label" showIfTruncated matTooltip="{{ formFieldData.formfieldTextname | translate }}"
        >{{ formFieldData.formfieldTextname | translate }}
      </span>
      <span *ngIf="formFieldData.formfieldRequired" class="requiredField">*</span>
      <span
        class="tooltip"
        *ngIf="formFieldData.formfieldInfotextname"
        matTooltip="{{ parseEntityTooltip('\n', '&emsp;', formFieldData.formfieldInfotextname | translate) }}"
        [matTooltipClass]="'fo-mat-tooltip tree-tooltip'"
        ><mat-icon class="entityTooltipIcon">info</mat-icon></span
      >
    </ng-container>
    <ng-container *ngIf="!formFieldData.formfieldLabelDisplay">
      <span class="label">&nbsp;</span>
    </ng-container>
  </div>
  <div class="value-container">
    <span class="value">
      <ng-container *ngIf="formFieldData.formfieldEntityType === 'string' && formFieldData.formfieldEntityPrecision !== 3">
        <mat-form-field
          appearance="outline"
          class="form-field-input reset-outline-hint"
          [ngClass]="[formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '']"
        >
          <input
            *ngIf="formFieldData.formfieldEntityPrecision == null || formFieldData.formfieldEntityPrecision === 1"
            type="text"
            matInput
            [(ngModel)]="formFieldData.formfieldEntityColumn"
            [disabled]="true"
          />
          <textarea
            *ngIf="formFieldData.formfieldEntityPrecision != null && formFieldData.formfieldEntityPrecision === 2"
            type="text"
            matInput
            [(ngModel)]="formFieldData.formfieldEntityColumn"
            [disabled]="true"
            cdkTextareaAutosize
          ></textarea>

          <button *ngIf="formFieldData.formfieldEntityColumn" mat-icon-button class="reset-filter" matSuffix [disabled]="true">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="formFieldData.formfieldEntityType === 'string' && formFieldData.formfieldEntityPrecision === 3">
        <ckeditor
          #ckeditor
          [editor]="Editor"
          [(ngModel)]="formFieldData.formfieldEntityText"
          [disabled]="true"
          (ready)="onCkeditorReady()"
          [config]="config"
        ></ckeditor>
      </ng-container>
      <ng-container *ngIf="formFieldData.formfieldEntityType === 'mailtemplate'">
        <div class="form-field-mailtemplate">
          <div class="mailtemplate-row">
            <div class="base-form-field form-field-inputfield onequarter">
              <div class="label-container">
                <span class="label">{{ 'mailbody_languageid' | translate }}</span>
              </div>
              <div class="value-container">
                <span class="value">
                  <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
                    <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
                  </mat-form-field>
                </span>
              </div>
            </div>
            <div class="base-form-field form-field-inputfield onequarter" style="padding: 0 0.5rem">
              <div class="label-container">
                <span class="label">{{ 'mailbody_stage' | translate }}</span>
              </div>
              <div class="value-container">
                <span class="value">
                  <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
                    <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
                  </mat-form-field>
                </span>
              </div>
            </div>
            <div class="base-form-field form-field-inputfield onequarter">&nbsp;</div>
            <div class="base-form-field form-field-inputfield onequarter">
              <div class="label-container">
                <span class="label">{{ 'mailbody_template' | translate }}</span>
              </div>
              <div class="value-container">
                <span class="value">
                  <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
                    <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
                  </mat-form-field>
                </span>
              </div>
            </div>
          </div>
          <div class="mailtemplate-row">
            <div class="base-form-field form-field-inputfield">
              <div class="label-container">
                <span class="label">{{ 'mailbody_subject' | translate }}</span>
                <span class="requiredField">*</span>
              </div>
              <div class="value-container">
                <span class="value">
                  <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
                    <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
                  </mat-form-field>
                </span>
              </div>
            </div>
          </div>
          <div class="mailtemplate-editor-row">
            <div class="base-form-field form-field-inputfield multiline">
              <div class="label-container">
                <span class="label">{{ 'mailbody_body' | translate }}</span>
                <span class="requiredField">*</span>
              </div>
              <div class="value-container">
                <span class="value"
                  ><ckeditor
                    #ckeditor
                    [editor]="Editor"
                    [(ngModel)]="formFieldData.formfieldEntityText"
                    [disabled]="true"
                    [config]="config"
                  ></ckeditor>
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="formFieldData.formfieldEntityType !== 'string' && formFieldData.formfieldEntityType !== 'mailtemplate'">
        <mat-form-field
          appearance="outline"
          class="form-field-input reset-outline-hint"
          [ngClass]="[formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : '']"
        >
          <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityColumn" [disabled]="true" />
          <button *ngIf="formFieldData.formfieldEntityColumn" mat-icon-button class="reset-filter" matSuffix [disabled]="true">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </ng-container>
    </span>
  </div>
  <button class="remove-button" (click)="remove(); $event.stopPropagation()" mat-icon-button *ngIf="isEditMode">
    <mat-icon>close</mat-icon>
  </button>
</div>
