import { LicenseAssetPeriodAllocationRequest } from 'src/app/models/asset-allocation';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { Userlicense } from 'src/app/models/userlicense';
import { CancelConsumersFromAssetConsumerStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-consumers-from-asset-wizard/cancel-consumers-from-asset-consumers-step/cancel-consumers-from-asset-consumers.step';
import { CancelConsumersFromAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-consumers-from-asset-wizard/init-step/init-step';
export interface State {
  initStep: CancelConsumersFromAssetInitStep;
  consumersStep: CancelConsumersFromAssetConsumerStep;
  billingStep: Userlicense[];
  request: LicenseAssetPeriodAllocationRequest[];
  response: EntityWizardResponse;
  errorMessage: string;
}

export const initialState: State = {
  initStep: null,
  consumersStep: null,
  billingStep: null,
  request: null,
  response: null,
  errorMessage: null,
};
