import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entity } from 'src/app/models/entity';
import { GenericEntityFormFieldStep } from 'src/app/models/forms/form-field';
import { EntityFieldsListContentCallBack } from 'src/app/models/generic-entity-field';
import { GenericRelationEntity } from 'src/app/models/generic-relation-entity';
import { RootStoreState } from 'src/app/root-store';
import { LogService } from 'src/app/services/log-service';
import { RelationDataService } from 'src/app/services/relation-data.service';
import { GridBaseComponent } from 'src/app/shared/base-components/grid-base-component';
import {
  GenericAuditLogModalDialogComponent,
  GenericAuditLogModalDialogData,
} from '../generic-audit-log-modal-dialog/generic-audit-log-modal-dialog.component';
import { GenericEntityFormComponent } from '../generic-entity-form/generic-entity-form.component';
import { GenericGridFilterModel, GenericGridViewModel } from '../generic-grid-view/generic-grid-view.component';

export enum GenericEntitiesRelationStatus {
  view = 0,
  edit = 1,
  remove = 2,
  add = 3,
  readOnly = 4,
}

export const SELECT_ALL_ENTITY_ID = -1;

@Component({
  selector: 'app-generic-entities-relation, generic-entities-relation',
  templateUrl: './generic-entities-relation.component.html',
  styleUrls: ['./generic-entities-relation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GenericEntitiesRelationComponent extends GridBaseComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('confirmCancelMenu') confirmCancelMenu: MatMenuTrigger;
  @ViewChild(GenericEntityFormComponent) entityFormContent: GenericEntityFormComponent;
  @Input() isPanelExpanded = false;
  @Input() showPanelTitleToggle = true;
  @Input() title: string;
  @Input() entityId: number;
  @Input() apiPrefix = '';
  @Input() baseEntity: string;
  @Input() otherEntity: string;
  @Input() otherEntityApipath: string;
  @Input() relationName: string;
  @Input() hideDetails: boolean;
  @Input() readOnly: boolean;
  @Input() pagination = true;
  @Input() searchFilter = true;
  @Input() gridEnableHeader = false;
  @Input() gridColumnsConfig: GenericGridViewModel[];
  @Input() gridFiltersConfig: GenericGridFilterModel[];
  @Input() addModeSelectAllEnabled = false;
  @Input() addModeSelectAllLabel = _('table_select_all');
  @Input() addTableFilterPlaceholder = _('table_filter_placeholder');
  @Input() enableFilterKindId = false;

  @Output() isEditingChange = new EventEmitter<boolean>();
  @Output() relationsNumber = new EventEmitter<number>();
  @Output() rowGridSelectedEvEm = new EventEmitter<Entity>();
  relationLabel: string;
  enableConfirmCancel = false;

  selectedEntityRelationsApiPath: string;
  allOtherEntityElementsApiPath: string;
  // selectedEntityApiPath: string;

  entityRelations: GenericRelationEntity[];
  allEntityRelations: GenericRelationEntity[];
  // itemsBoard: GenericRelationEntity[] = [];
  itemsBoardTable: MatTableDataSource<GenericRelationEntity>;

  selectedRelation: GenericRelationEntity = {} as GenericRelationEntity;

  // State of component
  componentStatus = GenericEntitiesRelationStatus.view;
  previousStatus = GenericEntitiesRelationStatus.view;
  // Details
  showDetails = false;
  saveIsDisabled = true;
  atomicDetailsSteps: GenericEntityFormFieldStep[];
  atomicCommonIsLoading = false;
  isDetailsEditing = false;

  constructor(
    private relationDataService: RelationDataService,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef
  ) {
    super(store, cdRef);
    // this.resetStatusComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entityId && !changes.entityId.isFirstChange() && changes.entityId.currentValue !== changes.entityId.previousValue) {
      this.resetRelationPathAndData();
      this.resetAllEntityRelationPathAndData();
      this.resetStatusComponent();
    }
    if (
      changes.otherEntity &&
      !changes.otherEntity.isFirstChange() &&
      changes.otherEntity.currentValue !== changes.otherEntity.previousValue
    ) {
      this.resetRelationPathAndData();
      this.resetAllEntityRelationPathAndData();
      if (this.otherEntity.indexOf('entity_') !== 0) {
        this.relationLabel = 'entity_' + this.otherEntity;
      } else {
        this.relationLabel = this.otherEntity;
      }
      this.resetStatusComponent();
    }
  }

  ngAfterViewChecked(): void {
    if (this.entityFormContent) {
      this.saveIsDisabled = this.entityFormContent.saveIsDisabled;
      this.cdRef.detectChanges();
    }
  }

  gbGetDisplayColumnTable() {
    if (this.gridColumnsConfig) {
      const columns = [];
      this.gridColumnsConfig.forEach((column) => {
        columns.push(column.entityField);
      });
      if (this.isEditMode()) {
        return [...columns, 'cancel'];
      } else if (this.isAddMode()) {
        return ['checkbox', 'entityName', 'entityDescription', 'cancel'];
      } else if (this.isRemoveMode()) {
        return [...columns, 'cancel'];
      } else {
        return [...columns, 'auditlog'];
      }
    } else {
      if (this.isEditMode()) {
        return ['entityName', 'entityDescription', 'cancel'];
      } else if (this.isAddMode()) {
        return ['checkbox', 'entityName', 'entityDescription', 'cancel'];
      } else if (this.isRemoveMode()) {
        return ['entityName', 'entityDescription', 'cancel'];
      } else {
        return ['entityName', 'entityDescription', 'auditlog'];
      }
    }
  }

  resetRelationPathAndData() {
    this.clearRelationEntityDetails();
    this.initRequest();
    this.initRelationApiPath();
    this.gbLoadEntitiesData();
    // this.componentStatus = GenericEntitiesRelationStatus.view;
  }

  resetAllEntityRelationPathAndData() {
    this.clearRelationEntityDetails();
    this.initRequest();
    this.initOtherEntityApiPath();
    this.gbLoadEntitiesData();
    // this.loadOtherEntitiesData();
    // this.componentStatus = GenericEntitiesRelationStatus.view;
  }

  resetStatusComponent() {
    if (this.readOnly) {
      this.componentStatus = GenericEntitiesRelationStatus.readOnly;
    } else {
      this.componentStatus = GenericEntitiesRelationStatus.view;
    }
  }

  async gbAfterViewInitTable() {
    if (this.baseEntity != null && this.entityId != null && this.relationName != null && this.otherEntity != null) {
      this.initRelationApiPath();
      this.initOtherEntityApiPath();
      if (this.otherEntity.indexOf('entity_') !== 0) {
        this.relationLabel = 'entity_' + this.otherEntity;
      } else {
        this.relationLabel = this.otherEntity;
      }
    } else {
      throw Error('Missing input argument');
    }
    this.itemsBoardTable = new MatTableDataSource();
    // Init Generic Entities Relation Page
    this.initRequest();
    this.gbLoadEntitiesData();
    // this.loadOtherEntitiesData();
  }

  gbGetInitialOrderBy() {
    return 'entityName';
  }

  gbSetSelectedEntity(row: Entity) {
    this.selectedEntity = row;
    this.selectedRelation = { ...(row as GenericRelationEntity) };
    this.selectedEntitySubject.next(this.selectedEntity);
  }

  setDataSourceTable() {
    this.displayedColumnsTable = this.gbGetDisplayColumnTable();
    if (this.isAddMode()) {
      // fast way to deep copy an array avoiding reference update.
      if (this.allEntityRelations) {
        this.dataSourceTable.data = JSON.parse(JSON.stringify(this.allEntityRelations)) as Entity[];
      }
    } else {
      if (this.entityRelations) {
        this.dataSourceTable.data = JSON.parse(JSON.stringify(this.entityRelations)) as Entity[];
      }
    }
    if (this.itemsBoardTable.data && this.itemsBoardTable.data.length > 0) {
      this.itemsBoardTable.data.forEach((row) => {
        this.removeRowFromDatasource(row);
      });
    }
    // this.dataSourceTable.sort = this.sortTable;
  }

  private removeRowFromDatasource(row: GenericRelationEntity) {
    const index: number = this.dataSourceTable.data.findIndex((rel) => rel.entityId === row.entityId);
    if (index !== -1) {
      this.dataSourceTable.data.splice(index, 1);
    }
  }

  private initRelationApiPath() {
    // Api Path to get the list of relations between baseEntity and otherEntity
    this.selectedEntityRelationsApiPath = ApiPath.Relations.RELATIONS(this.apiPrefix, this.baseEntity, this.entityId, this.relationName);
  }

  private initOtherEntityApiPath() {
    // Api Path to get the list of all possible otherEntity elements
    if (this.otherEntityApipath != null) {
      this.allOtherEntityElementsApiPath = this.otherEntityApipath;
    } else {
      this.allOtherEntityElementsApiPath = this.enableFilterKindId
        ? ApiPath.Relations.RELATION_OTHER_ENTITIES_LIST(this.apiPrefix, this.otherEntity) +
          `?filterEntityKind=${this.baseEntity}&filterEntityId=${this.entityId}`
        : ApiPath.Relations.RELATION_OTHER_ENTITIES_LIST(this.apiPrefix, this.otherEntity);
    }
  }

  /* Button events */

  onEditChange(isEditDetails: boolean) {
    this.isEditing = isEditDetails;
    this.isDetailsEditing = isEditDetails;
    this.isEditingChange.emit(this.isEditing);
  }

  onCancel($event) {
    if (!this.isItemsboardEmpty()) {
      this.enableConfirmCancel = true;
      this.confirmCancelMenu.openMenu();
      this.cdRef.detectChanges();
      $event.stopPropagation();
    } else {
      this.onConfirmCancel();
    }
  }

  onConfirmCancel() {
    this.itemsBoardTable.data = [];
    this.initRequest();
    this.clearFilters();
    this.gbLoadEntitiesData();
    // this.loadOtherEntitiesData();
    if (this.previousStatus === GenericEntitiesRelationStatus.view) {
      this.viewEntryMode();
    } else {
      this.editEntryMode();
    }
    this.enableConfirmCancel = false;
  }

  onSave() {
    const selectedIds = this.itemsBoardTable.data.map((rel) => rel.entityId);
    if (this.isAddMode()) {
      this.addRelationsToSelectedEntity(selectedIds);
    } else {
      this.removeRelationsToSelectedEntity(selectedIds);
    }
  }

  selectAllRelations() {
    this.itemsBoardTable.data = [];
    this.dataSourceTable.data = [];
    const allRelations: GenericRelationEntity = {
      ...new GenericRelationEntity(),
      entityId: SELECT_ALL_ENTITY_ID,
      entityName: this.translate.instant('label_all'),
    };
    this.itemsBoardTable.data.push(allRelations);
    this.dataSourceTable._updateChangeSubscription();
    this.itemsBoardTable._updateChangeSubscription();
  }

  addRelationToItemBoard(row: GenericRelationEntity) {
    this.itemsBoardTable.data.push(row);
    const index: number = this.dataSourceTable.data.findIndex((rel) => rel.entityId === row.entityId);
    if (index !== -1) {
      this.dataSourceTable.data.splice(index, 1);
    }
    this.dataSourceTable._updateChangeSubscription();
    this.itemsBoardTable._updateChangeSubscription();
  }

  removeRelationFromItemBoard(row: GenericRelationEntity) {
    if (this.isSelectAllSelected()) {
      this.itemsBoardTable.data = [];
      this.setDataSourceTable();
    } else {
      this.dataSourceTable.data.push(row as Entity);
      const index: number = this.itemsBoardTable.data.findIndex((rel) => rel.entityId === row.entityId);
      if (index !== -1) {
        this.itemsBoardTable.data.splice(index, 1);
      }
      this.dataSourceTable._updateChangeSubscription();
      this.itemsBoardTable._updateChangeSubscription();
    }
  }

  clearRelationEntityDetails() {
    this.showDetails = false;
  }

  resetTable() {
    if (this.filter) {
      this.clearFilters();
      // Automatically reload table
    } else {
      this.gbLoadEntitiesData();
    }
  }

  /* Change status controller */

  viewEntryMode() {
    this.previousStatus = this.componentStatus;
    this.componentStatus = GenericEntitiesRelationStatus.view;
    this.isEditingChange.emit(false);
    this.resetTable();
    this.clearRelationEntityDetails();
    this.setDataSourceTable();
  }

  editEntryMode() {
    this.previousStatus = this.componentStatus;
    this.componentStatus = GenericEntitiesRelationStatus.edit;
    this.isEditingChange.emit(true);
    this.resetTable();
    this.clearRelationEntityDetails();
    this.setDataSourceTable();
  }

  addEntryMode() {
    this.previousStatus = this.componentStatus;
    this.componentStatus = GenericEntitiesRelationStatus.add;
    this.isEditingChange.emit(true);
    this.resetTable();
    this.clearRelationEntityDetails();
    this.setDataSourceTable();
  }

  removeEntryMode(row: GenericRelationEntity) {
    if (!this.isRemoveMode()) {
      this.previousStatus = this.componentStatus;
      this.componentStatus = GenericEntitiesRelationStatus.remove;
      this.isEditingChange.emit(true);
      // this.resetTable();
      this.clearRelationEntityDetails();
      this.setDataSourceTable();
    }
    this.addRelationToItemBoard(row);
  }

  /* View boolean controller */

  isDatasourceEmpty() {
    return this.dataSourceTable.data.length === 0;
  }

  isItemsboardEmpty() {
    return this.itemsBoardTable.data.length === 0;
  }

  isReadOnlyMode() {
    return this.componentStatus === GenericEntitiesRelationStatus.readOnly;
  }
  isViewMode() {
    return this.componentStatus === GenericEntitiesRelationStatus.view;
  }
  isEditMode() {
    return this.componentStatus === GenericEntitiesRelationStatus.edit;
  }
  isAddMode() {
    return this.componentStatus === GenericEntitiesRelationStatus.add;
  }
  isRemoveMode() {
    return this.componentStatus === GenericEntitiesRelationStatus.remove;
  }

  isAlreadyAssigned(row: GenericRelationEntity) {
    return !!this.entityRelations.find((relation) => relation.entityName === row.entityName);
  }

  isSelectAllSelected() {
    return this.itemsBoardTable.data.find((row) => row.entityId === SELECT_ALL_ENTITY_ID);
  }

  mustShowRoleDetails() {
    return (this.isReadOnlyMode() || this.isViewMode()) && this.entityRelations && this.entityRelations.length > 0 && this.showDetails;
  }

  /* Backend Calls */
  gbLoadEntitiesData() {
    this.clearSelection();
    this.isLoading = true;
    this.dataSourceTable = new MatTableDataSource();
    this.noEntityData = false;
    if (this.isAddMode()) {
      if (this.gridLoadSubscription != null) {
        this.gridLoadSubscription.unsubscribe();
      }
      this.gridLoadSubscription = this.subscribe(
        this.relationDataService.getOtherEntityElements(this.allOtherEntityElementsApiPath, this.entityId, this.request),
        (response) => {
          if (response.data) {
            this.allEntityRelations = response.data;
            this.pageTotalElements = response.data[0].entityCount;
          } else {
            this.pageTotalElements = 0;
            this.allEntityRelations = [];
            // this.noEntityData = true;
          }
          this.setDataSourceTable();
        },
        (error) => {
          /* HTTP Errors are managed on ServerErrorInterceptor */ this.isLoading = false;
        },
        () => (this.isLoading = false)
      );
    } else {
      if (this.gridLoadSubscription != null) {
        this.gridLoadSubscription.unsubscribe();
      }
      this.gridLoadSubscription = this.subscribe(
        this.relationDataService.getRelations(this.selectedEntityRelationsApiPath, this.request),
        (response) => {
          if (response.data) {
            this.entityRelations = response.data;
            this.relationsNumber.emit(this.entityRelations.length);
            this.pageTotalElements = response.data[0].entityCount;
          } else {
            this.pageTotalElements = 0;
            this.entityRelations = [];
            this.relationsNumber.emit(this.entityRelations.length);
            // this.noEntityData = true;
          }
          this.setDataSourceTable();
        },
        (error) => {
          /* HTTP Errors are managed on ServerErrorInterceptor */ this.isLoading = false;
        },
        () => (this.isLoading = false)
      );
    }
  }

  addRelationsToSelectedEntity(selectedIds: number[]) {
    this.subscribe(
      this.relationDataService.addRelation(this.selectedEntityRelationsApiPath, selectedIds),
      (response) => {
        if (response.data) {
          if (response.data.state && !response.data.error) {
            this.messageNotifierService.showSuccessMessage(_('record_successfully_added'));
            this.itemsBoardTable.data = [];
            this.viewEntryMode();
          } else if (response.data.state && response.data.error) {
            this.messageNotifierService.showWarningMessage(response.data.error);
          } else {
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          }
        } else {
          this.messageNotifierService.showWarningMessage(_('toastr_no_data'));
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      },
      () => {
        this.initRequest();
        this.clearFilters();
        this.gbLoadEntitiesData();
      }
    );
  }

  removeRelationsToSelectedEntity(selectedIds: number[]) {
    this.subscribe(
      this.relationDataService.removeRelation(this.selectedEntityRelationsApiPath, selectedIds),
      (response) => {
        if (response.data) {
          if (response.data.state && !response.data.error) {
            this.messageNotifierService.showSuccessMessage(_('record_successfully_removed'));
            this.itemsBoardTable.data = [];
            this.viewEntryMode();
          } else if (response.data.state && response.data.error) {
            this.messageNotifierService.showWarningMessage(response.data.error);
          } else {
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          }
        } else {
          this.messageNotifierService.showWarningMessage(_('toastr_no_data'));
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      },
      () => {
        this.initRequest();
        this.clearFilters();
        this.gbLoadEntitiesData();
      }
    );
  }

  /* GRID COMPONENT OVERRIDED METHOD */
  onRowClicked(row: Entity) {
    if (this.isReadOnlyMode() || this.isViewMode()) {
      if (!this.hideDetails) {
        this.clearRelationEntityDetails();
        this.gbSetSelectedEntity(row);
        this.loadEntityFormFieldSteps();
        this.cdRef.detectChanges();
        this.showDetails = true;
      } else {
        this.rowGridSelectedEvEm.emit(row);
      }
    }
  }

  /** Generic Entity Details */

  loadEntityFormFieldSteps() {
    if (this.selectedEntity && this.atomicDetailsSteps == null) {
      this.atomicCommonIsLoading = true;
      this.subscribe(
        this.coreDataService.getGenericEntityFormFieldSteps(
          this.selectedEntity.entityKind,
          this.selectedEntity.entityId,
          this.isTenantAdmin
        ),
        ({ data }) => (this.atomicDetailsSteps = data),
        (error) => LogService.error(this, this.loadEntityFormFieldSteps.name, 'Error Loading Detail Steps', error),
        () => {
          this.atomicCommonIsLoading = false;
          this.selectedEntitySubject.next(this.selectedEntity);
        }
      );
    }
  }

  getMainDetailsPath(stepFormId: number) {
    return ApiPath.Relations.RELATION_FORMDETAILS(this.apiPrefix, this.selectedEntity.entityKind, this.selectedEntity.entityId, stepFormId);
    // return ApiPath.Relations.RELATION_DETAILS(this.relationName, this.selectedEntity.entityId);
  }

  createUpdateDetailsCallbackEvEm(callback: EntityFieldsListContentCallBack) {
    const m = this.createUpdateDetailsCallbackEvEm.name;

    LogService.debug(this, m, 'CREATE/UPDATE callback.isSuccess: ' + callback.isSuccess + ' - Callback Object:', callback);
    if (callback.isSuccess) {
      this.onRowClicked(this.selectedEntity);
      this.gbLoadEntitiesData();
    }
    this.onEditChange(callback.isEditing);
    // ? this.isNewEntity = callback.isNewEntity;
  }

  onDetailsSave($event) {
    if (this.entityFormContent) {
      this.entityFormContent.onSave($event);
    }
    $event.stopPropagation();
  }

  onDetailsCancel($event) {
    if (!this.saveIsDisabled) {
      this.enableConfirmCancel = true;
      this.confirmCancelMenu.openMenu();
      this.cdRef.detectChanges();
      // this.confirmCancelButton.focus();
      $event.stopPropagation();
    } else {
      this.onDetailsConfirmCancel();
    }
  }

  onDetailsConfirmCancel() {
    if (this.entityFormContent) {
      this.entityFormContent.onConfirmCancel();
    }
    this.enableConfirmCancel = false;
  }

  getTooltipString(c: GenericGridViewModel, row: any): string {
    switch (c.columnType) {
      case 'date':
        return this.getDate(row[c.entityField]);
      case 'timestamp':
        return this.getTimestamp(row[c.entityField]);
      case 'price':
        return row[c.entityField] != null ? this.getMoney(row[c.entityField]) : row[c.columnPriceSwitchField];
      case 'factor':
        return this.getFactor(row[c.entityField]);
      case 'percentage':
        return this.getPercentageNormalized(row[c.entityField]);
      default:
        return row[c.entityField];
    }
  }

  openAuditLog(row: Entity) {
    this.dialog.open(GenericAuditLogModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'generic-audit-log-modal-dialog',
      disableClose: true,
      data: {
        selectedEntity: row,
      } as GenericAuditLogModalDialogData,
    });
  }
}
