import { KeyValue } from '@angular/common';
import {
  BannerSettings,
  FlyingButtonSettings,
  HelpSettings,
  LanguageRegion,
  NewsSettings,
  PublicPageSettings,
  QuoteSettings,
  TopNavbarSettings,
} from 'src/app/models/preference-settings';

export interface State {
  paginatorOptions: number[];
  filterRules: KeyValue<string, string>[];
  languageRegion: LanguageRegion;
  navbarSettings: TopNavbarSettings;
  bannerSettings: BannerSettings;
  publicPageSettings: PublicPageSettings;
  quoteSettings: QuoteSettings;
  newsSettings: NewsSettings;
  initialPageLink: string;
  demoHtml: string;
  loginImage: string;
  flyingButtonCoordinates: FlyingButtonSettings;
  enabledPaths: string[];
  settingShowEntityid: boolean;
  helpSettings: HelpSettings;
  settingLicenseRenewal: string;
  settingTenantPrefixBrowserTab: boolean;
  isLoading?: boolean;
  error?: boolean;
}

export const initialState: State = {
  paginatorOptions: [],
  filterRules: [],
  languageRegion: null,
  navbarSettings: null,
  bannerSettings: null,
  publicPageSettings: null,
  quoteSettings: null,
  newsSettings: null,
  helpSettings: null,
  settingLicenseRenewal: null,
  initialPageLink: '',
  demoHtml: '',
  loginImage: null,
  flyingButtonCoordinates: { x: -100, y: -100 },
  enabledPaths: null,
  settingShowEntityid: false,
  settingTenantPrefixBrowserTab: false,
  isLoading: false,
  error: false,
};
