import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Entity } from 'src/app/models/entity';
import { GenericEntityFormFieldEvent, GenericEntityFormFieldStep } from 'src/app/models/forms/form-field';
import { EntityFieldsListContentCallBack, GenericEntityField } from 'src/app/models/generic-entity-field';
import { RootStoreState } from 'src/app/root-store';
import { AuthStoreSelectors } from 'src/app/root-store/auth-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import { LogService } from 'src/app/services/log-service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';
import {
  GenericEntityFormModalDialogComponent,
  GenericEntityFormModalDialogData,
} from '../generic-entity-form-modal-dialog/generic-entity-form-modal-dialog.component';
import { GenericEntityFormComponent } from '../generic-entity-form/generic-entity-form.component';
import {
  GenericEntityWizardModalDialogComponent,
  GenericEntityWizardModalDialogData,
} from '../generic-entity-wizard-modal-dialog/generic-entity-wizard-modal-dialog.component';
import { GenericGridViewComponent, GenericGridViewModel } from '../generic-grid-view/generic-grid-view.component';

@Component({
  selector: 'app-generic-grid-view-with-details',
  templateUrl: './generic-grid-view-with-details.component.html',
  styleUrls: ['./generic-grid-view-with-details.component.scss'],
})
export class GenericGridViewWithDetailsComponent extends FormatComponent implements OnInit, AfterViewChecked {
  @ViewChild('confirmCancelMenu') confirmCancelMenu: MatMenuTrigger;
  @Input() titleKey: string;
  @Input() isEditing: boolean;
  @Input() isPanelExpanded = true;
  @Input() showGridTitle = false;
  @Input() showPanelTitleToggle = false;
  @Input() showSpreadsheetButton = false;

  @Input() leftButtonKey = _('label_edit');
  @Input() rightButtonKey = _('label_add');
  @Input() allowAdd = true;
  @Input() allowRemove = true;
  @Input() autoSelectFirstRow = false;
  @Input() rightButtonEnabledOverride = true;
  @Input() isWizard = false;

  @Input() gridEntityKind: string;
  @Input() gridApiPath: string;
  @Input() gridRefreshEvent: Observable<void>;
  @Input() gridEnableSearch = false;
  @Input() gridEnablePagination = false;
  @Input() gridEnableHeader = false;
  @Input() gridColumnsConfig: GenericGridViewModel[];
  @Input() gridNoDataMessage: string;
  @Input() gridPageSize = 10;

  @Input() detailsBaseApiPath: string;
  @Input() noDetailsCustomMessage = false;

  @Input() relatedEntityKinds: string[] = null;
  @Input() relatedEntityIds: number[] = null;
  @Input() createEventDataChangedCallback?: (event: GenericEntityFormFieldEvent) => void;

  @Output() isEditingChange = new EventEmitter<boolean>();
  @Output() rowGridSelectedEvEm = new EventEmitter<Entity>();
  @Output() treeNodeFetchedEvEm = new EventEmitter<GenericEntityField[]>();
  @Output() rowDetailsUpdatedEvEm = new EventEmitter<GenericEntityField[]>();
  @Output() eventDataChanged = new EventEmitter<GenericEntityFormFieldEvent>();

  leftButtonEnabled = false;
  rightButtonEnabled = false;
  isLoading = false;

  selectedEntitySubject: BehaviorSubject<Entity> = new BehaviorSubject<Entity>(null);
  selectedRow: Entity;
  selectedRowToRestore: Entity;
  noEntityData = false;
  detailsRowNoData = false;
  saveIsDisabled = true;
  enableConfirmCancel = false;
  isTenantAdmin = false;
  gridRowData: GenericEntityField[];
  atomicDetailsSteps: GenericEntityFormFieldStep[];
  atomicCommonIsLoading = false;

  @ViewChild('genericGridView') grid: GenericGridViewComponent;
  @ViewChild(GenericEntityFormComponent) entityFormContent: GenericEntityFormComponent;

  constructor(
    protected dialog: MatDialog,
    protected cdRef: ChangeDetectorRef,
    protected coreDataService: CoreDataService,
    protected store: Store<RootStoreState.State>,
    protected messageNotifierService: MessageNotifierService,
    protected router: Router
  ) {
    super();
  }

  ngOnInit() {
    const m = this.ngOnInit.name;
    LogService.debug(this, m, 'gridApiPath: ' + this.gridApiPath, null);
    LogService.debug(this, m, 'detailsBaseApiPath: ' + this.detailsBaseApiPath, null);
    this.cdRef.detectChanges();

    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)), (isTenantAdmin) => {
      this.isTenantAdmin = isTenantAdmin;
    });
    if (this.isPanelExpanded === undefined) {
      this.isPanelExpanded = true;
    }
    if (this.gridRefreshEvent) {
      this.subscribe(this.gridRefreshEvent, () => {
        this.gridRowData = null;
        // this.selectedRow = this.selectedRowToRestore ? this.selectedRowToRestore : null;
        // this.selectedEntitySubject.next(this.selectedRow);
        // this.selectedRowToRestore = null;
        this.detailsRowNoData = false;
      });
    }
  }

  ngAfterViewChecked(): void {
    if (this.entityFormContent) {
      this.saveIsDisabled = this.entityFormContent.saveIsDisabled;
      this.cdRef.detectChanges();
    }
  }

  loadEntityFormFieldSteps() {
    if (this.selectedRow && this.atomicDetailsSteps == null) {
      this.atomicCommonIsLoading = true;
      this.subscribe(
        this.coreDataService.getGenericEntityFormFieldSteps(this.selectedRow.entityKind, this.selectedRow.entityId, this.isTenantAdmin),
        ({ data }) => (this.atomicDetailsSteps = data),
        (error) => LogService.error(this, this.loadEntityFormFieldSteps.name, 'Error Loading Detail Steps', error),
        () => {
          this.atomicCommonIsLoading = false;
          this.selectedEntitySubject.next(this.selectedRow);
        }
      );
    }
  }

  onEditChange($event: boolean) {
    this.isEditingChange.emit($event);
    this.isEditing = $event;
    this.cdRef.detectChanges();
  }

  leftButtonClick($event) {
    const m = this.leftButtonClick.name;

    this.isEditing = true;
    this.isEditingChange.emit(this.isEditing);
    $event.stopPropagation();
  }

  rightButtonClick($event) {
    const m = this.rightButtonClick.name;

    $event.stopPropagation();
    const dialogRef = this.dialog.open(GenericEntityWizardModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'generic-entity-wizard-modal-dialog',
      disableClose: true,
      data: {
        wizardName: this.gridEntityKind,
        relatedEntityKinds: this.relatedEntityKinds,
        relatedEntityIds: this.relatedEntityIds,
        eventDataChangedCallback: this.createEventDataChangedCallback,
        isDocumentsTabEnabled: this.isDocumentEnabled,
      } as GenericEntityWizardModalDialogData,
    });
    this.subscribe(dialogRef.afterClosed(), (entity) => {
      if (entity) {
        this.noEntityData = false;
        this.gridRowData = null;
        this.selectedRowToRestore = entity;
        this.rowDetailsUpdatedEvEm.emit(entity);
      }
    });
  }

  gridRowClick(entity: Entity) {
    const m = this.gridRowClick.name;

    this.selectedRow = null;
    this.cdRef.detectChanges();
    this.selectedRow = entity;
    this.grid.gbSetSelectedEntity(entity);
    if (this.isWizard) {
      this.subscribe(
        this.dialog
          .open(GenericEntityFormModalDialogComponent, {
            autoFocus: false,
            width: '90vw',
            maxWidth: '90vw',
            height: '90vh',
            panelClass: 'generic-entity-form-modal-dialog',
            disableClose: true,
            data: {
              selectedEntity: this.selectedRow,
            } as GenericEntityFormModalDialogData,
          })
          .afterClosed()
      );
    } else {
      this.selectedEntitySubject.next(this.selectedRow);
      this.loadEntityFormFieldSteps();
      this.leftButtonEnabled = entity.entityUpdate;
      LogService.debug(this, m, 'selectedRow: ', this.selectedRow);
      this.cdRef.detectChanges();
      this.rowGridSelectedEvEm.emit(entity);
    }
  }

  resetSelectedRow() {
    this.selectedRow = null;
    this.selectedEntitySubject.next(this.selectedRow);
    this.rowGridSelectedEvEm.emit(null);
    this.cdRef.detectChanges();
  }

  getAPICompletePath() {
    return this.detailsBaseApiPath + '/' + this.selectedRow.entityId + '/details';
  }

  putAPICompletePath() {
    return this.detailsBaseApiPath + '/' + this.selectedRow.entityId + '/details';
  }

  getPutMainAPIFormFieldsPath(stepFormId: number) {
    return `${this.detailsBaseApiPath}/${this.selectedRow.entityId}/${stepFormId}/form`;
  }

  fetchDetailsCallbackEvEm(callback: EntityFieldsListContentCallBack) {
    const m = this.fetchDetailsCallbackEvEm.name;

    LogService.debug(this, m, 'FETCH DETAILS callback.isSuccess: ' + callback.isSuccess + ' - Callback Object:', callback);
    // Nothing;
    if (!callback.data || callback.data.length === 0) {
      this.detailsRowNoData = true;
    }
  }

  createUpdateCallbackEvEm(callback: EntityFieldsListContentCallBack) {
    const m = this.createUpdateCallbackEvEm.name;

    LogService.debug(this, m, 'callback.isSuccess: ' + callback.isSuccess + ' - Callback Object:', callback);
    this.isEditing = callback.isEditing;
    this.cdRef.detectChanges();
    if (callback.isSuccess) {
      this.gridRowData = null;
      this.selectedRowToRestore = this.selectedRow;
      this.rowDetailsUpdatedEvEm.emit(callback.data);
    }
    this.isEditingChange.emit(this.isEditing);
  }

  onSave($event) {
    if (this.entityFormContent) {
      this.entityFormContent.onSave($event);
    }
    $event.stopPropagation();
  }

  onCancel($event) {
    if (!this.saveIsDisabled) {
      this.enableConfirmCancel = true;
      this.confirmCancelMenu.openMenu();
      this.cdRef.detectChanges();
      // this.confirmCancelButton.focus();
      $event.stopPropagation();
    } else {
      this.onConfirmCancel();
    }
  }

  onConfirmCancel() {
    if (this.entityFormContent) {
      this.entityFormContent.onConfirmCancel();
    }
    this.enableConfirmCancel = false;
  }

  loadSpreadsheetFile() {
    this.grid.loadSpreadsheetFile();
  }

  getFlyingButtonClass() {
    if (this.isEditing) {
      return 'double-buttons';
    } else {
      return this.showSpreadsheetButton
        ? this.allowAdd || (this.grid && this.grid.isDeleteEnabled)
          ? 'triple-buttons'
          : 'double-buttons'
        : this.allowAdd || (this.grid && this.grid.isDeleteEnabled)
        ? 'double-buttons'
        : '';
    }
  }
}
