import { KeyValue } from '@angular/common';
import { EntityRecord } from './entity';
import { GenericTreeNode } from './tree-view-flat-node';
import { UserParticularRequest } from './user';

export class Desk extends GenericTreeNode {
  deskId: number;
  deskParentId: number;
  deskParent: string;
  deskUser: string;
  deskUserId: number;
  deskUserName: string;
  deskUserFirstname: string;
  deskUserFullname: string;
  deskUserPno: string;
  deskUserLogin: string;
  deskUserLogin2: string;
  deskUserEmail: string;
  deskUserPhone: string;
  deskUserMobile: string;
  deskUserType: string;
  deskUserTypeId: number;
  deskUserGroupType: string;
  deskUserGroupTypeEnabled: boolean;
  deskUserGroupTypeId: number;
  deskUserRoles: EntityRecord[];
  deskUserDelegates: EntityRecord[];
  deskUserRoleRights: boolean;
  deskUserStarts: Date;
  deskUserEnds: Date;
  deskUserEnabled: boolean;
  deskOrganisation: string;
  deskOrganisationId: number;
  deskCostcentre: string;
  deskCostcentreId: number;
  deskLocation: string;
  deskLocationId: number;
  deskPercentualAllocation: number;
  deskHrimport: string;
  deskHrimportId: number;
  deskStarts: Date;
  deskEnds: Date;
  deskBillingCostcentre: string;
  deskBillingCostcentreId: number;
  deskUserInfo1: string;
  deskUserInfo1Enabled: boolean;
  deskUserInfo2: string;
  deskUserInfo2Enabled: boolean;
  deskUserLocked: boolean;
  deskUserPasswordvaliduntil: string;
  deskUserRemarks: string;
  deskUserGroupTypeOptions: KeyValue<string, string>[];
  deskUserTypeOptions: KeyValue<string, string>[];
  deskLegalEntity: string;
  deskLegalEntityId: number;
  deskUserRoleMandatory: boolean;
  deleted?: boolean;
  public constructor(init?: Partial<Desk>) {
    super();
    Object.assign(this, init);
  }
}

export class DeskParticularsRequest {
  deskId: number;
  deskOrganisationId: number;
  deskCostcentreId: number;
  deskBillingCostcentreId: number;
  deskLocationId: number;
  deskPercentualAllocation: number;
  deskStarts: string;
  deskEnds: string;
  deskUser: UserParticularRequest;
  deskUserId: number;
  deskDeleted: boolean;
  deskHrimportId: number;
}

export class OrganisationDeskParticularsRequest {
  deskOrganisationId: number;
  deskCostcentreId: number;
  deskBillingCostcentreId: number;
  deskLocationId: number;
  deskStarts: string;
  deskEnds: string;
}

export class DeskCostcentreRequest {
  desks: DeskParticularsRequest[];
}

export class DeskCostcentreChangeRequest {
  desks: DeskParticularsRequest[];
  changeDate: string;
}

export class DeskParams {
  public static FILTER_ADDRESS_IDS = 'filterAddressIds';
  public static FILTER_BP_IDS = 'filterBpIds';
  public static FILTER_ENDS = 'filterEnds';
  public static FILTER_ISORGANISATIONDESK_IDS = 'filterIsorganisationdeskIds';
  public static FILTER_ORGANISATION_IDS = 'filterOrganisationIds';
  public static FILTER_COSTCENTRE_IDS = 'filterCostcentreIds';
  public static FILTER_LOCATION_IDS = 'filterLocationIds';
  public static FILTER_STARTS = 'filterStarts';
  public static FILTER_USER_IDS = 'filterUserIds';
}
