import { HttpParams } from '@angular/common/http';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import {
  WizardCommunicationStage,
  WizardCommunicationStageType,
} from '../shared/wizard-common-steps/wizard-communication-step/wizard-communication-step.component';
import { Entity } from './entity';

export class Service extends Entity {
  serviceId: number;
  serviceAbb: string;
  serviceName: string;
  serviceType: string;
  serviceTypeId: number;
  serviceKind: string;
  serviceCountry: string;
  serviceIssuer: string;
  serviceProductfamily: string;
  deleted?: boolean;
  serviceCatalogitemcategory: string;
  serviceCatalogitemcategoryId: number;
  serviceCatalogitemkind: string;
  serviceCatalogitemkindId: number;
  serviceCatalogitemtype: string;
  serviceCatalogitemtypeId: number;
  serviceCatalogstyle: string;
  serviceCatalogstyleId: number;
  serviceIssuerId: number;
  serviceProductfamilyId: number;
  serviceRemarks: string;
  serviceSimiliarity: number;
  serviceMonthlyCosts: number;
  serviceMonthlyCostsCurrency: string;
  serviceMonthlyCostsCurrencyId: number;
  serviceMonthlyReportingCosts: number;
  serviceMonthlyReportingCostsCurrency: string;
  serviceMonthlyReportingCostsCurrencyId: number;
}

export class ServiceAlias extends Entity {
  servicealiasCode: string;
  servicealiasId: number;
  servicealiasName: string;
  servicealiasRemarks: string;
  servicealiasServiceCatalogitemcategory: string;
  servicealiasServiceCatalogitemcategoryId: number;
  servicealiasServiceCatalogitemkind: string;
  servicealiasServiceCatalogitemkindId: number;
  servicealiasServiceCatalogitemtype: string;
  servicealiasServiceCatalogitemtypeId: number;
  servicealiasServiceCatalogstyle: string;
  servicealiasServiceCatalogstyleId: number;
  servicealiasServiceCode: string;
  servicealiasServiceCountry: string;
  servicealiasServiceId: number;
  servicealiasServiceName: string;
  servicealiasServiceIssuer: string;
  servicealiasServiceIssuerId: number;
  servicealiasServiceKind: string;
  servicealiasServiceKindId: number;
  servicealiasServiceType: string;
  servicealiasServiceTypeId: number;
}

export class OrderMarketDataService extends Service {
  serviceProviderId: number;
  serviceBillingaccountId: number;
  serviceBillingitemId: number;
  serviceDeliveryitemId: number;
}

export class ServicePriceIncreaseRequest {
  code: string;
  price: number;
  renewalMonths: number;
  validFrom: string;
}

export function getServiceRequestParams(request: PaginatedRequest): HttpParams {
  return PaginatedRequest.getBaseRequestParams(request);
}

export class ServiceParams {
  public static FILTER_TYPE_IDS = 'filterTypeIds';
  public static FILTER_ISSUER_IDS = 'filterIssuerIds';
  public static FILTER_PRODUCTFAMILY_IDS = 'filterProductfamilyIds';
  public static FILTER_EXCLUDE_DELIVERYITEM_IDS = 'filterExcludeDeliveryitemIds';
}

export class ServiceProductMailtemplateStages {
  public static stages: WizardCommunicationStage[] = [
    new WizardCommunicationStage(1050, true, WizardCommunicationStageType.info),
    new WizardCommunicationStage(1051, true, WizardCommunicationStageType.invoice),
  ];
}
