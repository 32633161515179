<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <h1 mat-dialog-title>
    {{ 'changepwd_title' | translate }}
  </h1>

  <div class="button-wrapper">
    <button
      [disabled]="!(changePwdForm && changePwdForm.valid && changePwdForm.dirty) || (isSubmitted && isChanged)"
      mat-raised-button
      color="primary"
      type="submit"
      form="change-psw-form"
      (click)="$event.stopPropagation()"
    >
      {{ 'label_change_password' | translate }}
    </button>
    <span style="padding-right: 5px"></span>
    <button [mat-dialog-close]="null" mat-raised-button color="primary">
      {{ 'label_close' | translate }}
    </button>
  </div>
</div>
<div mat-dialog-content class="change-password">
  <div class="form-wrapper">
    <div *ngIf="!(isSubmitted && isChanged); else pwdChanged">
      <form id="change-psw-form" class="change-password-form" [formGroup]="changePwdForm" (ngSubmit)="changePwd()">
        <div class="wizard-input-field">
          <div class="label-container">
            <span class="label truncate-text">{{ 'changepwd_placeholder_newpwd' | translate }}</span>
          </div>
          <span class="value-container">
            <mat-form-field appearance="outline" class="password-input form-field-input reset-outline-hint">
              <input type="password" formControlName="newPassword" matInput class="form-control" autocomplete="new-password" />
            </mat-form-field>
          </span>
          <mat-error *ngIf="formControls.newPassword.errors">
            <div *ngFor="let errorMessage of getErrorMessageNewPassword()">
              {{ errorMessage | translate }}
            </div>
          </mat-error>
        </div>
        <div class="wizard-input-field">
          <div class="label-container">
            <span class="label truncate-text">{{ 'changepwd_placeholder_confirmpwd' | translate }}</span>
          </div>
          <span class="value-container">
            <mat-form-field appearance="outline" class="password-input form-field-input reset-outline-hint">
              <input type="password" formControlName="confirmNewPassword" matInput autocomplete="new-password" />
            </mat-form-field>
          </span>
          <mat-error *ngIf="formControls.confirmNewPassword.errors">
            <div *ngFor="let errorMessage of getErrorMessageConfirmNewPassword()">
              {{ errorMessage | translate }}
            </div>
          </mat-error>
          <div style="width: 100%" *ngIf="isSubmitted && error">
            <app-alert status="error" [message]="error.errorKey | translate" [tooltip]="error.errorMessage"></app-alert>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #pwdChanged>
    <app-alert status="success" [message]="'changepwd_message_success' | translate"></app-alert>
  </ng-template>
</div>
