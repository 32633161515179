import { KeyValue } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { AccessMode } from '../configs/access-mode';
import { Entity, EntityIdFlagValue, EntityIdValue } from './entity';

export class InvoiceParams {
  public static FILTER_ACCESSMODE = 'accessmode';
  public static FILTER_VENDOR_IDS = 'filterVendorIds';
  public static FILTER_CURRENCY_IDS = 'filterCurrencyIds';
  public static FILTER_LEDGER_IDS = 'filterLedgerIds';
  public static FILTER_SERVICE_IDS = 'filterServiceIds';
  public static FILTER_STATUS_IDS = 'filterStatusIds';
  public static FILTER_BILLINGACCOUNT_IDS = 'filterBillingaccountIds';
  public static FILTER_BILLINGITEM_IDS = 'filterBillingitemIds';
  public static FILTER_CLASSIFICATION_IDS = 'filterClassificationIds';
  public static FILTER_PERIOD_IDS = 'filterPeriodIds';
  public static FILTER_STARTS = 'filterStarts';
  public static FILTER_ENDS = 'filterEnds';
  public static FILTER_IDS = 'filterIds';
}

export class Invoice extends Entity {
  invoiceId: number;
  invoiceVendor: string;
  invoiceVendorId: number;
  invoiceInvoiceno: string;
  invoiceAccount: string;
  invoiceStatus: string;
  invoiceDate: Date;
  invoicePeriod: string;
  invoicePeriodId: number;
  invoiceStarts: Date;
  invoiceEnds: Date;
  invoiceCurrency: string;
  invoiceSubtotal: number;
  invoiceTotal: number;
  invoiceRemarks: number;
  invoiceCurrencyId: number;
  invoiceBillingaccounts: Entity[];
  invoiceNextExpectedDate: Date;
  invoiceReference: string;
  invoiceEmailCount: number;
}

export class InvoiceCreditNote extends Entity {
  invoicecreditnoteId: number;
  invoicecreditnoteInvoice: string;
  invoicecreditnoteInvoiceId: number;
  invoicecreditnoteVendor: string;
  invoicecreditnoteVendorId: number;
  invoicecreditnoteInvoiceno: string;
  invoicecreditnoteAccount: string;
  invoicecreditnoteStatus: string;
  invoicecreditnoteDate: Date;
  invoicecreditnotePeriod: string;
  invoicecreditnotePeriodId: number;
  invoicecreditnoteStarts: Date;
  invoicecreditnoteEnds: Date;
  invoicecreditnoteCurrency: string;
  invoicecreditnoteSubtotal: number;
  invoicecreditnoteTotal: number;
  invoicecreditnoteRemarks: number;
  invoicecreditnoteCurrencyId: number;
  invoicecreditnoteBillingaccounts: Entity[];
  invoicecreditnoteReference: string;
  invoicecreditnoteEmailCount: number;
}

export class InvoiceParticulars {
  invoiceId: number;
  invoiceInvoiceno: string;
  invoiceAccounts: EntityIdValue[];
  invoiceAccountsToAdd?: EntityIdFlagValue[];
  invoiceAccountsToDelete?: EntityIdValue[];
  invoiceAccountOptions: EntityIdValue[];
  invoiceAskForAddingRegularCharges: boolean;
  invoiceVendorId: number;
  invoiceVendor: string;
  invoiceStatusId: number;
  invoiceStatus: string;
  invoiceStatusOptions: KeyValue<string, string>[];
  invoiceClassificationId: number;
  invoiceClassification: string;
  invoiceClassificationOptions: KeyValue<string, string>[];
  invoiceReference: string;
  invoiceFinancialsystemnumber: string;
  invoiceDate: Date;
  invoiceStarts: Date;
  invoiceEnds: Date;
  invoicePeriodId: number;
  invoicePeriod: string;
  invoicePeriodOptions: KeyValue<string, string>[];
  invoiceCurrencyId: number;
  invoiceCurrency: string;
  invoiceAccrued: boolean;
  invoiceCredit?: boolean;
  invoiceDebit?: boolean;
  invoiceFixedOnly?: boolean;
  invoiceTaxable: boolean;
  invoiceAmount: number;
  invoiceSubtotal: number;
  invoiceTax: number;
  invoiceTotal: number;
  invoiceRegularSubtotal: number;
  invoiceRegularTax: number;
  invoiceRegularTotal: number;
  invoiceAdditionalSubtotal: number;
  invoiceAdditionalTax: number;
  invoiceAdditionalTotal: number;
  invoiceFixedSubtotal: number;
  invoiceFixedTax: number;
  invoiceFixedTotal: number;
  invoiceDifferenceSubtotal: number;
  invoiceDifferenceTax: number;
  invoiceDifferenceTotal: number;
  invoicePaid: Date;
  invoicePaymentCurrencyId: number;
  invoicePaymentCurrency: string;
  invoicePaymentRate: number;
  invoicePayment: number;
  invoicePurchaseorder: string;
  invoiceCreatedby: string;
  invoiceCreatedtime: Date;
  invoiceChangedby: string;
  invoiceChangedtime: Date;
  invoiceRemarks?: string;
}

export class InvoiceParticularsItem extends Entity {
  invoiceitemId: number;
  invoiceitemBillingitemId: number;
  invoiceitemService: string;
  invoiceitemDescription: string;
  invoiceitemServiceCode: string;
  invoiceitemBillingaccountId: number;
  invoiceitemBillingaccount: string;
  invoiceitemPlatform: string;
  invoiceitemQuantity: number;
  invoiceitemQuantityEquals: boolean;
  invoiceitemAccess: number;
  invoiceitemAccessEquals: boolean;
  invoiceitemBasic: number;
  invoiceitemBasicEquals: boolean;
  invoiceitemDiscount: number;
  invoiceitemDiscountEquals: boolean;
  invoiceitemSubtotal: number;
  invoiceitemCurrencyId: number;
  invoiceitemCurrencyIdEquals: boolean;
  invoiceitemCurrency: string;
  invoiceitemTax: number;
  invoiceitemTaxEquals: boolean;
  invoiceitemTotal: number;
  invoiceitemTotalEquals: boolean;
  invoiceitemStarts: Date;
  invoiceitemStartsEquals: boolean;
  invoiceitemEnds: Date;
  invoiceitemEndsEquals: boolean;
  invoiceitemHowcount: string;
  invoiceitemHowcountId: number;
  invoiceitemHowcountIdEquals: boolean;
  invoiceitemHowcountOptions: KeyValue<string, string>[];
  invoiceItemDeleted = false;
  invoiceitemIsUsage: boolean;
  invoiceitemIsUsageUnits: boolean;
}

export class InvoiceWorkflow {
  invoiceworkflowDate: Date;
  invoiceworkflowChangedby: string;
  invoiceworkflowChangedon: Date;
  invoiceworkflowExpectedreceive: string;
  invoiceworkflowExpectedreceiveDate: Date;
  invoiceworkflowExpectedreceiveChangedby: string;
  invoiceworkflowExpectedreceiveChangedon: Date;
  invoiceworkflowExpectedreceiveRemarks: string;
  invoiceworkflowReceivefinance: string;
  invoiceworkflowReceivefinanceDate: Date;
  invoiceworkflowReceivefinanceChangedby: string;
  invoiceworkflowReceivefinanceChangedon: Date;
  invoiceworkflowReceivefinanceRemarks: string;
  invoiceworkflowReceivecontrolling: string;
  invoiceworkflowReceivecontrollingDate: Date;
  invoiceworkflowReceivecontrollingChangedby: string;
  invoiceworkflowReceivecontrollingChangedon: Date;
  invoiceworkflowReceivecontrollingRemarks: string;
  invoiceworkflowPassfinance: string;
  invoiceworkflowPassfinanceDate: Date;
  invoiceworkflowPassfinanceChangedby: string;
  invoiceworkflowPassfinanceChangedon: Date;
  invoiceworkflowPassfinanceRemarks: string;
  invoiceworkflowBaseduedate: string;
  invoiceworkflowBaseduedateDate: Date;
  invoiceworkflowBaseduedateChangedby: string;
  invoiceworkflowBaseduedateChangedon: Date;
  invoiceworkflowBaseduedateRemarks: string;
  invoiceworkflowPaymentterms: string;
  invoiceworkflowPaymenttermsDays: number;
  invoiceworkflowPaymenttermsChangedby: string;
  invoiceworkflowPaymenttermsChangedon: Date;
  invoiceworkflowPaymenttermsRemarks: string;
  invoiceworkflowDuedate: string;
  invoiceworkflowDuedateDate: Date;
  invoiceworkflowDuedateChangedby: string;
  invoiceworkflowDuedateChangedon: Date;
  invoiceworkflowDuedateRemarks: string;
  invoiceworkflowPaymentpaid: string;
  invoiceworkflowPaymentpaidDate: Date;
  invoiceworkflowPaymentpaidChangedby: string;
  invoiceworkflowPaymentpaidChangedon: Date;
  invoiceworkflowPaymentpaidRemarks: string;
  invoiceworkflowExcalationRemarks: string;
}

export class InvoiceParticularsItemQuantity extends Entity {
  invoiceitemincludeId: number;
  invoiceitemincludeAsset: string;
  invoiceitemincludeAssetId: number;
  invoiceitemincludeInclude: boolean;
  invoiceitemincludeQuantity: number;
  invoiceitemincludeStarts: Date;
}

export function getInvoiceRequestParams(request: PaginatedRequest, linkable: boolean): HttpParams {
  let params = PaginatedRequest.getBaseRequestParams(request);
  if (linkable) {
    params = params.append(InvoiceParams.FILTER_ACCESSMODE, AccessMode.link.toString());
  }
  return params;
}
