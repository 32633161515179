import { KeyValue } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import insertTextAtCursor from 'insert-text-at-cursor';
import { Observable, firstValueFrom } from 'rxjs';
import Base64UploaderPlugin from 'src/@ckeditor/Base64Upload';
import {
  MailPreviewModalDialogComponent,
  MailPreviewModalDialogData,
} from 'src/app/configurations-pages/mail-template/mail-preview-modal-dialog/mail-preview-modal-dialog.component';
import { Entity } from 'src/app/models/entity';
import {
  MailTemplateItemParticular,
  MailTemplateItemRequest,
  MailTemplateStage,
} from 'src/app/models/mailtemplate/mail-template-particular';
import { MailTemplatesDataService } from 'src/app/services/mail-template-data.service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { BaseFormFieldComponent } from '../base-form-field/base-form-field-component';

@Component({
  selector: 'app-form-field-mailtemplate',
  templateUrl: './form-field-mailtemplate.component.html',
  styleUrls: ['./form-field-mailtemplate.component.scss'],
})
export class FormFieldMailtemplateComponent extends BaseFormFieldComponent implements OnInit {
  @ViewChild('ckeditor') ckeditor: CKEditorComponent;
  @Input() entityKind: string;
  @Input() mailpreviewSubj: Observable<void> = new Observable<void>();

  mailtemplateId: number;
  stageOptions: MailTemplateStage[];
  selectedStage: MailTemplateStage;

  languageOptions: Entity[];
  selectedLanguage: Entity;
  mailTemplateItem: MailTemplateItemParticular;
  isStringInputMenuOpen = false;
  isLoading = false;

  public Editor = ClassicEditor;
  public config;

  constructor(
    private mailTemplateService: MailTemplatesDataService,
    private messageNotifierService: MessageNotifierService,
    protected dialog: MatDialog
  ) {
    super();

    this.config = {
      heading: {
        options: [
          { model: 'paragraph', title: this.translate.instant(_('ckeditor_paragraph')), class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: this.translate.instant(_('ckeditor_heading1')), class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: this.translate.instant(_('ckeditor_heading2')), class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: this.translate.instant(_('ckeditor_heading3')), class: 'ck-heading_heading3' },
        ],
      },
      language: this.translate.currentLang,
      extraPlugins: [Base64UploaderPlugin],
      link: { addTargetToExternalLinks: true },
    };
  }

  async ngOnInit(): Promise<void> {
    this.mailtemplateId = this.formFieldData.formfieldEntityValue;
    this.isLoading = true;
    await this.loadMailTemplateOptions();
    if (this.selectedStage && this.selectedLanguage) {
      if (this.mailtemplateId == null) {
        this.subscribe(this.mailTemplateService.createMailTemplate(this.entityKind), ({ data }) => {
          if (data && data.state) {
            this.mailtemplateId = data.id;
            this.formFieldData.formfieldEntityValue = data.id;
            this.changeValueEvEm.emit();
            this.loadMailTemplateItemParticulars();
          } else if (data && !data.state && data.error) {
            this.messageNotifierService.showErrorMessage(data.error, data.systemerrorId);
            this.isLoading = false;
          }
        });
      } else {
        this.loadMailTemplateItemParticulars();
      }
    }

    if (this.saveSubj) {
      this.subscribe(this.saveSubj, () => {
        this.saveMailTemplateItem();
        this.isSavePressed = true;
      });
    }

    if (this.mailpreviewSubj) {
      this.subscribe(this.mailpreviewSubj, () => this.showPreview());
    }
  }

  loadMailTemplateItemParticulars() {
    this.isLoading = true;
    this.subscribe(
      this.mailTemplateService.getMailTemplateItemParticular(
        this.mailtemplateId,
        this.selectedStage.entityId,
        this.selectedLanguage.entityId
      ),

      ({ data }) => {
        this.mailTemplateItem = data;
        this.mailTemplateItem.mailtemplateitemStageId = this.selectedStage.entityId;
        this.mailTemplateItem.mailtemplateitemLanguageId = this.selectedLanguage.entityId;
        this.isLoading = false;
      }
    );
  }

  async loadMailTemplateOptions() {
    this.stageOptions = (await firstValueFrom(this.mailTemplateService.getMailTemplateStageOptions(this.entityKind))).data;
    this.selectedStage = this.stageOptions && this.stageOptions.length > 0 ? this.stageOptions[0] : null;
    this.languageOptions = (await firstValueFrom(this.mailTemplateService.getMailTemplateLanguageOptions())).data;
    this.selectedLanguage = this.languageOptions && this.languageOptions.length > 0 ? this.languageOptions[0] : null;
  }

  selectedStageOptionChanged() {
    this.saveMailTemplateItem();
  }

  selectedLanguageOptionChanged() {
    this.saveMailTemplateItem();
  }

  saveMailTemplateItem() {
    if (this.mailTemplateItem.mailtemplateitemSubject != null && this.mailTemplateItem.mailtemplateitemBody != null) {
      const request: MailTemplateItemRequest = {
        mailtemplateitemStageId: this.mailTemplateItem.mailtemplateitemStageId,
        mailtemplateitemLanguageId: this.mailTemplateItem.mailtemplateitemLanguageId,
        mailtemplateitemSubject: this.mailTemplateItem.mailtemplateitemSubject,
        mailtemplateitemBody: this.mailTemplateItem.mailtemplateitemBody,
      };
      if (this.mailTemplateItem.mailtemplateitemId === -1) {
        this.subscribe(this.mailTemplateService.createMailTemplateItem(this.mailtemplateId, request), ({ data }) => {
          this.loadMailTemplateItemParticulars();
        });
      } else {
        this.subscribe(
          this.mailTemplateService.saveMailTemplateItem(this.mailtemplateId, this.mailTemplateItem.mailtemplateitemId, request),
          ({ data }) => {
            this.loadMailTemplateItemParticulars();
          }
        );
      }
    } else {
      this.loadMailTemplateItemParticulars();
    }
  }

  showPreview() {
    this.dialog.open(MailPreviewModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'mail-preview-modal-dialog',
      disableClose: true,
      data: {
        mailframedefaultId: null,
        mailtemplateId: this.mailtemplateId,
        stageId: this.mailTemplateItem.mailtemplateitemStageId,
        languageId: this.mailTemplateItem.mailtemplateitemLanguageId,
      } as MailPreviewModalDialogData,
    });
  }

  onAddSingleTextPlaceholder(button: KeyValue<string, string>) {
    if (this.mailTemplateItem.mailtemplateitemSubject == null) {
      this.mailTemplateItem.mailtemplateitemSubject = '';
    }
    const el = document.getElementById('form-field-multiline-inputarea');
    insertTextAtCursor(el, button.key);
  }

  onAddCkeditorTextPlaceholder(button: KeyValue<string, string>) {
    this.ckeditor.editorInstance?.model.change((writer) => {
      const insertPosition = this.ckeditor.editorInstance?.model.document.selection.getLastPosition();
      writer.insertText(button.key, null, insertPosition);
    });
  }

  onCkeditorReady(): void {
    // WORKAROUND - to fix ngModel binding does not set initial value https://github.com/ckeditor/ckeditor5-angular/issues/265
    if (this.ckeditor && this.mailTemplateItem.mailtemplateitemBody) {
      setTimeout(() => {
        // this.ckeditor.editorInstance?.setData(this.mailTemplateItem.mailtemplateitemBody);
      }, 0);
    }
  }
}
