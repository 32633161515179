import { Action, createReducer, on } from '@ngrx/store';
import * as PreferencesActions from './actions';
import { State, initialState } from './state';

export const preferencesFeatureKey = 'preferences';

export const preferencesReducer = createReducer(
  initialState,
  on(PreferencesActions.loadPreferenceSettings, (state) => ({
    ...state,
    isLoading: true,
    initialPageLink: null,
    demoHtml: null,
    loginImage: null,
    error: null,
    newsSettings: null,
  })),
  on(PreferencesActions.loadPreferenceSettingsSuccess, (state, { preferenceSettings }) => ({
    ...state,
    isLoading: false,
    paginatorOptions: preferenceSettings.settingPagesizes,
    filterRules: preferenceSettings.settingFilterrules,
    initialPageLink: preferenceSettings.settingStartpage,
    demoHtml: preferenceSettings.settingDemopageHtml,
    loginImage: preferenceSettings.settingLoginImage,
    settingShowEntityid: preferenceSettings.settingShowEntityid,
    settingLicenseRenewal: preferenceSettings.settingLicenseRenewal,
    settingTenantPrefixBrowserTab: preferenceSettings.settingTenantPrefixBrowserTab,
    navbarSettings: {
      tenantImage: preferenceSettings.settingTenantImage,
      headerImage: preferenceSettings.settingHeaderImage,
      headerImageBackgroundColor: preferenceSettings.settingHeaderImageBackgroundColor,
      backgroundColor: preferenceSettings.settingHeaderBackgroundColor,
      description: preferenceSettings.settingDescription,
      viewAsUserEnabled: preferenceSettings.settingViewAsUserEnabled,
      settingInboxEnabled: preferenceSettings.settingInboxEnabled,
      settingAllowUseCases: preferenceSettings.settingAllowUseCases,
    },
    quoteSettings: {
      quote: preferenceSettings.settingQuote,
      quoteAuthor: preferenceSettings.settingQuoteAuthor,
    },
    newsSettings: {
      settingNews: preferenceSettings.settingNews,
      settingNewsEnabled: preferenceSettings.settingNewsEnabled,
    },
    // bannerSettings: {
    //   isBannerVisible: true,
    //   bannerIntro: 'test text test text test text test text test text ',
    //   bannerLinkText: 'here',
    //   bannerLink: 'https://project.bst-ag.ch/issues/1477',
    //   bannerOutro: 'outro',
    //   bannerFontColor: preferenceSettings.settingBannerFontColor,
    //   bannerLinkColor: preferenceSettings.settingBannerLinkColor,
    //   bannerBackgroundColor: preferenceSettings.settingBannerBackgroundColor,
    // },
    bannerSettings: {
      isBannerVisible: preferenceSettings.settingBannerLinkText != null && preferenceSettings.settingBannerLink != null,
      bannerIntro: preferenceSettings.settingBannerIntro,
      bannerLinkText: preferenceSettings.settingBannerLinkText,
      bannerLink: preferenceSettings.settingBannerLink,
      bannerOutro: preferenceSettings.settingBannerOutro,
      bannerFontColor: preferenceSettings.settingBannerFontColor,
      bannerLinkColor: preferenceSettings.settingBannerLinkColor,
      bannerBackgroundColor: preferenceSettings.settingBannerBackgroundColor,
    },
    publicPageSettings: {
      backgroungImage: preferenceSettings.settingBackgroundImage,
      footerText: preferenceSettings.settingFooterText,
      footerLinkText: preferenceSettings.settingFooterLinkText,
      footerLink: preferenceSettings.settingFooterLink,
      footerCopyright: preferenceSettings.settingFooterCopyright,
      footerFontColor: preferenceSettings.settingFooterFontColor,
      footerLinkColor: preferenceSettings.settingFooterLinkColor,
      footerBackgroundColor: preferenceSettings.settingFooterBackgroundColor,
      openAuthorizations: preferenceSettings.settingOpenAuthorizations,
    },
    helpSettings: {
      settingHelpUrl: preferenceSettings.settingHelpUrl,
      settingHelpLogin: preferenceSettings.settingHelpLogin,
      settingHelpPassword: preferenceSettings.settingHelpPassword,
    },
    languageRegion: { language: preferenceSettings.settingLanguage, region: preferenceSettings.settingRegionalsetting },
  })),
  on(PreferencesActions.loadFlyingButtonSettingsSuccess, (state, { flyingButtonSettings }) => ({
    ...state,
    flyingButtonCoordinates: { x: flyingButtonSettings.x, y: flyingButtonSettings.y },
  })),
  on(PreferencesActions.loadPreferenceSettingsFailure, (state, error) => ({
    ...state,
    isLoading: false,
    error: true,
  })),
  on(PreferencesActions.loadEnabledPathsSuccess, (state, { enabledPaths }) => ({
    ...state,
    enabledPaths,
  })),
  on(PreferencesActions.loadEnabledPathsFailure, (state, error) => ({
    ...state,
    isLoading: false,
    error: true,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return preferencesReducer(state, action);
}
