import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { combineLatest } from 'rxjs';
import { NotificationCount, NotificationKind } from 'src/app/models/notification';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { HelpSettings, NewsSettings, TopNavbarSettings } from 'src/app/models/preference-settings';
import { AuthStoreAction, AuthStoreSelectors, RootStoreState } from 'src/app/root-store';
import { CoreUiInterfaceStoreSelectors } from 'src/app/root-store/core-ui-interface.store';
import { ErrorHandlingStoreAction, ErrorHandlingStoreSelectors } from 'src/app/root-store/error-handling-store';
import { NotificationsStoreAction, NotificationsStoreSelectors } from 'src/app/root-store/notifications-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HelpAuthService } from 'src/app/services/auth/help-auth.service';
import { CoreDataService } from 'src/app/services/core-data.service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { BaseComponent } from 'src/app/shared/base-components/base-component';
import { environment } from 'src/environments/environment';
import { AuthUser } from '../../auth/auth-user';
import { NewsModalComponent, NewsModalData } from '../../modal/news-modal/news-modal.component';
import { ViewasuserModalDialogComponent } from '../../modal/viewasuser-modal-dialog/viewasuser-modal-dialog.component';

@Component({
  selector: 'app-top-navbar-content',
  templateUrl: './top-navbar-content.component.html',
  styleUrls: ['./top-navbar-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopNavbarContentComponent extends BaseComponent implements OnInit {
  // @Input() messages = [];
  // @Input() notifications = [];
  sideMenuVisible = true;
  // userProfileVisible = true;

  notificationCount: NotificationCount;

  authUser: AuthUser;
  isAuthenticated = false;
  isTenantAdminAuthenticated = false;
  selectedOption: string;
  title: string;
  navbarSettings: TopNavbarSettings;
  helpSettings: HelpSettings;
  newsSettings: NewsSettings;

  isErrorOccurred = false;
  isServerUnreachableError = false;
  isExceptionErrorFileLoading = false;

  isMenuDisabled = false;

  constructor(
    private messageNotifierService: MessageNotifierService,
    private sanitizer: DomSanitizer,
    private coreDataService: CoreDataService,
    private authService: AuthService,
    public dialog: MatDialog,
    private store: Store<RootStoreState.State>,
    private router: Router,
    private translate: TranslateService,
    private helpAuthService: HelpAuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectAuthUser)), (authUser) => {
      this.authUser = authUser;
      // if (this.authUser) {
      //   this.showWelcomMessage();
      // }
    });
    this.subscribe(this.initialData(), ([isJustAuth, isTenantAdminAuth, navbarSettings, helpSettings, newsSettings]) => {
      this.isAuthenticated = isJustAuth;
      this.isTenantAdminAuthenticated = isTenantAdminAuth;
      this.navbarSettings = navbarSettings;
      this.helpSettings = helpSettings;
      this.newsSettings = newsSettings;
      if (this.isAuthenticated) {
        this.store.dispatch(NotificationsStoreAction.startPollingNotificationsCount());
      } else {
        this.store.dispatch(NotificationsStoreAction.finishPollingNotificationsCount());
      }
      if (
        (this.isAuthenticated || this.isTenantAdminAuthenticated) &&
        this.newsSettings != null &&
        this.newsSettings.settingNewsEnabled &&
        this.newsSettings?.settingNews.length > 0
      ) {
        this.dialog.open(NewsModalComponent, {
          height: '80vh',
          width: '80vw',
          autoFocus: false,
          data: {
            news: this.newsSettings?.settingNews,
          } as NewsModalData,
        });
      }
    });
    this.subscribe(this.store.select(ErrorHandlingStoreSelectors.selectIsErrorOccurred), (isError) => {
      this.isErrorOccurred = isError;
    });
    this.subscribe(this.store.select(NotificationsStoreSelectors.selectCountNotifications), (count) => {
      if (this.isServerUnreachableError) {
        this.store.dispatch(ErrorHandlingStoreAction.resetServerUnreachableError());
      }
      this.notificationCount = count;
      this.checkToastrMessages();
      // this.notificationCount.countTaricsEntitlement = [
      //   { id: 1, name: 'Test1', date: new Date(), value: 'aaa' },
      //   { id: 2, name: 'Test2', date: new Date(), value: 'aaa' },
      //   { id: 3, name: 'Test3', date: new Date(), value: 'aaa' },
      // ];
    });
    this.subscribe(
      this.store.select(ErrorHandlingStoreSelectors.selectServerUnreachableErrorOccurred),
      (isError) => (this.isServerUnreachableError = isError)
    );
    this.subscribe(
      this.store.pipe(select(CoreUiInterfaceStoreSelectors.selectCoreUiInterfaceIsUiDisabled)),
      (isDisabled) => (this.isMenuDisabled = isDisabled)
    );
  }

  private initialData() {
    return combineLatest([
      this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticated)),
      this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)),
      this.store.pipe(select(PreferencesSelectors.selectPreferencesTopNavBarSettings)),
      this.store.pipe(select(PreferencesSelectors.selectPreferencesHelp)),
      this.store.pipe(select(PreferencesSelectors.selectPreferencesNewsSettings)),
    ]);
  }

  goToUsecases() {
    this.router.navigate(['', { outlets: { usecasesOutlet: 'usecases' } }], { skipLocationChange: environment.hideOutletUrl });
  }

  reload() {
    window.location.reload();
  }

  // Call this method in the image source, it will sanitize it.
  transform(base64Image: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }

  goToNotifications() {
    this.router.navigate(['pages/notifications']);
  }

  goToIncomingMails() {
    this.router.navigate(['pages/inbox-mail']);
  }

  goToHelpChat() {
    this.router.navigate(['pages/help-chatbot']);
  }

  configurationOverlay() {
    this.router.navigate(['', { outlets: { configurationOutlet: 'configurations' } }], { skipLocationChange: environment.hideOutletUrl });
  }

  async helpOverlay() {
    // this.router.navigate(['', { outlets: { helpOutlet: 'help' } }], { skipLocationChange: environment.hideOutletUrl });
    // this.subscribe(this.helpAuthService.getWordpress(), (response) => {
    //   console.log(response);
    // });
    let newWindow = window.open();
    document.body.classList.add('waiting-mouse-cursor');
    this.subscribe(
      this.helpAuthService.authHelpWordpress(this.helpSettings.settingHelpUrl, this.authUser.email, this.helpSettings.settingHelpPassword),
      (resp) => {
        if (resp.code === 200) {
          newWindow.location.href = `${this.helpSettings.settingHelpUrl}?mo_jwt_token=${resp.jwt_token}`;
          document.body.classList.remove('waiting-mouse-cursor');
        }
      },
      (error) => {
        this.subscribe(
          this.helpAuthService.authHelpWordpress(
            this.helpSettings.settingHelpUrl,
            this.helpSettings.settingHelpLogin,
            this.helpSettings.settingHelpPassword
          ),
          (resp) => {
            if (resp.code === 200) {
              newWindow.location.href = `${this.helpSettings.settingHelpUrl}?mo_jwt_token=${resp.jwt_token}`;
              document.body.classList.remove('waiting-mouse-cursor');
            }
          },
          (error) => {
            newWindow.location.href = this.helpSettings.settingHelpUrl;
            document.body.classList.remove('waiting-mouse-cursor');
          }
        );
      }
    );
  }

  logout() {
    this.store.dispatch(AuthStoreAction.logout());
  }

  loadExceptionErrorFile() {
    this.setErrorSpreadsheetLoading(true);
    const pr: PaginatedRequest = {
      pageIndex: 0,
      pageSize: 10,
      orderBy: 'error_timestamp',
      sort: 'desc',
    };
    this.subscribe(
      this.coreDataService.getExceptionErrorFileForLoggedUser(pr, this.isTenantAdminAuthenticated),
      (blob) => {
        this.setErrorSpreadsheetLoading(false);
        FileSaver.saveAs(blob.file, blob.filename); // FileSaver;
        this.store.dispatch(ErrorHandlingStoreAction.resetError());
      },
      (error) => this.setErrorSpreadsheetLoading(false)
    );
  }

  // toggleSideMenu(): void {
  //   this.sideMenuService.sidenav.toggle().then((this.sideMenuVisible = !this.sideMenuVisible));
  // }

  setErrorSpreadsheetLoading(isLoading: boolean) {
    this.isExceptionErrorFileLoading = isLoading;
    if (isLoading) {
      document.body.classList.add('waiting-mouse-cursor');
    } else {
      document.body.classList.remove('waiting-mouse-cursor');
    }
  }

  showWelcomMessage() {
    let welcomeMessage: string;
    const curHr = new Date().getHours();

    if (curHr >= 5 && curHr < 11) {
      welcomeMessage = _('dashboard_goodmorning');
    } else if (curHr >= 14 && curHr < 18) {
      welcomeMessage = _('dashboard_goodafternoon');
    } else if (curHr >= 18 && curHr < 22) {
      welcomeMessage = _('dashboard_goodevening');
    } else {
      welcomeMessage = _('dashboard_welcome');
    }
    this.subscribe(this.translate.get(welcomeMessage), (message) => {
      this.messageNotifierService.showInfoMessage(
        `${message} ${this.authUser.firstname ? this.authUser.firstname : this.authUser.fullname}`
      );
    });
  }

  checkToastrMessages() {
    if (this.notificationCount.toastrMessages && this.notificationCount.toastrMessages.length > 0) {
      this.notificationCount.toastrMessages.forEach((msg) => {
        if (msg.id === NotificationKind.info) {
          this.messageNotifierService.showFixedSuccessMessage(msg.value);
        }
        if (msg.id === NotificationKind.warning) {
          this.messageNotifierService.showFixedWarningMessage(msg.value);
        }
        if (msg.id === NotificationKind.error) {
          this.messageNotifierService.showFixedErrorMessage(msg.value);
        }
      });
    }
  }

  viewAsUser() {
    this.dialog.open(ViewasuserModalDialogComponent, {
      width: '60vw',
      minHeight: '60vh',
      autoFocus: false,
    });
  }

  get Environment() {
    return environment;
  }
}
