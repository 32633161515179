import { createAction, props } from '@ngrx/store';
import { ActionResponse } from 'src/app/models/action-response';
import { BillingItem, BillingitemAddRequest } from 'src/app/models/billingitem';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { BillingAccount } from 'src/app/models/vendor-account';

export const selectServices = createAction(
  '[Wizard Service Step] Select Add ServiceToBillingaccount Service Step',
  props<{ servicesStep: BillingItem[] }>()
);

export const selectBillingaccounts = createAction(
  '[Wizard Billing account Step] Select Add ServiceToBillingaccount Billingaccount Step',
  props<{ billingaccountsStep: BillingAccount[] }>()
);

export const exitAddServicesToBillingaccountsWizard = createAction(
  '[AddServicesToBillingaccounts Wizard Page] Exit Add ServiceToBillingaccount Wizard'
);

export const destroyWizard = createAction('[AddServicesToBillingaccounts Wizard Page] Destroy Wizard');

export const validateAddServicesToBillingaccounts = createAction(
  '[Wizard Confirmation Step] Validate Add ServiceToBillingaccount Step',
  props<{ request: BillingitemAddRequest[] }>()
);

export const validateAddServicesToBillingaccountsResult = createAction(
  '[Wizard Confirmation Step] Validate Add ServiceToBillingaccount Step Result',
  props<{ response: EntityWizardResponse }>()
);

export const validateAddServicesToBillingaccountsFail = createAction(
  '[Wizard Confirmation Step] Validate Add ServiceToBillingaccount Step Fail',
  props<{ errorMessage: string }>()
);

export const createNewAddServicesToBillingaccounts = createAction(
  '[Wizard Confirmation Step] Create Add ServiceToBillingaccount Information Step',
  props<{ request: BillingitemAddRequest[] }>()
);

export const createNewAddServicesToBillingaccountsSuccess = createAction(
  '[Wizard Confirmation Step] Create Add ServiceToBillingaccount Information Step Success',
  props<{ response: ActionResponse }>()
);

export const createNewAddServicesToBillingaccountsFail = createAction(
  '[Wizard Confirmation Step] Create Add ServiceToBillingaccount Information Step Fail',
  props<{ errorMessage: string }>()
);
