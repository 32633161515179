import { KeyValue } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export class EntityIdValue {
  id: number;
  value: string;
}

export class EntityIdFlagValue extends EntityIdValue {
  flag: boolean;
}

export class Entity {
  entityCount: number;
  entityId: number;
  entityKind: string;
  entityName: string;
  entityDescription: string;
  entityInfo: string;
  entityUpdate: boolean;
  entityDelete: boolean;
  entityParentId: number;
  entityActive: boolean;
  hasNoteFlag?: boolean;
}

export class EntitySelection extends Entity {
  deleted: boolean;
  isReadonly?: boolean;
}

export class EntityRecord {
  entityId: number;
  entityKind: string;
  entityName: string;
  entityDeleted: boolean;
  entityDescription: string;
}

export class EntityRequest {
  entityId: number;
  entityKind: string;
}

export class EntityOptionFilter {
  entityoptionfilterId: number;
  entityoptionfilterName: string;
  entityoptionfilterOptions: KeyValue<string, string>[];
}

export class EntityPageStatus {
  search: string;
  pageSize: number;
  sortTable: MatSort;
  dataSourceTable: MatTableDataSource<Entity>;
  selectedEntity: Entity;
  filterFirstId?: number;
  filterFirstText?: string;
}
