import { LicenseAssetPeriodAllocationRequest } from 'src/app/models/asset-allocation';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { CancelSharedAssetAllocationUpdateStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-shared-asset-wizard/cancel-shared-asset-allocation-update-step/cancel-shared-asset-allocation-update-step';
import { CancelSharedAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-shared-asset-wizard/init-step/init-step';

export interface State {
  initStep: CancelSharedAssetInitStep;
  allocationUpdate: CancelSharedAssetAllocationUpdateStep;
  request: LicenseAssetPeriodAllocationRequest[];
  response: EntityWizardResponse;
  errorMessage: any;
}

export const initialState: State = {
  initStep: null,
  allocationUpdate: null,
  request: null,
  response: null,
  errorMessage: null,
};
