<div class="base-form-field form-field-chips" (click)="onItemClick()">
  <div class="label-container" *ngIf="formFieldData.formfieldLabelShow">
    <ng-container *ngIf="formFieldData.formfieldLabelDisplay">
      <span class="label" showIfTruncated matTooltip="{{ formFieldData.formfieldTextname | translate }}"
        >{{ formFieldData.formfieldTextname | translate }}
      </span>
      <span *ngIf="formFieldData.formfieldRequired" class="requiredField">*</span>
      <span
        class="tooltip"
        *ngIf="formFieldData.formfieldInfotextname"
        matTooltip="{{ parseEntityTooltip('\n', '&emsp;', formFieldData.formfieldInfotextname | translate) }}"
        [matTooltipClass]="'fo-mat-tooltip tree-tooltip'"
        ><mat-icon class="entityTooltipIcon">info</mat-icon></span
      >
    </ng-container>
    <ng-container *ngIf="!formFieldData.formfieldLabelDisplay">
      <span class="label">&nbsp;</span>
    </ng-container>
  </div>
  <div class="value-container">
    <span class="value">
      <mat-form-field
        *ngIf="
          formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.CHIPS ||
          formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.MULTISELECTION_CHIPS
        "
        appearance="outline"
        class="form-field-input reset-outline-hint chips-input mat-form-field-disabled"
        ><mat-chip-grid #chipList aria-label="Chip Example">
          <mat-chip-row *ngFor="let entity of mockEntities" [removable]="false">
            {{ entity }}
            <mat-icon matChipRemove>clear</mat-icon>
          </mat-chip-row>
          <input [disabled]="true" placeholder="Add..." [matChipInputFor]="chipList" readonly />
        </mat-chip-grid>
        <button [disabled]="true" mat-icon-button matSuffix class="reset-filter">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
      <div
        *ngIf="
          formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.LIST ||
          formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.MULTISELECTION_LIST
        "
        class="entity-table"
      >
        <div class="table-wrapper">
          <div class="table-container">
            <table class="table table-stripes no-header-table" mat-table [dataSource]="mockEntities">
              <ng-container matColumnDef="entity_name">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  {{ row }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
            </table>
          </div>
        </div>
      </div>
    </span>
  </div>
  <button class="remove-button" (click)="remove(); $event.stopPropagation()" mat-icon-button *ngIf="isEditMode">
    <mat-icon>close</mat-icon>
  </button>
</div>
