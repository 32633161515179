<mat-sidenav-container autosize #drawerContainer class="main-sidenav-container">
  <!-- *ngIf="!isRestrictedAuthenticated" -->
  <mat-sidenav
    #sideMenu
    mode="side"
    [opened]="openMenu && !isRestrictedPage"
    [disableClose]="true"
    position="start"
    class="main-sidenav"
    [ngClass]="sideMenuService.sidemenuExpanded ? 'expanded' : 'collapsed'"
  >
    <!--
    [ngClass]="isServerUnreachableError ? 'hidden' : sideMenuService.sidemenuExpanded ? 'expanded' : 'collapsed'" -->
    <app-side-menu-content #sideMenuContent *ngIf="isAuthenticated && !isRestrictedPage"></app-side-menu-content>
  </mat-sidenav>
  <mat-sidenav-content class="main-content-container">
    <app-responsive-breakpoints></app-responsive-breakpoints>
    <app-top-navbar-content
      *ngIf="!isRestrictedPage && isAuthenticated"
      class="main-navbar-container"
      [ngClass]="!isAuthenticated ? 'no-auth' : ''"
    ></app-top-navbar-content>
    <div
      *ngIf="isAuthenticated && bannerSettings && bannerSettings.isBannerVisible"
      class="toolbar-banner-container"
      [ngStyle]="{
        'background-color': bannerSettings && bannerSettings.bannerBackgroundColor ? bannerSettings.bannerBackgroundColor : ''
      }"
    >
      <span
        [ngStyle]="{
          color: bannerSettings && bannerSettings.bannerFontColor ? bannerSettings.bannerFontColor : ''
        }"
        >{{ bannerSettings?.bannerIntro }}
        <a
          [href]="bannerSettings.bannerLink"
          [ngStyle]="{
            color: bannerSettings && bannerSettings.bannerLinkColor ? bannerSettings.bannerLinkColor : ''
          }"
          >{{ bannerSettings?.bannerLinkText }}</a
        >
        {{ bannerSettings?.bannerOutro }}
      </span>
    </div>
    <div
      class="main-content-outer"
      [ngClass]="[
        !isAuthenticated ? 'no-auth' : '',
        bannerSettings && bannerSettings.isBannerVisible ? 'banner' : '',
        isServerUnreachableError ? 'blurred' : ''
      ]"
    >
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="isServerUnreachableError" id="server-error-blurred-overlay"></div>
  </mat-sidenav-content>
</mat-sidenav-container>
<ng-container *ngIf="isAuthenticated">
  <router-outlet #configurationOutlet="outlet" name="configurationOutlet"></router-outlet>
</ng-container>
<ng-container *ngIf="isAuthenticated">
  <router-outlet #helpOutlet="outlet" name="helpOutlet"></router-outlet>
</ng-container>
<ng-container *ngIf="isAuthenticated">
  <router-outlet #usecasesOutlet="outlet" name="usecasesOutlet"></router-outlet>
</ng-container>

<ng-image-fullscreen-view
  *ngIf="imageObject"
  [images]="imageObject"
  [imageIndex]="0"
  [show]="showPreview"
  (close)="closeEventHandler()"
></ng-image-fullscreen-view>
