import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import {
  AssetAllocation,
  AssetAllocationCalculations,
  AssetAllocationParticulars,
  AssetAllocationParticularsRequest,
  LicenseAssetPeriodAllocation,
  LicenseAssetPeriodAllocationRequest,
} from '../models/asset-allocation';
import { AssetSubscription, AssetSubscriptionDate, AssetSubscriptionDateRequest } from '../models/asset-subscription';
import { BaseResponse } from '../models/base-response';
import { EntityWizardResponse } from '../models/forms/entity-wizard';
import { License } from '../models/license';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class LicenseDataService {
  getLicenses(request: PaginatedRequest): Observable<BaseResponse<License[]>> {
    const m = this.getLicenses.name;

    const path = environment.getEndpoint(ApiPath.Licenses.LICENSES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<License[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getLicensesSpreadsheet(request: PaginatedRequest) {
    const m = this.getLicensesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Licenses.LICENSES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getLicenseAllocations(request: PaginatedRequest, assetId: number): Observable<BaseResponse<AssetSubscription[]>> {
    const m = this.getLicenseAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SUBSCRIPTIONS_BYID(assetId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<AssetSubscription[]>>(path, {
      params,
    });

    return retVal;
  }

  getLicenseAllocationsSpreadsheet(request: PaginatedRequest, assetId: number) {
    const m = this.getLicenseAllocationsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SUBSCRIPTIONS_BYID_SPREADSHEET(assetId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getLicenseAssetPeriods(assetId: number, periodStarts?: string): Observable<BaseResponse<LicenseAssetPeriodAllocation[]>> {
    const m = this.getLicenseAssetPeriods.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_PERIODS(assetId));
    const params = periodStarts ? new HttpParams().set('periodStarts', periodStarts) : null;
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<LicenseAssetPeriodAllocation[]>>(path, { params });

    return retVal;
  }

  getLicenseAssetAllocationsByAssetIdAndAssetStarts(assetId: number, periodStarts: string): Observable<BaseResponse<AssetAllocation[]>> {
    const m = this.getLicenseAssetAllocationsByAssetIdAndAssetStarts.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ALLOCATIONS);
    LogService.info(this, m, LogService.GET + path, null);

    const params = new HttpParams().set('filterPeriodStarts', periodStarts).set('filterAssetIds', assetId);
    const retVal = this.http.get<BaseResponse<AssetAllocation[]>>(path, {
      params,
    });

    return retVal;
  }

  getLicenseAssetAllocations(request: PaginatedRequest): Observable<BaseResponse<AssetAllocation[]>> {
    const m = this.getLicenseAssetAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ALLOCATIONS);
    LogService.info(this, m, LogService.GET + path, null);

    const params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<AssetAllocation[]>>(path, {
      params,
    });

    return retVal;
  }

  getLicenseAssetAllocationsSpreadsheet(request: PaginatedRequest, assetId: number) {
    const m = this.getLicenseAssetAllocationsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ALLOCATIONS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    const params = PaginatedRequest.getBaseRequestParams(request).set('filterAssetIds', assetId);
    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getLicenseAssetPeriodAllocations(
    assetId: number,
    periodOnly: boolean,
    activationDate: string,
    billingStarts: string,
    activationDeskIds: number[],
    activationFoc: boolean,
    deactivationDate: string,
    billingEnds: string,
    deactivationDeskIds: number[]
  ): Observable<BaseResponse<LicenseAssetPeriodAllocation[]>> {
    const m = this.getLicenseAssetPeriodAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ALLOCATIONS_BYID(assetId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams();
    if (periodOnly != null) {
      params = params.append('periodOnly', true);
    }
    if (activationDate != null) {
      params = params.append('activationDate', activationDate);
    }
    if (billingStarts != null) {
      params = params.append('billingStarts', billingStarts);
    }
    if (activationDeskIds != null) {
      params = params.append('activationDeskIds', activationDeskIds.join(','));
    }
    if (activationFoc === true) {
      params = params.append('activationFoc', true);
    }
    if (deactivationDate != null) {
      params = params.append('deactivationDate', deactivationDate);
    }
    if (billingEnds != null) {
      params = params.append('billingEnds', billingEnds);
    }
    if (deactivationDeskIds != null) {
      params = params.append('deactivationDeskIds', deactivationDeskIds.join(','));
    }
    const retVal = this.http.get<BaseResponse<LicenseAssetPeriodAllocation[]>>(path, { params });

    return retVal;
  }

  saveLicenseAssetPeriodAllocations(
    assetId: number,
    request: LicenseAssetPeriodAllocationRequest[]
  ): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.saveLicenseAssetPeriodAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ALLOCATIONS_BYID(assetId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  getLicensePeriodAllocations(
    assetId: number,
    periodId: number,
    request?: PaginatedRequest
  ): Observable<BaseResponse<AssetAllocationCalculations[]>> {
    const m = this.getLicensePeriodAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_PERIOD_ALLOCATION(assetId, periodId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<AssetAllocationCalculations[]>>(path, {
      params: request ? PaginatedRequest.getBaseRequestParams(request) : null,
    });

    return retVal;
  }

  getLicensePeriodAllocationsSpreadsheet(assetId: number, periodId: number, request: PaginatedRequest) {
    const m = this.getLicensePeriodAllocationsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_PERIOD_ALLOCATION_SPREADSHEET(assetId, periodId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  reopenLicense(periodId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.reopenLicense.name;

    const path = environment.getEndpoint(ApiPath.Licenses.LICENSE_REOPENING(periodId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  addLicensePeriod(licenseId: number, periodId: number, date: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.addLicensePeriod.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ADD_PERIOD(licenseId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    const params = new HttpParams().set('starts', date);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  addLicenseHistoryPeriod(licenseId: number, periodId: number, date: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.addLicensePeriod.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ADD_HISTORY_PERIOD(licenseId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    const params = new HttpParams().set('starts', date);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getAssetSubscriptionChangeDate(
    filterAssetIds: number[],
    filterSubscriptionIds: number[],
    filterBillingitemIds: number[],
    filterDeliveryitemIds: number[],
    filterServiceIds: number[]
  ): Observable<BaseResponse<AssetSubscriptionDate>> {
    const m = this.getLicensePeriodAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SUBSCRIPTIONS_CHANGE_DATES);
    LogService.info(this, m, LogService.GET + path, null);

    let params = new HttpParams();
    if (filterAssetIds) {
      params = params.append('filterAssetIds', filterAssetIds.join(','));
    }
    if (filterSubscriptionIds) {
      params = params.append('filterSubscriptionIds', filterSubscriptionIds.join(','));
    }
    if (filterBillingitemIds) {
      params = params.append('filterBillingitemIds', filterBillingitemIds.join(','));
    }
    if (filterDeliveryitemIds) {
      params = params.append('filterDeliveryitemIds', filterDeliveryitemIds.join(','));
    }
    if (filterServiceIds) {
      params = params.append('filterServiceIds', filterServiceIds.join(','));
    }

    const retVal = this.http.get<BaseResponse<AssetSubscriptionDate>>(path, { params });

    return retVal;
  }

  changeAssetSubscriptionDates(request: AssetSubscriptionDateRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.changeAssetSubscriptionDates.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SUBSCRIPTIONS_CHANGE_DATES);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getAssetAllocationParticulars(licenseId: number): Observable<BaseResponse<AssetAllocationParticulars>> {
    const m = this.getLicensePeriodAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ALLOCATIONS_PARTICULARS_BYID(licenseId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<AssetAllocationParticulars>>(path);

    return retVal;
  }

  putAssetAllocationParticulars(licenseId: number, request: AssetAllocationParticularsRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.getLicensePeriodAllocations.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_ALLOCATIONS_PARTICULARS_BYID(licenseId));
    LogService.info(this, m, LogService.PUT + path, null);
    LogService.info(this, m, LogService.REQUEST, null);

    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  changeLicenseAccFoc(
    licenseId: number,
    date: string,
    acc: number,
    foc: number,
    assetbillingitemId: number
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.changeLicenseAccFoc.name;

    const path = environment.getEndpoint(ApiPath.Licenses.LICENSE_CHANGE_ACCFOC(licenseId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    let params = new HttpParams().set('date', date).set('acc', acc.toString()).set('foc', foc.toString());
    if (assetbillingitemId) {
      params = params.append('assetbillingitemId', assetbillingitemId);
    }
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }
  constructor(private http: HttpClient) {}
}
