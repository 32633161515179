<div id="generic-form-configuration-container">
  <div class="field-gridster-wrapper">
    <div *ngIf="isEditing" class="edit-fieldlist-container">
      <div class="edit-fieldlist">
        <div class="edit-section-title">
          <span>{{ 'form_configuration_fieldlist_title' | translate }}</span>
        </div>
        <div class="edit-fieldlist-list">
          <mat-list
            cdkDropList
            #formConfigEntityFieldList="cdkDropList"
            cdkDropListSortingDisabled
            cdkDropListConnectedTo="formConfigGridster"
            [cdkDropListData]="availableFormFieldConfigurationFieldKinds"
          >
            <mat-list-item
              *ngFor="let field of availableFormFieldConfigurationFieldKinds"
              class="fieldlist-item-container"
              cdkDrag
              [cdkDragData]="field"
            >
              <div class="fieldlist-item-content">
                <span>{{ field.formfieldkindName | translate }}</span>

                <button
                  class="remove-button"
                  (click)="removeProductFieldDialog(confirmRemoveProductFieldDialog, field); $event.stopPropagation()"
                  mat-icon-button
                  *ngIf="isProductFieldConfiguration && !field.formfieldkindMultiuseable"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
              <!-- DRAG&DROP PREVIEW -->
              <mat-card class="form-config-drag-preview" *cdkDragPreview>
                <mat-card-content class="card-content">
                  <span class="card-item-name"> {{ field.formfieldkindName | translate }}</span>
                </mat-card-content>
              </mat-card>
              <div class="form-config-drag-placeholder" *cdkDragPlaceholder></div>
              <!-- DRAG&DROP PREVIEW -->
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
    <mat-divider *ngIf="isEditing" [vertical]="true" style="margin: 0 0.5rem"></mat-divider>
    <div
      class="field-gridster-container"
      cdkDropList
      id="formConfigGridster"
      #formConfigGridster="cdkDropList"
      (cdkDropListDropped)="addGridsterItem($event.item.data)"
      (resized)="gridsterContainerResized($event)"
    >
      <div class="form-config-gridster-placeholder" *cdkDragPlaceholder></div>
      <gridster [ngClass]="isEditing ? 'fo-gridster-edit' : 'fo-gridster-view'" [options]="options" *ngIf="options">
        <ng-container *ngFor="let item of formFieldConfigurationItems; let index = index">
          <ng-container *ngIf="isFormFieldConfigurationEnabled(item)">
            <gridster-item
              [item]="item"
              [ngSwitch]="item.formfieldKindId"
              [ngClass]="[
                selectedItem === item ? 'selected-item' : '',
                item.formfieldKindId === FormFieldConfigurationKind.SEPARATOR && !item.formfieldLabelShow
                  ? 'separator-background'
                  : 'separator-foreground'
              ]"
            >
              <app-form-field-config-separator
                *ngSwitchCase="FormFieldConfigurationKind.SEPARATOR"
                [formFieldData]="item"
                [isEditMode]="isEditing"
                (itemClickEvEm)="setSelectedItem(item, index)"
                (itemRemoveEvEm)="removeItem(item)"
              ></app-form-field-config-separator>
              <app-form-field-config-text
                *ngSwitchCase="FormFieldConfigurationKind.TEXT"
                [formFieldData]="item"
                [isEditMode]="isEditing"
                (itemClickEvEm)="setSelectedItem(item, index)"
                (itemRemoveEvEm)="removeItem(item)"
              ></app-form-field-config-text>
              <app-form-field-config-search
                *ngSwitchCase="FormFieldConfigurationKind.SEARCH"
                [formFieldData]="item"
                [isEditMode]="isEditing"
                (itemClickEvEm)="setSelectedItem(item, index)"
                (itemRemoveEvEm)="removeItem(item)"
              ></app-form-field-config-search>
              <app-form-field-config-entityfield
                *ngSwitchCase="FormFieldConfigurationKind.ENTITY_FIELD"
                [formFieldData]="item"
                [isEditMode]="isEditing"
                [rowHeight]="rowHeight"
                (itemClickEvEm)="setSelectedItem(item, index)"
                (itemRemoveEvEm)="removeItem(item)"
              ></app-form-field-config-entityfield>
              <app-form-field-config-inputfield
                *ngSwitchCase="FormFieldConfigurationKind.INPUT_FIELD"
                [formFieldData]="item"
                [isEditMode]="isEditing"
                [rowHeight]="rowHeight"
                (itemClickEvEm)="setSelectedItem(item, index)"
                (itemRemoveEvEm)="removeItem(item)"
              ></app-form-field-config-inputfield>
              <app-form-field-config-stepcontrol
                *ngSwitchCase="FormFieldConfigurationKind.STEP_CONTROL"
                [formFieldData]="item"
                [isEditMode]="isEditing"
                (itemClickEvEm)="setSelectedItem(item, index)"
                (itemRemoveEvEm)="removeItem(item)"
              ></app-form-field-config-stepcontrol>
              <app-form-field-config-chips
                *ngSwitchCase="FormFieldConfigurationKind.CHIPS"
                [formFieldData]="item"
                [isEditMode]="isEditing"
                (itemClickEvEm)="setSelectedItem(item, index)"
                (itemRemoveEvEm)="removeItem(item)"
              ></app-form-field-config-chips>
              <app-form-field-config-mailtemplate
                *ngSwitchCase="FormFieldConfigurationKind.MAILTEMPLATE"
                [formFieldData]="item"
                [isEditMode]="isEditing"
                (itemClickEvEm)="setSelectedItem(item, index)"
                (itemRemoveEvEm)="removeItem(item)"
              ></app-form-field-config-mailtemplate>
            </gridster-item>
          </ng-container>
        </ng-container>
      </gridster>
    </div>
    <mat-divider *ngIf="isEditing && selectedItem" [vertical]="true" style="margin: 0 0.5rem"></mat-divider>
    <div *ngIf="isEditing && selectedItem" class="edit-field-options-container">
      <div class="edit-field-options">
        <div class="edit-field-options-header edit-section-title">
          <span>{{ 'form_configuration_field_title' | translate }} - {{ selectedItem.formfieldKind | translate }}</span>

          <div class="control-button">
            <button (click)="deselectItem()" mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <!-- ************************************************************ COMMON ************************************************************ -->

        <ng-container *ngIf="selectedItem.formfieldKindId !== FormFieldConfigurationKind.SEPARATOR">
          <!-- TEXTNAME -->
          <div class="details-field-option">
            <span class="label"> {{ 'form_configuration_fieldoptions_textname' | translate }}</span>
            <span class="value">
              <app-translations-input-dialog-control
                [isDirtyEnabled]="true"
                [keyvaluesTranslations]="selectedItem.formfieldTextnameTranslations"
                [isDisabled]="isTemplateFormFieldConfiguration && selectedItem.formfieldKindId !== FormFieldConfigurationKind.TEXT"
                (changedValueEvEm)="selectedItem.isDirty = true"
              ></app-translations-input-dialog-control
            ></span>
          </div>
          <!-- INFO TEXTNAME -->
          <div class="details-field-option">
            <span class="label"> {{ 'form_configuration_fieldoptions_info' | translate }}</span>
            <span class="value">
              <app-translations-input-dialog-control
                [isDirtyEnabled]="true"
                [keyvaluesTranslations]="selectedItem.formfieldInfotextnameTranslations"
                [isDisabled]="isTemplateFormFieldConfiguration"
                (changedValueEvEm)="selectedItem.isDirty = true"
              ></app-translations-input-dialog-control
            ></span>
          </div>
        </ng-container>
        <!-- ************************************************************ SEPARATOR ************************************************************ -->
        <ng-container *ngIf="selectedItem.formfieldKindId === FormFieldConfigurationKind.SEPARATOR">
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_separator_background' | translate }}</span>
            <span class="value">
              <mat-slide-toggle
                color="primary"
                [(ngModel)]="selectedItem.formfieldLabelShow"
                (toggleChange)="selectedItem.isDirty = true"
                >{{ (selectedItem.formfieldLabelShow ? 'label_foreground_color' : 'label_background_color') | translate }}</mat-slide-toggle
              >
            </span>
          </div>
        </ng-container>
        <!-- ************************************************************ TEXT ************************************************************ -->
        <ng-container *ngIf="selectedItem.formfieldKindId === FormFieldConfigurationKind.TEXT">
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_text_kind' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldTextKindId"
                  [ngClass]="selectedItem.formfieldTextSizeId !== selectedItem.original?.formfieldTextSizeId ? DIRTY_CLASSNAME : ''"
                  (selectionChange)="selectedItem.isDirty = true"
                >
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of selectedItem.options.formfieldoptionTextKindOptions"
                    [value]="castToInt(opt.key)"
                  >
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_text_size' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldTextSizeId"
                  [ngClass]="selectedItem.formfieldTextSizeId !== selectedItem.original?.formfieldTextSizeId ? DIRTY_CLASSNAME : ''"
                  (selectionChange)="selectedItem.isDirty = true"
                >
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of selectedItem.options.formfieldoptionTextSizeOptions"
                    [value]="castToInt(opt.key)"
                  >
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_text_weight' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldTextWeightId"
                  [ngClass]="selectedItem.formfieldTextWeightId !== selectedItem.original?.formfieldTextWeightId ? DIRTY_CLASSNAME : ''"
                  (selectionChange)="selectedItem.isDirty = true"
                >
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of selectedItem.options.formfieldoptionTextWeightOptions"
                    [value]="castToInt(opt.key)"
                  >
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
        </ng-container>
        <!-- ************************************************************ SEARCH ************************************************************ -->
        <!-- ************************************************************ CHIPS ************************************************************ -->
        <ng-container
          *ngIf="
            selectedItem.formfieldKindId === FormFieldConfigurationKind.SEARCH ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.CHIPS
          "
        >
          <div class="details-field-option" *ngIf="selectedItem.formfieldKindId === FormFieldConfigurationKind.SEARCH">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_search_entitykind' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldEntityKindId"
                  [ngClass]="selectedItem.formfieldEntityKindId !== selectedItem.original?.formfieldEntityKindId ? DIRTY_CLASSNAME : ''"
                  (selectionChange)="loadForeignOptionFilters(); selectedItem.isDirty = true"
                >
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of selectedItem.options.formfieldoptionSearchEntityKindOptions"
                    [value]="castToInt(opt.key)"
                  >
                    {{ opt.value | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <!-- FILTER BY FOREIGN -->
          <!-- <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_search_filterbyentity' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select disableOptionCentering panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldFilterForeignKindId"
                  [ngClass]="
                    selectedItem.formfieldFilterForeignKindId !== selectedItem.original?.formfieldFilterForeignKindId ? DIRTY_CLASSNAME : ''
                  "
                  (selectionChange)="setFilterForeignKind()"
                >
                  <mat-option class="form-field-option" *ngFor="let opt of formFieldConfigurationForeignFilters" [value]="opt.entityId">
                    {{ opt.entityName | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_search_filterbyentity' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <input
                  #foreignInput
                  type="text"
                  matInput
                  [(ngModel)]="selectedItem.formfieldFilterForeignText"
                  (matInputAutocompleteFromApi)="foreignEntityOptions = $event"
                  [debounceTime]="600" maxlength="1500"
                  [entityKind]="selectedItem.formfieldFilterForeignKind"
                  (click)="$event.target.select()"
                  (blur)="checkSelectedOption()"
                  [matAutocomplete]="autoStringOption"
                  [ngClass]="
                    selectedItem.formfieldFilterForeignText !== selectedItem.original?.formfieldFilterForeignText ? DIRTY_CLASSNAME : ''
                  "
                  [disabled]="!selectedItem.formfieldFilterForeignKind"
                />
                <button
                  (click)="$event.stopPropagation(); openDialog(selectedItem.formfieldFilterForeignKind)"
                  mat-icon-button
                  matSuffix
                  class="small-icon"
                  [disabled]="!selectedItem.formfieldFilterForeignKind"
                >
                  <mat-icon>search</mat-icon>
                </button>
                <mat-autocomplete                 (opened)="matAutocompletPanelOpened()"                (closed)="matAutocompletPanelClosed()" matAutocompleteScrollToOption
                  #autoStringOption="matAutocomplete"
                  autoActiveFirstOption
                  (optionSelected)="onForeignOptionSelectionChanged($event.option.value)"
                >
                  <mat-option class="form-field-option" *ngFor="let opt of foreignEntityOptions" [value]="opt.entityName">
                    {{ opt.entityName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </span>
          </div> -->
          <!-- FILTER BY OPTION 1 -->
          <div class="details-field-option single-row">
            <div class="details-field-option" style="width: 30%">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_search_filterbyoption1' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    [(value)]="selectedItem.formfieldFilterOption1ColumnId"
                    [ngClass]="
                      selectedItem.formfieldFilterOption1ColumnId !== selectedItem.original?.formfieldFilterOption1ColumnId
                        ? DIRTY_CLASSNAME
                        : ''
                    "
                    (selectionChange)="selectedItem.isDirty = true"
                  >
                    <!--
                  (selectionChange)="setFilterOption1Column()" -->
                    <mat-option
                      class="form-field-option"
                      *ngFor="let opt of formFieldConfigurationOptionFilters"
                      [value]="opt.entityoptionfilterId"
                    >
                      {{ opt.entityoptionfilterName | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
            <div style="width: 1rem"></div>
            <div class="details-field-option" style="width: 70%">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_search_filteroption1' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    [(value)]="selectedItem.formfieldFilterOption1Values"
                    (ngModelChange)="formfieldFilterOption1Change($event)"
                    (selectionChange)="selectedItem.isDirty = true"
                    [ngClass]="isOption1FilterDirty() ? DIRTY_CLASSNAME : ''"
                    [disabled]="!selectedItem.formfieldFilterOption1ColumnId"
                    multiple
                  >
                    <mat-option class="form-field-option" *ngFor="let opt of entityoptionfilterOptions1" [value]="castToInt(opt.key)">
                      {{ opt.value | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </div>
          <!-- FILTER BY OPTION 2 -->
          <div class="details-field-option single-row">
            <div class="details-field-option" style="width: 30%">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_search_filterbyoption2' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    [(ngModel)]="selectedItem.formfieldFilterOption2ColumnId"
                    (selectionChange)="selectedItem.isDirty = true"
                    [ngClass]="
                      selectedItem.formfieldFilterOption2ColumnId !== selectedItem.original?.formfieldFilterOption2ColumnId
                        ? DIRTY_CLASSNAME
                        : ''
                    "
                  >
                    <!--
                  (selectionChange)="setFilterOption2Column()" -->
                    <mat-option
                      class="form-field-option"
                      *ngFor="let opt of formFieldConfigurationOptionFilters"
                      [value]="opt.entityoptionfilterId"
                    >
                      {{ opt.entityoptionfilterName | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
            <div style="width: 1rem"></div>
            <div class="details-field-option" style="width: 70%">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_search_filteroption2' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    [(ngModel)]="selectedItem.formfieldFilterOption2Values"
                    (ngModelChange)="formfieldFilterOption2Change($event)"
                    (selectionChange)="selectedItem.isDirty = true"
                    [ngClass]="isOption2FilterDirty() ? DIRTY_CLASSNAME : ''"
                    [disabled]="!selectedItem.formfieldFilterOption2ColumnId"
                    multiple
                  >
                    <mat-option class="form-field-option" *ngFor="let opt of entityoptionfilterOptions2" [value]="castToInt(opt.key)">
                      {{ opt.value | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </div>
          <ng-container *ngIf="selectedItem.formfieldKindId === FormFieldConfigurationKind.CHIPS">
            <div class="details-field-option single-row">
              <div class="details-field-option" style="width: 50%">
                <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_related_kind' | translate }}</span>
                <span class="value">
                  <mat-form-field appearance="outline" class="reset-outline-hint">
                    <mat-select
                      disableOptionCentering
                      panelClass="finoffice-matselect-panel"
                      [(value)]="selectedItem.formfieldEntityRelatedKindId"
                      (selectionChange)="setChipsEntityRelatedKind(); selectedItem.isDirty = true"
                      [ngClass]="
                        selectedItem.formfieldEntityRelatedKindId !== selectedItem.original?.formfieldEntityRelatedKindId
                          ? DIRTY_CLASSNAME
                          : ''
                      "
                      [disabled]="isFormFieldConfiguration || isTemplateFormFieldConfiguration"
                    >
                      <mat-option
                        class="form-field-option"
                        *ngFor="let opt of selectedItem.options.formfieldoptionRelatedEntityKindOptions"
                        [value]="castToInt(opt.key)"
                      >
                        {{ opt.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>
              </div>
              <div style="width: 1rem"></div>
              <div class="details-field-option" style="width: 50%">
                <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_other_entity' | translate }}</span>
                <span class="value">
                  <mat-form-field appearance="outline" class="reset-outline-hint">
                    <mat-select
                      disableOptionCentering
                      panelClass="finoffice-matselect-panel"
                      [(value)]="selectedItem.formfieldEntityOtherKindId"
                      (selectionChange)="setChipsOtherKind(); selectedItem.isDirty = true"
                      [ngClass]="
                        selectedItem.formfieldEntityOtherKindId !== selectedItem.original?.formfieldEntityOtherKindId ? DIRTY_CLASSNAME : ''
                      "
                      [disabled]="isFormFieldConfiguration || isTemplateFormFieldConfiguration"
                    >
                      <mat-option
                        class="form-field-option"
                        *ngFor="let opt of selectedItem.options.formfieldoptionRelatedEntityKindOptions"
                        [value]="castToInt(opt.key)"
                      >
                        {{ opt.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>
              </div>
            </div>

            <div class="details-field-option">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_chips_option_display' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    [(value)]="selectedItem.formfieldDisplayType"
                    [ngClass]="selectedItem.formfieldDisplayType !== selectedItem.original?.formfieldDisplayType ? DIRTY_CLASSNAME : ''"
                    (selectionChange)="selectedItem.isDirty = true"
                  >
                    <mat-option
                      *ngFor="let opt of selectedItem.options.formfieldoptionDisplayRelationTypeOptions"
                      [value]="castToInt(opt.key)"
                    >
                      {{ opt.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </ng-container>
        </ng-container>
        <!-- ************************************************************ ENTITY FIELD ************************************************************ -->
        <ng-container *ngIf="selectedItem.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD">
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_entity_column' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldEntityColumnId"
                  (selectionChange)="setEntityFieldColum(); selectedItem.isDirty = true"
                  [ngClass]="selectedItem.formfieldEntityColumnId !== selectedItem.original?.formfieldEntityColumnId ? DIRTY_CLASSNAME : ''"
                  [disabled]="
                    (isFormFieldConfiguration || isTemplateFormFieldConfiguration) &&
                    selectedItem.formfieldId !== -1 &&
                    selectedItem.formfieldEntityColumnId != null
                  "
                >
                  <!-- ISSUE #2928 remove from disabled condition => || isProductFieldConfiguration from OR evalutation -->
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of selectedItem.options.formfieldoptionEntityColumnOptions"
                    [value]="castToInt(opt.key)"
                  >
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <!-- <ng-container *ngIf="isFormFieldConfiguration"> -->
          <div class="details-field-option" *ngIf="defaultValueEnabled()">
            <span class="label"> {{ 'form_configuration_fieldoptions_default_value' | translate }}</span>
            <span class="value">
              <app-form-field-inputfield
                [formFieldData]="defaultValue"
                [isDirtyEnabled]="true"
                (changeValueEvEm)="defaultValueChanged(); selectedItem.isDirty = true"
              ></app-form-field-inputfield
            ></span>
          </div>

          <div class="details-field-option" *ngIf="isFormFieldBitsetColumn() || isFormFieldOptionColumn()">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_entity_option_display' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldDisplayType"
                  [ngClass]="selectedItem.formfieldDisplayType !== selectedItem.original?.formfieldDisplayType ? DIRTY_CLASSNAME : ''"
                  (selectionChange)="selectedItem.isDirty = true"
                >
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of selectedItem.options.formfieldoptionDisplayOptionTypeOptions"
                    [value]="castToInt(opt.key)"
                  >
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <div class="details-field-option" *ngIf="isFormFieldStringColumn()">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_entity_rows' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [disabled]="isTemplateFormFieldConfiguration"
                  [(value)]="selectedItem.formfieldEntityPrecision"
                  [ngClass]="
                    selectedItem.formfieldEntityPrecision !== selectedItem.original?.formfieldEntityPrecision ? DIRTY_CLASSNAME : ''
                  "
                  (selectionChange)="selectedItem.isDirty = true"
                >
                  <mat-option [value]="1">
                    {{ 'form_configuration_fieldoptions_entity_singlerow' | translate }}
                  </mat-option>
                  <mat-option [value]="2">
                    {{ 'form_configuration_fieldoptions_entity_multiplerow' | translate }}
                  </mat-option>
                  <mat-option [value]="3">
                    {{ 'form_configuration_fieldoptions_entity_html_editor' | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <div class="details-field-option" *ngIf="isFormFieldStringColumn()">
            <span class="label"> {{ 'form_configuration_fieldoptions_entity_regex' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint"
                ><input
                  type="text"
                  matInput
                  [disabled]="isTemplateFormFieldConfiguration"
                  [(ngModel)]="selectedItem.formfieldEntityRegex"
                  [ngClass]="selectedItem.formfieldEntityRegex !== selectedItem.original?.formfieldEntityRegex ? DIRTY_CLASSNAME : ''"
                  (input)="selectedItem.isDirty = true"
                />
                <button
                  *ngIf="selectedItem.formfieldEntityRegex != null"
                  mat-icon-button
                  matSuffix
                  class="small-icon"
                  (click)="openRegexTranslationsDialog()"
                >
                  <mat-icon>error</mat-icon>
                </button>
              </mat-form-field>
            </span>
          </div>
          <div class="details-field-option" *ngIf="isFormFieldImageColumn()">
            <span class="label"> {{ 'form_configuration_fieldoptions_entity_imagetype' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <input
                  type="text"
                  matInput
                  [(ngModel)]="selectedItem.formfieldEntitySpecification"
                  (input)="selectedItem.isDirty = true"
                  [ngClass]="
                    selectedItem.formfieldEntitySpecification !== selectedItem.original?.formfieldEntitySpecification ? DIRTY_CLASSNAME : ''
                  "
                />
              </mat-form-field>
            </span>
          </div>
          <div class="details-field-option" *ngIf="isFormFieldImageColumn()">
            <span class="label"> {{ 'form_configuration_fieldoptions_entity_imagesize' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <input
                  type="text"
                  matInput
                  [(ngModel)]="selectedItem.formfieldEntityScale"
                  (input)="selectedItem.isDirty = true"
                  [ngClass]="selectedItem.formfieldEntityScale !== selectedItem.original?.formfieldEntityScale ? DIRTY_CLASSNAME : ''"
                />
              </mat-form-field>
            </span>
          </div>
        </ng-container>
        <!-- </ng-container> -->
        <!-- ************************************************************ INPUT FIELD ************************************************************ -->
        <ng-container *ngIf="selectedItem.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD">
          <!-- INPUT FIELD TYPE -->
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_entity_type' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldEntityTypeId"
                  (selectionChange)="setInputFieldType(); selectedItem.isDirty = true"
                  [ngClass]="selectedItem.formfieldEntityTypeId !== selectedItem.original?.formfieldEntityTypeId ? DIRTY_CLASSNAME : ''"
                >
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of selectedItem.options.formfieldoptionEntityTypeOptions"
                    [value]="castToInt(opt.key)"
                  >
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <ng-container *ngIf="selectedItem.formfieldEntityType === 'boolean'">
            <div class="details-field-option">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_entity_boolean_display' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    [(value)]="selectedItem.formfieldDisplayType"
                    (selectionChange)="selectedItem.isDirty = true"
                    [ngClass]="selectedItem.formfieldDisplayType !== selectedItem.original?.formfieldDisplayType ? DIRTY_CLASSNAME : ''"
                  >
                    <mat-option
                      *ngFor="let opt of selectedItem.options.formfieldoptionDisplayBooleanTypeOptions"
                      [value]="castToInt(opt.key)"
                    >
                      {{ opt.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </ng-container>
          <!-- INPUT FIELD FOREIGN -->
          <ng-container *ngIf="selectedItem.formfieldEntityType === 'foreign'">
            <div class="details-field-option">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_entity_foreign' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    [(value)]="selectedItem.formfieldEntityKindId"
                    (selectionChange)="selectedItem.isDirty = true"
                    [ngClass]="selectedItem.formfieldEntityKindId !== selectedItem.original?.formfieldEntityKindId ? DIRTY_CLASSNAME : ''"
                  >
                    <mat-option
                      class="form-field-option"
                      *ngFor="let opt of selectedItem.options.formfieldoptionEntityKindOptions"
                      [value]="castToInt(opt.key)"
                    >
                      {{ opt.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </ng-container>
          <!-- INPUT FIELD OPTION -->
          <ng-container *ngIf="selectedItem.formfieldEntityType === 'option'">
            <div class="details-field-option">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_entity_option' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    [(value)]="selectedItem.formfieldEntityOption"
                    (selectionChange)="setInputFieldOption(); selectedItem.isDirty = true"
                    [ngClass]="selectedItem.formfieldEntityOption !== selectedItem.original?.formfieldEntityOption ? DIRTY_CLASSNAME : ''"
                  >
                    <mat-option
                      class="form-field-option"
                      *ngFor="let opt of selectedItem.options.formfieldoptionInputOptionOptions"
                      [value]="opt.key"
                    >
                      {{ opt.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
            <div class="details-field-option">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_entity_option_display' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    [(value)]="selectedItem.formfieldDisplayType"
                    (selectionChange)="selectedItem.isDirty = true"
                    [ngClass]="selectedItem.formfieldDisplayType !== selectedItem.original?.formfieldDisplayType ? DIRTY_CLASSNAME : ''"
                  >
                    <mat-option
                      *ngFor="let opt of selectedItem.options.formfieldoptionDisplayOptionTypeOptions"
                      [value]="castToInt(opt.key)"
                    >
                      {{ opt.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </ng-container>

          <!-- INPUT FIELD STRING -->
          <ng-container *ngIf="selectedItem.formfieldEntityType === 'string'">
            <div class="details-field-option">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_entity_rows' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <mat-select
                    disableOptionCentering
                    panelClass="finoffice-matselect-panel"
                    [(value)]="selectedItem.formfieldEntityPrecision"
                    (selectionChange)="selectedItem.isDirty = true"
                    [ngClass]="
                      selectedItem.formfieldEntityPrecision !== selectedItem.original?.formfieldEntityPrecision ? DIRTY_CLASSNAME : ''
                    "
                  >
                    <mat-option [value]="1">
                      {{ 'form_configuration_fieldoptions_entity_singlerow' | translate }}
                    </mat-option>
                    <mat-option [value]="2">
                      {{ 'form_configuration_fieldoptions_entity_multiplerow' | translate }}
                    </mat-option>
                    <mat-option [value]="3">
                      {{ 'form_configuration_fieldoptions_entity_html_editor' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
            <div class="details-field-option">
              <span class="label"> {{ 'form_configuration_fieldoptions_entity_regex' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint"
                  ><input
                    type="text"
                    matInput
                    [(ngModel)]="selectedItem.formfieldEntityRegex"
                    (input)="selectedItem.isDirty = true"
                    [ngClass]="selectedItem.formfieldEntityRegex !== selectedItem.original?.formfieldEntityRegex ? DIRTY_CLASSNAME : ''"
                /></mat-form-field>
              </span>
            </div>
            <!-- <div class="details-field-option">
              <span class="label"> {{ 'form_configuration_fieldoptions_entity_regex_error' | translate }}</span>
              <span class="value">
                <app-translations-input-dialog-control
                  [isDirtyEnabled]="true"
                  [keyvaluesTranslations]="selectedItem.formfieldEntityRegexerrormsgTranslations"
                ></app-translations-input-dialog-control
              ></span>
            </div> -->
          </ng-container>
          <!-- INPUT FIELD IMAGE -->
          <ng-container *ngIf="selectedItem.formfieldEntityType === 'image'">
            <div class="details-field-option">
              <span class="label"> {{ 'form_configuration_fieldoptions_entity_imagetype' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <input
                    type="text"
                    matInput
                    [(ngModel)]="selectedItem.formfieldEntitySpecification"
                    (input)="selectedItem.isDirty = true"
                    [ngClass]="
                      selectedItem.formfieldEntitySpecification !== selectedItem.original?.formfieldEntitySpecification
                        ? DIRTY_CLASSNAME
                        : ''
                    "
                  />
                </mat-form-field>
              </span>
            </div>
            <div class="details-field-option">
              <span class="label"> {{ 'form_configuration_fieldoptions_entity_imagesize' | translate }}</span>
              <span class="value">
                <mat-form-field appearance="outline" class="reset-outline-hint">
                  <input
                    type="text"
                    matInput
                    [(ngModel)]="selectedItem.formfieldEntityScale"
                    (input)="selectedItem.isDirty = true"
                    [ngClass]="selectedItem.formfieldEntityScale !== selectedItem.original?.formfieldEntityScale ? DIRTY_CLASSNAME : ''"
                  />
                </mat-form-field>
              </span>
            </div>
          </ng-container>
          <!-- INPUT FIELD UNIT -->
          <div class="details-field-option">
            <span class="label"> {{ 'form_configuration_fieldoptions_unit' | translate }}</span>
            <span class="value">
              <app-translations-input-dialog-control
                [isDirtyEnabled]="true"
                [keyvaluesTranslations]="selectedItem.formfieldEntityUnitTranslations"
                (changedValueEvEm)="selectedItem.isDirty = true"
              ></app-translations-input-dialog-control
            ></span>
          </div>
          <!-- INPUT FIELD DEFAULT -->
          <div class="details-field-option" *ngIf="defaultValueEnabled()">
            <span class="label"> {{ 'form_configuration_fieldoptions_default_value' | translate }}</span>
            <span class="value">
              <app-form-field-inputfield
                [formFieldData]="defaultValue"
                [isDirtyEnabled]="true"
                (changeValueEvEm)="defaultValueChanged(); selectedItem.isDirty = true"
              ></app-form-field-inputfield
            ></span>
          </div>
        </ng-container>
        <!-- ************************************************************ STEP CONTROL ************************************************************ -->
        <ng-container *ngIf="selectedItem.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL">
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_stepcontrol_entity' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldEntityKindId"
                  (selectionChange)="selectedItem.isDirty = true"
                  [disabled]="isFormFieldConfiguration && isTemplateFormFieldConfiguration && isProductFieldConfiguration"
                  [ngClass]="selectedItem.formfieldEntityKindId !== selectedItem.original?.formfieldEntityKindId ? DIRTY_CLASSNAME : ''"
                >
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of selectedItem.options.formfieldoptionStepEntityKindOptions"
                    [value]="castToInt(opt.key)"
                  >
                    {{ opt.value | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_precision' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldEntityPrecision"
                  (selectionChange)="selectedItem.isDirty = true"
                  [disabled]="isFormFieldConfiguration && isTemplateFormFieldConfiguration && isProductFieldConfiguration"
                  [ngClass]="
                    selectedItem.formfieldEntityPrecision !== selectedItem.original?.formfieldEntityPrecision ? DIRTY_CLASSNAME : ''
                  "
                >
                  <mat-option class="form-field-option" *ngFor="let opt of precisionOptions" [value]="opt">
                    {{ opt }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_scale' | translate }}</span>
            <span class="value">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldEntityScale"
                  (selectionChange)="selectedItem.isDirty = true"
                  [disabled]="isFormFieldConfiguration && isTemplateFormFieldConfiguration && isProductFieldConfiguration"
                  [ngClass]="selectedItem.formfieldEntityScale !== selectedItem.original?.formfieldEntityScale ? DIRTY_CLASSNAME : ''"
                >
                  <mat-option class="form-field-option" *ngFor="let opt of scaleOptions" [value]="opt">
                    {{ opt }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
          </div>
        </ng-container>
        <!-- ************************************************************ COMMON CONTROLS ************************************************************ -->
        <div
          class="details-field-option single-row"
          *ngIf="
            selectedItem.formfieldKindId === FormFieldConfigurationKind.SEPARATOR ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.TEXT ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.CHIPS ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.SEARCH
          "
        >
          <!-- LABEL SHOW -->
          <ng-container
            *ngIf="
              selectedItem.formfieldKindId !== FormFieldConfigurationKind.SEPARATOR &&
              selectedItem.formfieldKindId !== FormFieldConfigurationKind.TEXT
            "
          >
            <div class="details-field-option">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_showlabel' | translate }}</span>
              <span class="value">
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="selectedItem.formfieldLabelShow"
                  (toggleChange)="selectedItem.isDirty = true"
                  >{{ (selectedItem.formfieldLabelShow ? 'label_show' : 'label_hide') | translate }}</mat-slide-toggle
                >
              </span>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              selectedItem.formfieldKindId !== FormFieldConfigurationKind.SEPARATOR &&
              selectedItem.formfieldKindId !== FormFieldConfigurationKind.TEXT
            "
          >
            <div class="details-field-option">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_display_label' | translate }}</span>
              <span class="value">
                <mat-slide-toggle
                  color="primary"
                  [(ngModel)]="selectedItem.formfieldLabelDisplay"
                  (toggleChange)="selectedItem.isDirty = true"
                  >{{ (selectedItem.formfieldLabelDisplay ? 'label_show' : 'label_hide') | translate }}</mat-slide-toggle
                >
              </span>
            </div>
          </ng-container>
        </div>
        <div
          class="details-field-option single-row"
          *ngIf="
            selectedItem.formfieldKindId === FormFieldConfigurationKind.SEPARATOR ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.TEXT ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.CHIPS ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.SEARCH
          "
        >
          <ng-container
            *ngIf="
              selectedItem.formfieldKindId !== FormFieldConfigurationKind.SEPARATOR &&
              selectedItem.formfieldKindId !== FormFieldConfigurationKind.TEXT
            "
          >
            <div class="details-field-option">
              <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_entity_required' | translate }}</span>
              <span class="value">
                <mat-radio-group
                  [(ngModel)]="selectedItem.formfieldRequired"
                  (change)="selectedItem.isDirty = true"
                  [ngClass]="selectedItem.formfieldRequired !== selectedItem.original?.formfieldRequired ? DIRTY_CHECKBOX_CLASSNAME : ''"
                >
                  <mat-radio-button *ngFor="let opt of selectedItem.options.formfieldoptionRequiredOptions" [value]="castToInt(opt.key)">{{
                    opt.value
                  }}</mat-radio-button>
                </mat-radio-group>
              </span>
            </div>
          </ng-container>
          <!-- HIDE -->
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_entity_hide' | translate }}</span>
            <span class="value">
              <mat-radio-group
                [(ngModel)]="selectedItem.formfieldHideId"
                (change)="selectedItem.isDirty = true"
                [ngClass]="selectedItem.formfieldHideId !== selectedItem.original?.formfieldHideId ? DIRTY_CHECKBOX_CLASSNAME : ''"
              >
                <mat-radio-button *ngFor="let opt of selectedItem.options.formfieldoptionHideOptions" [value]="castToInt(opt.key)">{{
                  opt.value
                }}</mat-radio-button>
              </mat-radio-group>
            </span>
          </div>
        </div>
        <!-- HIDE ON -->
        <div class="details-field-option single-row" style="justify-content: space-between">
          <div class="details-field-option" style="width: 80%">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_hideon_label' | translate }}</span>
            <span class="value select-toggle">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldHideonfield"
                  (selectionChange)="selectedItem.isDirty = true"
                  [ngClass]="selectedItem.formfieldHideonfield !== selectedItem.original?.formfieldHideonfield ? DIRTY_CLASSNAME : ''"
                >
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of selectedItem.options.formfieldoptionDependencyfieldOptions"
                    [value]="castToInt(opt.key)"
                  >
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              &nbsp;
              <mat-slide-toggle
                color="primary"
                [(ngModel)]="selectedItem.formfieldHideontrue"
                labelPosition="before"
                (toggleChange)="selectedItem.isDirty = true"
              >
                {{ 'form_configuration_fieldoptions_label_on' | translate }}
              </mat-slide-toggle>
              <span>{{ (selectedItem.formfieldHideontrue ? 'label_true' : 'label_false') | translate }}</span>
            </span>
          </div>
          <div style="width: 1rem"></div>
          <div class="details-field-option" style="width: 100px">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_label_hideoncompact' | translate }}</span>
            <span class="value select-toggle">
              <mat-slide-toggle
                color="primary"
                [(ngModel)]="selectedItem.formfieldHideoncompact"
                (toggleChange)="selectedItem.isDirty = true"
              >
              </mat-slide-toggle>
              &nbsp;
              <span>{{ (selectedItem.formfieldHideoncompact ? 'label_hide' : 'label_keep') | translate }}</span>
            </span>
          </div>
        </div>

        <!-- READONLY ON -->
        <ng-container
          *ngIf="
            selectedItem.formfieldKindId === FormFieldConfigurationKind.SEARCH ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.ENTITY_FIELD ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.INPUT_FIELD ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.STEP_CONTROL ||
            selectedItem.formfieldKindId === FormFieldConfigurationKind.CHIPS
          "
        >
          <div class="details-field-option">
            <span class="label truncate-text"> {{ 'form_configuration_fieldoptions_readonlyon_label' | translate }}</span>
            <span class="value select-toggle">
              <mat-form-field appearance="outline" class="reset-outline-hint">
                <mat-select
                  disableOptionCentering
                  panelClass="finoffice-matselect-panel"
                  [(value)]="selectedItem.formfieldReadonlyonfield"
                  (selectionChange)="selectedItem.isDirty = true"
                  [ngClass]="
                    selectedItem.formfieldReadonlyonfield !== selectedItem.original?.formfieldReadonlyonfield ? DIRTY_CLASSNAME : ''
                  "
                >
                  <mat-option
                    class="form-field-option"
                    *ngFor="let opt of selectedItem.options.formfieldoptionDependencyfieldOptions"
                    [value]="castToInt(opt.key)"
                  >
                    {{ opt.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              &nbsp;
              <mat-slide-toggle
                color="primary"
                [(ngModel)]="selectedItem.formfieldReadonlyontrue"
                labelPosition="before"
                (toggleChange)="selectedItem.isDirty = true"
              >
                {{ 'form_configuration_fieldoptions_label_on' | translate }}
              </mat-slide-toggle>
              &nbsp;
              <span>{{ (selectedItem.formfieldReadonlyontrue ? 'label_true' : 'label_false') | translate }}</span>
            </span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!hideFlyingButtons && selectedEntity?.entityUpdate"
  id="generic-entity-form-overlay-button"
  #flyingButtons
  cdkDragBoundary=".main-content-container"
  (cdkDragEnded)="flyingButtonsDragEnd($event)"
  (cdkDragStarted)="flyingBtnDragging = true"
  [style.pointer-events]="flyingBtnDragging ? 'none' : null"
  class="flying-button-overlay-button"
  [ngClass]="[isEditing ? 'double-buttons' : '']"
  cdkDrag
>
  <ng-container *ngIf="!isEditing">
    <button mat-icon-button (click)="goEditMode()" matTooltip="{{ 'label_edit' | translate }}" [matTooltipClass]="'fo-mat-tooltip'">
      <mat-icon fontSet="fas" fontIcon="fa-edit"></mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="isEditing">
    <button
      mat-icon-button
      (click)="onSave()"
      [disabled]="!isDirty() || !isValid()"
      matTooltip="{{ 'label_save' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-save"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="onCancel($event)"
      [matMenuTriggerFor]="contextEditMenu"
      #confirmCancelMenu="matMenuTrigger"
      matTooltip="{{ 'label_cancel' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-times-circle"></mat-icon>
    </button>
  </ng-container>
</div>

<mat-menu
  class="flying-button-context-menu"
  #contextEditMenu="matMenu"
  xPosition="before"
  yPosition="above"
  (closed)="enableConfirmCancel = false"
>
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="onConfirmCancel()">
      <mat-icon fontSet="fas" fontIcon="fa-times-circle"></mat-icon>
      <span> {{ 'label_confirm_cancel' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>

<ng-template #confirmRemoveProductFieldDialog>
  <div id="confirm-remove-product-field-modal" mat-dialog-container>
    <mat-toolbar color="primary" class="mat-elevation-z2">
      <mat-card-header>{{ 'label_confirm' | translate }}</mat-card-header>
    </mat-toolbar>
    <div class="confirm-message">
      <span>
        {{ 'product_configuration_field_confirm_remove_message' | translate }}
        <!-- <span>Data has been changed! Would you like to save or discard data' with Save and Discard button</span> -->
      </span>
      <div>
        <button mat-raised-button color="primary" (click)="$event.stopPropagation(); onProductFieldConfirmRemove()">
          <mat-icon>save</mat-icon>
          <span>{{ 'label_confirm' | translate }}</span>
        </button>
        <button mat-raised-button color="primary" (click)="$event.stopPropagation(); onProductFieldRemoveClose()">
          <mat-icon>cancel</mat-icon>
          <span>{{ 'label_cancel' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<!--
   <div class="buttons-wrapper">
    <div *ngIf="!isEditing" class="buttons-container">
      <button mat-raised-button color="primary" (click)="goEditMode(); $event.stopPropagation()" [disabled]="putAPIPath == null">
        <mat-icon>edit</mat-icon>
        <span>{{ 'label_edit' | translate }}</span>
      </button>
    </div>
    <div *ngIf="isEditing" class="buttons-container edit-mode">
      <button mat-raised-button color="primary" (click)="onSave($event)" [disabled]="!isDirty() || !isValid()">
        <mat-icon>save</mat-icon>
        <span>{{ 'label_save' | translate }}</span>
      </button>
      <span style="margin-left: 0.5rem"></span>
      <ng-container *ngIf="!enableConfirmCancel; else confirmCancel">
        <button mat-raised-button color="primary" (click)="onCancel($event)">
          <mat-icon>cancel</mat-icon>
          <span>{{ 'label_cancel' | translate }}</span>
        </button>
      </ng-container>
      <ng-template #confirmCancel>
        <button
          #confirmCancelButton
          class="cancel-confirm"
          mat-raised-button
          color="warn"
          (click)="onConfirmCancel($event)"
          (blur)="enableConfirmCancel = false"
        >
          <mat-icon>cancel</mat-icon>
          <span>{{ 'label_confirm_cancel' | translate }}</span>
        </button>
      </ng-template>
    </div>
  </div>
 -->
