export class Entities {
  public static ASSET_ALLOCATION = 'entity_lurfactor';
  public static ACCOUNT = 'entity_account';
  public static ADDRESS = 'entity_address';
  public static ALERTTRIGGER = 'entity_fo_alerttrigger';
  public static ALERTTRIGGEREVENT = 'entity_fo_alerttriggerevent';
  public static BB_ACCOUNTS = 'entity_bb_accounts';
  public static BB_CURRENCIES = 'entity_bb_currency';
  public static BB_ADMINFEES = 'entity_bb_adminmap';
  public static BB_HWCOSTS = 'entity_bb_terminalhardware';
  public static BB_THIRDPARTIES = 'entity_bb_thirdparty';
  public static BILLINGACCOUNT = 'entity_cu';
  public static BILLINGACCOUNT_PURCHASE_ORDER = 'entity_cupurchaseorder';
  public static BILLINGITEM = 'entity_srcu';
  public static BILLINGITEM_TAX = 'entity_taxschemesrcu';
  public static BROADCAST = 'entity_fo_broadcast';
  public static BUDGET = 'entity_budget';
  public static BUDGET_NONINVENTORY = 'entity_budget_noninventory';
  public static BUDGET_CURRENCY = 'entity_budget_currency';
  public static BUDGET_PERIOD = 'entity_budget_period';
  public static BUDGET_RATE = 'entity_budget_rate';
  public static BUDGET_COSTELEMENT = 'entity_budget_costelement';
  public static BUDGET_VENDOR = 'entity_budget_vendor';
  public static BUDGET_COSTCENTRE = 'entity_budget_costcentre';
  public static BUDGET_ORGANISATION = 'entity_budget_organisation';
  public static BUDGET_LOCATION = 'entity_budget_location';
  public static BUDGET_SERVICETYPE = 'entity_budget_service_type';
  public static BUDGET_SERVICE = 'entity_budget_service';
  public static BUDGET_USER = 'entity_budget_user';
  public static BUDGET_ASSET = 'entity_budget_device';
  public static BUDGET_RULE = 'entity_budget_rule';
  public static CATEGORY = 'entity_category';
  public static CONTACT = 'entity_fo_contact';
  public static COLLABORATION = 'entity_fo_collaboration';
  public static COMPANY_FOBASE = 'entity_fobase_company';
  public static CONTRACT = 'entity_fo_contract';
  public static CONTRACT_OBJECT = 'entity_fo_contract_object';
  public static CONTRACT_RIGHT_OPTION = 'entity_fo_contract_right_option';
  public static CONTRACT_DEFINITION = 'entity_fo_contract_definition';
  public static CURRENCY = 'entity_currency';
  public static CURRENCY_HISTORY = 'entity_cur_history';
  public static CURRENCY_RATE = 'entity_cur';
  public static COSTCENTRE = 'entity_ccr';
  public static COSTCENTRE_REPLACEMENT = 'entity_ccrccr';
  public static COSTCENTRE_TRANSITION = 'entity_ccrtrans';
  public static COSTCENTRE_RESPONSIBLE = 'entity_ccruser';
  public static DACS_IMPORT = 'entity_dacs_import';
  public static DACS_PLATFORM = 'entity_dacs_platform_params';
  public static DACS_TYPE = 'entity_dacs_type_mp';
  public static DACS_SITE = 'entity_dacs_site_mp';
  public static DACS_FEED = 'entity_dacs_vendorservice_mp';
  public static DACS_APPLICATION = 'entity_dacs_application';
  public static DACS_ADMINGROUP = 'entity_dacs_admingroup_mp';
  public static DACS_APPLICATION_SERVICE = 'entity_dacs_app_service';
  public static DACS_SERVICE = 'entity_dacs_service_mp';
  public static DACS_THIRDPARTY = 'entity_dacs_thirdparty_mp';
  public static DACS_CATEGORY_SERVICE = 'entity_dacs_category_service';
  public static DACS_CATEGORY_THIRDPARTY = 'entity_dacs_category_thirdparty';
  public static DAD_VENDOR = 'entity_dad_vendor';
  public static DAD_PERIOD = 'entity_dad_period';
  public static DAD_VENDOR_PERIOD = 'entity_dad_vendor_period';
  public static DAD_LOCATION = 'entity_dad_location';
  public static DAD_LOCATION_TYPE = 'entity_dad_locationtype';
  public static DAD_LOCATIONMAP = 'entity_dad_locationmap';
  public static DAD_ACCOUNT = 'entity_dad_account';
  public static DAD_ACCOUNTMAP = 'entity_dad_accountmap';
  public static DAD_PLATFORM = 'entity_dad_platform';
  public static DAD_PLATFORMMAP = 'entity_dad_platformmap';
  public static DAD_USER = 'entity_dad_user';
  public static DAD_SERVICE = 'entity_dad_service';
  public static DAD_SERVICEFOC = 'entity_dad_servicelevelmap';
  public static DESK = 'entity_ur';
  public static FEEDITEM = 'entity_sr';
  public static FILE = 'entity_fo_file';
  public static FILE_USER = 'entity_fo_fileuser';
  public static FILE_GENERIC = 'entity_fo_filegeneric';
  public static FILE_COMPANY = 'entity_fo_filecompany';
  public static FIS_GLOBAL = 'entity_it_platform_params';
  public static FIS_GLOBAL_ACCOUNTMAP = 'entity_it_accountmap';
  public static FIS_GLOBAL_SERVICETYPE = 'entity_it_servicetype';
  public static FIS_GLOBAL_COUNTRY = 'entity_it_country';
  public static FIS_GLOBAL_SERVICE = 'entity_it_service';
  public static FKIS = 'entity_fkis_platform_params';
  public static FLOW = 'entity_fo_flow';
  public static FLOWTASK = 'entity_fo_flowtask';
  public static FONT_FAMILY = 'entity_fo_fontname';
  public static FORM_CONFIGURATION = 'entity_fo_form';
  public static FORM_WIZARD = 'entity_fo_formwizard';
  public static FORM_WIZARD_DATA = 'entity_fo_formwizarddata';
  public static FORM_WIZARD_STEP = 'entity_fo_formwizardstep';
  public static FTP_CONNECTION = 'entity_fo_ftpconnection';
  public static GROUP = 'entity_fo_group';
  public static HR_CFG = 'entity_hr_cfg';
  public static HR_CFG_FILE = 'entity_hr_cfgfile';
  public static HR_IMPORT = 'entity_hr_import';
  public static IIF_SERVICE_MAPPING = 'entity_iif_tpl_invsaid_map';
  public static INDEX = 'entity_fo_index';
  public static INDEX_GROUP = 'entity_fo_index_group';
  public static INDEX_PACKAGE = 'entity_fo_index_package';
  public static INDEX_PACKAGE_ASSIGN = 'entity_fo_index_package_assign';
  public static INDICE_REVIEW = 'entity_fo_indice_review';
  public static INDICE_SUB_REVIEW = 'entity_fo_indice_subreview';
  public static INDICE_REVIEW_USER = 'entity_fo_indice_review_user';
  public static INVOICE = 'entity_inv';
  public static INVOICE_STATUS = 'entity_inv_status';
  public static IIF_TEMPLATE = 'entity_iif_tpl';
  public static IIF_MODULE = 'entity_iif_tpl_module';
  public static JOB = 'entity_fo_job';
  public static JOB_SCHEDULE = 'entity_fo_schedule';
  public static LANGUAGE = 'entity_fo_language';
  public static LICENSE = 'entity_logical';
  public static LICENSE_SERVICE = 'entity_lsrcu';
  public static LOCATION = 'entity_location';
  public static LOG = 'entity_logvalue';
  public static MAIL_TEMPLATE = 'entity_fo_mailtemplate';
  public static MAIL_TEMPLATE_FRAME_DEFAULT = 'entity_fo_mailframedefault';
  public static MAIL_TEMPLATE_DEFAULT = 'entity_fo_maildefaulttemplate';
  public static MAIL_TEMPLATE_DEFAULT_ITEM = 'entity_fo_maildefaulttemplateitem';
  public static MAIL_TEMPLATE_FOBASE = 'entity_fobase_mailtemplate';
  public static MAIL_TEMPLATE_BODY_FOBASE = 'entity_fobase_mailbody';
  public static MECS_CONFIGURATION = 'entity_mecs_configuration';
  public static NOTE = 'entity_fo_note';
  public static OPTIONS = 'entity_options';
  public static ORGANISATION = 'entity_our';
  public static ORGANISATION_COSTCENTRE = 'entity_ourccr';
  public static ORGANISATION_RESPONSIBLE = 'entity_ouruser';
  public static PACKAGE = 'entity_package';
  public static PACKAGECONTENT = 'entity_packagecontent';
  public static PERMISSION = 'entity_sritem';
  public static PLATFORM = 'entity_platform';
  public static POLICY = 'entity_fo_policy';
  public static PRICE = 'entity_cs';
  public static PRICE_PERIOD = 'entity_csp';
  public static PRIORITY = 'entity_priority';
  public static PRODUCT = 'entity_product';
  public static PRODUCT_FAMILY = 'entity_product_family';
  public static PRODUCT_FAMILY_MAIL_TEMPLATE = 'entity_product_family_mailtemplate';
  public static PRODUCT_FAMILY_POLICY = 'entity_product_family_policy';
  public static PRODUCT_CONFIGFORM = 'entity_product_configform';
  public static PRODUCTFAMILY_CONFIGFORM = 'entity_product_family_configform';
  public static PROPERTY = 'entity_fo_property';
  public static PROPERTY_COLUMN = 'entity_fo_propertycolumn';
  public static PROPERTY_FORM = 'entity_fo_propertyform';
  public static PROPERTY_FOBASE = 'entity_fobase_property';
  public static PROPERTY_COLUMN_FOBASE = 'entity_fobase_propertycolumn';
  public static PROPERTY_FORM_FOBASE = 'entity_fobase_propertyform';
  public static PROPERTY_TEMPLATE = 'entity_fo_propertytemplate';
  public static PROPERTY_TEMPLATE_FORM = 'entity_fo_propertytemplateform';
  public static PROPERTY_TEMPLATE_COLUMN = 'entity_fo_propertytemplatecolumn';
  public static REQUEST = 'entity_fo_request';
  public static REQUEST_COMMENT = 'entity_fo_request_history';
  public static REPORT_CFG = 'entity_rep_cfg';
  public static REPORT_TEMPLATE = 'entity_rep_reports';
  public static REPORT_STYLE = 'entity_rep_reportstyle';
  public static REPORT_CATEGORY = 'entity_rep_category';
  public static ROLE = 'entity_fo_role';
  public static SAP_CFG = 'entity_sap_connection';
  public static SERVICE = 'entity_service';
  public static SERVICE_ALIAS = 'entity_service_alias';
  public static SERVICE_CATALOGSTYLE = 'entity_service_catalogstyle';
  public static SERVICE_CONFIGFORM = 'entity_service_configform';
  public static SELF_CERTIFICATION = 'entity_fo_selfcertification';
  public static SELL_INVOICE_PERIOD = 'entity_sellinv_period';
  public static SURVEY = 'entity_fo_survey';
  public static SURVEY_QUESTION = 'entity_fo_survey_question';
  public static SYSTEMMONITOR = 'entity_fo_systemmonitor';
  public static SYSTEMERROR = 'entity_fo_exception';
  public static TAX_SCHEME = 'entity_taxscheme';
  public static TAX = 'entity_taxes';
  public static TAX_VALUE = 'entity_taxvalue';
  public static TASK = 'entity_fo_task';
  public static TASK_TOPIC = 'entity_fo_task_topic';
  public static TARICS_CONFIGURATION = 'entity_tarics_config';
  public static TARICS_INSTANCE = 'entity_tarics_instance';
  public static TARICS_LOG = 'entity_tarics_instance_log';
  public static TENANT = 'entity_tenant';
  public static TENANT_FOBASE = 'entity_fobase_tenant';
  public static TENANT_AREA = 'entity_tenant_area';
  public static TENANT_AREA_CHARGING = 'entity_tenant_ledger_charging';
  public static TENANT_BANK_ACCOUNT = 'entity_tenant_ba';
  public static TENANT_COSTELEMENT = 'entity_tenant_costelement';
  public static TENANT_COSTELEMENT_MAP = 'entity_tenant_costelement_map';
  public static TENANT_CREDITOR = 'entity_tenant_creditor';
  public static TENANT_CREDITOR_BANK_ACCOUNT = 'entity_tenant_creditor_ba';
  public static TENANT_CREDITOR_MAP = 'entity_tenant_creditor_map';
  public static TENANT_INVOICE = 'entity_tenant_invoice';
  public static TENANT_LEDGER = 'entity_tenant_ledger';
  public static TENANT_LEDGER_CHARGING = 'entity_tenant_ledger_charging';
  public static TENANT_PERIOD = 'entity_tenant_period';
  public static TENANT_TAX = 'entity_tenant_tax';
  public static TENANT_PRETAX = 'entity_tenant_pretax';
  public static TERMS_AND_REGULATION = 'entity_article_refs';
  public static TYPES = 'entity_types';
  public static TYPES_KIND = 'entity_types_kind';
  public static USER_LICENSE = 'entity_lur';
  public static USERS = 'entity_users';
  public static USERS_TRADER = 'entity_users_trader';
  public static VENDOR = 'entity_vendor';
  public static VENDOR_ALIAS = 'entity_vendor_alias';
  public static VENDOR_LOCATION = 'entity_vendor_location';
  public static VENDOR_SERVICE_TYPE_VENDOR = 'entity_vendor_servicetypepolicy';
  public static VENDOR_POLICY = 'entity_vendor_policy';
  public static VENDOR_PROFILE = 'entity_vendor_profile';
  public static VENDOR_PROFILER_3DI_VENDORS = 'entity_fobase_3divendor';
  public static PROFILER_3DI_PRODUCTS = 'entity_fobase_3diproduct';
  public static PROFILER_3DI_EXCHANGES = 'entity_fobase_3diexchange';
}
