import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import * as saveAs from 'file-saver';
import { ApiPath } from 'src/app/configs/api-paths';
import { DefaultColsTable } from 'src/app/configs/default-cols-table';
import { Entities } from 'src/app/configs/entities';
import { Entity } from 'src/app/models/entity';
import { FileGridNode } from 'src/app/models/file-grid-node';
import { EntityFieldsListContentCallBack } from 'src/app/models/generic-entity-field';
import { RootStoreState } from 'src/app/root-store';
import { ImageFullscreenViewerStoreAction } from 'src/app/root-store/image-fullscreen-viewer-store';
import { FilesService } from 'src/app/services/files.data.services';
import { LogService } from 'src/app/services/log-service';
import { FileTabType } from 'src/app/shared/files/file-tab/file-tab-type';
import { GridBaseComponent } from '../../base-components/grid-base-component';
import { MessageBox, MessageBoxButton, MessageBoxStyle, MessageBoxType } from '../../message-box';

export interface GenericEntityFileUploadDialogData {
  selectedEntity: Entity;
}

@Component({
  selector: 'app-generic-entity-file-upload-dialog',
  templateUrl: './generic-entity-file-upload-dialog.component.html',
  styleUrls: ['./generic-entity-file-upload-dialog.component.scss'],
})
export class GenericEntityFileUploadDialogComponent extends GridBaseComponent implements OnInit {
  showThumbnail = false;

  dataSourceTable: MatTableDataSource<FileGridNode>;
  selectedEntity: Entity;

  // Upload
  isUploading = false;
  progressPercentage = 0;
  private fileUploadSubscription: any;

  constructor(
    public dialogRef: MatDialogRef<GenericEntityFileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericEntityFileUploadDialogData,
    private sanitizer: DomSanitizer,
    private filesService: FilesService,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef
  ) {
    super(store, cdRef);
  }

  /* GRID COMPONENT OVERRIDED METHOD */

  async gbAfterViewInitTable() {
    this.dataSourceTable = new MatTableDataSource();
    this.selectedEntity = this.data.selectedEntity;
    this.initRequest();
    this.gbLoadEntitiesData();
  }

  gbGetDisplayColumnTable() {
    return [...DefaultColsTable.ENTITY_FILES_COLUMN];
  }

  gbGetInitialOrderBy() {
    return 'filenodeName';
  }

  gbSetSelectedEntity(row: Entity) {
    this.selectedEntity = row;
  }

  gbLoadEntitiesData() {
    this.clearSelection();
    this.isLoading = true;
    this.dataSourceTable = new MatTableDataSource();
    this.noEntityData = false;
    if (this.gridLoadSubscription != null) {
      this.gridLoadSubscription.unsubscribe();
    }
    this.gridLoadSubscription = this.subscribe(
      this.filesService.getFilesList(
        ApiPath.Files.ENTITY_FILES_GRID(this.selectedEntity.entityKind, this.selectedEntity.entityId),
        this.request
      ),
      (response) => {
        this.lastUsedFilters = response.filters;
        if (response.data) {
          this.dataSourceTable.data = response.data;
          this.pageTotalElements = response.data[0].entityCount;
        } else {
          this.pageTotalElements = 0;
          this.noEntityData = true;
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      },
      () => (this.isLoading = false)
    );
  }

  createUpdateDetailsCallbackEvEm(callback: EntityFieldsListContentCallBack) {
    const m = this.createUpdateDetailsCallbackEvEm.name;

    LogService.debug(this, m, 'CREATE/UPDATE callback.isSuccess: ' + callback.isSuccess + ' - Callback Object:', callback);
    if (callback.isSuccess) {
      this.gbLoadEntitiesData();
    }
    // this.switchEditDetails(callback.isEditing);
    // ? this.isNewEntity = callback.isNewEntity;
  }

  onRowClicked(row: Entity) {
    return;
  }

  /* other */

  uploadFiles(fileList: FileList) {
    this.isUploading = true;
    this.fileUploadSubscription = this.filesService
      .uploadMultipleFilesToServer(
        ApiPath.Files.ENTITY_FILES_GRID(this.selectedEntity.entityKind, this.selectedEntity.entityId),
        Array.from(fileList)
      )
      .subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressPercentage = Math.floor((event.loaded * 100) / event.total);
          }
          if (event.type === HttpEventType.Response) {
            // const response = event.body as BaseResponse<InterfaceWizardResponse>;
            // this.uploadCompleted.emit(response.data);
            this.isUploading = false;
            this.progressPercentage = 0;
            this.gbLoadEntitiesData();
          }
        },
        (error: any) => {
          if (this.fileUploadSubscription) {
            this.fileUploadSubscription.unsubscribe();
          }
          this.isUploading = false;
          this.progressPercentage = 0;
        }
      );
  }

  downloadFile(file: FileGridNode) {
    this.subscribe(this.filesService.downloadFile(Entities.FILE, file.filenodeFileid), (blob) => {
      console.log(blob.type);
      if (blob.type === 'text/plain' || blob.type.match(/image\/\w+/)) {
        const fileURL = URL.createObjectURL(blob);

        window.open(fileURL, '_blank');
      } else {
        saveAs(blob, file.filenodeName); // FileSaver;
      }
    });
  }

  deleteFile(file: FileGridNode) {
    const selectedEntityApiPath = ApiPath.Files.FILES_DELETE(FileTabType.generic.key, file.filenodeId);
    this.gbSetSelectedEntity(file);
    MessageBox.show(
      this.dialog,
      this.translate.instant(this.selectedEntity.entityKind) + ': ' + this.selectedEntity.entityName,
      this.translate.instant('label_confirm'),
      this.translate.instant('label_really_delete_record'),
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.removeRecord(selectedEntityApiPath);
      }
    });
  }

  protected removeRecord(apiPath: string) {
    this.subscribe(
      this.coreDataService.removeEntity(apiPath),
      (response) => {
        if (response.data) {
          if (response.data.state) {
            // Success
            this.messageNotifierService.showSuccessMessage('toastr_success_removed');
            this.gbLoadEntitiesData();
          } else if (response.data.error) {
            // Fail
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          } else {
            this.messageNotifierService.showWarningMessage('toastr_no_data');
          }
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      }
    );
  }

  previewFile(file: FileGridNode) {
    this.subscribe(this.filesService.getFilePreviewImage(file.filenodeFileid), ({ data }) => {
      if (data) {
        const imageObject = [
          {
            image: data.image,
            title: file.filenodeName,
          },
        ];
        this.store.dispatch(ImageFullscreenViewerStoreAction.setImage({ imageObject }));
      }
    });
  }

  // Call this method in the image source, it will sanitize it.
  transform(base64Image: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }
}
