import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entities } from 'src/app/configs/entities';
import { FileGridNode } from 'src/app/models/file-grid-node';
import { EntityWizardStep } from 'src/app/models/forms/entity-wizard';
import { GenericEntityFormFieldEvent, GenericFormField } from 'src/app/models/forms/form-field';
import { CoreDataService } from 'src/app/services/core-data.service';
import { FilesService } from 'src/app/services/files.data.services';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FileTabType } from 'src/app/shared/files/file-tab/file-tab-type';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

@Component({
  selector: 'app-generic-entity-wizard-form-step',
  templateUrl: './generic-entity-wizard-form-step.component.html',
  styleUrls: ['./generic-entity-wizard-form-step.component.scss'],
})
export class GenericEntityWizardFormStepComponent extends FormatComponent implements OnInit {
  @Input() wizardId: number;
  @Input() entityKind: string;
  @Input() entityWizardStepData: EntityWizardStep;
  @Input() foreignFilterEntityKind: string;
  @Input() foreignFilterEntityId: number;
  @Input() isDocumentsTabEnabled: boolean;
  @Input() saveSubj: Observable<void> = new Observable<void>();
  @Input() eventDataChangedCallback?: (event: GenericEntityFormFieldEvent) => void;
  @Output() searchChangeValueEvEm = new EventEmitter<GenericFormField[]>();
  @Output() changeValueEvEm = new EventEmitter<GenericFormField[]>();
  isLoading = false;
  entityWizardStepFields: GenericFormField[];
  fileList: FileGridNode[];

  //Uploading
  private fileUploadSubscription: any;
  progressPercentage = 0;
  isUploading = false;

  constructor(
    private coreDataService: CoreDataService,
    private filesService: FilesService,
    protected messageNotifierService: MessageNotifierService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadStepData();
    this.loadStepFiles();
  }

  loadStepData() {
    this.isLoading = true;
    this.subscribe(
      this.coreDataService.getEntityWizardStepData(this.wizardId, this.entityWizardStepData.formstepStep, this.isTenantAdmin),
      ({ data }) => {
        this.entityWizardStepFields = data ? data.map((d) => new GenericFormField(d)) : [];
        // LogService.debug(this, this.loadStepData.name, 'Step Data', this.entityWizardStepFields);
        this.isLoading = false;
      }
    );
  }

  loadStepFiles() {
    const apiPath = this.isTenantAdmin
      ? ApiPath.TenantAdministration.Files.ENTITY_FILES_GRID(Entities.FORM_WIZARD_DATA, this.wizardId)
      : ApiPath.Files.ENTITY_FILES_GRID(Entities.FORM_WIZARD_DATA, this.wizardId);
    this.subscribe(
      this.filesService.getFilesList(apiPath),
      (response) => {
        if (response.data) {
          this.fileList = response.data;
        } else {
          this.fileList = [];
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      },
      () => (this.isLoading = false)
    );
  }

  removeFile(file: FileGridNode) {
    const apiPath = this.isTenantAdmin
      ? ApiPath.TenantAdministration.Files.ENTITY_FILENODE(file.filenodeId)
      : ApiPath.Files.FILES_DELETE(FileTabType.generic.key, file.filenodeId);
    this.subscribe(
      this.coreDataService.removeEntity(apiPath),
      (response) => {
        if (response.data) {
          if (response.data.state) {
            // Success
            // this.messageNotifierService.showSuccessMessage('toastr_success_removed');
            this.loadStepFiles();
          } else if (response.data.error) {
            // Fail
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          }
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      }
    );
  }

  uploadFiles(fileList: FileList) {
    this.isUploading = true;
    const apiPath = this.isTenantAdmin
      ? ApiPath.TenantAdministration.Files.ENTITY_FILES_GRID(Entities.FORM_WIZARD_DATA, this.wizardId)
      : ApiPath.Files.ENTITY_FILES_GRID(Entities.FORM_WIZARD_DATA, this.wizardId);
    this.fileUploadSubscription = this.filesService.uploadMultipleFilesToServer(apiPath, Array.from(fileList)).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressPercentage = Math.floor((event.loaded * 100) / event.total);
        }
        if (event.type === HttpEventType.Response) {
          // const response = event.body as BaseResponse<ActionResponse>;
          // this.uploadCompleted.emit(response.data);
          this.isUploading = false;
          this.progressPercentage = 0;
          this.loadStepFiles();
        }
      },
      (error: any) => {
        if (this.fileUploadSubscription) {
          this.fileUploadSubscription.unsubscribe();
        }
        this.isUploading = false;
        this.progressPercentage = 0;
      }
    );
  }
}
