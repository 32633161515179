import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { ActionResponse } from 'src/app/models/action-response';
import { LicenseAssetPeriodAllocationRequest } from 'src/app/models/asset-allocation';
import { AddonCancelWizardRequest } from 'src/app/models/asset-subscription';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { CancelAssetWizardRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-asset-wizard/cancel-asset-confirm-step/cancel-asset-services-request';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../../configs/api-paths';
import { BaseResponse } from '../../models/base-response';
import { UserLicenseServices } from '../../models/product-services';
import { CancelLicenseServicesRequest } from '../../shared-components/allocation-wizard-modal-dialogs/cancel-wizard/confirmation-step/cancel-license-services-request';
import { CancelLicenseServicesResponse } from '../../shared-components/allocation-wizard-modal-dialogs/cancel-wizard/confirmation-step/cancel-license-services-response';
import { LogService } from '../log-service';

class BaseParams {
  public static USER_ID = 'userId';
  public static DESK_ID = 'deskId';
  public static LICENSE_ID = 'licenseId';
}

@Injectable({
  providedIn: 'root',
})
export class CancelWizardDataService {
  constructor(private http: HttpClient) {}

  getLicenceServices(
    activationDate: Date,
    deactivationDate: Date,
    cancelService: boolean,
    userId: number,
    deskId?: number,
    licenseId?: number
  ): Observable<BaseResponse<UserLicenseServices[]>> {
    const m = this.getLicenceServices.name;

    const path = environment.getEndpoint(ApiPath.Licenses.LICENSES_SERVICES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'userId: ' + userId, null);
    LogService.info(this, m, 'deskId: ' + deskId, null);
    LogService.info(this, m, 'licenseId: ' + licenseId, null);
    const retVal = this.http.get<BaseResponse<UserLicenseServices[]>>(path, {
      params: this.getParams(activationDate, deactivationDate, cancelService, userId, deskId, licenseId),
    });

    return retVal;
  }

  getLicenceAddonsBillingaccounts(deactivationDate: Date, licenseId: number): Observable<BaseResponse<UserLicenseServices[]>> {
    const m = this.getLicenceAddonsBillingaccounts.name;

    const path = environment.getEndpoint(ApiPath.Licenses.LICENSES_SERVICES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'licenseId: ' + licenseId, null);
    const retVal = this.http.get<BaseResponse<UserLicenseServices[]>>(path, {
      params: this.getParams(null, deactivationDate, null, null, null, licenseId),
    });

    return retVal;
  }

  cancelLicenseServices(request: CancelLicenseServicesRequest): Observable<BaseResponse<CancelLicenseServicesResponse>> {
    const m = this.cancelLicenseServices.name;

    const path = environment.getEndpoint(ApiPath.Licenses.CANCEL_LICENSE_WIZARD);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<CancelLicenseServicesResponse>>(path, request);

    return retVal;
  }
  validateCancelMultiAddons(request: AddonCancelWizardRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.validateCancelMultiAddons.name;

    const path = environment.getEndpoint(ApiPath.Licenses.LICENSE_CANCEL_ADDONS_VALIDATION);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  executeCancelMultiAddons(request: AddonCancelWizardRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.validateCancelMultiAddons.name;

    const path = environment.getEndpoint(ApiPath.Licenses.LICENSE_CANCEL_ADDONS);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  cancelAssetLicenseServices(assetId: number, request: CancelAssetWizardRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.cancelLicenseServices.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_CANCEL_ASSET_WIZARD(assetId));
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  cancelSharedAssetLicenseServices(
    assetId: number,
    request: LicenseAssetPeriodAllocationRequest[]
  ): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.cancelSharedAssetLicenseServices.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_CANCEL_SHARED_ASSET_WIZARD(assetId));
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  private getParams(
    activationDate: Date,
    deactivationDate: Date,
    cancelService: boolean,
    userId?: number,
    deskId?: number,
    licenseId?: number
  ): HttpParams {
    let params = new HttpParams();
    if (activationDate) {
      params = params.append('filterStarts', moment(activationDate).utc(true).format('YYYY-MM-DD'));
    }
    if (deactivationDate) {
      params = params.append('filterEnds', moment(deactivationDate).utc(true).format('YYYY-MM-DD'));
    }
    if (userId) {
      params = params.append(BaseParams.USER_ID, userId.toString());
    }
    if (deskId) {
      params = params.append(BaseParams.DESK_ID, deskId.toString());
    }
    if (licenseId) {
      params = params.append(BaseParams.LICENSE_ID, licenseId.toString());
    }
    if (cancelService != null) {
      params = params.append('addon', cancelService);
    }
    return params;
  }
}
