import { AccessMode } from './access-mode';

export class ApiPath {
  public static PREFERENCES_SIDEMENUS = 'api/preferences/sidemenus';
  // public static PREFERENCES_LANGUAGE_REGION = 'api/preferences/language-region';
  public static PREFERENCES_FEEDBACK_EMAIL = 'api/preferences/feedback';
  public static PREFERENCES_SETTINGS = 'api/preferences/settings';
  public static FLYINGBUTTON_SETTINGS = 'api/preferences/flyingbutton';
  public static PREFERENCES_ENABLED_PATHS = 'api/preferences/enabledpathes';
  public static PREFERENCES_NOTIFICATIONS = 'api/preferences/notifications';
  public static TRANSLATIONS = 'api/translations';
  public static PREFERENCES_MODULE_ITEM_BYMODULENAME = (moduleName) => `api/preferences/modules/${moduleName}`;
  public static PREFERENCES_SYSTEM_DETAILS = (preferenceId) => `api/preferences/${preferenceId}/form`;

  static Public = class {
    public static TRANSLATIONS = 'api/public/translations';
    public static PREFERENCES_MODULE_ITEM = 'api/public/preferences/modules';
    public static PREFERENCES_SETTINGS = 'api/public/preferences/settings';
  };

  static Utils = class {
    public static CALCULATION_PERIOD_END = 'api/utils/calculation/periodends';
    public static CALCULATION_REPORT_PERIOD = 'api/utils/calculation/reportperiod';
  };

  static Help = class {
    public static HELP = 'api/help';
    public static HELP_CHATBOT = 'api/chatbot';
  };

  static SystemError = class {
    static Filters = class {
      public static USERS = 'api/systemerrors/users';
    };
    public static SYSTEM_ERRORS_GRID = 'api/systemerrors';
    public static SYSTEM_ERRORS_GRID_SPREADSHEET = 'api/systemerrors/spreadsheet';
    public static SYSTEM_ERRORS_DISMISS = 'api/systemerrors/dismiss';
    public static SYSTEM_ERRORS_READ = 'api/systemerrors/read';
    public static SYSTEM_ERRORS_SEND = (systemerrorId) => `api/systemerrors/${systemerrorId}/send`;
  };

  static SqlConsole = class {
    public static SQL_EXECUTE = 'api/sql/execute';
    public static SQL_EXECUTE_SPREADSHEET = 'api/sql/execute/spreadsheet';
  };

  static Forms = class {
    public static FORMS_GRID_ITEMS = 'api/forms';
    public static FORMS_GRID_ITEMS_SPREADSHEET = 'api/forms/spreadsheet';
    public static FORMS_CONFIGURATION_FIELDKINDS = 'api/forms/fieldkinds';
    public static FORMS_WIZARD = 'api/forms/wizard';
    public static FORMS_WIZARD_STEP = 'api/forms/wizardstep';
    public static FORMS_WIZARD_VALIDATION = 'api/forms/wizard/validation';
    public static FORMS_WIZARD_COMMIT = 'api/forms/wizard/commit';
    public static FORMS_WIZARD_WRITE = 'api/forms/wizard/writing';
    public static FORMS_WIZARD_NODES = 'api/forms/wizards';
    public static FORMS_WIZARD_NODES_SPREADSHEET = 'api/forms/wizards/spreadsheet';
    public static FORMS_SEARCH_BY_FORMFIELDID = (formfieldId) => `api/forms/search/${formfieldId}`;
    public static FORMS_CONFIGURATION_BY_ID = (formId) => `api/forms/${formId}/configuration`;
  };

  static Properties = class {
    public static PROPERTY_COLUMNS = 'api/properties/columns';
    public static PROPERTY_COLUMNS_PROPERTIES = 'api/properties';
    public static PROPERTY_COLUMNS_TYPES = 'api/properties/columns/types';
    public static PROPERTY_PARTICULARS = (propertyId) => `api/properties/${propertyId}/particulars`;
  };

  static Chat = class {
    public static CHATS = (entityApiPath, entityId) => `${entityApiPath}/${entityId}/chats`;
    public static CHATS_BYID = (entityApiPath, entityId, chatId) => `${entityApiPath}/${entityId}/chats/${chatId}`;
  };

  static TenantAdministration = class {
    static Common = class {
      public static PROGRESS = 'api/tenantadministration/progress';
    };

    static Tenants = class {
      static Filters = class {
        public static ENABLED = 'api/tenantadministration/tenants/enabled';
        public static TESTS = 'api/tenantadministration/tenants/tests';
      };
      public static TENANTS = 'api/tenantadministration/tenants';
      public static TENANTS_SPREADSHEET = 'api/tenantadministration/tenants/spreadsheet';
      public static TENANTS_PARTICULARS_DEFAULT = 'api/tenantadministration/tenants/defaults';
      public static TENANTS_PARTICULARS = 'api/tenantadministration/tenants/particulars';
      public static TENANTS_PARTICULARS_BYID = (tenantId) => `api/tenantadministration/tenants/${tenantId}/particulars`;
      public static TENANTS_BACKUP_BYID = (tenantId) => `api/tenantadministration/tenants/${tenantId}/backup`;
      public static TENANTS_RESTORE_BYID = (tenantId) => `api/tenantadministration/tenants/${tenantId}/restore`;
      // public static TENANTS_CREATE = `api/tenantadministration/entities/entity_fobase_tenant/details`;
      // public static TENANTS_DETAILS = (entityId) => `api/tenantadministration/entities/entity_fobase_tenant/${entityId}/details`;
      // public static TENANTS_DELETE = (entityId) => `api/tenantadministration/entities/entity_fobase_tenant/${entityId}`;
    };

    static SystemError = class {
      static Filters = class {
        public static LOGINS = 'api/tenantadministration/errors/logins';
      };
      public static SYSTEM_ERRORS_GRID = 'api/tenantadministration/errors';
      public static SYSTEM_ERRORS_GRID_SPREADSHEET = 'api/tenantadministration/errors/spreadsheet';
    };
    static Logins = class {
      static Filters = class {
        public static ISADMIN = 'api/tenantadministration/logins/isadmins';
        public static ISDISABLED = 'api/tenantadministration/logins/isdisableds';
        public static ISLOCKED = 'api/tenantadministration/logins/islockeds';
        public static TENANT = 'api/tenantadministration/tenants';
      };
      public static LOGINS = 'api/tenantadministration/logins';
      public static LOGINS_SPREADSHEET = 'api/tenantadministration/logins/spreadsheet';
    };
    static LoginStatistics = class {
      static Filters = class {
        public static COMPANIES = 'api/tenantadministration/loginstatistics/companies';
        public static LOGINS = 'api/tenantadministration/loginstatistics/logins';
        public static METHODS = 'api/tenantadministration/loginstatistics/methods';
        public static TENANTS = 'api/tenantadministration/loginstatistics/tenants';
      };
      public static LOGINSTATISTICS = 'api/tenantadministration/loginstatistics';
      public static LOGINSTATISTICS_SPREADSHEET = 'api/tenantadministration/loginstatistics/spreadsheet';
    };
    static Companies = class {
      public static COMPANIES = 'api/tenantadministration/companies';
      public static COMPANIES_SPREADSHEET = 'api/tenantadministration/companies/spreadsheet';
      public static COMPANIES_NODES = (companyId) => `api/tenantadministration/companies/${companyId}/nodes`;
    };
    static Entities = class {
      public static ENTITIES_DELETION_PRECAST = `api/tenantadministration/entities/deletionprecast`;
      public static ENTITIES_DELETION = 'api/tenantadministration/entities/deletion';
      public static ENTITY_WIZARD = 'api/tenantadministration/entities/wizard';
      public static ENTITY_WIZARD_STEPDATA = `api/tenantadministration/entities/wizardstep`;
      public static ENTITY_FORMS_LIST = 'api/tenantadministration/entities/forms';
      public static ENTITY_FORMS_LIST_SPREADSHEET = 'api/tenantadministration/entities/forms/spreadsheet';
      public static ENTITIES_GRID_BY_ENTITYKIND = (entityKind) => `api/tenantadministration/entities/${entityKind}`;
      public static DELETE_ENTITY_KIND = (entityKind: string, ids: number[]) =>
        `api/tenantadministration/entities/${entityKind}?idList=${ids.join(',')}`;
      public static ENTITY_FORMFIELDS_STEPS = (entityKind) => `api/tenantadministration/entities/${entityKind}/steps`;
      public static ENTITY_GET_PUT_FORMFIELDS = (entityKind, entityId, formId) =>
        `api/tenantadministration/entities/${entityKind}/${entityId}/${formId}/form`;
      public static ENTITIES_ENTITYKIND_TREEENABLED = (entityKind) => `api/tenantadministration/entities/${entityKind}/treeenabled`;
      public static ENTITY_KIND_FORM_CONFIG = (entityKind, formId) =>
        `api/tenantadministration/entities/${entityKind}/forms/${formId}/configuration`;
      public static ENTITY_KIND_FORM_ENTITYLIST = (entityKind) => `api/tenantadministration/entities/formfieldkinds?type=${entityKind}`;
      public static ENTITY_RESET_FORM = (entityKind) => `api/tenantadministration/entities/forms/${entityKind}/reset`;
      // public static ENTITY_KIND_CONFIG = (entityKind) => `api/tenantadministration/entities/${entityKind}/configuration`;
    };
    static Errors = class {
      public static ERRORS_SPREADSHEET_LOGGED_USER = 'api/tenantadministration/errors/spreadsheet/user';
    };
    static Files = class {
      public static ENTITY_FILES_GRID = (entity, entityId) => `api/tenantadministration/files/${entity}/${entityId}`;
      public static ENTITY_FILENODE = (filenodeId) => `api/tenantadministration/files/generic/${filenodeId}`;
    };
    static MailTemplates = class {
      public static MAIL_TEMPLATES = 'api/tenantadministration/mailtemplates';
      public static MAIL_TEMPLATES_SPREADSHEET = 'api/tenantadministration/mailtemplates/spreadsheet';
      public static MAIL_TEMPLATES_FRAMES = 'api/tenantadministration/mailtemplates/frames';
      public static MAIL_TEMPLATES_FRAMES_SPREADSHEET = 'api/tenantadministration/mailtemplates/frames/spreadsheet';
      public static MAIL_TEMPLATES_PREVIEW = 'api/tenantadministration/mailtemplates/preview';
      public static MAIL_TEMPLATES_FRAMES_BODIES = (frameId) => `api/tenantadministration/mailtemplates/frames/${frameId}/bodies`;
      public static MAIL_TEMPLATES_FRAMES_STATIC_OPTIONS = (frameId) => `api/tenantadministration/mailtemplates/frames/${frameId}/statics`;
    };
    static MailServers = class {
      static Filters = class {
        public static COMPANIES = 'api/tenantadministration/mailincomingaccounts/companies';
        public static PROTOCOLS = 'api/tenantadministration/mailincomingaccounts/protocols';
      };
      public static MAIL_SERVERS_OUTGOING = 'api/tenantadministration/mailoutgoingaccounts';
      public static MAIL_SERVERS_OUTGOING_SPREADSHEET = 'api/tenantadministration/mailoutgoingaccounts/spreadsheet';
      public static MAIL_SERVERS_INCOMING = 'api/tenantadministration/mailincomingaccounts';
      public static MAIL_SERVERS_INCOMING_SPREADSHEET = 'api/tenantadministration/mailincomingaccounts/spreadsheet';
    };
    static Openauthorizations = class {
      public static OPEN_AUTHORIZATIONS = 'api/tenantadministration/openauthorizations';
      public static OPEN_AUTHORIZATIONS_SPREADSHEET = 'api/tenantadministration/openauthorizations/spreadsheet';
    };
    static Logs = class {
      static Filters = class {
        public static LOGS_FILES_LOGLEVELS = (fileId) => `api/tenantadministration/logs/files/${fileId}/loglevels`;
        public static LOGS_FILES_PROCESSES = (fileId) => `api/tenantadministration/logs/files/${fileId}/processes`;
        public static LOGS_FILES_THREADS = (fileId) => `api/tenantadministration/logs/files/${fileId}/threads`;
        public static LOGS_FILES_CLASSNAMES = (fileId) => `api/tenantadministration/logs/files/${fileId}/classnames`;
      };
      public static LOGS_FILES = 'api/tenantadministration/logs/files';
      public static LOGS_FILES_DATA = (fileId) => `api/tenantadministration/logs/files/${fileId}/data`;
      public static LOGS_FILES_DATA_SPREADSHEET = (fileId) => `api/tenantadministration/logs/files/${fileId}/data/spreadsheet`;
    };
    static News = class {
      static Filters = class {
        public static NEWS_CATEGORIES = 'api/tenantadministration/news/categories';
        public static NEWS_PRIORITIES = 'api/tenantadministration/news/priorities';
        public static NEWS_HASREADS = 'api/tenantadministration/news/hasreads';
      };
      public static NEWS = 'api/tenantadministration/news';
      public static NEWS_SPREADSHEET = 'api/tenantadministration/news/spreadsheet';
    };
    public static TENANT_CAPABILITY = 'api/public/tenantcapability';
    public static AUTH = 'api/tenantadministration/auth';
    public static TRANSLATIONS = 'api/tenantadministration/translations';
    public static PREFERENCES_SIDEMENUS = 'api/tenantadministration/preferences/sidemenus';
    public static PREFERENCES_SETTINGS = 'api/tenantadministration/preferences/settings';
    public static FLYINGBUTTON_SETTINGS = 'api/tenantadministration/preferences/flyingbutton';
    public static PREFERENCE_FORM_STEPS = 'api/tenantadministration/preferences/form/steps';
    public static PREFERENCE_FORMFIELDS = (formId) => `api/tenantadministration/preferences/form/${formId}`;
  };

  static Errors = class {
    public static ERRORS_SPREADSHEET_LOGGED_USER = 'api/errors/spreadsheet/user';
  };

  static Auth = class {
    public static AUTH = 'api/auth';
    public static CHANGE_PWD = 'api/auth/changepwd';
    public static SET_PWD = 'api/auth/setpwd';
    public static FORGOT_PWD = 'api/auth/forgotpwd';
    public static RESET_PWD = 'api/auth/resetpwd';
  };

  static AuditLog = class {
    public static LOG_DETAILS = (entityKind, entityId) => `api/logs/${entityKind}/${entityId}`;
    public static LOG_DETAILS_SPREADSHEET = (entityKind, entityId) => `api/logs/${entityKind}/${entityId}/spreadsheet`;
  };

  static Note = class {
    static Filters = class {
      public static TOPICS = (entityKind, entityId) => `api/notes/${entityKind}/${entityId}/topics`;
      public static USERS = (entityKind, entityId) => `api/notes/${entityKind}/${entityId}/users`;
    };
    public static NOTES = (entityKind, entityId) => `api/notes/${entityKind}/${entityId}`;
  };

  static Entities = class {
    static Filters = class {
      public static ENTITYKIND = 'api/entities/templates/entities';
      public static ENTITY_FORMS_DEFAULTCHANGED = 'api/entities/forms/defaultchanged';
      public static ENTITY_FORMS_FIELDCHANGED = 'api/entities/forms/fieldchanged';
    };
    public static ENTITY_TEMPLATE_NODES = 'api/entities/templates';
    public static ENTITY_TEMPLATE_NODES_SPREADSHEET = 'api/entities/templates/spreadsheet';
    public static ENTITY_CONFIGURATION_LIST = 'api/entities/configurations';
    public static ENTITY_FORMS_LIST = 'api/entities/forms';
    public static ENTITY_CONFIGURATION_LIST_SPREADSHEET = 'api/entities/configurations/spreadsheet';
    public static ENTITY_FORMS_LIST_SPREADSHEET = 'api/entities/forms/spreadsheet';
    public static ENTITIES_DELETION_PRECAST = `api/entities/deletionprecast`;
    public static ENTITIES_DELETION = 'api/entities/deletion';
    public static ENTITY_WIZARD = 'api/entities/wizard';
    public static ENTITY_WIZARD_FINISH = 'api/entities/wizard/writing';
    public static ENTITY_WIZARD_STEPDATA = `api/entities/wizardstep`;
    public static ENTITY_SAVE_PROGRESS = `api/entities/saving`;
    public static ENTITY_UNIQUENESS_VALIDATION = (entityKind) => `api/entities/${entityKind}/unique-validation`;
    public static ENTITY_RELATED_UNIQUENESS_VALIDATION = (entityKind, relatedEntityKind, relatedEntityId) =>
      `api/entities/${entityKind}/${relatedEntityKind}/${relatedEntityId}/unique-validation`;
    public static ENTITY_OWNERSHIP_BY_ENTITYKIND_BY_ENTITYID = (entityKind, entityId) => `api/entities/${entityKind}/${entityId}/ownership`;
    public static ENTITY_OWNERSHIP_BY_ENTITYKIND_BY_ENTITYID_AND_USERID = (entityKind, entityId, userId) =>
      `api/entities/${entityKind}/${entityId}/ownership/${userId}`;
    public static ENTITY_TEMPLATE_BY_ENTITYKIND = (entityKind) => `api/entities/${entityKind}/templates`;
    public static ENTITIES_ENTITYKIND_TREEENABLED = (entityKind) => `api/entities/${entityKind}/treeenabled`;
    public static ENTITIES_GRID_BY_ENTITYKIND = (entityKind) => `api/entities/${entityKind}`;
    public static ENTITIES_FOREIGN_FILTERS = (entityKind) => `api/entities/${entityKind}/foreignfilters`;
    public static ENTITIES_OPTION_FILTERS = (entityKind) => `api/entities/${entityKind}/optionfilters`;
    public static GET_PUT_DETAILS = (entityKind, entityId) => `api/entities/${entityKind}/${entityId}/details`;
    public static POST_DETAILS = (entityKind) => `api/entities/${entityKind}/details`;
    public static ENTITY_FORMFIELDS_STEPS = (entityKind, entityId) => `api/entities/${entityKind}/${entityId}/steps`;
    public static ENTITY_GET_PUT_FORMFIELDS = (entityKind, entityId, formId) => `api/entities/${entityKind}/${entityId}/${formId}/form`;
    public static ENTITY_POST_FORMFIELDS = (entityKind) => `api/entities/${entityKind}/form`;
    public static ENTITY_RESET_FORM = (entityKind) => `api/entities/forms/${entityKind}/reset`;
    public static ENTITY_RESET_FORMFIELD = (entityKind, formId) => `api/entities/forms/${entityKind}/${formId}/reset`;
    public static GET_RELATED_DETAILS = (entityKind, entityId, relatedKind) =>
      `api/entities/${entityKind}/${entityId}/related/${relatedKind}/details`;
    public static GET_RELATED_RECORD = (entityKind, entityId, relatedKind) =>
      `api/entities/${entityKind}/${entityId}/related/${relatedKind}`;
    public static DELETE_ENTITY_KIND = (entityKind: string, ids: number[]) => `api/entities/${entityKind}?idList=${ids.join(',')}`;
    public static ENTITY_KIND_CONFIG = (entityKind) => `api/entities/${entityKind}/configuration`;
    public static ENTITY_KIND_FORM_CONFIG = (entityKind, formId) => `api/entities/${entityKind}/forms/${formId}/configuration`;
    public static ENTITY_KIND_FORM_ENTITYLIST = (entityKind) => `api/entities/formfieldkinds?type=${entityKind}`;
    public static ENTITY_KIND_TEMPLATE_FORM_CONFIG = (entityKind, templateId, formId) =>
      `api/entities/${entityKind}/templates/${templateId}/forms/${formId}/configuration`;
    public static ENTITY_KIND_TEMPLATE_FORM_ENTITYLIST = (entityKind, templateId) =>
      `api/entities/${entityKind}/templates/${templateId}/formfieldkinds`;
    public static ENTITY_KIND_MASS_EDIT_FORM = (entityKind: string) => `api/entities/${entityKind}/masseditform`;
    public static ENTITY_KIND_MAIL_TEMPLATE = (entityKind: string, entityId) => `api/entities/${entityKind}/${entityId}/mailtemplate`;
  };

  static Generic = class {
    public static ENTITIES_DETAILS_BASEPATH = (baseEntity) => `api/entities/${baseEntity}`;
    public static ENTITIES_GRID_BASEPATH = (baseEntity, entityKind, entityId) =>
      `api/entities/${baseEntity}?filterEntityKind=${entityKind}&filterEntityId=${entityId}`;
  };

  static GlobalSearch = class {
    public static SEARCH = 'api/search';
    public static SEARCH_LAST = 'api/search/last';
    public static SEARCH_ENTITIES = 'api/search/entities';
    public static SEARCH_FILTERRULES = 'api/search/filterrules';
    public static SEARCH_ID = (searchId) => `api/search/${searchId}`;
  };

  static Relations = class {
    public static RELATIONS = (apiPrefix, baseEntity, entityId, relationName) =>
      `api/${apiPrefix}${baseEntity}/${entityId}/${relationName}`;
    public static RELATION_FORMDETAILS = (apiPrefix, entityKind, relationId, stepFormId) =>
      `api/${apiPrefix}entities/${entityKind}/${relationId}/${stepFormId}/form`;
    public static RELATION_OTHER_ENTITIES_LIST = (apiPrefix, otherEntity) => `api/${apiPrefix}entities/${otherEntity}`;
  };

  static Accounts = class {
    static Filters = class {
      public static TYPES = 'api/accounts/types';
    };
    public static ACCOUNTS = 'api/accounts';
    public static ACCOUNTS_SPREADSHEET = 'api/accounts/spreadsheet';
  };

  static Addresses = class {
    static Filters = class {
      public static BUSINESSES = 'api/addresses/businesses';
      public static BUSINESSES_PARTNERS = 'api/addresses/businesspartners';
      public static SALUTATIONS = 'api/addresses/salutations';
    };
    public static ADDRESSES = 'api/addresses';
    public static ADDRESSES_SPREADSHEET = 'api/addresses/spreadsheet';
    //public static ADDRESSES_CREATE = `api/entities/entity_address/details`;
    //public static ADDRESSES_DETAILS = (entityId) => `api/entities/entity_address/${entityId}/details`;
  };

  static Contacts = class {
    static Filters = class {
      public static SALUTATIONS = 'api/contacts/salutations';
    };
    public static CONTACTS = 'api/contacts';
    public static CONTACTS_SPREADSHEET = 'api/contacts/spreadsheet';
    //public static ADDRESSES_CREATE = `api/entities/entity_address/details`;
    //public static ADDRESSES_DETAILS = (entityId) => `api/entities/entity_address/${entityId}/details`;
  };

  static Administrations = class {
    static Filters = class {
      public static TEXT_LANGUAGES = 'api/entities/entity_fo_language';
    };
    public static ADMIN_GROUPS = 'api/administration/groups';
    public static ADMIN_GROUPS_SPREADSHEET = 'api/administration/groups/spreadsheet';
    public static ADMIN_TEXTS = 'api/administration/texts';
    public static ADMIN_VIEW_AS_USER = 'api/administration/view-as-user';
    public static ADMIN_ROLEGROUP_ACCESSRIGHT = (rolegroupId) => `api/rolegroups/${rolegroupId}/rights`;
  };

  static Roles = class {
    static Filters = class {
      public static USERS = 'api/roles/users';
    };
    public static ROLES = 'api/roles';
    public static ROLES_SPREADSHEET = 'api/roles/spreadsheet';
    public static ROLES_DASHBOARDS = (roleId) => `api/roles/${roleId}/dashboards`;
  };

  static ChargeBack = class {
    public static CHARGE_BACK_CURRENCIES = 'api/chargebacks/currencies';
    public static CHARGE_BACK_CUTOFF_ACTIONS = 'api/chargebacks/cutoffactions';
    public static CHARGE_BACK_YEARS = (currencyId) => `api/chargebacks/${currencyId}/years`;
    public static CHARGE_BACK_GRID = (currencyId, yearId) => `api/chargebacks/${currencyId}/years/${yearId}`;
    public static CHARGE_BACK_LOCK = (currencyId) => `api/chargebacks/${currencyId}/lock`;
    public static CHARGE_BACK_UNLOCK = (currencyId) => `api/chargebacks/${currencyId}/unlock`;
    public static CHARGE_BACK_CONFIG = (currencyId) => `api/chargebacks/${currencyId}/configuration`;
    public static CHARGE_BACK_ACTION = (currencyId) => `api/chargebacks/${currencyId}`;
    public static CHARGE_BACK_CUTOFFS_GRID = (currencyId) => `api/chargebacks/${currencyId}/cutoffs`;
    public static CHARGE_BACK_CUTOFFS_ADD = (currencyId) => `api/chargebacks/${currencyId}/cutoffs`;
    public static CHARGE_BACK_CUTOFFS_DELETE = (currencyId, cutoffid) => `api/chargebacks/${currencyId}/cutoffs/${cutoffid}`;
  };

  static Assets = class {
    static Filters = class {
      public static DESKS = 'api/assets/desks';
      public static GROUPS = 'api/assets/groups';
      public static HASASSETS = 'api/assets/hasassets';
      public static KINDS = 'api/assets/kinds';
      public static LOCATIONS = 'api/assets/locations';
      public static PLATFORMS = 'api/assets/platforms';
      public static SHAREDS = 'api/assets/shareds';
      public static SITES = 'api/assets/sites';
      public static STATUSES = 'api/assets/statuses';
      public static TYPES = 'api/assets/types';
      public static USERS = 'api/assets/users';
      public static VENDORS = 'api/assets/vendors';
      public static WAITINGROOMS = 'api/assets/waitingrooms';
      public static HAS_COSTS = 'api/assets/hascosts';
      public static SERVICES = 'api/assets/services';
      public static SERVICETYPES = 'api/assets/servicetypes';
      public static BILLINGACCOUNTS = 'api/assets/billingaccounts';
      public static PRODUCT_FAMILIES = 'api/assets/productfamilies';
      public static BILLERS = 'api/assets/billers';
      public static PERMISSIONING_SYSTEMS = 'api/assets/permissioningsystems';
      public static PROVIDERS = 'api/assets/providers';
      public static FREE_OF_CHARGES = 'api/assets/freeofcharges';
      public static BILLERS_BYID = (serviceId) => `api/assets/${serviceId}/billers`;
      public static BILLINGACCOUNTS_BYID = (serviceId) => `api/assets/${serviceId}/billingaccounts`;
      public static PRODUCTFAMILIES_BYID = (serviceId) => `api/assets/${serviceId}/productfamilies`;
      public static PROVIDERS_BYID = (serviceId) => `api/assets/${serviceId}/providers`;
    };
    public static ASSETS_NODES = 'api/assets';
    public static ASSETS_NODES_SPREADSHEET = 'api/assets/spreadsheet';
    public static ASSET_PER_VENDOR_NODES = 'api/assets/per-vendor';
    public static ASSET_PER_VENDOR_NODES_SPREADSHEET = 'api/assets/per-vendor/spreadsheet';
    public static ASSET_PER_VENDOR_PER_BILLINGACCOUNT_NODES = 'api/assets/per-vendor-per-billingaccount';
    public static ASSET_PER_VENDOR_PER_BILLINGACCOUNT_NODES_SPREADSHEET = 'api/assets/per-vendor-per-billingaccount/spreadsheet';
    public static USERPERASSET_NODES = 'api/assets/peruser';
    public static USERPERASSET_NODES_SPREADSHEET = 'api/assets/peruser/spreadsheet';
    public static ASSETS_ALLOCATIONS = 'api/assets/allocations';
    public static ASSETS_ALLOCATIONS_SPREADSHEET = 'api/assets/allocations/spreadsheet';
    public static ASSETS_SUBSCRIPTIONS = 'api/assets/subscriptions';
    public static ASSETS_SUBSCRIPTIONS_CHANGE_QUANTITY = `api/assets/subscriptions/change-quantity`;
    public static ASSETS_SUBSCRIPTIONS_CHANGE_DATES = `api/assets/subscriptions/change-dates`;
    public static ASSETS_PARTTICULARS_DEFAULTS = 'api/assets/particulars/defaults';
    public static ASSETS_ADD_SUBSCRIPTIONS_VALIDATION = 'api/assets/subscriptions/add/validation';
    public static ASSETS_ADD_SUBSCRIPTIONS = 'api/assets/subscriptions/add';
    public static ASSETS_MOVE_ASSET_WIZARD = (assetId) => `api/assets/${assetId}/move`;
    public static ASSETS_MOVE_SHARED_ASSET_WIZARD = (assetId) => `api/assets/${assetId}/move-shared`;
    public static ASSETS_CANCEL_ASSET_WIZARD = (assetId) => `api/assets/${assetId}/cancel`;
    public static ASSETS_CANCEL_SHARED_ASSET_WIZARD = (assetId) => `api/assets/${assetId}/cancel-shared`;
    public static ASSETS_ALLOCATIONS_BYID = (assetId) => `api/assets/${assetId}/allocations`;
    public static ASSETS_HISTORY = (assetId) => `api/assets/${assetId}/history`;
    public static ASSETS_PERIODS = (assetId) => `api/assets/${assetId}/periods`;
    public static ASSETS_ADD_PERIOD = (assetId) => `api/assets/${assetId}/periods`;
    public static ASSETS_ADD_HISTORY_PERIOD = (assetId) => `api/assets/${assetId}/history`;
    public static ASSETS_PERIOD_ALLOCATION = (assetId, periodId) => `api/assets/${assetId}/periods/${periodId}/allocations`;
    public static ASSETS_PERIOD_ALLOCATION_SPREADSHEET = (assetId, periodId) =>
      `api/assets/${assetId}/periods/${periodId}/allocations/spreadsheet`;
    public static ASSETS_SERVICES = (assetId) => `api/assets/${assetId}/services`;
    public static ASSETS_COPY = (assetId) => `api/assets/${assetId}/copy`;
    public static ASSETS_SPREADSHEET_BYASSETID = (assetId) => `api/assets/${assetId}/spreadsheet`;
    public static ASSETS_BASE_SERVICES = (assetId) => `api/assets/${assetId}/feeditems`;
    public static ASSETS_SUBSCRIPTIONS_BYID = (assetId) => `api/assets/${assetId}/subscriptions`;
    public static ASSETS_SUBSCRIPTIONS_BYID_SPREADSHEET = (subscriptionId) => `api/assets/${subscriptionId}/subscriptions/spreadsheet`;
    public static ASSETS_ALLOCATIONS_PARTICULARS_BYID = (subscriptionId) => `api/assets/allocations/${subscriptionId}/particulars`;
    public static ASSETS_REPLACE_PRODUCT_SERVICE = (assetId) => `api/assets/${assetId}/replace-service`;
    public static ASSETS_EMAIL_HISTORIES_BYID = (entityId) => `api/assets/${entityId}/emailhistories`;
    public static ASSETS_ALLOCATIONS_STEPDATES = (entityId) => `api/assets/${entityId}/allocations/stepdates`;
  };

  static BillingItems = class {
    static Filters = class {
      public static BILLINGACCOUNTS = 'api/billingitems/billingaccounts';
      public static BILLERALIASES = 'api/billingitems/billeraliases';
      public static DELIVERYITEMS = 'api/billingitems/deliveryitems';
      public static PRODUCTFAMILIES = 'api/billingitems/productfamilies';
      public static SUGESTOPTDATES = 'api/billingitems/sugestoptdates';
      public static PRICECALCS = 'api/billingitems/pricecalcs';
      public static STATUSES = 'api/billingitems/statuses';
      public static CATEGORIES = 'api/billingitems/categories';
      public static STEERABLES = 'api/billingitems/steerables';
      public static BILLERS_BYID = (serviceId) => `api/billingitems/${serviceId}/billers`;
      public static BILLINGACCOUNTS_BYID = (serviceId) => `api/billingitems/${serviceId}/billingaccounts`;
      public static PRODUCTFAMILIES_BYID = (serviceId) => `api/billingitems/${serviceId}/productfamilies`;
      public static PROVIDERS_BYID = (serviceId) => `api/billingitems/${serviceId}/providers`;
    };
    public static BILLING_ITEMS = 'api/billingitems';
    public static BILLING_ITEMS_SPREADSHEET = 'api/billingitems/spreadsheet';
    public static BILLING_ITEMS_TAXES_DETAILS_BASE_PATH = `api/entities/entity_taxschemesrcu`;
    public static BILLING_ITEMS_RULE_DETAILS_BASE_PATH = `api/entities/entity_ctsrcu`;
    public static BILLING_ITEMS_PRICES_DETAILS_BASE_PATH = `api/entities/entity_csp`;
    public static BILLING_ITEMS_PRODUCTSERVICES_DETAILS_BASE_PATH = `api/entities/entity_lsrcu`;
    public static BILLING_ITEMS_CREATION_WIZARD = 'api/billingitems/creation';
    public static BILLING_ITEMS_BILLINGACCOUNTS = 'api/billingitems/billingaccounts';
    public static BILLING_ITEMS_BILLINGACCOUNTS_VALIDATION = 'api/billingitems/billingaccounts/validation';
    public static BILLING_ITEMS_MOVE = 'api/billingitems/move';
    //public static BILLING_ITEMS_PRICEDEFAULTS = (billingitemId) => `api/billingitems/${billingitemId}/prices/defaults`;
    public static BILLING_ITEMS_SUBSCRIPTIONS = (billingitemId) => `api/billingitems/${billingitemId}/subscriptions`;
    public static BILLING_ITEMS_SUBSCRIPTIONS_SPREADSHEET = (billingitemId) =>
      `api/billingitems/${billingitemId}/subscriptions/spreadsheet	`;
    public static BILLING_ITEMS_ALLOCATION_DEFAULTS = (billingitemId) => `api/billingitems/${billingitemId}/allocationdefaults`;
    public static BILLING_ITEMS_TAXES = (billingitemId) =>
      `api/entities/entity_taxschemesrcu?filterEntityKind=entity_srcu&filterEntityId=${billingitemId}`;
    public static BILLING_ITEMS_RULES = (billingitemId) =>
      `api/entities/entity_ctsrcu?filterEntityKind=entity_srcu&filterEntityId=${billingitemId}`;
    // public static BILLING_ITEMS_PRICES = (billingitemId) =>
    //   `api/entities/entity_csp?filterEntityKind=entity_srcu&filterEntityId=${billingitemId}`;
    public static BILLING_ITEMS_PRICES = (billingitemId) => `api/billingitems/${billingitemId}/prices`;
    public static BILLING_ITEMS_PRICE_PARTICULARS = (billingitemId, priceId) =>
      `api/billingitems/${billingitemId}/prices/${priceId}/particulars`;
    public static BILLING_ITEMS_PRICE_PARTICULARS_DEFAULT = (billingitemId) => `api/billingitems/${billingitemId}/prices/defaults`;
    public static BILLING_ITEMS_PRICE_PARTICULARS_CREATE = (billingitemId) => `api/billingitems/${billingitemId}/prices/particulars`;
    public static BILLING_ITEMS_PRODUCTSERVICES = (billingitemId) =>
      `api/entities/entity_lsrcu?filterEntityKind=entity_srcu&filterEntityId=${billingitemId}`;
    public static BILLING_ITEMS_PACKAGE = (billingitemId) => `api/billingitems/${billingitemId}/package`;
    public static BILLING_ITEMS_COPY = (billingitemId) => `api/billingitems/${billingitemId}/copy`;
    public static BILLING_ITEMS_REPLACE = (billingitemId) => `api/billingitems/${billingitemId}/replace`;
  };

  static Usages = class {
    public static USAGE_PERIODS = `api/usages/periods`;
    public static USAGE_PERIODS_LOCK = `api/usages/periods/lock`;
    public static USAGE_PERIODS_UNLOCK = `api/usages/periods/unlock`;
    public static USAGES = `api/usages`;
    public static USAGES_SHOW_USAGES = `api/usages/raw/spreadsheet`;
  };

  static Budgets = class {
    static Filters = class {};
    public static BUDGETS = 'api/budgets';
    public static BUDGETS_SPREADSHEET = 'api/budgets/spreadsheet';
  };

  static BudgetManager = class {
    static Filters = class {
      public static ACTIONS = `api/budgetmanager/actions`;
      public static CURRENCIES = `api/budgetmanager/currencies`;
      public static STARTSTYPE = `api/budgetmanager/startstypes`;
      public static ENDSTYPE = `api/budgetmanager/endstypes`;
      public static TENANTLEDGERS = `api/budgetmanager/tenantledgers`;
      public static COSTCENTRES = (periodId) => `api/budgetmanager/periods/${periodId}/filter/costcentres`;
      public static COSTELEMENTS = (periodId) => `api/budgetmanager/periods/${periodId}/filter/costelements`;
      public static LOCATIONS = (periodId) => `api/budgetmanager/periods/${periodId}/filter/locations`;
      public static ORGANISATIONS = (periodId) => `api/budgetmanager/periods/${periodId}/filter/organisations`;
      public static SERVICES = (periodId) => `api/budgetmanager/periods/${periodId}/services`;
      public static SERVICETYPES = (periodId) => `api/budgetmanager/periods/${periodId}/filter/servicetypes`;
      public static USERS = (periodId) => `api/budgetmanager/periods/${periodId}/users`;
      public static VENDORS = (periodId) => `api/budgetmanager/periods/${periodId}/filter/vendors`;
    };
    public static BUDGETMANAGER_CURRENCIES_GRID = 'api/budgetmanager/currencies';
    public static BUDGETMANAGER_CURRENCIES_GRID_SPREADSHEET = 'api/budgetmanager/currencies/spreadsheet';
    public static BUDGETMANAGER_PERIODS_GRID = 'api/budgetmanager/periods';
    public static BUDGETMANAGER_PERIODS_GRID_SPREADSHEET = 'api/budgetmanager/periods/spreadsheet';
    public static BUDGETMANAGER_BUDGET_ACTUALS = (periodId) => `api/budgetmanager/periods/${periodId}/actuals`;
    public static BUDGETMANAGER_BUDGET_ACTUAL_COMPARISON = (periodId) =>
      `api/budgetmanager/periods/${periodId}/budget/actualcomparision/spreadsheet`;
    public static BUDGETMANAGER_BUDGET_DETAILS_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/details`;
    public static BUDGETMANAGER_BUDGET_DETAILS_TOTALS = (periodId) => `api/budgetmanager/periods/${periodId}/details/total`;
    public static BUDGETMANAGER_BUDGET_DETAILS_REMOVE = (periodId, detailsId, isInventory) =>
      `api/budgetmanager/periods/${periodId}/details/${detailsId}/${isInventory}/remove`;
    public static BUDGETMANAGER_BUDGET_DETAILS_RESTORE = (periodId, detailsId) =>
      `api/budgetmanager/periods/${periodId}/details/${detailsId}/restore`;
    public static BUDGETMANAGER_BUDGET_DETAILS_COPYUSER = (periodId, detailsId) =>
      `api/budgetmanager/periods/${periodId}/details/${detailsId}/copyuser`;
    public static BUDGETMANAGER_BUDGET_DETAILS_COPYASSET = (periodId, detailsId) =>
      `api/budgetmanager/periods/${periodId}/details/${detailsId}/copyasset`;
    public static BUDGETMANAGER_BUDGET_RULES_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/rules`;
    public static BUDGETMANAGER_BUDGET_RULES_GRID_SPREADSHEET = (periodId) => `api/budgetmanager/periods/${periodId}/rules/spreadsheet`;
    public static BUDGETMANAGER_BUDGET_RULES_MAX_PRIORITY = (periodId) => `api/budgetmanager/periods/${periodId}/rules/maxpriority`;
    public static BUDGETMANAGER_BUDGET_RULES_IMPORT = (periodId, sourcePeriodId) =>
      `api/budgetmanager/periods/${periodId}/rules/import?sourcePeriodId=${sourcePeriodId}`;
    public static BUDGETMANAGER_BUDGET_RULES_MOVEUP = (periodId, ruleId) => `api/budgetmanager/periods/${periodId}/rules/${ruleId}/moveup`;
    public static BUDGETMANAGER_BUDGET_RULES_MOVEDOWN = (periodId, ruleId) =>
      `api/budgetmanager/periods/${periodId}/rules/${ruleId}/movedown`;
    public static BUDGETMANAGER_BUDGET_DETAILS_GRID_SPREADSHEET = (periodId) => `api/budgetmanager/periods/${periodId}/details/spreadsheet`;
    public static BUDGETMANAGER_BUDGET_COSTCENTRE_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/budget/costcentres`;
    public static BUDGETMANAGER_BUDGET_COSTCENTRE_TOTALS = (periodId) => `api/budgetmanager/periods/${periodId}/budget/costcentres/total`;
    public static BUDGETMANAGER_BUDGET_COSTELEMENT_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/budget/costelements`;
    public static BUDGETMANAGER_BUDGET_COSTELEMENT_TOTALS = (periodId) => `api/budgetmanager/periods/${periodId}/budget/costelements/total`;
    public static BUDGETMANAGER_BUDGET_COSTCENTRE_GRID_SPREADSHEET = (periodId) =>
      `api/budgetmanager/periods/${periodId}/budget/costcentres/spreadsheet`;
    public static BUDGETMANAGER_BUDGET_COSTELEMENT_GRID_SPREADSHEET = (periodId) =>
      `api/budgetmanager/periods/${periodId}/budget/costelements/spreadsheet`;
    public static BUDGETMANAGER_BUDGET_USER_GRID_SPREADSHEET = (periodId) =>
      `api/budgetmanager/periods/${periodId}/budget/users/spreadsheet`;
    public static BUDGETMANAGER_BUDGET_VENDOR_GRID_SPREADSHEET = (periodId) =>
      `api/budgetmanager/periods/${periodId}/budget/vendors/spreadsheet`;
    public static BUDGETMANAGER_COSTELEMENT_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/costelements`;
    public static BUDGETMANAGER_COSTELEMENT_GRID_SPREADSHEET = (periodId) =>
      `api/budgetmanager/periods/${periodId}/costelements/spreadsheet`;
    public static BUDGETMANAGER_VENDOR_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/vendors`;
    public static BUDGETMANAGER_VENDOR_GRID_SPREADSHEET = (periodId) => `api/budgetmanager/periods/${periodId}/vendors/spreadsheet`;
    public static BUDGETMANAGER_COSTCENTRE_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/costcentres`;
    public static BUDGETMANAGER_COSTCENTRE_GRID_SPREADSHEET = (periodId) => `api/budgetmanager/periods/${periodId}/costcentres/spreadsheet`;
    public static BUDGETMANAGER_ORGANISATION_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/organisations`;
    public static BUDGETMANAGER_ORGANISATION_GRID_SPREADSHEET = (periodId) =>
      `api/budgetmanager/periods/${periodId}/organisations/spreadsheet`;
    public static BUDGETMANAGER_LOCATION_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/locations`;
    public static BUDGETMANAGER_LOCATION_GRID_SPREADSHEET = (periodId) => `api/budgetmanager/periods/${periodId}/locations/spreadsheet`;
    public static BUDGETMANAGER_SERVICETYPE_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/servicetypes`;
    public static BUDGETMANAGER_SERVICETYPE_GRID_SPREADSHEET = (periodId) =>
      `api/budgetmanager/periods/${periodId}/servicetypes/spreadsheet`;
    public static BUDGETMANAGER_SERVICE_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/services`;
    public static BUDGETMANAGER_SERVICE_GRID_SPREADSHEET = (periodId) => `api/budgetmanager/periods/${periodId}/services/spreadsheet`;
    public static BUDGETMANAGER_USER_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/users`;
    public static BUDGETMANAGER_USER_GRID_SPREADSHEET = (periodId) => `api/budgetmanager/periods/${periodId}/users/spreadsheet`;
    public static BUDGETMANAGER_ASSET_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/assets`;
    public static BUDGETMANAGER_ASSET_GRID_SPREADSHEET = (periodId) => `api/budgetmanager/periods/${periodId}/assets/spreadsheet`;
    public static BUDGETMANAGER_RATE_GRID = (periodId) => `api/budgetmanager/periods/${periodId}/rates`;
    public static BUDGETMANAGER_RATE_GRID_SPREADSHEET = (periodId) => `api/budgetmanager/periods/${periodId}/rates/spreadsheet`;
    public static BUDGETMANAGER_RATE_DEFAULTS = (periodId) => `api/budgetmanager/periods/${periodId}/rates/defaults`;
    public static BUDGETMANAGER_RATE_PARTICULARS = (periodId) => `api/budgetmanager/periods/${periodId}/rates/particulars`;
    public static BUDGETMANAGER_RATE_PARTICULARS_BYID = (periodId, rateId) =>
      `api/budgetmanager/periods/${periodId}/rates/${rateId}/particulars`;
    public static BUDGETMANAGER_COLLECT_ACTUALS = (periodId) => `api/budgetmanager/periods/${periodId}/collectactuals`;
    public static BUDGETMANAGER_COLLECT_INVENTORY = (periodId) => `api/budgetmanager/periods/${periodId}/collectinventory`;
    public static BUDGETMANAGER_CALCULATE = (periodId) => `api/budgetmanager/periods/${periodId}/calculation`;
    public static BUDGETMANAGER_COPY_TOBUDGET = (periodId) => `api/budgetmanager/periods/${periodId}/details/copy`;
  };

  static DadManager = class {
    static Filters = class {
      public static TEMPLATE = 'api/dad/templates';
      public static SPLITSNAPSHOT = 'api/dad/splitsnapshots';
      public static SPLITREPORTS = 'api/dad/splitreports';
    };
    public static DADMANAGER_VENDORS_GRID = 'api/dad/vendors';
    public static DADMANAGER_VENDORS_GRID_SPREADSHEET = 'api/dad/vendors/spreadsheet';
    public static DADMANAGER_LOCATIONMAP_GRID = 'api/dad/locationmaps';
    public static DADMANAGER_LOCATIONMAP_GRID_SPREADSHEET = 'api/dad/locationmaps/spreadsheet';
    public static DADMANAGER_ACCOUNT_GRID = 'api/dad/accounts';
    public static DADMANAGER_ACCOUNT_GRID_SPREADSHEET = 'api/dad/accounts/spreadsheet';
    public static DADMANAGER_ACCOUNTMAP_GRID = 'api/dad/accountmaps';
    public static DADMANAGER_ACCOUNTMAP_GRID_SPREADSHEET = 'api/dad/accountmaps/spreadsheet';
    public static DADMANAGER_PERIOD_GRID = (vendorId) => `api/dad/vendors/${vendorId}/periods`;
    public static DADMANAGER_PERIOD_GRID_SPREADSHEET = (vendorId) => `api/dad/vendors/${vendorId}/periods/spreadsheet`;
    public static DADMANAGER_LOCATION_TYPES = (vendorId, locationId) => `api/dad/vendors/${vendorId}/locations/${locationId}/types`;
    public static DADMANAGER_LOCATION_GRID = (vendorId) => `api/dad/vendors/${vendorId}/locations`;
    public static DADMANAGER_LOCATION_GRID_SPREADSHEET = (vendorId) => `api/dad/vendors/${vendorId}/locations/spreadsheet`;
    public static DADMANAGER_PLATFORM_GRID = (vendorId) => `api/dad/vendors/${vendorId}/platforms`;
    public static DADMANAGER_PLATFORM_GRID_SPREADSHEET = (vendorId) => `api/dad/vendors/${vendorId}/platforms/spreadsheet`;
    public static DADMANAGER_PLATFORMMAP_GRID = (vendorId) => `api/dad/vendors/${vendorId}/platformmaps`;
    public static DADMANAGER_PLATFORMMAP_GRID_SPREADSHEET = (vendorId) => `api/dad/vendors/${vendorId}/platformmaps/spreadsheet`;
    public static DADMANAGER_USER_GRID = (vendorId) => `api/dad/vendors/${vendorId}/users`;
    public static DADMANAGER_USER_GRID_SPREADSHEET = (vendorId) => `api/dad/vendors/${vendorId}/users/spreadsheet`;
    public static DADMANAGER_SERVICE_GRID = (vendorId) => `api/dad/vendors/${vendorId}/services`;
    public static DADMANAGER_SERVICE_GRID_SPREADSHEET = (vendorId) => `api/dad/vendors/${vendorId}/services/spreadsheet`;
    public static DADMANAGER_SERVICEFOC_GRID = (vendorId) => `api/dad/vendors/${vendorId}/servicefocs`;
    public static DADMANAGER_SERVICEFOC_GRID_SPREADSHEET = (vendorId) => `api/dad/vendors/${vendorId}/servicefocs/spreadsheet`;
    public static DADMANAGER_SNAPSHOT_GRID = (vendorId, periodId) => `api/dad/vendors/${vendorId}/periods/${periodId}/snapshot`;
    public static DADMANAGER_SNAPSHOT_GRID_SPREADSHEET = (vendorId, periodId) => `api/dad/vendors/${vendorId}/periods/${periodId}/snapshot`;
    public static DADMANAGER_SNAPSHOT_VENDOR_IMPORT = (vendorId, periodId, locationId) =>
      `api/dad/vendors/${vendorId}/periods/${periodId}/locations/${locationId}/vendor-import`;
    public static DADMANAGER_SNAPSHOT_VENDOR_REMOVE = (vendorId, periodId, locationId) =>
      `api/dad/vendors/${vendorId}/periods/${periodId}/locations/${locationId}/vendor-remove`;
    public static DADMANAGER_SNAPSHOT_FINOFFICE_COLLECT = (vendorId, periodId, locationId) =>
      `api/dad/vendors/${vendorId}/periods/${periodId}/locations/${locationId}/finoffice-collect`;
    public static DADMANAGER_SNAPSHOT_FINOFFICE_REMOVE = (vendorId, periodId, locationId) =>
      `api/dad/vendors/${vendorId}/periods/${periodId}/locations/${locationId}/finoffice-remove`;
    public static DADMANAGER_SNAPSHOT_LOCK = (vendorId, periodId, locationId) =>
      `api/dad/vendors/${vendorId}/periods/${periodId}/locations/${locationId}/lock`;
    public static DADMANAGER_TEMPLATE_REPORT_LIST = (vendorId, templateId, locationId) =>
      `api/dad/vendors/${vendorId}/templates/${templateId}/locations/${locationId}/reports`;
    public static DADMANAGER_TEMPLATE_REPORT_BYID = (vendorId, periodId, locationId, reportId) =>
      `api/dad/vendors/${vendorId}/periods/${periodId}/locations/${locationId}/reports/${reportId}`;
  };

  static Catalog = class {
    static Filters = class {
      public static BILLERS = 'api/catalog/billers';
      public static BILLINGACCOUNTS = 'api/catalog/billingaccounts';
      public static CATALOGITEM_CATEGORIES = 'api/catalog/catalogitemcategories';
      public static CATALOGITEM_KINDS = 'api/catalog/catalogitemkinds';
      public static CATALOGITEM_TYPES = 'api/catalog/catalogitemtypes';
      public static CONTRACTORS = 'api/catalog/contractors';
      public static HAS_CONTRACTS = 'api/catalog/has-contracts';
      public static HAS_PRICES = 'api/catalog/has-prices';
      public static IS_IN_PACKAGE = 'api/catalog/is-in-package';
      public static IS_PACKAGE = 'api/catalog/is-package';
      public static IS_SELECTED = 'api/catalog/is-selected';
      public static ISSUERS = 'api/catalog/issuers';
      public static PLATFORMS = 'api/catalog/platforms';
      public static PRODUCTFAMILIES = 'api/catalog/productfamilies';
      public static PROVIDERS = 'api/catalog/providers';
      public static SERVICETYPES = 'api/catalog/servicetypes';
      public static SUBSCRIBED = 'api/catalog/subscribed';
      public static TENANTLEDGERS = 'api/catalog/tenantledgers';
      public static ISPACKAGES = 'api/catalog/is-package';
      public static ISINPACKAGES = 'api/catalog/is-in-package';
    };
    public static CATALOG_FILTERS = 'api/catalog/filters';
    public static CATALOG_ITEMS_KINDS = 'api/catalog/catalogitemkinds';
    public static CATALOG_PARTICULARS_DEFAULTS = 'api/catalog/particulars/defaults';
    public static CATALOG_PARTICULARS = 'api/catalog/particulars';
    public static CATALOG_PARTICULARS_REVIEW = 'api/catalog/particulars/review';
    public static CATALOG_NODES = 'api/catalog';
    public static CATALOG_SELECTION_NODES = 'api/catalog/selection';
    public static CATALOG_ALLOCATION_NODES = 'api/catalog/allocation';
    public static CATALOG_NODES_SPREADSHEET = 'api/catalog/spreadsheet';
    public static CATALOG_SERVICE_BY_PROVIDER_NODES = 'api/catalog/service-by-provider';
    public static CATALOG_SERVICE_BY_PROVIDER_NODES_SPREADSHEET = 'api/catalog/service-by-provider/spreadsheet';
    public static CATALOG_SERVICE_BY_BILLINGACCOUNT_NODES = 'api/catalog/service-by-billingaccount';
    public static CATALOG_SERVICE_BY_BILLINGACCOUNT_NODES_SPREADSHEET = 'api/catalog/service-by-billingaccount/spreadsheet';
    public static CATALOG_STYLES = 'api/catalog/styles';
    public static CATALOG_STYLES_SPREADSHEET = 'api/catalog/styles/spreadsheet';
    public static CATALOG_ALLOCATE_CONSUMERS = 'api/catalog/allocate-consumers';
    public static CATALOG_ALLOCATE_CONSUMERS_VALIDATION = 'api/catalog/allocate-consumers/validation';
    public static CATALOG_ADDONS_ALLOCATE_CONSUMERS = 'api/catalog/addon-allocate-consumers';
    public static CATALOG_ADDONS_ALLOCATE_CONSUMERS_VALIDATION = 'api/catalog/addon-allocate-consumers/validation';
    public static CATALOG_ACTIVATION = 'api/catalog/activation';
    public static CATALOG_DEACTIVATION = 'api/catalog/deactivation';
    public static CATALOG_PARTICULARS_BYID = (catalogId) => `api/catalog/${catalogId}/particulars`;
  };

  static ServiceCategories = class {
    public static SERVICE_CATEGORIES = 'api/services/categories';
    public static SERVICE_CATEGORIES_SPREADSHEET = 'api/services/categories/spreadsheet';
  };

  static ProductFamily = class {
    static Filters = class {
      public static CATALOGITEMKINDS = 'api/productfamilies/catalogitemkinds';
      public static PROVIDERS = 'api/productfamilies/providers';
      public static PERMISSIONINGSYSTEMS = 'api/productfamilies/permissioningsystems';
      public static SITES = 'api/productfamilies/sites';
    };
    public static PRODUCTFAMILIES = 'api/productfamilies';
    public static PRODUCTFAMILIES_SPREADSHEET = 'api/productfamilies/spreadsheet';
    public static PRODUCTFAMILIES_WAITINGROOM_NODES = 'api/productfamilies/waitingrooms';
    public static PRODUCTFAMILIES_PRODUCTS_CONFIGURATION = (entityId) => `api/productfamilies/${entityId}/forms`;
    public static PRODUCTFAMILIES_CONFIGURATION_FORM_STEPS = (productfamilyId) => `api/productfamilies/${productfamilyId}/forms/steps`;
    public static PRODUCTFAMILIES_CONFIGURATION_FORM = (productfamilyId, formId) =>
      `api/productfamilies/${productfamilyId}/forms/${formId}`;
    public static PRODUCTFAMILIES_CONFIGURATION_FORM_CONFIGURATION = (productfamilyId, formId) =>
      `api/productfamilies/${productfamilyId}/forms/${formId}/configuration`;
    public static PRODUCTFAMILIES_CONFIGURATION_FORM_FIELDKINDS = (productfamilyId) => `api/productfamilies/${productfamilyId}/fieldkinds`;
    public static PRODUCTFAMILIES_CONFIGURATION_COPY = (productfamilyFromId, productfamilyToId) =>
      `api/productfamilies/${productfamilyFromId}/copy/${productfamilyToId}`;
    public static PRODUCTFAMILIES_POLICIES = (productfamilyId) => `api/productfamilies/${productfamilyId}/policies`;
    public static PRODUCTFAMILIES_MAILTEMPLATES = (productfamilyId) => `api/productfamilies/${productfamilyId}/mailtemplates`;
    public static PRODUCTFAMILIES_MAILTEMPLATES_SPREADSHEET = (productfamilyId) =>
      `api/productfamilies/${productfamilyId}/mailtemplates/spreadsheet`;
  };

  static Languages = class {
    public static LANGUAGES = 'api/languages';
    public static LANGUAGES_SPREADSHEET = 'api/languages/spreadsheet';
  };

  static Costbrowser = class {
    static Filters = class {
      public static YEARS = 'api/costbrowser/years';
      public static INCLUSIVE_VAT = 'api/costbrowser/inclusivevats';
      public static PRODUCTS = (year) => `api/costbrowser/products?year=${year}`;
      public static VENDORS = (year) => `api/costbrowser/vendors?year=${year}`;
      public static CURRENCY = (year) => `api/costbrowser/currencies?year=${year}`;
      public static TENANTS = (year) => `api/costbrowser/tenants?year=${year}`;
      public static DIVISIONS = (year, tenants) => `api/costbrowser/divisions?year=${year}&tenantIds=${tenants}`;
      public static DEPARTEMENTS = (year, tenants, divisions) =>
        `api/costbrowser/departements?year=${year}&tenantIds=${tenants}&divisionIds=${divisions}`;
      public static COSTCENTRES = (year, tenants, divisions, departments) =>
        `api/costbrowser/costcentres?year=${year}&tenantIds=${tenants}&divisionIds=${divisions}&departementIds=${departments}`;
      public static USERS = (year, tenants, divisions, departments, costcentres) =>
        `api/costbrowser/users?year=${year}&tenantIds=${tenants}&divisionIds=${divisions}&departementIds=${departments}&costcentreIds=${costcentres}`;
    };
    public static COSTBROWSER_DATA = 'api/costbrowser';
    public static COSTBROWSER_TENANT_FILTER_ENABLED = 'api/costbrowser/tenants/enabled';
    public static COSTBROWSER_SPREADSHEET = 'api/costbrowser/spreadsheet';
    public static COSTBROWSER_SPREADSHEET_PROGRESS = 'api/costbrowser/spreadsheet/progress';
    public static COSTBROWSER_RECALCULATION = 'api/costbrowser/recalculation';
  };

  static Budgetbrowser = class {
    static Filters = class {
      public static PERIODS = 'api/budgetbrowser/periods';
      public static TENANTS = (budgetperiodId) => `api/budgetbrowser/tenants?budgetperiodId=${budgetperiodId}`;
      public static DIVISIONS = (budgetperiodId, tenants) =>
        `api/budgetbrowser/divisions?budgetperiodId=${budgetperiodId}&tenantIds=${tenants}`;
      public static DEPARTEMENTS = (budgetperiodId, tenants, divisions) =>
        `api/budgetbrowser/departements?budgetperiodId=${budgetperiodId}&tenantIds=${tenants}&divisionIds=${divisions}`;
      public static COSTCENTRES = (budgetperiodId, tenants, divisions, departments) =>
        `api/budgetbrowser/costcentres?budgetperiodId=${budgetperiodId}&tenantIds=${tenants}&divisionIds=${divisions}&departementIds=${departments}`;
      public static USERS = (budgetperiodId, tenants, divisions, departments, costcentres) =>
        `api/budgetbrowser/users?budgetperiodId=${budgetperiodId}&tenantIds=${tenants}&divisionIds=${divisions}&departementIds=${departments}&costcentreIds=${costcentres}`;
    };
    public static BUDGETBROWSER_TREE = 'api/budgetbrowser';
    public static BUDGETBROWSER_SPREADSHEET = 'api/budgetbrowser/spreadsheet';
    public static BUDGETBROWSER_SPREADSHEET_PROGRESS = 'api/budgetbrowser/spreadsheet/progress';
  };

  static Contracts = class {
    static Filters = class {
      public static CATEGORIES = 'api/contracts/categories';
      public static ISSUERS = 'api/contracts/issuers';
      public static KINDS = 'api/contracts/kinds';
      public static OBJECTDETAILS = 'api/contracts/objectdetails';
      public static CREATORS = 'api/contracts/creators';
      public static OWNERS = 'api/contracts/owners';
      public static RECEIVERS = 'api/contracts/receivers';
      public static STATUSES = 'api/contracts/statuses';
      public static TYPES = 'api/contracts/types';
      public static PERIODC_REVIEWS = 'api/contracts/periodicreviews';
      public static DEFINITIONS_VENDOR = 'api/contracts/definitions/vendors';
      public static RIGHTS_TEMPLATE_KINDS = 'api/contracts/rights/templatekinds';
      public static RIGHTS_KINDS = 'api/contracts/rights/kinds';
    };
    public static CONTRACTS = 'api/contracts';
    public static CONTRACTS_NODES = 'api/contracts/nodes';
    public static CONTRACTS_DEFINITIONS = 'api/contracts/definitions';
    public static CONTRACTS_DEFINITIONS_SPREADSHEET = 'api/contracts/definitions/spreadsheet';
    public static CONTRACTS_KIND = 'api/contracts/kinds';
    public static CONTRACTS_SERVICES = 'api/contracts/services';
    public static CONTRACTS_FAVORITE = 'api/contracts/favorites';
    public static CONTRACTS_RECENTLYUSED = 'api/contracts/recentlyused';
    public static CONTRACTS_SYSTEM = 'api/contracts/system';
    public static CONTRACTS_PERIODIC_REVIEWS = 'api/contracts/periodicreviews';
    public static CONTRACTS_SPREADSHEET = 'api/contracts/spreadsheet';
    public static CONTRACTS_TYPES = 'api/contracts/types';
    public static CONTRACTS_STATUS = 'api/contracts/status';
    public static CONTRACTS_PARTICULARS_DEFAULT = 'api/contracts/defaults';
    public static CONTRACTS_PARTICULARS_CREATE = 'api/contracts/particulars';
    public static CONTRACTS_RIGHTS = 'api/contracts/rights';
    public static CONTRACTS_OBJECTS = 'api/contracts/objects';
    public static CONTRACTS_OBJECTS_SPREADSHEET = 'api/contracts/objects/spreadsheet';
    public static CONTRACTS_TEMPLATES = 'api/contracts/templates';
    public static CONTRACTS_TEMPLATES_SPREADSHEET = 'api/contracts/templates/spreadsheet';
    public static CONTRACTS_RIGHTS_OPTIONS = 'api/contracts/rights/options';
    public static CONTRACTS_RIGHTS_OPTIONS_SPREADSHEET = 'api/contracts/rights/options/spreadsheet';
    public static CONTRACTS_FAMILY = (contractId) => `api/contracts/${contractId}/family`;
    public static CONTRACTS_CHANGE_FAVORITE = (contractId) => `api/contracts/${contractId}/favorite`;
    public static CONTRACTS_COPY = (contractId) => `api/contracts/${contractId}/copy`;
    public static CONTRACTS_PARTICULARS = (entityId) => `api/contracts/${entityId}/particulars`;
    public static CONTRACTS_TEMPLATE_PARTICULARS = (entityId) => `api/contracts/templates/${entityId}/particulars`;
    public static CONTRACTS_TREE_BY_CONTRACTID = (entityId) => `api/contracts/${entityId}/tree`;
    // public static CONTRACTS_PRODUCTS = (entityId) =>
    //   `api/entities/entity_ctlogical?filterEntityKind=entity_contract&filterEntityId=${entityId}`;
    // public static CONTRACTS_SERVICES = (entityId) =>
    //   `api/entities/entity_ctservice?filterEntityKind=entity_contract&filterEntityId=${entityId}`;
    // public static CONTRACTS_BILLING_ITEMS = (entityId) =>
    //   `api/entities/entity_ctsrcu?filterEntityKind=entity_contract&filterEntityId=${entityId}`;
    public static CONTRACTS_KEY_ACCOUNT_MANAGER_HISTORY = (contractId) => `api/contracts/${contractId}/keyaccountmanagers`;
    public static CONTRACTS_CURRENT_OWNER_HISTORY = (contractId) => `api/contracts/${contractId}/currentowners`;
  };

  static Costcentres = class {
    static Filters = class {
      public static HASALLOCATIONS = 'api/costcentres/hasallocations';
      public static LEVELS = 'api/costcentres/levels';
      public static TYPES = 'api/costcentres/types';
      public static RESPONSIBLES = 'api/costcentres/responsibles';
      public static LOCATIONS = 'api/costcentres/locations';
      public static STATUSES = 'api/costcentres/statuses';
      public static TENANTAREAS = 'api/costcentres/tenantareas';
    };
    public static COSTCENTRES = 'api/costcentres';
    public static COSTCENTRES_SPREADSHEET = 'api/costcentres/spreadsheet';
    public static COSTCENTRES_REPLACEMENTS_DETAILS_BASE_PATH = `api/entities/entity_ccrccr`;
    public static COSTCENTRES_NODES = (nodeId) => `api/costcentres/${nodeId}/nodes`;
    public static COSTCENTRES_TRANSITION = (costcentreId) => `api/costcentres/${costcentreId}/transitions`;
    public static COSTCENTRES_REPLACEMENTS = (costcentreId) => `api/costcentres/${costcentreId}/replacements`;
    public static COSTCENTRES_RESPONSIBLES = (costcentreId) => `api/costcentres/${costcentreId}/users`;
  };

  static Currencies = class {
    static Filters = class {
      public static LOCATIONS = 'api/currencies/locations';
    };
    public static CURRENCIES = 'api/currencies';
    public static CURRENCIES_SPREADSHEET = 'api/currencies/spreadsheet';
    public static CURRENCIES_REPORTING = (currencyId) => `api/currencies/${currencyId}/reporting`;
    public static CURRENCY_RATES = (currencyId) => `api/currencies/${currencyId}/exchangerates`;
    public static CURRENCY_EXCHANGE_RATE_PAIRS = (currencyId) => `api/currencies/${currencyId}/exchangeratepairs`;
    public static CURRENCY_EXCHANGE_RATE_PAIR_RATES = (currencyId, pairCurrencyId) =>
      `api/currencies/${currencyId}/${pairCurrencyId}/exchangerates`;
  };

  static Desks = class {
    static Filters = class {
      public static USERS = 'api/desks/users';
      public static ORGANISATIONS = 'api/desks/organisations';
      public static COSTCENTRES = 'api/desks/costcentres';
      public static LOCATIONS = 'api/desks/locations';
      public static ADDRESSES = 'api/desks/addresses';
      public static LEGALENTITIES = 'api/desks/legalentities';
      public static ISORGANISATIONDESKS = 'api/desks/isorganisationdesks';
    };
    public static DESKS = 'api/desks';
    public static DESKS_DEFAULT = 'api/desks/default';
    public static DESKS_PARTICULAR_DEFAULTS = 'api/desks/defaults';
    public static DESKS_DEFAULT_PERIOD = 'api/desks/defaultperiod';
    public static DESKS_PARTICULARS = 'api/desks/particulars';
    public static DESK_MOVE = `api/desks/move`;
    public static DESK_MOVE_FROMDESK = (deskId) => `api/desks/${deskId}/particulars/move`;
    public static DESKS_PARTICULARS_BYID = (deskId) => `api/desks/${deskId}/particulars`;
    public static DESKS_CLIPBOARD_BYID = (deskId) => `api/desks/${deskId}/clipboard`;
    public static DESKS_COSTCENTRE_PERIODS = (deskId) => `api/desks/${deskId}/costcentres/periods`;
    public static DESKS_COSTCENTRES = (deskId) => `api/desks/${deskId}/costcentres`;
    public static DESKS_TASKS = (deskId) => `api/desks/${deskId}/tasks`;
    public static DESKS_TASKS_SPREADSHEET = (deskId) => `api/desks/${deskId}/tasks/spreadsheet`;
    public static DESKS_COSTCENTRES_CHANGE = (deskId) => `api/desks/${deskId}/costcentres/change`;
  };

  static OrganisationDesks = class {
    static Filters = class {
      public static USERS = 'api/organisationdesks/users';
      public static ORGANISATIONS = 'api/organisationdesks/organisations';
      public static COSTCENTRES = 'api/organisationdesks/costcentres';
      public static LOCATIONS = 'api/organisationdesks/locations';
      public static ADDRESSES = 'api/organisationdesks/addresses';
      public static LEGALENTITIES = 'api/organisationdesks/legalentities';
    };
    public static ORGANISATIONDESKS = 'api/organisationdesks';
    public static ORGANISATIONDESKS_SPREADSHEET = 'api/organisationdesks/spreadsheet';
    public static ORGANISATIONDESKS_PARTICULAR_DEFAULTS = 'api/organisationdesks/defaults';
    public static ORGANISATIONDESKS_PARTICULARS = 'api/organisationdesks/particulars';
    public static ORGANISATIONDESKS_PARTICULARS_BYID = (deskId) => `api/organisationdesks/${deskId}/particulars`;
    public static ORGANISATIONDESKS_CLIPBOARD_BYID = (deskId) => `api/organisationdesks/${deskId}/clipboard`;
  };

  static ExchangeRate = class {
    public static EXCHANGE_RATES = 'api/exchangerates';
    public static EXCHANGE_RATES_ID = (exchangeRateId) => `api/exchangerates/${exchangeRateId}`;
    public static EXCHANGE_RATES_CURRENCIES = (currencyId) => `api/exchangerates/currencies?filterCurrencyId=${currencyId}`;
  };

  static FeedItems = class {
    static Filters = class {
      public static SITES = 'api/feeditems/sites';
      public static PLATFORMS = 'api/feeditems/platforms';
      public static STATUSES = 'api/feeditems/statuses';
      public static PRODUCT_FAMILIES = 'api/feeditems/productfamilies';
      public static PROVIDERS = 'api/feeditems/providers';
      public static ISSUERS = 'api/feeditems/issuers';
      public static SERVICETYPES = 'api/feeditems/servicetypes';
      public static ISBASESERVICES = 'api/feeditems/isbaseservices';
      public static BILLERS_BYID = (serviceId) => `api/feeditems/${serviceId}/billers`;
      public static BILLINGACCOUNTS_BYID = (serviceId) => `api/feeditems/${serviceId}/billingaccounts`;
      public static PRODUCTFAMILIES_BYID = (serviceId) => `api/feeditems/${serviceId}/productfamilies`;
      public static PROVIDERS_BYID = (serviceId) => `api/feeditems/${serviceId}/providers`;
    };
    public static FEEDITEMS = 'api/feeditems';
    public static FEEDITEMS_SPREADSHEET = 'api/feeditems/spreadsheet';
    public static FEEDITEMS_BILLINGACCOUNTS = 'api/feeditems/billingaccounts';
    public static FEEDITEMS_SUBSCRIPTIONS = (feeditemId) => `api/feeditems/${feeditemId}/subscriptions`;
    public static FEEDITEMS_REPLACE = (feeditemId) => `api/feeditems/${feeditemId}/replace`;
    public static FEEDITEMS_PRICES = (entityId) => `api/feeditems/${entityId}/prices`;
    public static FEEDITEMS_PRICE_PARTICULARS = (entityId, priceId) => `api/feeditems/${entityId}/prices/${priceId}/particulars`;
    public static FEEDITEMS_PRICE_PARTICULARS_DEFAULT = (entityId) => `api/feeditems/${entityId}/prices/defaults`;
    public static FEEDITEMS_PRICE_PARTICULARS_CREATE = (entityId) => `api/feeditems/${entityId}/prices/particulars`;
    public static FEEDITEMS_SUBSCRIPTIONS_BYID = (subscriptionId) => `api/feeditems/${subscriptionId}/subscriptions`;
    public static FEEDITEMS_SUBSCRIPTIONS_BYID_SPREADSHEET = (subscriptionId) =>
      `api/feeditems/${subscriptionId}/subscriptions/spreadsheet`;
    public static DELIVERYITEMS_PARTICULARS_BYID = (deliveryitemId) => `api/deliveryitems/${deliveryitemId}/particulars`;
  };

  static Groups = class {
    static Filters = class {
      public static GROUP_COLUMN_FILTERS = 'api/groups/columnfilters';
    };
    public static ACCESS_RIGHT_ENTITIES = 'api/groups/accessrightentities';
    public static GROUP_ACCESS_RIGHT = (groupId, entityKind) => `api/groups/${groupId}/${entityKind}/accessrights`;
    public static GROUP_ACCESS_RIGHT_INCLUDE = (groupId, entityKind) => `api/groups/${groupId}/${entityKind}/accessrights?include=true`;
    public static GROUP_ACCESS_RIGHT_EXCLUDE = (groupId, entityKind) => `api/groups/${groupId}/${entityKind}/accessrights?include=false`;
    public static ACCESS_RIGHT_INCLUDE = (selectedEntity) => `${selectedEntity}/accessrights?include=true`;
    public static ACCESS_RIGHT_EXCLUDE = (selectedEntity) => `${selectedEntity}/accessrights?include=false`;
    public static GROUP_ACCESS_COLUMN_FILTER = (groupId) => `api/groups/${groupId}/columnfilters`;
  };

  static Invoices = class {
    static Filters = class {
      public static BILLINGACCOUNTS = (vendors) => `api/invoices/billingaccounts?filterVendorIds=${vendors}`;
      public static CLASSIFICATIONS = 'api/invoices/classifications';
      public static CURRENCIES = 'api/invoices/currencies';
      public static LEDGERS = 'api/invoices/ledgers';
      public static PERIODS = 'api/invoices/periods';
      public static SERVICES = 'api/invoices/services';
      public static STATUSES = 'api/invoices/statuses';
      public static VENDORS = 'api/invoices/vendors';
    };
    public static INVOICES = 'api/invoices';
    public static INVOICES_SPREADSHEET = 'api/invoices/spreadsheet';
    public static INVOICES_STATUS = 'api/entities/entity_inv_status';
    public static INVOICES_PARTICULARS = `api/invoices/particulars`;
    public static INVOICES_CHANGESTATUS = `api/invoices/status`;
    public static INVOICES_OUTSTANDINGS = 'api/invoices/outstandings';
    public static INVOICES_PARTICULARS_DEFAULTS = `api/invoices/particulars`;
    public static INVOICES_PARTICULARS_DEFAULTS_SORTORDER = `api/invoices/particulars/sortorder`;
    public static INVOICES_OUTSTANDINGS_DISMISS = (invoiceId) => `api/invoices/${invoiceId}/outstandings/dismiss`;
    public static INVOICES_CREDITNOTES = (invoiceId) => `api/invoices/${invoiceId}/creditnotes`;
    public static INVOICES_CREATION_BYBILLINGACCOUNTID = (billingaccountId) => `api/invoices/creation?billingaccountId=${billingaccountId}`;
    public static INVOICES_PARTICULARS_ID = (entityId) => `api/invoices/${entityId}/particulars`;
    public static INVOICES_PARTICULARS_ITEMS = (invoiceId, itemType) => `api/invoices/${invoiceId}/items/${itemType}`;
    public static INVOICES_PARTICULARS_ITEM_DEFAULT_VALUES = (invoiceId, billingitemId) =>
      `api/invoices/${invoiceId}/items/defaults?billingitemIds=${billingitemId}`;
    public static INVOICES_PARTICULARS_SERVICES = (invoiceId, itemType) => `api/invoices/${invoiceId}/services/${itemType}`;
    public static INVOICES_PARTICULARS_WORKFLOW = (invoiceId) => `api/invoices/${invoiceId}/workflow`;
    public static INVOICES_SPREADSHEET_ID = (entityId) => `api/invoices/${entityId}/spreadsheet`;
    public static INVOICES_PDF_ID = (entityId) => `api/invoices/${entityId}/pdf`;
    public static INVOICES_EXCHANGE_RATE = (invoiceId) => `api/invoices/${invoiceId}/exchangerates`;
    public static INVOICES_PARTICULARS_ITEMS_QUANTITIES = (invoiceId, itemId) => `api/invoices/${invoiceId}/items/${itemId}/includes`;
  };

  static InvoicesStatus = class {
    static Filters = class {
      public static PAIDS = 'api/invoice-statuses/paids';
      public static READONLYS = 'api/invoice-statuses/readonlys';
    };
    public static INVOICESSTATUS_GRIDS = 'api/invoice-statuses';
    public static INVOICESSTATUS_SPREADSHEET = 'api/invoice-statuses/spreadsheet	';
  };

  static Jobs = class {
    static Filters = class {
      public static METHODS = 'api/jobs/methods';
      public static STATUSES = 'api/jobs/statuses';
      public static ONDEMANDS = 'api/jobs/ondemands';
      public static SCHEDULE_ACTIVATEDS = 'api/jobs/schedules/activateds';
      public static SCHEDULE_RECURRINGS = 'api/jobs/schedules/recurrings';
      public static SCHEDULE_STATUSES = 'api/jobs/schedules/statuses';
      public static HISTORY_USERS = (filterMethodTextnames) => `api/jobs/history/users?filterMethodTextnames=${filterMethodTextnames}`;
    };
    public static JOBS = 'api/jobs';
    public static JOBS_SPREADSHEET = 'api/jobs/spreadsheet';
    public static JOBS_SCHEDULES = 'api/jobs/schedules';
    public static JOBS_SCHEDULES_SPREADSHEET = 'api/jobs/schedules/spreadsheet';
    public static JOB_HISTORIES = `api/jobs/history`;
    public static JOB_HISTORIES_SPREADSHEET = `api/jobs/history/spreadsheet`;
    public static JOB_SCHEDULE_PARAMETERS = (scheduleId) => `api/jobs/schedules/${scheduleId}/parameters`;
    public static JOB_START = (taskId) => `api/jobs/${taskId}/start`;
    public static JOB_HISTORY = (jobId) => `api/jobs/${jobId}/history`;
    public static JOB_SCHEDULES_START = (scheduleId) => `api/jobs/schedules/${scheduleId}/start`;
    public static JOB_SCHEDULES_CRON = (scheduleId) => `api/jobs/schedules/${scheduleId}/cron`;
    public static JOB_PARAMETERS = (jobId) => `api/jobs/${jobId}/parameters`;
  };

  static Locations = class {
    static Filters = class {
      public static HASALLOCATIONS = 'api/locations/hasallocations';
      public static LEVELS = 'api/locations/levels';
    };
    public static LOCATIONS = 'api/locations';
    public static LOCATIONS_SPREADSHEET = 'api/locations/spreadsheet';
    public static LOCATIONS_NODES = (nodeId) => `api/locations/${nodeId}/nodes`;
  };

  static Logs = class {
    static Filters = class {
      public static ENTITYKINDS = 'api/logs/entities';
      public static KINDS = 'api/logs/kinds';
      public static USERS = 'api/logs/users';
      public static LOGS_FILES_LOGLEVELS = (fileId) => `api/logs/files/${fileId}/loglevels`;
      public static LOGS_FILES_PROCESSES = (fileId) => `api/logs/files/${fileId}/processes`;
      public static LOGS_FILES_THREADS = (fileId) => `api/logs/files/${fileId}/threads`;
      public static LOGS_FILES_CLASSNAMES = (fileId) => `api/logs/files/${fileId}/classnames`;
    };
    public static LOGS = 'api/logs';
    public static LOGS_SPREADSHEET = 'api/logs/spreadsheet';
    public static LOGS_FILES = 'api/logs/files';
    public static LOGS_FILES_DATA = (fileId) => `api/logs/files/${fileId}/data`;
    public static LOGS_FILES_DATA_SPREADSHEET = (fileId) => `api/logs/files/${fileId}/data/spreadsheet`;
  };
  static News = class {
    static Filters = class {
      public static NEWS_CATEGORIES = 'api/news/categories';
      public static NEWS_PRIORITIES = 'api/news/priorities';
      public static NEWS_HASREADS = 'api/news/hasreads';
    };
    public static NEWS = 'api/news';
    public static NEWS_SPREADSHEET = 'api/news/spreadsheet';
  };

  static Licenses = class {
    static Filters = class {
      public static DESKS = 'api/licenses/desks';
      public static GROUPS = 'api/licenses/groups';
      public static KINDS = 'api/licenses/kinds';
      public static LOCATIONS = 'api/licenses/locations';
      public static PLATFORMS = 'api/licenses/platforms';
      public static SHAREDS = 'api/licenses/shareds';
      public static SITES = 'api/licenses/sites';
      public static STATUSES = 'api/licenses/statuses';
      public static TYPES = 'api/licenses/types';
      public static PRODUCT_FAMILIES = 'api/licenses/productfamilies';
      public static BILLINGACCOUNTS = 'api/licenses/billingaccounts';
      public static VENDORS = 'api/licenses/vendors';
      public static WAITINGROOMS = 'api/licenses/waitingrooms';
    };
    public static LICENSES = 'api/licenses';
    public static LICENSES_SPREADSHEET = 'api/licenses/spreadsheet';
    public static LICENSES_SERVICES = 'api/licenses/services';
    public static LICENSES_RULE_DETAILS_BASE_PATH = `api/entities/entity_ctlogical`;
    public static PRODUCTS_TYPES = 'api/licenses/types';
    public static ADD_LICENSE_WIZARD = 'api/licenses/add-wizard';
    public static CANCEL_LICENSE_WIZARD = 'api/licenses/cancel-wizard';
    public static MOVE_LICENSE_WIZARD = 'api/licenses/move-wizard';
    public static SWAP_LICENSE_WIZARD = 'api/licenses/swap';
    public static SWAP_LICENSE_VALIDATION = 'api/licenses/swap/validation';
    public static LICENSE_CANCEL_ADDONS = 'api/licenses/cancel-addon-wizard';
    public static LICENSE_CANCEL_ADDONS_VALIDATION = 'api/licenses/cancel-addon-wizard/validation';
    public static LICENSE_RULES = (entityId) => `api/entities/entity_ctlogical?filterEntityKind=entity_logical&filterEntityId=${entityId}`;
    public static LICENSE_ALLOCATIONS = (licenseId) => `api/licenses/${licenseId}/allocations`;
    public static LICENSE_REOPENING = (licenseId) => `api/licenses/${licenseId}/reopening`;
    public static LICENSE_CHANGE_ACCFOC = (licenseId) => `api/licenses/${licenseId}/changeaccfoc`;
  };

  static Messages = class {
    static Filters = class {
      public static LEVELS = 'api/messages/levels';
      public static USERS = 'api/messages/users';
    };
    public static MESSAGES = 'api/messages';
    public static MESSAGES_SPREADSHEET = 'api/messages/spreadsheet';
  };

  static Systemmonitor = class {
    public static SYSTEMMONITOR = 'api/systemmonitor';
    public static SYSTEMMONITOR_SPREADSHEET = 'api/systemmonitor/spreadsheet';
  };

  static Organisations = class {
    static Filters = class {
      public static HASALLOCATIONS = 'api/organisations/hasallocations';
      public static LEVELS = 'api/organisations/levels';
      public static TYPES = 'api/organisations/types';
      public static LOCATIONS = 'api/organisations/locations';
      public static STATUSES = 'api/organisations/statuses';
      public static TENANTAREAS = 'api/organisations/tenantareas';
      public static USERS = 'api/organisations/users';
      public static RESPONSIBLES = 'api/organisations/responsibles';
    };
    public static ORGANISATIONS = 'api/organisations';
    public static ORGANISATIONS_SPREADSHEET = 'api/organisations/spreadsheet';
    public static ORGANISATIONS_NODES = (nodeId) => `api/organisations/${nodeId}/nodes`;
    public static ORGANISATIONS_COSTCENTRE_PERIODS = (organisationId) => `api/organisations/${organisationId}/costcentreperiods`;
    public static ORGANISATIONS_COSTCENTRE_CHANGE = (organisationId, costcentreperiodId) =>
      `api/organisations/${organisationId}/costcentreperiods/${costcentreperiodId}/change`;
    public static ORGANISATIONS_COSTCENTRE_FORMFIELDS = (periodId, stepId) => `api/entities/entity_ourccr/${periodId}/${stepId}/form`;
    public static ORGANISATIONS_RESPONSIBLES = (organisationId) => `api/organisations/${organisationId}/users`;
  };

  static Packages = class {
    static Filters = class {
      public static CONTENT_BILLINGITEMS = 'api/packages/contentbillingitems';
      public static BILLINGITEMS = 'api/packages/billingitems';
      public static USECOUNTS = 'api/packages/usecounts';
      public static RULES = 'api/packages/rules';
    };
    public static PACKAGES = 'api/packages';
    public static PACKAGES_SPREADSHEET = 'api/packages/spreadsheet';
    public static PACKAGES_PARTICULARS = 'api/packages/particulars';
    public static PACKAGES_CONTENTS = 'api/packages/contents';
    public static PACKAGES_BILLING_ITEMS = (packageId) => `api/packages/${packageId}/billingitems`;
  };

  static Platforms = class {
    static Filters = class {
      public static TYPES = 'api/platforms/types';
      public static STATUSES = 'api/platforms/statuses';
    };
    public static PLATFORMS = 'api/platforms';
    public static PLATFORMS_SPREADSHEET = 'api/platforms/spreadsheet';
  };

  static Policies = class {
    static Filters = class {
      public static CATALOGITEMTYPES = 'api/policies/catalogitemtypes';
    };
    public static POLICIES = 'api/policies';
    public static POLICIES_SPREADSHEET = 'api/policies/spreadsheet';
    public static POLICIES_PARTICULAR_DEFAULTS = 'api/policies/defaults';
    public static POLICIES_PARTICULARS = 'api/policies/particulars';
    public static POLICIES_PARTICULARS_BYID = (policyId) => `api/policies/${policyId}/particulars`;
  };

  static Preferences = class {
    static Filters = class {
      public static CATEGORIES = 'api/preferences/categories';
    };
    public static PREFERENCE_SYSTEM = 'api/preferences/system';
    public static PREFERENCE_SYSTEM_SPREADSHEET = 'api/preferences/system/spreadsheet';
    public static PREFERENCE_CONFIGURATION_ITEMS = 'api/preferences/configurations';
    public static PREFERENCE_CONFIGURATION_ITEMS_FAVORITES = 'api/preferences/configurations/favorite';
    public static PREFERENCE_CONFIGURATION_ITEMS_TOPICS = 'api/preferences/configurations/topics';
    public static PREFERENCE_INTERFACE_ITEMS = 'api/preferences/interfaces';
    public static PREFERENCE_FORM_STEPS = 'api/preferences/form/steps';
    public static PREFERENCE_SYSTEM_CATEGORIES_FORM = (categoryId) => `api/preferences/system/categories/${categoryId}/form`;
    public static PREFERENCE_FORMFIELDS = (formId) => `api/preferences/form/${formId}`;
    public static PREFERENCE_CONFIGURATION_ITEMS_FAVORITE_CHANGE = (configurationId) =>
      `api/preferences/configurations/${configurationId}/favorite`;
  };

  static Costs = class {
    static Filters = class {
      public static BUDGETS = 'api/costs/budgets';
      public static CURRENCIES = 'api/costs/currencies';
      public static COSTKINDS = 'api/costs/costkinds';
      public static SERVICES = 'api/costs/services';
      public static VENDORS = 'api/costs/vendors';
    };
    public static COSTS = 'api/costs';
    public static COSTS_NODES = 'api/costs/nodes';
    public static COSTS_SPREADSHEET = 'api/costs/spreadsheet';
    public static COSTS_PARTICULARS_INCREASE = `api/costs/increase`;
    public static COSTS_PERIOD_COPY = `api/costs/periods/copy`;
    public static COSTS_PARTICULARS = (entityId) => `api/costs/${entityId}/particulars`;
  };

  static Priorities = class {
    static Filters = class {};
    public static PRIORITIES = 'api/priorities';
    public static PRIORITIES_SPREADSHEET = 'api/priorities/spreadsheet';
  };

  static Products = class {
    static Filters = class {
      public static SITES = 'api/products/sites';
      public static PLATFORMS = 'api/products/platforms';
      public static VENDORS = 'api/products/vendors';
      public static TYPES = 'api/products/types';
      public static GROUPS = 'api/products/groups';
      public static KINDS = 'api/products/kinds';
      public static STATUSES = 'api/products/statuses';
      public static ENABLED = 'api/products/enabled';
    };
    public static PRODUCTS = 'api/products';
    public static PRODUCTS_SPREADSHEET = 'api/products/spreadsheet';
    public static PRODUCTS_CONFIGURATIONS_ACCOUNTS = `api/products/configurations/accounts`;

    public static ORDER_WIZARD_PRODUCTS_NODES = (productId) =>
      `api/products/${productId}/waitingrooms?accessmode=${AccessMode.none.toString()}`;
    //public static PRODUCTS_DETAILS = (productId) => `api/entities/entity_product/${productId}/details`;
    public static PRODUCTS_CONFIGURATION_DETAILS = (productId) => `api/products/${productId}/configurations/details`;
    public static PRODUCTS_CONFIGURATION_DETAILS_CONFIG = (productId) => `api/products/${productId}/configurations/configuration`;
    public static PRODUCTS_CONFIGURATION = (entityId) => `api/products/${entityId}/forms`;
    public static PRODUCTS_CONFIGURATION_FORM_STEPS = (productId) => `api/products/${productId}/forms/steps`;
    public static PRODUCTS_CONFIGURATION_FORM = (productId, formId) => `api/products/${productId}/forms/${formId}`;
    public static PRODUCTS_CONFIGURATION_FORM_CONFIGURATION = (productId, formId) =>
      `api/products/${productId}/forms/${formId}/configuration`;
    public static PRODUCTS_CONFIGURATION_FORM_FIELDKINDS = (productId) => `api/products/${productId}/fieldkinds`;
    public static PRODUCTS_CONFIGURATION_COPY = (productFromId, productToId) => `api/products/${productFromId}/copy/${productToId}`;
    public static PRODUCTS_BASE_SERVICES = (productId) => `api/products/${productId}/feeditems`;
  };

  static Reports = class {
    static Filters = class {
      public static AUTODISTRIBUTIONS = 'api/reports/autodistributions';
      public static OWNERS = 'api/reports/owners';
      public static TEMPLATES = 'api/reports/templates';
      public static FORMATS = 'api/reports/formats';
      public static KINDS = 'api/reports/kinds';
    };
    public static REPORTS_TEPLATES = 'api/reporttemplates';
    public static REPORTS = 'api/reports';
    public static REPORTS_TEMPLATES = 'api/reports/templates';
    public static REPORTS_TEMPLATES_SPREADSHEET = 'api/reports/templates/spreadsheet';
    public static REPORTS_SPREADSHEET = 'api/reports/spreadsheet';
    public static REPORTS_FAVORITE = 'api/reports/favorites';
    public static REPORTS_RECENTLYUSED = 'api/reports/recentlyused';
    public static REPORTS_STYLES = 'api/reports/reportstyles';
    public static REPORTS_HIDE_OPTIONS = 'api/reports/hides';
    public static REPORTS_FILTER_OPTIONS = 'api/reports/filters';
    public static REPORTS_SORTING_OPTIONS = 'api/reports/sortings';
    public static REPORTS_GENERATION = `api/reports/generation`;
    public static REPORTS_GENERATION_QUEUE = `api/reports/queueing`;
    public static REPORTS_PARTICULARS_CREATE = `api/reports/particulars`;
    public static REPORTS_CATEGORIES = `api/reports/categories`;
    public static REPORTS_CATEGORIES_SPREADSHEET = `api/reports/categories/spreadsheet`;
    public static REPORTS_CATEGORY_LINKS = `api/reports/categorylinks`;
    public static REPORTS_CATEGORY_LINKS_SPREADSHEET = `api/reports/categorylinks/spreadsheet`;
    public static REPORTS_PARTICULARS = (reportId) => `api/reports/${reportId}/particulars`;
    public static REPORTS_FILTERS = (reportId) => `api/reports/${reportId}/filters`;
    public static REPORTS_FIELDS = (reportId) => `api/reports/${reportId}/fields`;
    public static REPORTS_AUTODISTRIBUTIONS = (reportId) => `api/reports/${reportId}/autodistributions`;
    public static REPORTS_TEMPLATE_AVAILABLE_FIELDS = (templateId) => `api/reports/templates/${templateId}/availablefields`;
    public static REPORTS_STYLES_DETAILS_COPY = (reportstyleId) => `api/reports/reportstyles/${reportstyleId}/copy`;
    public static REPORTS_STYLES_PREVIEW = (styleId) => `api/reports/styles/${styleId}`;
    public static REPORTS_CHANGE_FAVORITE = (reportId) => `api/reports/${reportId}/favorite`;
    public static REPORTS_TEMPLATES_IMAGE = (templateId) => `api/reports/templates/${templateId}/image`;
    public static REPORTS_ADD_CATEGORY_REPORT = (categoryId) => `api/reports/categories/${categoryId}/add`;
  };

  static Requests = class {
    static Filters = class {
      public static CHANGESON = 'api/requests/changeson';
      public static CLOSED = 'api/requests/closed';
      public static KINDS = 'api/requests/kinds';
      public static PLATFORMS = 'api/requests/platforms';
      public static PRODUCT_FAMILIES = 'api/requests/productfamilies';
      public static REQUESTSTATES = 'api/requests/requeststates';
      public static SOURCES = 'api/requests/sources';
      public static USERS = 'api/requests/users';
    };
    public static REQUESTS = 'api/requests';
    public static REQUESTS_SPREADSHEET = 'api/requests/spreadsheet';
    public static REQUESTS_COMMENTS_BASEPATH = `api/requests`;
    public static REQUEST_MARKET_DATA_ORDER_VALIDATION = `api/requests/market-data-order/validation`;
    public static REQUEST_MARKET_DATA_ORDER = `api/requests/market-data-order`;
    public static REQUEST_LICENSE_SERVICES = (requestId) => `api/requests/${requestId}/licenseservices`;
    public static REQUEST_LICENSE_SERVICES_REPLACEMENT = (requestId) => `api/requests/${requestId}/licenseservices/replacement`;
    public static REQUEST_MARKET_DATA_ORDER_DATA = (requestId) => `api/requests/${requestId}/market-data-order`;
    public static REQUEST_SEND_EMAIL = (requestId) => `api/requests/${requestId}/sendemail`;
    public static REQUEST_PRODUCT_DATA = (requestId) => `api/requests/${requestId}/productdata`;
    public static REQUEST_VALIDATION = (requestId) => `api/requests/${requestId}/validation`;
    public static REQUEST_EXECUTION = (requestId) => `api/requests/${requestId}/execution`;
    public static REQUEST_REJECTION = (requestId) => `api/requests/${requestId}/rejection`;
    public static REQUEST_CONFIRMED = (requestId) => `api/requests/${requestId}/confirmed`;
    public static REQUEST_SYNC_STATE = (requestId) => `api/requests/${requestId}/syncstate`;
    public static REQUEST_CLOSE = (requestId) => `api/requests/${requestId}/close`;
    public static REQUEST_REOPEN = (requestId) => `api/requests/${requestId}/reopen`;
    public static REQUEST_PRODUCT_DATA_FORM_STEPS = (requestId) => `api/requests/${requestId}/forms/steps`;
    public static REQUEST_PRODUCT_DATA_FORM = (requestId, formId) => `api/requests/${requestId}/forms/${formId}`;
    public static REQUEST_FLOW_STEPS = (requestId) => `api/requests/${requestId}/flowsteps`;
    public static REQUEST_CURRENT_FLOW_STEPS = (requestId) => `api/requests/${requestId}/flowsteps/current`;
    public static REQUEST_CURRENT_FLOW_STEPS_APPROVAL = (requestId) => `api/requests/${requestId}/flowsteps/current/approval`;
    public static REQUEST_CURRENT_FLOW_STEPS_REJECTION = (requestId) => `api/requests/${requestId}/flowsteps/current/rejection`;
    public static REQUEST_SHARED_ASSET_EXECUTION = (requestId, assetId) =>
      `api/requests/${requestId}/cancel-shared-asset/${assetId}/execution`;
  };

  static Surveys = class {
    static Filters = class {};
    public static SURVEYS = 'api/surveys';
    public static SURVEYS_SPREADSHEET = 'api/surveys/spreadsheet';
    public static SURVEYS_DEFAULT = 'api/surveys/defaults';
    public static SURVEY_CREATE_PARTICULARS = 'api/surveys/particulars';
    public static SURVEY_PARTICULARS = (entityId) => `api/surveys/${entityId}/particulars`;
  };

  static Services = class {
    static Filters = class {
      public static TYPES = 'api/services/types';
      public static ISSUERS = 'api/services/issuers';
      public static PRODUCTFAMILIES = 'api/services/productfamilies';
      public static BILLERS_BYID = (serviceId) => `api/services/${serviceId}/billers`;
      public static BILLINGACCOUNTS_BYID = (serviceId) => `api/services/${serviceId}/billingaccounts`;
      public static PRODUCTFAMILIES_BYID = (serviceId) => `api/services/${serviceId}/productfamilies`;
      public static PROVIDERS_BYID = (serviceId) => `api/services/${serviceId}/providers`;
    };
    public static SERVICES = 'api/services';
    public static SERVICES_SPREADSHEET = 'api/services/spreadsheet';
    public static SERVICE_BILLINGACCOUNTS = `api/services/billingaccounts`;
    public static SERVICE_TRIAL_PERIOD = 'api/services/trialperiods';
    public static SERVICE_COSTS = `api/services/costs`;
    public static SERVICE_ALIASES = 'api/services/aliases';

    public static SERVICE_PROFILES = (serviceId) => `api/services/${serviceId}/profiles`;
    public static SERVICE_SUBSCRIPTIONS = (serviceId) => `api/services/${serviceId}/subscriptions`;
    public static SERVICE_SUBSCRIPTIONS_SPREADSHEET = (serviceId) => `api/services/${serviceId}/subscriptions/spreadsheet`;

    public static SERVICE_PRODUCTS_CONFIGURATION_FORM_STEPS = (productId) => `api/services/${productId}/forms/steps`;
    public static SERVICE_PRODUCTS_CONFIGURATION_FORM = (productId, formId) => `api/services/${productId}/forms/${formId}`;
    public static SERVICE_PRODUCTS_CONFIGURATION_FORM_CONFIGURATION = (productId, formId) =>
      `api/services/${productId}/forms/${formId}/configuration`;
    public static SERVICE_PRODUCTS_CONFIGURATION_COPY = (productFromId, productToId) => `api/services/${productFromId}/copy/${productToId}`;
    public static SERVICE_PRODUCTS_CONFIGURATION_FORM_FIELDKINDS = (productId) => `api/services/${productId}/fieldkinds`;
    public static SERVICE_PRICES = (entityId) => `api/services/${entityId}/prices`;
    public static SERVICE_PRICE_PARTICULARS = (entityId, priceId) => `api/services/${entityId}/prices/${priceId}/particulars`;
    public static SERVICE_PRICE_PARTICULARS_DEFAULT = (entityId) => `api/services/${entityId}/prices/defaults`;
    public static SERVICE_PRICE_PARTICULARS_CREATE = (entityId) => `api/services/${entityId}/prices/particulars`;
    public static SERVICE_ASSETS = (serviceId) => `api/services/${serviceId}/assets`;
    public static SERVICE_ASSETS_WAITINGROOMS = (serviceId) => `api/services/${serviceId}/assets/waitingrooms`;
    public static SERVICE_ASSETS_SHAREDS = (serviceId) => `api/services/${serviceId}/assets/shareds`;
    public static SERVICE_BASE_CATALOG_ITEMS = (serviceId) => `api/services/${serviceId}/basedeliveryitems`;
    public static SERVICE_PRODUCTS_CONFIGURATION = (entityId) => `api/services/${entityId}/forms`;
    public static SERVICE_PRICE_INCREASE = (entityId) => `api/services/${entityId}/prices/increase`;
  };

  static Task = class {
    static Filters = class {
      public static ACTIVES = 'api/tasks/actives';
      public static PRIORITIES = 'api/tasks/priorities';
      public static STATUSES = 'api/tasks/statuses';

      public static DUEDATE = 'api/tasks/duedates';
      public static TOPIC = 'api/tasks/topics';
      public static RECURRING_DATE_PERIODS = 'api/tasks/recurringdateperiods';
      public static ASSIGNMENT = 'api/tasks/assignmentfilters';

      public static TASKS_TOPICS_ISENABLED = 'api/tasks/topics/isenabled';
    };
    public static TASKS = 'api/tasks';
    public static TASKS_SPREADSHEET = 'api/tasks/spreadsheet';
    public static TASKS_TOPICS = 'api/tasks/topics';
    public static TASKS_TOPICS_SPREADSHEET = 'api/tasks/topics/spreadsheet';

    public static TASKS_RECURRINGS = 'api/tasks/recurrings';
    public static TASKS_RECURRINGS_SPREADSHEET = 'api/tasks/recurrings/spreadsheet';
    public static TASKS_ASSIGNTO = 'api/tasks/assign';
    public static TASK_PARTICULARS = 'api/tasks/particulars';
    public static TASK_PARTICULARS_DEFAULTS = 'api/tasks/particulars/defaults';
    public static TASK_PARTICULARS_RECURRING_DEFAULTS = 'api/tasks/recurrings/particulars/defaults';
    public static TASK_PARTICULARS_BYID = (taskId) => `api/tasks/${taskId}/particulars`;
    public static TASK_MARKCOMPLETED = (taskId) => `api/tasks/${taskId}/mark`;
    public static TASK_ACTIVATION = (taskId) => `api/tasks/${taskId}/activation`;
    public static TASK_DEACTIVATION = (taskId) => `api/tasks/${taskId}/deactivation`;
  };

  static Flows = class {
    public static FLOWS = 'api/flows';
    public static FLOWS_SPREADSHEET = 'api/flows/spreadsheet';
    public static FLOWS_STEPS = 'api/flows/steps';
    public static FLOWS_STEPS_DEFAULT = 'api/flows/steps/defaults';

    public static FLOWS_TASKS = (flowtaskId) => `api/flows/flowtask/${flowtaskId}/nodes`;
    public static FLOWS_TASK_DETAILS = (flowtaskId) => `api/entities/entity_fo_flowtask/${flowtaskId}/details`;
    public static FLOWS_TASK_TRUE_DETAILS = (flowtaskId) => `api/flows/flowtask/${flowtaskId}/truedetails`;
    public static FLOWS_TASK_FALSE_DETAILS = (flowtaskId) => `api/flows/flowtask/${flowtaskId}/falsedetails`;
    public static FLOWS_STEPS_BY_ID = (flowId) => `api/flows/${flowId}/steps`;
  };

  static Taxes = class {
    static Filters = class {};
    public static TAXES = 'api/taxes';
    public static TAXES_SPREADSHEET = 'api/taxes/spreadsheet';

    public static TAX_VALUES_DETAILS_BASE_PATH = 'api/entities/entity_taxvalue'; // USE THIS: 'api/entities/entity_taxvalue'

    public static TAX_VALUES = (taxItemId) => `api/taxes/${taxItemId}/values`;
  };

  static Tenants = class {
    static Filters = class {
      public static TENANTS_INVOICES_USERS = 'api/tenantinvoices/users';
      public static EXCHANGE_RATE_REPORTINGCURRENCY = (entityId) => `api/tenantledgers/${entityId}/exchangerates/reportingcurrencies`;
      public static EXCHANGE_RATE_CHARGINGCURRENCY = (entityId) => `api/tenantledgers/${entityId}/exchangerates/chargingcurrencies`;
      //public static ADDRESSES = 'api/tenants/addresses';
    };
    public static TENANTS = 'api/tenants';
    public static TENANTS_SPREADSHEET = 'api/tenants/spreadsheet';

    public static TENANTS_LEDGERS = 'api/tenantledgers';
    public static TENANTS_LEDGER_PARTICULAR_DEFAULTS = 'api/tenantledgers/defaults';
    public static TENANTS_LEDGER_PARTICULARS = 'api/tenantledgers/particulars';
    public static TENANTS_LEDGERS_CHARGINGS = `api/tenantledgerchargings`;
    public static TENANTS_PERIODS = `api/tenantperiods`;
    public static TENANTS_INVOICES = `api/tenantinvoices`;
    public static TENANTS_INVOICES_SPREADSHEET = `api/tenantinvoices/spreadsheet`;
    public static TENANTS_INVOICES_PARTICULARS = `api/tenantinvoices/particulars`;
    public static TENANTS_PERIODS_PARTICULARS = `api/tenantperiods/particulars`;
    public static TENANTS_LEDGER_PARTICULARS_BYID = (entityId) => `api/tenantledgers/${entityId}/particulars`;
    public static TENANTS_LEDGER_CURRENCYRATES = (entityId) => `api/tenantledgers/${entityId}/chargingrates`;
    public static TENANTS_LEDGER_STYLE_SHEET = (entityId) => `api/tenantledgers/${entityId}/stylesheet`;
    public static TENANT_AREAS = (tenantId) => `api/tenants/${tenantId}/areas`;

    public static TENANTS_INVOICES_DOWNLOAD = (tenantinvoiceId) => `api/tenantinvoices/${tenantinvoiceId}/download`;
    public static TENANTS_INVOICES_PREVIEW = (tenantinvoiceId) => `api/tenantinvoices/${tenantinvoiceId}/preview`;
    public static TENANTS_INVOICES_SEND = (tenantinvoiceId) => `api/tenantinvoices/${tenantinvoiceId}/send`;
    public static TENANTS_INVOICES_UNLOCK = (tenantinvoiceId) => `api/tenantinvoices/${tenantinvoiceId}/unlock`;
    public static TENANTS_PERIODS_INVOICES_SENDALL = (tenantperiodId) => `api/tenantperiods/${tenantperiodId}/sendall`;
  };

  static TermsAndRegulations = class {
    static Filters = class {
      public static LICENSESTARTKINDS = 'api/termsandregulations/licensestartkinds';
      public static NOTICEEOMS = 'api/termsandregulations/noticeeoms';
      public static BILLEDPERIODS = 'api/termsandregulations/billedperiods';
    };
    public static TERMSANDREGULATIONS = 'api/termsandregulations';
    public static TERMSANDREGULATIONS_SPREADSHEET = 'api/termsandregulations/spreadsheet';
  };

  static Types = class {
    static Filters = class {
      public static KINDS = 'api/types/kinds';
    };
    public static TYPES = 'api/types';
    public static TYPES_NODES = 'api/types/nodes';
    public static TYPES_SPREADSHEET = 'api/types/spreadsheet';
    public static TYPES_KINDS_NODES = (kindId) => `api/types/kinds/${kindId}/nodes`;
    public static TYPES_NODES_CHILDS = (typeId) => `api/types/${typeId}/childs`;
  };

  static Users = class {
    static Filters = class {
      public static ROLES = 'api/users/roles';
      public static COSTCENTRES = 'api/users/costcentres';
      public static ORGANISATIONS = 'api/users/organisations';
      public static LOCATIONS = 'api/users/locations';
      public static HASALLOCATIONS = 'api/users/hasallocations';
      public static TYPES = 'api/users/types';
      public static GROUP_TYPES = 'api/users/grouptypes';
      public static ASSETS = 'api/users/assets';
      public static PRODUCT_FAMILIES = 'api/users/productfamilies';
      public static SERVICES = 'api/users/services';
      public static SHAREDS = 'api/users/shareds';
      public static BILLINGACCOUNTS = 'api/users/billingaccounts';
      public static ACTIVES = 'api/users/actives';
    };
    public static USERS = 'api/users';
    public static USER_ROLES = 'api/userroles';
    public static USERS_VIEW_AS_USER = 'api/users/view-as-user';

    public static USERS_PARTICULARS = 'api/users/particulars';

    public static USERS_RESPONSIBILITIES = (entityId) => `api/users/${entityId}/responsibilities`;
    public static USERS_PARTICULARS_BYID = (entityId) => `api/users/${entityId}/particulars`;
    public static USERS_EMAIL_HISTORIES_BYID = (entityId) => `api/users/${entityId}/emailhistories`;
    // public static USER_ENTITLEMENTS = (entityKind, entityId) => `api/${entityKind}/${entityId}/entitlement`;
  };

  static Consumers = class {
    static Filters = class {
      public static HASALLOCATIONS = 'api/consumers/hasallocations';
      public static HASDESKS = 'api/consumers/hasdesks';
      public static ASSETS = 'api/consumers/assets';
      public static BILLINGACCOUNTS = 'api/consumers/billingaccounts';
      public static COSTCENTRES = 'api/consumers/costcentres';
      public static GROUP_TYPES = 'api/consumers/grouptypes';
      public static LOCATIONS = 'api/consumers/locations';
      public static ORGANISATIONS = 'api/consumers/organisations';
      public static PRODUCT_FAMILIES = 'api/consumers/productfamilies';
      public static ROLES = 'api/consumers/roles';
      public static SERVICES = 'api/consumers/services';
      public static SHAREDS = 'api/consumers/shareds';
      public static TYPES = 'api/consumers/types';
      public static RESPONSIBLES = 'api/consumers/responsibles';
      public static TRADERCANREGISTERVENDORS = 'api/consumers/traders-can-register-vendors';
      public static ISORGANISATIONDESKS = 'api/consumers/isorganisationdesks';
    };
    public static CONSUMERS_HASALLOCATIONS_DEFAULTS = 'api/consumers/hasallocations/defaults';
    public static CONSUMERS = 'api/consumers';
    public static CONSUMERS_SPREADSHEET = 'api/consumers/spreadsheet';
    public static CONSUMERS_SERVICES = 'api/consumerservices';
    public static CONSUMERS_ORGANISATIONS = 'api/consumerorganisations';
    public static CONSUMERS_ORGANISATIONS_SPREADSHEET = 'api/consumerorganisations/spreadsheet';
    public static CONSUMERS_SPREADSHEET_BY_DESKID = (deskId) => `api/consumers/${deskId}/spreadsheet`;
  };

  static UserLicences = class {
    static Filters = class {
      public static COSTCENTRES = 'api/userlicenses/costcentres';
      public static ORGANISATIONS = 'api/userlicenses/organisations';
      public static LOCATIONS = 'api/userlicenses/locations';
      public static PRODUCT_FAMILIES = 'api/userlicenses/productfamilies';
      public static PRODUCT_FAMILIES_ALLOW_CONTRACT_SWAP = 'api/userlicenses/productfamilies?filterAllowcontractswapIds=1';
      public static SERVICES = 'api/userlicenses/services';
    };
    public static USERLICENSES = 'api/userlicenses';
  };

  static Allocations = class {
    public static ENTITY_ALLOCATIONS = (entityKind, entityId) => `api/${entityKind}/${entityId}/allocations`;
    public static ENTITY_EMPLOYEES = (entityKind, entityId) => `api/${entityKind}/${entityId}/employees`;
  };

  static Vendors = class {
    static Filters = class {
      // public static KINDS = 'api/vendors/kinds';
      public static ADDRESSES = 'api/vendors/addresses';
      public static ACCOUNTS = 'api/vendors/accounts';
      public static BUDGETS = 'api/vendors/budgets';
    };
    public static VENDORS = 'api/vendors';
    public static VENDORS_SPREADSHEET = 'api/vendors/spreadsheet';
    public static VENDOR_ENTITIES = 'api/entities/entity_vendor';

    public static VENDOR_PARTICULAR_DEFAULTS = 'api/vendors/defaults';
    public static VENDOR_PARTICULARS = 'api/vendors/particulars';
    public static VENDOR_PARTICULARS_BYID = (vendorId) => `api/vendors/${vendorId}/particulars`;
    public static VENDOR_LOCATIONS = (vendorId) => `api/vendorlocations?filterVendorIds=${vendorId}`;
    public static VENDOR_CONTACTS = (vendorId) => `api/vendors/${vendorId}/contacts`;
    public static VENDOR_POLICIES = (vendorId) => `api/vendors/${vendorId}/policies`;
    public static VENDOR_PROFILES = (vendorId) => `api/vendors/${vendorId}/profiles`;
    public static VENDOR_PROFILES_SPREADSHEET = (vendorId) => `api/vendors/${vendorId}/profiles/spreadsheet`;
  };

  static Profiler = class {
    public static PROFILER_3DI_VENDORS = `api/profiler/3di/vendors`;
    public static PROFILER_3DI_PRODUCTS = `api/profiler/3di/products`;
    public static PROFILER_3DI_EXCHANGES = `api/profiler/3di/exchanges`;
    public static PROFILER_3DI_VENDOR_FORMFIELD_STEPS = (profileId) => `api/profiler/3di/vendors/${profileId}/steps`;
    public static PROFILER_3DI_VENDOR_GET_PUT_FORMFIELD = (profileId, formId) => `api/profiler/3di/vendors/${profileId}/${formId}/form`;
    public static PROFILER_3DI_PRODUCT_FORMFIELD_STEPS = (profileId) => `api/profiler/3di/products/${profileId}/steps`;
    public static PROFILER_3DI_PRODUCT_GET_PUT_FORMFIELD = (profileId, formId) => `api/profiler/3di/products/${profileId}/${formId}/form`;
    public static PROFILER_3DI_EXCHANGE_FORMFIELD_STEPS = (profileId) => `api/profiler/3di/exchanges/${profileId}/steps`;
    public static PROFILER_3DI_EXCHANGE_GET_PUT_FORMFIELD = (profileId, formId) => `api/profiler/3di/exchanges/${profileId}/${formId}/form`;
  };

  static BillingAccounts = class {
    static Filters = class {
      public static TYPES = 'api/billingaccounts/types';
      public static VENDORALIASES = 'api/billingaccounts/billvendors';
      public static LOCATIONS = 'api/billingaccounts/locations';
      public static ADDRESSES = 'api/billingaccounts/addresses';
      public static TENANT = 'api/billingaccounts/tenants';
      public static TENANT_LEDGER = 'api/billingaccounts/tenantledgers';
      public static PRODUCT_FAMILIES = 'api/billingaccounts/productfamilies';
      public static EXCHANGE_RATE_INVOICECURRENCY = (billingaccountId) =>
        `api/billingaccounts/${billingaccountId}/exchangerates/invoicecurrencies`;
      public static EXCHANGE_RATE_CATALOGCURRENCY = (billingaccountId) =>
        `api/billingaccounts/${billingaccountId}/exchangerates/catalogcurrencies`;
    };
    public static BILLINGACCOUNTS = 'api/billingaccounts';
    public static BILLINGACCOUNTS_SPREADSHEET = 'api/billingaccounts/spreadsheet';

    public static BILLINGACCOUNTS_PARTICULAR_DEFAULTS = 'api/billingaccounts/particulars/defaults';
    public static BILLINGACCOUNTS_PARTICULARS = 'api/billingaccounts/particulars';
    public static BILLINGACCOUNTS_PURCHASE_ORDERS = (billingaccountId) => `api/billingaccounts/${billingaccountId}/purchaseorders`;
    public static BILLINGACCOUNTS_CURRENCYRATES = (billingaccountId) => `api/billingaccounts/${billingaccountId}/exchangerates`;

    public static BILLINGACCOUNTS_RULE_PERIODS = (billingaccountId) => `api/billingaccounts/${billingaccountId}/rules`;
    public static BILLINGACCOUNTS_RULE_CHANGE = (billingaccountId, ruleId) =>
      `api/billingaccounts/${billingaccountId}/rules/${ruleId}/change`;

    public static BILLINGACCOUNTS_RULE_FORMFIELDS = (periodId, formId) => `api/entities/entity_cas/${periodId}/${formId}/form`;
  };

  static Interfaces = class {
    static Generic = class {
      static Filters = class {
        public static TEMPLATES_MODULES = 'api/interfaces/generic/templates/modules';
        public static TEMPLATES_VENDORS = 'api/interfaces/generic/templates/vendors';
        public static TEMPLATES_CURRENCIES = 'api/interfaces/generic/templates/currencies';
        public static TEMPLATES_PLATFORMS = 'api/interfaces/generic/templates/platforms';
        public static MODULES_KINDS = 'api/interfaces/generic/modules/kinds';
        public static MODULES_TYPES = 'api/interfaces/generic/modules/types';
        public static MODULES_STATUSES = 'api/interfaces/generic/modules/statuses';
        public static HASCOSTS = `api/interfaces/generic/servicemappings/hascosts`;
        public static VALID = 'api/interfaces/generic/servicemappings/validations';
        public static FILTER_CONFIGURATION = (interfaceName) => `api/interfaces/${interfaceName}`;
      };
      public static SERVICE_MAPPING = `api/interfaces/generic/servicemappings`;
      public static SERVICE_MAPPING_SPREADSHEET = `api/interfaces/generic/servicemappings/spreadsheet`;
      public static TEMPLATES = `api/interfaces/generic/templates`;
      public static TEMPLATES_SPREADSHEET = `api/interfaces/generic/templates/spreadsheet`;
      public static TEMPLATES_PARTICULARS = `api/interfaces/generic/templates/particulars`;
      public static TEMPLATES_MODULES = 'api/interfaces/generic/templates/modules';
      public static MODULES = `api/interfaces/generic/modules`;
      public static MODULES_SPREADSHEET = `api/interfaces/generic/modules/spreadsheet`;

      public static RESULT_MESSAGES = 'api/interfaces/resultmessages';
      public static RESULT_MESSAGES_SPREADSHEET = 'api/interfaces/resultmessages/spreadsheet';
      public static CONFIRM_SPREADSHEET = `api/interfaces/generic/confirmation/spreadsheet`;
    };

    static Mecs = class {
      public static MECS_CONFIGURATIONS = 'api/interfaces/mecs/configurations';
      public static MECS_CONFIGURATIONS_SPREADSHEET = 'api/interfaces/mecs/configurations/spreadsheet';
      public static MECS_CONFIRM_MESSAGES = 'api/interfaces/mecs/confirmation/messages';
      public static MECS_CONFIRM_PRODUCTS = 'api/interfaces/mecs/confirmation/products';
      public static MECS_CONFIRM_USERS = 'api/interfaces/mecs/confirmation/users';
      public static MECS_CONFIRM_ENTITLEMENTS = 'api/interfaces/mecs/confirmation/entitlements';
      public static MECS_CONFIRM_SPREADSHEET = 'api/interfaces/mecs/confirmation/spreadsheet';
      public static MECS_CONFIRM_VALIDATION = 'api/interfaces/mecs/confirmation';
      public static MECS_CONFIGURATIONS_VALIDATION = (configurationId) =>
        `api/interfaces/mecs/configurations/${configurationId}/validation`;
    };

    static IndexImporter = class {
      public static INDEX_IMPORTER_FIELDMAPPING_HEADERS = 'api/interfaces/indeximport/fieldmappings/headers';
      public static INDEX_IMPORTER_FIELDMAPPING_FIELDS = 'api/interfaces/indeximport/fieldmappings/fields';
      public static INDEX_IMPORTER_FIELDMAPPING = 'api/interfaces/indeximport/fieldmappings';
      public static INDEX_IMPORTER_INDEXES = 'api/interfaces/indeximport/indexes';
      public static INDEX_IMPORTER_INDEXES_SPREADSHEET = 'api/interfaces/indeximport/indexes/spreadsheet';
      public static INDEX_IMPORTER_INDEXES_SUMMARY_BACK = 'api/interfaces/indeximport/summary/back';
    };

    static Dacs = class {
      static Filters = class {
        public static SERVICETYPE = 'api/interfaces/dacs/filter/servicetypes';
        public static BILLINGACCOUNTS = 'api/interfaces/dacs/filter/billingaccounts';
        public static ASSETTYPES = 'api/interfaces/dacs/filter/assettypes';
        public static SITES = 'api/interfaces/dacs/filter/sites';
        public static PAYRULES = 'api/interfaces/dacs/filter/payrules';
        public static TERMS = 'api/interfaces/dacs/filter/terms';
        public static IMPORT_RUNS_SOURCE = `api/interfaces/dacs/runs/sources`;
        public static IMPORT_RUNS_CONFIGURATION = `api/interfaces/dacs/runs/configurations`;
      };
      public static DACS_CONFIGURATIONS = `api/interfaces/dacs`;
      public static DACS_CONFIGURATIONS_SPREADSHEET = `api/interfaces/dacs/spreadsheet`;
      public static DACS_PLATFORMS = `api/interfaces/dacs/platforms`;
      public static DACS_TYPES = `api/interfaces/dacs/types`;
      public static DACS_TYPES_SPREADSHEET = `api/interfaces/dacs/types/spreadsheet`;
      public static DACS_SITES = `api/interfaces/dacs/sites`;
      public static DACS_SITES_SELECTION = `api/interfaces/dacs/siteselection`;
      public static DACS_SITES_SPREADSHEET = `api/interfaces/dacs/sites/spreadsheet`;
      public static DACS_APPLICATIONS = `api/interfaces/dacs/applications`;
      public static DACS_APPLICATIONS_SPREADSHEET = `api/interfaces/dacs/applications/spreadsheet`;
      public static DACS_ADMINGROUPS = `api/interfaces/dacs/admingroups`;
      public static DACS_ADMINGROUPS_SPREADSHEET = `api/interfaces/dacs/admingroups/spreadsheet`;
      public static DACS_SERVICES = `api/interfaces/dacs/services`;
      public static DACS_SERVICES_SPREADSHEET = `api/interfaces/dacs/services/spreadsheet`;
      public static DACS_FEEDS = `api/interfaces/dacs/feeds`;
      public static DACS_FEEDS_SPREADSHEET = `api/interfaces/dacs/feeds/spreadsheet`;
      public static DACS_CONFIRM = `api/interfaces/dacs/confirmation`;
      public static DACS_CONFIRM_SPREADSHEET = `api/interfaces/dacs/confirmation/spreadsheet`;
      public static DACS_EXPORT_SPREADSHEET = 'api/interfaces/dacs/export/spreadsheet';
      public static DACS_WRITING = `api/interfaces/dacs/writing`;
      public static DACS_PREFERENCES = `api/interfaces/dacs`;
      public static DACS_PREFERENCES_SPREADSHEET = `api/interfaces/dacs/spreadsheet`;

      public static IMPORT_RUNS = (dacsId) => `api/interfaces/dacs/${dacsId}/runs`;
      public static IMPORT_RUNS_SPREADSHEET = (dacsId) => `api/interfaces/dacs/runs/${dacsId}/spreadsheet`;

      public static DACS_CONFIGURATION_VALIDATION = (configurationId) => `api/interfaces/dacs/${configurationId}/validation`;
    };

    static Fisglobal = class {
      static Filters = class {
        public static PLATFORM = 'api/interfaces/fisglobal/platforms';
        public static VALID = 'api/interfaces/fisglobal/filter/valid';
      };
      public static FISGLOBAL = `api/interfaces/fisglobal`;
      public static FISGLOBAL_EXPORT_SPREADSHEET = 'api/interfaces/fisglobal/export/spreadsheet';
      public static FISGLOBAL_EXPORT_VALIDATION = 'api/interfaces/fisglobal/export/validation';
      public static FISGLOBAL_SERVICES = `api/interfaces/fisglobal/services`;
      public static FISGLOBAL_SERVICES_CREATION = `api/interfaces/fisglobal/services/creation`;
      public static FISGLOBAL_SERVICES_SPREADSHEET = `api/interfaces/fisglobal/services/spreadsheet`;
      public static FISGLOBAL_CONFIGURATIONS = `api/interfaces/fisglobal/configurations`;
      public static FISGLOBAL_CONFIGURATIONS_SPREADSHEET = `api/interfaces/fisglobal/configurations/spreadsheet`;
      public static FISGLOBAL_CONFIGURATIONS_COUNTRY = `api/interfaces/fisglobal/countries`;
      public static FISGLOBAL_CONFIGURATIONS_COUNTRY_SPREADSHEET = `api/interfaces/fisglobal/countries/spreadsheet`;

      public static FISGLOBAL_CONFIGURATIONS_VALIDATION = (configurationId) => `api/interfaces/fisglobal/${configurationId}/validation`;
      public static FISGLOBAL_CONFIGURATIONS_TIMESETTINGS = (configurationId) => `api/interfaces/fisglobal/${configurationId}/particulars`;
    };

    static Hr = class {
      static Filters = class {
        public static IMPORT_RUNS_SOURCE = `api/interfaces/hr/runs/sources`;
        public static IMPORT_RUNS_CONFIGURATION = `api/interfaces/hr/runs/configurations`;
      };
      public static HR_CONFIGURATION = `api/interfaces/hr`;
      public static HR_CONFIGURATION_SPREADSHEET = `api/interfaces/hr/spreadsheet`;

      public static HR_CONFIGURATION_FILES_DETAILS = `api/entities/entity_hr_cfgfile`;
      public static HR_CONFIRMATION_SUMMARY_SPREADSHEET = `api/interfaces/hr/confirmation/summary/spreadsheet`;
      public static HR_CONFIGURATION_JOINERS_EMAIL = (configurationId) => `api/interfaces/hr/${configurationId}/joiners`;
      public static HR_CONFIGURATION_IMPORT_RUN = (configurationId) => `api/interfaces/hr/${configurationId}/runs`;
      public static HR_CONFIGURATION_IMPORT_RUN_SPREADSHEET = (configurationId) => `api/interfaces/hr/${configurationId}/runs/spreadsheet`;
      public static HR_CONFIGURATION_VALIDATION = (configurationId) => `api/interfaces/hr/${configurationId}/validation`;

      public static HR_CONFIGURATION_FILES = (configurationId) => `api/interfaces/hr/${configurationId}/files`;
      public static IMPORT_FILE_COUNT_BYCONFIG = (configurationId) => `api/interfaces/hr/${configurationId}/filecount`;
      public static IMPORT_MULTIPLE_FILES_BYCONFIG = (configurationId) => `api/interfaces/hr/${configurationId}/upload`;
      public static HR_CONFIRMATION = (importId) => `api/interfaces/hr/confirmation?importId=${importId}`;
      public static HR_CONFIRMATION_SUMMARY = (importId) => `api/interfaces/hr/confirmation/summary?importId=${importId}`;
      public static HR_CONFIRMATION_MESSAGES = (importId) => `api/interfaces/hr/confirmation/messages?importId=${importId}`;
      public static HR_CONFIRMATION_USERS_ADDED = (importId) => `api/interfaces/hr/confirmation/users/creation?importId=${importId}`;
      public static HR_CONFIRMATION_USERS_MOVED = (importId) => `api/interfaces/hr/confirmation/users/move?importId=${importId}`;
      public static HR_CONFIRMATION_USERS_REMOVED = (importId) => `api/interfaces/hr/confirmation/users/cancellation?importId=${importId}`;
      public static HR_CONFIRMATION_ORGANISATIONS_ADDED = (importId) =>
        `api/interfaces/hr/confirmation/organisations/creation?importId=${importId}`;
      public static HR_CONFIRMATION_ORGANISATIONS_MOVED = (importId) =>
        `api/interfaces/hr/confirmation/organisations/move?importId=${importId}`;
      public static HR_CONFIRMATION_ORGANISATIONS_REMOVED = (importId) =>
        `api/interfaces/hr/confirmation/organisations/cancellation?importId=${importId}`;
      public static HR_CONFIRMATION_COSTCENTRES_ADDED = (importId) =>
        `api/interfaces/hr/confirmation/costcentres/creation?importId=${importId}`;
      public static HR_CONFIRMATION_COSTCENTRES_MOVED = (importId) =>
        `api/interfaces/hr/confirmation/costcentres/move?importId=${importId}`;
      public static HR_CONFIRMATION_COSTCENTRES_REMOVED = (importId) =>
        `api/interfaces/hr/confirmation/costcentres/cancellation?importId=${importId}`;
      public static HR_CONFIRMATION_LOCATIONS_ADDED = (importId) =>
        `api/interfaces/hr/confirmation/locations/creation?importId=${importId}`;
      public static HR_CONFIRMATION_LOCATIONS_MOVED = (importId) => `api/interfaces/hr/confirmation/locations/move?importId=${importId}`;
      public static HR_CONFIRMATION_LOCATIONS_REMOVED = (importId) =>
        `api/interfaces/hr/confirmation/locations/cancellation?importId=${importId}`;
    };

    static Tarics = class {
      static Filters = class {
        public static INSTANCES_CONFIGURATIONS = 'api/tarics/instances/configurations';
        public static INSTANCES_STATUSES = 'api/tarics/instances/statuses';
        public static LOGS_INSTANCES = 'api/tarics/logs/instances';
        public static LOGS_STATUSES = 'api/tarics/logs/statuses';
        public static CONFIGURATION_PLATFORMS = 'api/tarics/configurations/platforms';
        public static CONFIGURATION_SITES = 'api/tarics/configurations/sites';
        public static CONFIGURATION_VENDORS = 'api/tarics/configurations/vendors';
        public static CONFIGURATION_BILLINGACCOUNTS = 'api/tarics/configurations/billingaccounts';
      };
      public static TARICS_MAP_COLLECT = 'api/tarics/mapcollect';
      public static TARICS_INSTANCES = 'api/tarics/instances';
      public static TARICS_LOGS = 'api/tarics/logs';
      public static TARICS_REQUESTS = 'api/tarics/requests';
      public static TARICS_CONFIGURATIONS = 'api/tarics/configurations';
      public static TARICS_INSTANCES_SPREADSHEET = 'api/tarics/instances/spreadsheet';
      public static TARICS_LOGS_SPREADSHEET = 'api/tarics/logs/spreadsheet';
      public static TARICS_REQUESTS_SPREADSHEET = 'api/tarics/requests/spreadsheet';
      public static TARICS_CONFIGURATIONS_SPREADSHEET = 'api/tarics/configurations/spreadsheet';
      public static TARICS_INSTANCES_CREATE = 'api/entities/entity_tarics_instance/details';
      public static TARICS_CONFIGURATIONS_CREATE = 'api/entities/entity_tarics_config/details';
      public static TARICS_ENTITLEMENT_CHANGES = 'api/tarics/entitlementchanges';
      public static TARICS_INSTANCES_DETAILS = (entityId) => `api/entities/entity_tarics_instance/${entityId}/details`;
      public static TARICS_LOGS_DETAILS = (entityId) => `api/entities/entity_tarics_instance_log/${entityId}/details`;
      public static TARICS_CONFIGURATIONS_DETAILS = (entityId) => `api/entities/entity_tarics_config/${entityId}/details`;
      public static TARICS_CONFIGURATIONS_MAP_COLLECT = (configurationId) => `api/tarics/configurations/${configurationId}/mapcollect`;
    };

    static Fkis = class {
      public static FKIS = `api/interfaces/sixfkis`;
      public static FKIS_PLATFORM = `api/interfaces/sixfkis/platforms`;
      public static FKIS_SPREADSHEET = `api/interfaces/sixfkis/spreadsheet`;
      public static FKIS_TRANSFER_MESSAGES = 'api/interfaces/sixfkis/transfer/messages';
      public static FKIS_TRANSFER_USERS = 'api/interfaces/sixfkis/transfer/users';
      public static FKIS_TRANSFER_ENTITLEMENTS = 'api/interfaces/sixfkis/transfer/entitlements';
      public static FKIS_TRANSFER_SPREADSHEET = 'api/interfaces/sixfkis/transfer/spreadsheet';
      public static FKIS_TRANSFER_VALIDATION = 'api/interfaces/sixfkis/transfer';
      public static FKIS_FINISH_MESSAGES = 'api/interfaces/sixfkis/finish/messages';
      public static FKIS_FINISH_USERS = 'api/interfaces/sixfkis/finish/users';
      public static FKIS_FINISH_ENTITLEMENTS = 'api/interfaces/sixfkis/finish/entitlements';
      public static FKIS_FINISH_SPREADSHEET = 'api/interfaces/sixfkis/finish/spreadsheet';
      public static FKIS_FINISH_VALIDATION = 'api/interfaces/sixfkis/finish';

      public static FKIS_TEST = (fkisId) => `api/interfaces/sixfkis/${fkisId}/test`;
    };

    static Sap = class {
      public static SAP_CONNECTION_LIST = 'api/interfaces/sap';
      public static SAP_CONNECTION_DOWNLOAD_INVOICE = 'api/interfaces/sap/downloadinvoice/connection';
      public static SAP_CONNECTION_UPLOAD_INVOICE = 'api/interfaces/sap/uploadinvoice/connection';
      public static SAP_PREPARATION_UPLOAD_INVOICE = 'api/interfaces/sap/uploadinvoice/preparation';
      public static SAP_CONFIRMATION_UPLOAD_INVOICE = 'api/interfaces/sap/uploadinvoice/confirmation';
      public static SAP_CONFIRMATION_UPLOAD_INVOICE_SPREADSHEET = 'api/interfaces/sap/uploadinvoice/confirmation/spreadsheet';
      public static SAP_WRITING_UPLOAD_INVOICE = 'api/interfaces/sap/uploadinvoice/writing';
      public static SAP_PREPARATION_UPLOAD_INVOICE_ERRORS = 'api/interfaces/sap/uploadinvoice/preparation/errors';
      public static SAP_CONNECTION_UPLOAD_COSTS = 'api/interfaces/sap/uploadcosts/connection';
      public static SAP_TEST_UPLOAD_COSTS = 'api/interfaces/sap/uploadcosts/test';
      public static SAP_CONFIRMATION_UPLOAD_COSTS = 'api/interfaces/sap/uploadcosts/confirmation';
      public static SAP_CONFIRMATION_UPLOAD_COSTS_SPREADSHEET = 'api/interfaces/sap/uploadcosts/confirmation/spreadsheet';
      public static SAP_WRITING_UPLOAD_COSTS = 'api/interfaces/sap/uploadcosts/writing';
      public static SAP_INVOICE_LIST = (sapId) => `api/interfaces/sap/${sapId}/invoices`;
    };

    static Bloomberg = class {
      static Filters = class {
        public static IMPORT_RUNS_SOURCE = `api/interfaces/bloomberg/runs/sources`;
        public static VALID = 'api/interfaces/bloomberg/validations';
        public static IS_CURRENT = 'api/interfaces/bloomberg/iscurrents';
      };
      public static ACCOUNTS = `api/interfaces/bloomberg/accounts`;
      public static CURRENCIES = 'api/interfaces/bloomberg/currencies';
      public static ADMINFEES = `api/interfaces/bloomberg/adminfees`;
      public static HARDWARECOSTS = `api/interfaces/bloomberg/hardwarecosts`;
      public static THIRDPARTIES = `api/interfaces/bloomberg/thirdparties`;
      public static ACCOUNTS_SPREADSHEET = `api/interfaces/bloomberg/accounts/spreadsheet`;
      public static CURRENCIES_SPREADSHEET = 'api/interfaces/bloomberg/currencies/spreadsheet';
      public static ADMINFEES_SPREADSHEET = `api/interfaces/bloomberg/adminfees/spreadsheet`;
      public static HARDWARECOSTS_SPREADSHEET = `api/interfaces/bloomberg/hardwarecosts/spreadsheet`;
      public static THIRDPARTIES_SPREADSHEET = `api/interfaces/bloomberg/thirdparties/spreadsheet`;
      public static CONFIRM = `api/interfaces/bloomberg/confirmation`;

      public static CONFIRM_BBSUBSCRIPTIONS = `api/interfaces/bloomberg/confirmation/subscriptions`;
      public static CONFIRM_BBRELOCATIONS = `api/interfaces/bloomberg/confirmation/relocations`;
      public static CONFIRM_BBINVOICES = `api/interfaces/bloomberg/confirmation/invoices`;
      public static CONFIRM_SPREADSHEET = `api/interfaces/bloomberg/confirmation/spreadsheet`;
      // public static CONFIRM_BBSUBSCRIPTIONS_SPREADSHEET = `api/interfaces/bloomberg/confirmation/subscriptions/spreadsheet`;
      // public static CONFIRM_BBRELOCATIONS_SPREADSHEET = `api/interfaces/bloomberg/confirmation/relocations/spreadsheet`;
      // public static CONFIRM_BBINVOICES_SPREADSHEET = `api/interfaces/bloomberg/confirmation/invoices/spreadsheet`;

      public static THIRDPARTIES_DETAILS = (thirdpartyId) => `api/interfaces/bloomberg/thirdparties/${thirdpartyId}/details`;
    };

    public static CONFIRM_VALIDATION = (interfaceName) => `api/interfaces/${interfaceName}/confirmation`;
    public static CONFIRM_IIUSERS = (interfaceName) => `api/interfaces/${interfaceName}/confirmation/users`;
    public static CONFIRM_IIFPRODUCTS = (interfaceName) => `api/interfaces/${interfaceName}/confirmation/products`;
    public static CONFIRM_IIFSERVICES = (interfaceName) => `api/interfaces/${interfaceName}/confirmation/services`;
    public static CONFIRM_IIFCOSTS = (interfaceName) => `api/interfaces/${interfaceName}/confirmation/costs`;
    public static CONFIRM_IIFPERMISSIONS = (interfaceName) => `api/interfaces/${interfaceName}/confirmation/permissions`;
    public static CONFIRM_IIFMESSAGES = (interfaceName) => `api/interfaces/${interfaceName}/confirmation/messages`;
    // public static CONFIRM_IIUSERS_SPREADSHEET = (interfaceName) => `api/interfaces/${interfaceName}/confirmation/users/spreadsheet`;
    // public static CONFIRM_IIFPRODUCTS_SPREADSHEET = (interfaceName) => `api/interfaces/${interfaceName}/confirmation/products/spreadsheet`;
    // public static CONFIRM_IIFSERVICES_SPREADSHEET = (interfaceName) => `api/interfaces/${interfaceName}/confirmation/services/spreadsheet`;
    // public static CONFIRM_IIFCOSTS_SPREADSHEET = (interfaceName) => `api/interfaces/${interfaceName}/confirmation/costs/spreadsheet`;
    // public static CONFIRM_IIFPERMISSIONS_SPREADSHEET = (interfaceName) =>
    //   `api/interfaces/${interfaceName}/confirmation/permissions/spreadsheet`;
    public static CONFIRM_IIUSERS_TOGGLE = (interfaceName, userId) => `api/interfaces/${interfaceName}/confirmation/users/${userId}/toggle`;
    public static CONFIRM_IIFPRODUCTS_TOGGLE = (interfaceName, productId) =>
      `api/interfaces/${interfaceName}/confirmation/products/${productId}/toggle`;
    public static CONFIRM_IIFSERVICES_TOGGLE = (interfaceName, serviceId) =>
      `api/interfaces/${interfaceName}/confirmation/services/${serviceId}/toggle`;
    public static CONFIRM_IIFCOSTS_TOGGLE = (interfaceName, costId) =>
      `api/interfaces/${interfaceName}/confirmation/costs/${costId}/toggle`;
    public static CONFIRM_IIFPERMISSIONS_TOGGLE = (interfaceName, permissionId) =>
      `api/interfaces/${interfaceName}/confirmation/permissions/${permissionId}/toggle`;
    public static CONFIRM_IIUSERS_NEWDESK = (interfaceName, userId) => `api/interfaces/${interfaceName}/confirmation/users/${userId}/desk`;

    public static CONFIGURATION_VALIDATION = (interfaceName) => `api/interfaces/${interfaceName}/validation`;
    public static TEMPLATE_VALIDATION = (interfaceName) => `api/interfaces/${interfaceName}/importtemplates/validation`;
    public static IMPORT_FILE_COUNT = (interfaceName) => `api/interfaces/${interfaceName}/filecount`;

    public static IMPORT_MULTIPLE_FILES = (interfaceName) => `api/interfaces/${interfaceName}/upload`;
    public static SHEETS = (interfaceName) => `api/interfaces/${interfaceName}/sheets`;
    public static TEMPLATES = (interfaceName) => `api/interfaces/${interfaceName}/importtemplates`;
    public static ANALYZE_STATUS = (interfaceName) => `api/interfaces/${interfaceName}/analysis`;
    public static COLLECT_STATUS = (interfaceName) => `api/interfaces/${interfaceName}/collecting`;
    public static PREPARE_STATUS = (interfaceName) => `api/interfaces/${interfaceName}/preparation`;
    public static POSTPREPARE_STATUS = (interfaceName) => `api/interfaces/${interfaceName}/postpreparation`;
    public static IMPORT_STATUS = (interfaceName) => `api/interfaces/${interfaceName}/import`;
    public static DOWNLOAD_STATUS = (interfaceName) => `api/interfaces/${interfaceName}/download`;
    public static WRITE_STATUS = (interfaceName) => `api/interfaces/${interfaceName}/writing`;
    public static TRANSFER_STATUS = (interfaceName) => `api/interfaces/${interfaceName}/transfer`;
    public static SYNCHRONIZE_STATUS = (interfaceName) => `api/interfaces/${interfaceName}/synchronisation`;
    public static PREPARE_EXPORT = (interfaceName) => `api/interfaces/${interfaceName}/export`;
    public static INTERFACE_RUN_LOGS = (interfaceName, runId) => `api/interfaces/${interfaceName}/runs/${runId}/logs`;
    public static INTERFACE_RUN_LOGS_SPREADSHEET = (interfaceName, runId) =>
      `api/interfaces/${interfaceName}/runs/${runId}/logs/spreadsheet`;
    public static CANCEL = (interfaceName) => `api/interfaces/${interfaceName}/cancellation`;
    public static FINISH = (interfaceName) => `api/interfaces/${interfaceName}/finish`;
  };

  static Files = class {
    static Filters = class {
      public static LINKTYPE = 'api/files/linktypes';
    };
    public static FILES = 'api/files';
    public static ENTITY_FILES_GRID = (entity, entityId) => `api/files/${entity}/${entityId}`;
    public static ENTITY_FILES_LINK = (entity, entityId, fileId) => `api/files/${entity}/${entityId}/link/${fileId}`;
    public static ENTITY_FILE_DOWNLOAD = (fileId) => `api/files/${fileId}/download`;

    public static FILES_TREE = (fileTreeType) => `api/files/${fileTreeType}/nodes`; // ${nodeId}/nodes is automatically added by generic tree view
    public static FILES_GRID = (fileGridType, nodeId, subdir) => `api/files/${fileGridType}/nodes/${nodeId}/files?subdirectories=${subdir}`;
    public static FILES_LINK = (fileGridType, nodeId, fileId) => `api/files/${fileGridType}/nodes/${nodeId}/link/${fileId}`;
    public static FILES_DIRECTORY = (fileGridType, nodeId) => `api/files/${fileGridType}/nodes/${nodeId}`;
    public static FILES_DIRECTORY_MOVE = (fileGridType, nodeId) => `api/files/${fileGridType}/nodes/${nodeId}/move`;
    public static FILES_MOVE = (fileGridType, nodeId) => `api/files/${fileGridType}/nodes/${nodeId}/files/move`;
    public static FILES_UPLOAD = (fileGridType, nodeId) => `api/files/${fileGridType}/nodes/${nodeId}/upload`;

    public static FILES_DOWNLOAD = (fileGridType, filenodeId) => `api/files/${fileGridType}/filenodes/${filenodeId}/download`;
    public static FILES_DELETE = (fileGridType, filenodeId) => `api/files/${fileGridType}/filenodes/${filenodeId}`;

    public static FILES_PREVIEW = (fileId) => `api/files/${fileId}/preview`;
  };

  static Dashboards = class {
    public static DASHBOARDS = `api/dashboards`;
    public static DASHBOARDS_DETAILS = (dashboardId) => `api/dashboards/${dashboardId}/details`;
    public static DASHBOARDS_CONFIGURATION = (dashboardId) => `api/dashboards/${dashboardId}/configuration`;
    public static DASHBOARDS_CONFIGURATION_FORM_STEPS = (dashboardId) => `api/dashboards/${dashboardId}/configuration/forms/steps`;
    public static DASHBOARDS_CONFIGURATION_FORM = (dashboardId, formId) => `api/dashboards/${dashboardId}/configuration/forms/${formId}`;
    public static DASHBOARDS_OPTION = (dashboardId) => `api/dashboards/${dashboardId}/options`;
    public static DASHBOARDS_TIMELINE = (dashboardId) => `api/dashboards/${dashboardId}/timeline`;
    public static DASHBOARDS_CALENDAR = (dashboardId, date) => `api/dashboards/${dashboardId}/calendar?date=${date}`;
    public static DASHBOARDS_LICENSE_COUNTS = (dashboardId) => `api/dashboards/${dashboardId}/license-counts`;
    public static DASHBOARDS_TOPFIVE = (dashboardId) => `api/dashboards/${dashboardId}/topfive`;

    public static DASHBOARDS_FORECASTBUDGETACTUALS = (dashboardId) => `api/dashboards/${dashboardId}/forecastbudgetactuals`;
    public static DASHBOARDS_QUOTE = (dashboardId) => `api/dashboards/${dashboardId}/quote`;
    public static DASHBOARDS_UNALLOCATEDPRODUCTS = (dashboardId) => `api/dashboards/${dashboardId}/unallocatedproducts`;
    public static DASHBOARDS_WAITINGROOMPRODUCTS = (dashboardId) => `api/dashboards/${dashboardId}/waitingroomproducts`;
    public static DASHBOARDS_MYMONTHLYCOSTS = (dashboardId) => `api/dashboards/${dashboardId}/mymonthlycosts`;
    public static DASHBOARDS_MYANNUALCOSTS = (dashboardId) => `api/dashboards/${dashboardId}/myannualcosts`;
    public static DASHBOARDS_MYYEARTODATECOSTCOMPARISON = (dashboardId) => `api/dashboards/${dashboardId}/myyeartodatecostcomparison`;
    public static DASHBOARDS_MYBROADCASTS = (dashboardId) => `api/dashboards/${dashboardId}/mybroadcasts`;
    public static DASHBOARDS_ASSETAPPROVALS = (dashboardId) => `api/dashboards/${dashboardId}/assetapprovals`;
    public static DASHBOARDS_PRODUCTAPPROVALS = (dashboardId) => `api/dashboards/${dashboardId}/productapprovals`;
    public static DASHBOARDS_PROFILEANDCOSTS = (dashboardId) => `api/dashboards/${dashboardId}/profile-and-costs`;
    public static DASHBOARDS_INDEX_LICENSING_ENQUIRY = (dashboardId) => `api/dashboards/${dashboardId}/index-licensing-enquiry`;
    public static DASHBOARDS_ORDER_MARKET_DATA = (dashboardId) => `api/dashboards/${dashboardId}/order-market-data`;
    public static DASHBOARDS_OUTSTANDINGS_INVOICES = (dashboardId) => `api/dashboards/${dashboardId}/outstanding-invoices`;
    public static DASHBOARDS_LAST_REQUESTS = (dashboardId) => `api/dashboards/${dashboardId}/lastrequests`;
    public static DASHBOARDS_HISTORY_REQUESTS = (dashboardId) => `api/dashboards/${dashboardId}/myrequesthistory`;
  };

  static Widgets = class {
    public static WIDGETS = `api/widgets`;
    public static LOGIN_STATISTICS = 'api/widgets/loginstatistics';
    public static WIDGETS_OPTIONS = (widgetId) => `api/widgets/${widgetId}/options`;
  };

  static Notifications = class {
    public static NOTIFICATIONS_GRID = `api/notifications`;
    public static NOTIFICATIONS_SPREADSHEET = `api/notifications/spreadsheet`;
    public static NOTIFICATIONS_COUNT = 'api/notifications/count';
    public static NOTIFICATIONS_KINDS = 'api/notifications/kinds';

    public static NOTIFICATIONS_SNOOZES = 'api/notifications/snoozes';
    public static NOTIFICATIONS_SNOOZE = 'api/notifications/snooze';
    public static NOTIFICATIONS_DISMISS = 'api/notifications/dismiss';
    public static NOTIFICATIONS_READ = 'api/notifications/read';
  };

  static Indexes = class {
    static Filters = class {
      public static ACTIVES = 'api/indexes/actives';
      public static LICENSED = 'api/indexes/licensed';

      public static FAMILIES = 'api/indexes/families';
      public static GROUPS = 'api/indexes/groups';
      public static ISSUERS = 'api/indexes/issuers';

      public static PACKAGE_CATEGORIES = 'api/indexes/packagecategories';
      public static PACKAGE_TYPES = 'api/indexes/packagetypes';
      public static POTENTIALRISKS = 'api/indexes/potentialrisks';
      public static TICKER_CURRENCIES = 'api/indexes/tickercurrencies';

      public static TYPES = 'api/indexes/types';
    };
    public static INDEXES = 'api/indexes';

    public static INDEXES_GRID_SPREADSHEET = 'api/indexes/spreadsheet';
    public static INDEXES_PACKAGES_GRID = 'api/indexes/packages';
    public static INDEXES_PACKAGES_GRID_SPREADSHEET = 'api/indexes/packages/spreadsheet';
    public static INDEXES_PARTICULARS_DEFAULTS = 'api/indexes/defaults';
    public static INDEXES_PARTICULARS = 'api/indexes/particulars';
    public static INDEXES_CONTRACT_GRID = (indexId) => `api/indexes/${indexId}/contracts`;
    public static INDEXES_PACKAGES_CONTENT_INDEXES = (packageId) => `api/indexes/packages/${packageId}/indexes`;
    public static INDEXES_PACKAGES_CONTENT_GRID = (packageId) => `api/indexes/packages/${packageId}/contents`;
    public static INDEXES_PACKAGES_CONTENT_SPREADSHEET = (packageId) => `api/indexes/packages/${packageId}/contents/spreadsheet`;
    public static INDEXES_PACKAGES_CONTRACT_GRID = (packageId) => `api/indexes/packages/${packageId}/contracts`;
    public static INDEXES_PACKAGES_LICENSE_GRID = (packageId) => `api/indexes/packages/${packageId}/licenses`;
    public static INDEXES_PACKAGES_LICENSE_SPREADSHEET = (packageId) => `api/indexes/packages/${packageId}/licenses/spreadsheet`;
    public static INDEXES_ACTIVATION = (indexId) => `api/indexes/${indexId}/activation`;
    public static INDEXES_PARTICULARS_BYID = (indexId) => `api/indexes/${indexId}/particulars`;
    public static INDEXES_LICENSES = (indexId) => `api/indexes/${indexId}/licenses`;
  };

  static Collaborations = class {
    static Filters = class {
      public static OWNER = 'api/collaborations/owners';
      public static TYPE = 'api/collaborations/types';
      public static STATUS = 'api/collaborations/statuses';
    };
    public static COLLABORATIONS_GRID = 'api/collaborations';
    public static COLLABORATIONS_GRID_SPREADSHEET = 'api/collaborations/spreadsheet';
    public static COLLABORATIONS_FAVORITES = 'api/collaborations/favorites';
    public static COLLABORATIONS_RECENTLYUSED = 'api/collaborations/recentlyused';
    public static COLLABORATIONS_TYPES = 'api/collaborations/types';
    public static COLLABORATIONS_CHANGE_FAVORITE = (collaborationId) => `api/collaborations/${collaborationId}/favorite`;
    public static COLLABORATIONS_ACTIVATION = (collaborationId) => `api/collaborations/${collaborationId}/activation`;
  };

  static SelfCertificationCosts = class {
    static Filters = class {
      public static ORGANISATIONS = (selfcertificationId) => `api/selfcertificationcosts/${selfcertificationId}/organisations`;
      public static COSTCENTRES = (selfcertificationId) => `api/selfcertificationcosts/${selfcertificationId}/costcentres`;
      public static FEEDBACKSTATUS = (selfcertificationId) => `api/selfcertificationcosts/${selfcertificationId}/feedbackstatus`;
      public static NEEDEXPERTISE = (selfcertificationId) => `api/selfcertificationcosts/${selfcertificationId}/needexpertise`;
      public static WITHSAVINGS = (selfcertificationId) => `api/selfcertificationcosts/${selfcertificationId}/withsavings`;
    };
    public static SELFCERTIFICATIONCOST_DEFAULT = `api/selfcertificationcosts/defaults`;
    public static SELFCERTIFICATIONCOST_CREATE_PARTICULARS = `api/selfcertificationcosts/particulars`;
    public static SELFCERTIFICATIONCOST_PARTICULARS = (selfcertificationId) =>
      `api/selfcertificationcosts/${selfcertificationId}/particulars`;
    public static SELFCERTIFICATIONCOST_SENDSURVEY = (selfcertificationId) =>
      `api/selfcertificationcosts/${selfcertificationId}/sendinitial`;
    public static SELFCERTIFICATIONCOST_STATUS = (selfcertificationId) => `api/selfcertificationcosts/${selfcertificationId}/status`;
    public static SELFCERTIFICATIONCOST_USERS = (selfcertificationId) => `api/selfcertificationcosts/${selfcertificationId}/users`;
    public static SELFCERTIFICATIONCOST_USERS_SERVICES = (selfcertificationId, userId) =>
      `api/selfcertificationcosts/${selfcertificationId}/users/${userId}/services`;
    public static SELFCERTIFICATIONCOST_USERS_COMMENTS = (selfcertificationId, userId) =>
      `api/selfcertificationcosts/${selfcertificationId}/users/${userId}/comments`;
    public static SELFCERTIFICATIONCOST_USERS_EXECUTION = (selfcertificationId, userId) =>
      `api/selfcertificationcosts/${selfcertificationId}/users/${userId}/execution`;
  };

  static IndiceReviews = class {
    static Filters = class {
      public static FEEDBACKSTATUS = (indicereviewId) => `api/indice-reviews/${indicereviewId}/feedbackstatus`;
      public static ORGANISATIONS = (indicereviewId) => `api/indice-reviews/${indicereviewId}/organisations`;
      public static COSTCENTRES = (indicereviewId) => `api/indice-reviews/${indicereviewId}/costcentres`;
      public static HASATTACHMENTS = (indicereviewId) => `api/indice-reviews/${indicereviewId}/hasattachments`;
      public static HASCOMMENTS = (indicereviewId) => `api/indice-reviews/${indicereviewId}/hascomments`;
      public static AGREEMENTSINPLACE = (indicereviewId) => `api/indice-reviews/${indicereviewId}/agreementsinplace`;
      public static RECIPIENTS = (indicereviewId) => `api/indice-reviews/${indicereviewId}/recipients`;
      public static ISSUERS = (indicereviewId) => `api/indice-reviews/${indicereviewId}/issuers`;
      public static PROVIDERS = (indicereviewId) => `api/indice-reviews/${indicereviewId}/providers`;
      public static LEGALENTITIES = (indicereviewId) => `api/indice-reviews/${indicereviewId}/legalentities`;
    };
    public static INDICE_REVIEWS = 'api/indice-reviews';
    public static INDICE_REVIEW_DEFAULT = `api/indice-reviews/defaults`;
    public static INDICE_REVIEW_CREATE_PARTICULARS = `api/indice-reviews/particulars`;
    public static INDICE_REVIEW_PARTICULARS = (indicereviewId) => `api/indice-reviews/${indicereviewId}/particulars`;
    public static INDICE_REVIEW_SENDSURVEY = (indicereviewId) => `api/indice-reviews/${indicereviewId}/sendinitial`;
    public static INDICE_REVIEW_STATUS = (indicereviewId) => `api/indice-reviews/${indicereviewId}/status`;
    public static INDICE_REVIEW_STATUS_SPREADSHEET = (indicereviewId) => `api/indice-reviews/${indicereviewId}/spreadsheet`;
    public static INDICE_REVIEW_STATUS_SPREADSHEET_DETAILED = (indicereviewId) =>
      `api/indice-reviews/${indicereviewId}/spreadsheet/detailed`;
    public static INDICE_REVIEW_USERS_RESULT = (indicereviewId) => `api/indice-reviews/${indicereviewId}/users`;
    public static INDICE_REVIEW_INDEXES_RESULT = (indicereviewId) => `api/indice-reviews/${indicereviewId}/indicevariants`;
    public static INDICE_REVIEW_USERS_QUESTIONAIRE_RESULT = (indicereviewId, userId) =>
      `api/indice-reviews/${indicereviewId}/users/${userId}/questions`;
    public static INDICE_REVIEW_INDICES_QUESTIONAIRE_RESULT = (indicereviewId, indicevariantId) =>
      `api/indice-reviews/${indicereviewId}/indicevariants/${indicevariantId}/questions`;
    public static INDICE_REVIEW_USERS_QUESTIONAIRE_STATUS = (indicereviewId, userId, completed) =>
      `api/indice-reviews/${indicereviewId}/users/${userId}/reviewstate?completed=${completed}`;
    public static INDICE_REVIEW_USERS_SEND_REMINDER = (indicereviewId, userId) =>
      `api/indice-reviews/${indicereviewId}/users/${userId}/send`;
    // public static INDICE_REVIEW_USERS = (selfcertificationId) => `api/selfcertificationcosts/${selfcertificationId}/users`;
    // public static INDICE_REVIEW_USERS_SERVICES = (selfcertificationId, userId) =>
    //   `api/selfcertificationcosts/${selfcertificationId}/users/${userId}/services`;
    // public static INDICE_REVIEW_USERS_COMMENTS = (selfcertificationId, userId) =>
    //   `api/selfcertificationcosts/${selfcertificationId}/users/${userId}/comments`;
    // public static INDICE_REVIEW_USERS_EXECUTION = (selfcertificationId, userId) =>
    //   `api/selfcertificationcosts/${selfcertificationId}/users/${userId}/execution`;
  };

  static IndiceSubReviews = class {
    public static INDICE_SUB_REVIEW_DEFAULT = `api/indice-sub-reviews/defaults`;
    public static INDICE_SUB_REVIEW_CREATE_PARTICULARS = `api/indice-sub-reviews/particulars`;
    public static INDICE_SUB_REVIEW_PARTICULARS = (indicereviewId) => `api/indice-sub-reviews/${indicereviewId}/particulars`;
    public static INDICE_SUB_REVIEW_STATUS = (indicereviewId) => `api/indice-sub-reviews/${indicereviewId}/status`;
    public static INDICE_SUB_REVIEW_STATUS_SPREADSHEET = (indicereviewId) => `api/indice-sub-reviews/${indicereviewId}/spreadsheet`;
    public static INDICE_SUB_REVIEW_STATUS_SPREADSHEET_DETAILED = (indicereviewId) =>
      `api/indice-sub-reviews/${indicereviewId}/spreadsheet/detailed`;
    public static INDICE_SUB_REVIEW_USERS_RESULT = (indicereviewId) => `api/indice-sub-reviews/${indicereviewId}/users`;
    public static INDICE_SUB_REVIEW_INDEXES_RESULT = (indicereviewId) => `api/indice-sub-reviews/${indicereviewId}/indicevariants`;
    public static INDICE_SUB_REVIEW_USERS_QUESTIONAIRE_RESULT = (indicereviewId, userId) =>
      `api/indice-sub-reviews/${indicereviewId}/users/${userId}/questions`;
    public static INDICE_SUB_REVIEW_INDICES_QUESTIONAIRE_RESULT = (indicereviewId, indicevariantId) =>
      `api/indice-sub-reviews/${indicereviewId}/indicevariants/${indicevariantId}/questions`;
  };

  static Broadcast = class {
    static Filters = class {
      public static ORGANISATIONS = (broadcastId) => `api/broadcasts/${broadcastId}/organisations`;
      public static COSTCENTRES = (broadcastId) => `api/broadcasts/${broadcastId}/costcentres`;
    };
    public static BROADCAST_DEFAULT = 'api/broadcasts/defaults';
    public static BROADCAST_CREATE_PARTICULARS = 'api/broadcasts/particulars';
    public static BROADCAST_PARTICULARS = (broadcastId) => `api/broadcasts/${broadcastId}/particulars`;
    public static BROADCAST_STATUS = (broadcastId) => `api/broadcasts/${broadcastId}/status`;
    public static BROADCAST_USERS = (broadcastId) => `api/broadcasts/${broadcastId}/users`;
    public static BROADCAST_SEND = (broadcastId) => `api/broadcasts/${broadcastId}/send`;
  };

  static MailTemplates = class {
    static Filters = class {
      public static LANGUAGE = 'api/mailtemplates/languages';

      public static STAGE_BY_ENTITYKIND = (entityKind) => `api/mailtemplates/${entityKind}/stages`;
    };
    public static MAIL_TEMPLATES = 'api/mailtemplates';
    public static MAIL_TEMPLATES_SPREADSHEET = 'api/mailtemplates/spreadsheet';
    public static MAIL_TEMPLATES_FRAMES = 'api/mailtemplates/frames';
    public static MAIL_TEMPLATES_FRAMES_SPREADSHEET = 'api/mailtemplates/frames/spreadsheet';
    public static MAIL_TEMPLATES_PREVIEW = 'api/mailtemplates/preview';
    public static MAIL_TEMPLATES_DEFAULT = 'api/mailtemplates/defaults';
    public static MAIL_TEMPLATES_DEFAULT_SPREADSHEET = 'api/mailtemplates/defaults/spreadsheet';
    public static MAIL_TEMPLATES_CREATE = (entityKind) => `api/mailtemplates/${entityKind}`;
    public static MAIL_TEMPLATES_ITEM_PARTICULAR = (mailtemplateId, stageId, languageId) =>
      `api/mailtemplates/${mailtemplateId}/items/${languageId}/${stageId}/particular`;
    public static MAIL_TEMPLATES_ITEM_CREATE = (mailtemplateId) => `api/mailtemplates/${mailtemplateId}/items`;
    public static MAIL_TEMPLATES_ITEM_SAVE = (mailtemplateId, itemId) => `api/mailtemplates/${mailtemplateId}/items/${itemId}`;
    public static MAIL_TEMPLATES_STATIC_OPTIONS = (entityKind) => `api/mailtemplates/${entityKind}/statics`;
    public static MAIL_TEMPLATES_FRAMES_BODIES = (frameId) => `api/mailtemplates/frames/${frameId}/bodies`;
    public static MAIL_TEMPLATES_FRAMES_STATIC_OPTIONS = (frameId, entityKind) =>
      `api/mailtemplates/frames/${frameId}/${entityKind}/statics`;
  };

  static Emails = class {
    public static EMAILS_INBOX = `api/emails/inbox`;
    public static EMAILS_INBOX_SPREADSHEET = `api/emails/inbox/spreadsheet`;
    public static EMAILS_INBOX_READ = `api/emails/inbox/read`;
    public static EMAILS_MAIL_HISTORY = `api/emails/emailhistory`;
    public static EMAILS_MAIL_HISTORY_SPREADSHEET = `api/emails/emailhistory/spreadsheet`;
    public static EMAILS_SEND = (entityKind, entityId) => `api/emails/${entityKind}/${entityId}/send`;
    public static EMAILS_MAIL_HISTORY_DEPRECATED = (entityKind, entityId) => `api/emails/${entityKind}/${entityId}/emailhistory`;
    public static EMAILS_MAIL_HISTORY_DEPRECATED_SPREADSHEET = (entityKind, entityId) =>
      `api/emails/${entityKind}/${entityId}/emailhistory/spreadsheet`;
    public static EMAILS_MAIL_HISTORY_PARTICULARS = (emailhistoryId) => `api/emails/emailhistory/${emailhistoryId}/particulars`;
    public static EMAILS_MAIL_HISTORY_PARTICULARS_BY_ENTITY = (entityKind, entityId, emailhistoryId) =>
      `api/emails/${entityKind}/${entityId}/emailhistory/${emailhistoryId}/particulars`;
  };

  static Restricted = class {
    static SelfCertificationCost = class {
      public static SELFCERTIFICATION_PARTICULARS = (selfcertificationId) => `api/restricted/selfcertificationcost/${selfcertificationId}`;
      public static SELFCERTIFICATION_PRODUCT_SERVICES = (selfcertificationId, productId) =>
        `api/restricted/selfcertificationcost/${selfcertificationId}/products/${productId}`;
      public static SELFCERTIFICATION_SUBMIT = (selfcertificationId) =>
        `api/restricted/selfcertificationcost/${selfcertificationId}/submit`;
    };
    static IndiceReviews = class {
      public static INDICEREVIEWS_PARTICULARS = (indicereviewId) => `api/restricted/indice-reviews/${indicereviewId}`;
      public static INDICEREVIEWS_DATA = (indicereviewId) => `api/restricted/indice-reviews/${indicereviewId}/data`;
      public static INDICEREVIEWS_QUESTIONS = (indicereviewId) => `api/restricted/indice-reviews/${indicereviewId}/questions`;
      public static INDICEREVIEWS_ISSUERS = (indicereviewId) => `api/restricted/indice-reviews/${indicereviewId}/issuers`;
      public static INDICEREVIEWS_PROVIDERS = (indicereviewId) => `api/restricted/indice-reviews/${indicereviewId}/providers`;
      public static INDICEREVIEWS_SUBMIT = (indicereviewId) => `api/restricted/indice-reviews/${indicereviewId}/submit`;
    };

    static Files = class {
      public static ENTITY_FILES_GRID = (entity, entityId) => `api/restricted/files/${entity}/${entityId}`;
    };
  };

  static FtpConnection = class {
    static Filters = class {
      public static PROTOCOLS = 'api/ftp/protocols';
    };
    public static FTP_ACTIONS = `api/ftp/actions`;
    public static FTP_CONNECTIONS = 'api/ftp/connections';
    public static FTP_CONNECTIONS_SPREADSHEET = 'api/ftp/connections/spreadsheet';
    public static FTP_CONNECTIONS_CONNECT = (connectionId) => `api/ftp/connections/${connectionId}/connect`;
    public static FTP_CONNECTIONS_EXECUTE = (connectionId) => `api/ftp/connections/${connectionId}/execute`;
  };

  static UseCases = class {
    public static USE_CASES_ACTIONS = 'api/usecases/actions';
    public static USE_CASES_TOPICS = 'api/usecases/topics';
    public static USE_CASES = 'api/usecases';
    public static USE_CASES_SPREADSHEET = 'api/usecases/spreadsheet';
    public static USE_CASES_FAVORITE = 'api/usecases/favorite';
    public static USE_CASES_FAVORITE_BYID = (usecaseId) => `api/usecases/${usecaseId}/favorite`;
  };

  static Grids = class {
    public static GRIDS_FILTERS = (gridName) => `api/grids/${gridName}/filters`;
    public static GRIDS_FILTERS_DEFAULT = (gridName, filterId) => `api/grids/${gridName}/filters/${filterId}/defaults`;
    public static GRIDS_FILTERS_FAVORITE = (gridName, filterId) => `api/grids/${gridName}/filters/${filterId}/favorite`;
    public static GRIDS_FILTERS_SORTORDER = (gridName) => `api/grids/${gridName}/filters/sortorder`;
    public static GRIDS_SEARCH_ELEMENTS_ACTIVE = (gridName, searchelementId) =>
      `api/grids/${gridName}/searchelements/${searchelementId}/activation`;
    public static GRIDS_SEARCH_ELEMENTS_SORTORDER = (gridName) => `api/grids/${gridName}/searchelements/sortorder`;
  };
}
