import { createAction, props } from '@ngrx/store';
import { LicenseAssetPeriodAllocationRequest } from 'src/app/models/asset-allocation';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { CancelSharedAssetAllocationUpdateStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-shared-asset-wizard/cancel-shared-asset-allocation-update-step/cancel-shared-asset-allocation-update-step';
import { CancelSharedAssetInitStep } from 'src/app/shared-components/allocation-wizard-modal-dialogs/cancel-shared-asset-wizard/init-step/init-step';

export const selectInitStep = createAction(
  '[Catalog Page] Select Init Cancel Shared Asset Information Step',
  props<{ initStep: CancelSharedAssetInitStep }>()
);

export const selectAllocationUpdateStep = createAction(
  '[CancelSharedAsset Wizard Page] Cancel Shared Asset Wizard - Allocation Update Step',
  props<{ allocationUpdate: CancelSharedAssetAllocationUpdateStep }>()
);

export const deleteAllocationUpdateStep = createAction('[CancelSharedAsset Wizard Page] Delete Allocation Update Step');

export const exitCancelSharedAssetWizard = createAction('[CancelSharedAsset Wizard Page] Exit Cancel Shared Asset Wizard');

export const destroyWizard = createAction('[CancelSharedAsset Wizard Page] Destroy Wizard');

export const createNewCancelSharedAsset = createAction(
  '[Wizard Confirmation Step] Create Cancel Shared Asset Information Step',
  props<{ request: LicenseAssetPeriodAllocationRequest[] }>()
);

export const createNewCancelSharedAssetSuccess = createAction(
  '[Wizard Confirmation Step] Create Cancel Shared Asset Information Step Success',
  props<{ response: EntityWizardResponse }>()
);

export const createNewCancelSharedAssetFail = createAction(
  '[Wizard Confirmation Step] Create Cancel Shared Asset Information Step Fail',
  props<{ errorMessage: any }>()
);
