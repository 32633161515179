import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LicenseAssetPeriodAllocationRequest } from 'src/app/models/asset-allocation';
import { UserLicenseServices } from 'src/app/models/product-services';
import { MoveAssetWizardRequest } from 'src/app/shared-components/allocation-wizard-modal-dialogs/move-asset-wizard/move-asset-confirm-step/move-asset-wizard-request';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../../configs/api-paths';
import { ActionResponse } from '../../models/action-response';
import { BaseResponse } from '../../models/base-response';
import { MoveDeskData } from '../../models/move-desk-data';
import { MoveLicenseServicesRequest } from '../../shared-components/allocation-wizard-modal-dialogs/move-wizard/confirmation-step/move-license-services-request';
import { LogService } from '../log-service';

@Injectable({
  providedIn: 'root',
})
export class MoveWizardDataService {
  constructor(private http: HttpClient) {}

  getFromDeskData(deskId: number): Observable<BaseResponse<MoveDeskData>> {
    const m = this.getFromDeskData.name;

    const path = environment.getEndpoint(ApiPath.Desks.DESK_MOVE_FROMDESK(deskId));
    LogService.info(this, m, LogService.GET + path, null);

    const retVal = this.http.get<BaseResponse<MoveDeskData>>(path);

    return retVal;
  }

  moveLicenseServices(request: MoveLicenseServicesRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.moveLicenseServices.name;

    const path = environment.getEndpoint(ApiPath.Licenses.MOVE_LICENSE_WIZARD);
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getLicenceServices(moveDate: string, licenseId: number): Observable<BaseResponse<UserLicenseServices[]>> {
    const m = this.getLicenceServices.name;

    const path = environment.getEndpoint(ApiPath.Licenses.LICENSES_SERVICES);
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, 'licenseId: ' + licenseId, null);
    let params = new HttpParams();
    if (licenseId) {
      params = params.append('licenseId', licenseId);
    }
    if (moveDate) {
      params = params.append('filterStarts', moveDate);
    }
    const retVal = this.http.get<BaseResponse<UserLicenseServices[]>>(path, {
      params,
    });

    return retVal;
  }

  moveAssetLicenseServices(assetId: number, request: MoveAssetWizardRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.moveLicenseServices.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_MOVE_ASSET_WIZARD(assetId));
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  moveSharedAssetLicenseServices(
    assetId: number,
    request: LicenseAssetPeriodAllocationRequest[]
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.moveSharedAssetLicenseServices.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_MOVE_SHARED_ASSET_WIZARD(assetId));
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }
}
