import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import * as saveAs from 'file-saver';
import { Observable } from 'rxjs';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entities } from 'src/app/configs/entities';
import { Entity } from 'src/app/models/entity';
import { FileGridNode } from 'src/app/models/file-grid-node';
import { RootStoreState } from 'src/app/root-store';
import { ImageFullscreenViewerStoreAction } from 'src/app/root-store/image-fullscreen-viewer-store';
import { FilesService } from 'src/app/services/files.data.services';
import { LogService } from 'src/app/services/log-service';
import { GridBaseComponent } from 'src/app/shared/base-components/grid-base-component';
import { MessageBox, MessageBoxButton, MessageBoxStyle, MessageBoxType } from 'src/app/shared/message-box';
import { FileModalComponent } from '../file-modal/file-modal.component';
import { FileTabType } from '../file-tab/file-tab-type';
import { TreeEntity } from '../file-tree/file-tree.component';
import {
  MoveDirectorySelectDialogComponent,
  MoveDirectorySelectDialogData,
} from '../file-tree/move-directory-select-dialog/move-directory-select-dialog.component';

@Component({
  selector: 'app-file-table',
  templateUrl: './file-table.component.html',
  styleUrls: ['./file-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileTableComponent extends GridBaseComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() tabType: FileTabType;
  @Input() treeEvent: Observable<TreeEntity>;
  @Input() isModal = false;
  @Output() fileSelected = new EventEmitter<FileGridNode>();
  selectedTreeNode: TreeEntity;
  selectedFile: FileGridNode;

  showThumbnail = false;

  // subdirectory
  subdirectoryChecked = false;

  // Upload
  isUploading = false;
  progressPercentage = 0;
  private fileUploadSubscription: any;

  constructor(
    private sanitizer: DomSanitizer,
    private filesService: FilesService,
    protected store: Store<RootStoreState.State>,
    protected cdRef: ChangeDetectorRef
  ) {
    super(store, cdRef);
  }

  addFileClick() {
    this.fileInput.nativeElement.click();
  }

  gbOnInit() {
    if (this.tabType === null || this.tabType === undefined) {
      throw new TypeError('The input ‘tabType’ is required');
    }
    this.subscribe(this.treeEvent, (entity) => {
      this.selectedTreeNode = entity;
      this.initRequest();
      this.gbLoadEntitiesData();
    });
  }

  reloadData() {
    if (this.selectedTreeNode) {
      this.initRequest();
      this.gbLoadEntitiesData();
    }
  }

  isUploadDisabled(): boolean {
    return (
      !this.selectedTreeNode ||
      (this.selectedTreeNode.entityKind === 'fo_property' && this.tabType === FileTabType.generic) ||
      (this.selectedTreeNode.entityKind === 'root' && this.tabType !== FileTabType.generic)
    );
  }

  get FileTabType() {
    return FileTabType;
  }

  /* GRID COMPONENT OVERRIDED METHOD */

  async gbAfterViewInitTable() {}

  gbGetDisplayColumnTable() {
    if (this.isModal) {
      return this.tabType === FileTabType.generic
        ? ['filenodeName', 'filenodeType', 'filenodeSize', 'filenodeUploaded', 'filenodeUploadedby', 'actionButtons']
        : ['radio', 'filenodeName', 'filenodeType', 'filenodeSize', 'filenodeUploaded', 'filenodeUploadedby', 'actionButtons'];
    } else {
      return this.tabType === FileTabType.generic
        ? ['filenodeName', 'filenodeType', 'filenodeSize', 'filenodeUploaded', 'filenodeUploadedby', 'actionButtons']
        : ['select', 'filenodeName', 'filenodeType', 'filenodeSize', 'filenodeUploaded', 'filenodeUploadedby', 'actionButtons'];
    }
  }

  gbGetInitialOrderBy() {
    return 'filenodeName';
  }

  gbSetSelectedEntity(row: Entity) {
    this.selectedFile = { ...(row as FileGridNode) };
    this.selectedEntity = row;
  }

  onEntitySelected(row: Entity) {
    this.selectedFile = { ...(row as FileGridNode) };
    this.selectedEntity = row;
    this.fileSelected.emit(this.selectedFile);
  }

  getLoadApiEntitiesPath(): string {
    switch (this.tabType) {
      case FileTabType.generic:
        return ApiPath.Files.ENTITY_FILES_GRID(this.selectedTreeNode.entityKind, this.selectedTreeNode.entityId);
      case FileTabType.company:
      case FileTabType.user:
        return ApiPath.Files.FILES_GRID(this.tabType.key, this.selectedTreeNode.entityId, this.subdirectoryChecked);
    }
  }

  getUploadApiEntitiesPath(): string {
    switch (this.tabType) {
      case FileTabType.generic:
        return ApiPath.Files.ENTITY_FILES_GRID(this.selectedTreeNode.entityKind, this.selectedTreeNode.entityId);
      case FileTabType.company:
      case FileTabType.user:
        return ApiPath.Files.FILES_UPLOAD(this.tabType.key, this.selectedTreeNode.entityId);
    }
  }

  getLinkApiPath(fileId: number): string {
    switch (this.tabType) {
      case FileTabType.generic:
        return ApiPath.Files.ENTITY_FILES_LINK(this.selectedTreeNode.entityKind, this.selectedTreeNode.entityId, fileId);
      case FileTabType.company:
      case FileTabType.user:
        return ApiPath.Files.FILES_LINK(this.tabType.key, this.selectedTreeNode.entityId, fileId);
    }
  }

  gbLoadEntitiesData() {
    this.clearSelection();
    this.isLoading = true;
    this.dataSourceTable = new MatTableDataSource();
    this.noEntityData = false;
    if (this.selectedTreeNode) {
      if (this.gridLoadSubscription != null) {
        this.gridLoadSubscription.unsubscribe();
      }
      this.gridLoadSubscription = this.subscribe(
        this.filesService.getFilesList(this.getLoadApiEntitiesPath(), this.request),
        (response) => {
          this.lastUsedFilters = response.filters;
          if (response.data) {
            this.dataSourceTable.data = response.data as Entity[];
            this.pageTotalElements = response.data[0].entityCount;
          } else {
            this.pageTotalElements = 0;
            this.noEntityData = true;
          }
        },
        (error) => {
          /* HTTP Errors are managed on ServerErrorInterceptor */
        },
        () => (this.isLoading = false)
      );
    }
  }

  displayDetails(row: FileGridNode) {
    this.fileSelected.emit(row);
  }

  moveFilesIntoDirectory() {
    this.subscribe(
      this.dialog
        .open(MoveDirectorySelectDialogComponent, {
          width: '40vw',
          minHeight: '60vh',
          data: {
            fileTabType: this.tabType.key,
            movedFolder: null,
            movedFolderId: this.selectedTreeNode.entityId,
            parentFolderId: null,
            disableChildNodes: false,
          } as MoveDirectorySelectDialogData,
        })
        .afterClosed(),
      (entity) => {
        if (entity) {
          this.subscribe(
            this.filesService.moveFiles(
              this.tabType.key,
              this.selectedTreeNode.entityId,
              entity.entityId,
              this.selection.selected.map((e) => e.entityId)
            ),
            (response) => {
              if (response.data) {
                if (response.data) {
                  if (response.data.state) {
                    this.messageNotifierService.showSuccessMessage(_('toastr_directory_success_moved'));
                    this.reloadData();
                  } else if (response.data.error) {
                    // Fail
                    this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
                  } else {
                    this.messageNotifierService.showWarningMessage('toastr_no_data');
                  }
                }
              }
            }
          );
        }
      }
    );
  }

  /* other */

  uploadFiles(fileList: FileList) {
    console.log(fileList);
    if (this.selectedTreeNode) {
      this.isUploading = true;
      this.fileUploadSubscription = this.filesService
        .uploadMultipleFilesToServer(this.getUploadApiEntitiesPath(), Array.from(fileList))
        .subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progressPercentage = Math.floor((event.loaded * 100) / event.total);
            }
            if (event.type === HttpEventType.Response) {
              // const response = event.body as BaseResponse<InterfaceWizardResponse>;
              // this.uploadCompleted.emit(response.data);
              this.isUploading = false;
              this.progressPercentage = 0;
              this.gbLoadEntitiesData();
            }
          },
          (error: any) => {
            if (this.fileUploadSubscription) {
              this.fileUploadSubscription.unsubscribe();
            }
            this.isUploading = false;
            this.progressPercentage = 0;
          }
        );
    }
  }

  deleteFile(file: FileGridNode) {
    const selectedEntityApiPath = ApiPath.Files.FILES_DELETE(this.tabType.key, file.filenodeId);
    this.gbSetSelectedEntity(file);
    MessageBox.show(
      this.dialog,
      this.translate.instant(this.selectedEntity.entityKind) + ': ' + this.selectedEntity.entityName,
      this.translate.instant('label_confirm'),
      this.translate.instant('label_really_delete_record'),
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.removeRecord(selectedEntityApiPath);
      }
    });
  }

  protected removeRecord(apiPath: string) {
    this.subscribe(
      this.coreDataService.removeEntity(apiPath),
      (response) => {
        if (response.data) {
          if (response.data.state) {
            // Success
            this.messageNotifierService.showSuccessMessage('toastr_success_removed');
            this.gbLoadEntitiesData();
          } else if (response.data.error) {
            // Fail
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          } else {
            this.messageNotifierService.showWarningMessage('toastr_no_data');
          }
        }
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      }
    );
  }

  downloadFile(file: FileGridNode) {
    this.subscribe(this.filesService.downloadFile(Entities.FILE, file.filenodeFileid), (blob) => {
      console.log(blob.type);
      if (blob.type === 'text/plain' || blob.type.match(/image\/\w+/)) {
        const fileURL = URL.createObjectURL(blob);

        window.open(fileURL, '_blank');
      } else {
        saveAs(blob, file.filenodeName); // FileSaver;
      }
    });
  }

  addLink() {
    this.dialog
      .open(FileModalComponent, { height: '80vh', width: '80vw' })
      .afterClosed()
      .subscribe((selectedFile) => {
        if (selectedFile) {
          LogService.debug(this, this.addLink.name, 'Selected file', selectedFile);
          this.subscribe(this.filesService.addLink(this.getLinkApiPath(selectedFile.filenodeFileid)), (response) => {
            if (response.data) {
              if (response.data) {
                if (response.data.state) {
                  this.messageNotifierService.showSuccessMessage('toastr_link_success_created');
                  // RELOAD DATA INTO TABLE COMPONENT
                  this.reloadData();
                } else if (response.data.error) {
                  // Fail
                  this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
                } else {
                  this.messageNotifierService.showWarningMessage('toastr_no_data');
                }
              }
            }
          });
        }
      });
  }

  previewFile(file: FileGridNode) {
    this.subscribe(this.filesService.getFilePreviewImage(file.filenodeFileid), ({ data }) => {
      if (data) {
        const imageObject = [
          {
            image: data.image,
            title: file.filenodeName,
          },
        ];
        this.store.dispatch(ImageFullscreenViewerStoreAction.setImage({ imageObject }));
      }
    });
  }

  showDropFolderError() {
    MessageBox.show(
      this.dialog,
      this.translate.instant(_('label_cannot_drop_folder_message')),
      this.translate.instant(_('label_cannot_drop_folder')),
      null,
      MessageBoxType.Comfirm,
      MessageBoxButton.Ok,
      false,
      MessageBoxStyle.Full,
      '600px'
    );
  }

  // Call this method in the image source, it will sanitize it.
  transform(base64Image: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }
}
