import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, of } from 'rxjs';
import { AuthError, AuthResult } from 'src/app/core/auth/auth-result';
import { AuthToken } from 'src/app/core/auth/auth-token';
import { RestrictedTokenService } from 'src/app/services/auth/restricted-token.service';
import { RestrictedAuthStoreAction } from '.';

@Injectable()
export class RestrictedAuthEffects {
  demoAutoAutentication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RestrictedAuthStoreAction.restrictedAutoAuthenticate),
      concatMap(({ token }) => {
        const authtoken = new AuthToken(token);
        if (!authtoken.isValid()) {
          // throw new AuthIllegalJWTTokenError('Restricted Token is empty or invalid.');

          return of(
            RestrictedAuthStoreAction.restrictedAuthSuccess({
              result: new AuthResult(false, null, new AuthError('Restricted Token is empty or invalid.')),
            })
          );
        }
        const result = new AuthResult(true, null, null, authtoken);
        this.tokenService.set(result.getToken());
        return of(RestrictedAuthStoreAction.restrictedAuthSuccess({ result }));
      })
    )
  );

  constructor(private actions$: Actions, private tokenService: RestrictedTokenService, private router: Router) {}
}
