import { AuthError } from 'src/app/core/auth/auth-result';
import { AuthUser } from 'src/app/core/auth/auth-user';

export interface State {
  isRestrictedAuthenticated: boolean;
  error: AuthError;
  authUser: AuthUser;
}

export const initialState: State = {
  isRestrictedAuthenticated: false,
  error: null,
  authUser: null,
};
