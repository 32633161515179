import { KeyValue } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthUser } from './auth-user';

export class AuthToken {
  private payload: any;

  constructor(private readonly token: string) {
    const jwtHelper = new JwtHelperService();
    this.payload = jwtHelper.decodeToken(token);
  }

  getCreatedAt(): Date {
    return this.payload && this.payload.iat ? new Date(Number(this.payload.iat) * 1000) : new Date();
  }

  getTokenExpDate(): Date {
    if (this.payload && !this.payload.hasOwnProperty('exp')) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(this.payload.exp); // 'cause jwt token are set in seconds
    return date;
  }

  // getOauthIdToken(): OauthIdToken {
  //   if (this.payload && !this.payload.hasOwnProperty('idtoken')) {
  //     return null;
  //   }
  //   return new OauthIdToken(this.payload.idtoken);
  // }

  getOauthLogout(): string {
    if (this.payload && !this.payload.hasOwnProperty('logout')) {
      return null;
    }
    return this.payload.logout;
  }

  getTokenTenant(): string {
    if (this.payload && !this.payload.hasOwnProperty('tenant')) {
      return null;
    }
    return this.payload.tenant;
  }

  getUser(): AuthUser {
    return this.payload ? new AuthUser(this.payload) : null;
  }

  isValid(): boolean {
    return !!this.token && (!this.getTokenExpDate() || new Date() < this.getTokenExpDate());
  }

  isTenantAdmin(): boolean {
    if (this.payload && !this.payload.hasOwnProperty('tenant')) {
      return false;
    }
    return this.payload.tenant === 'TENANTADMINISTRATION';
  }

  toString(): string {
    return !!this.token ? this.token : '';
  }
}

export class AuthIllegalJWTTokenError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class Token {
  token: string;
  tenants?: KeyValue<string, string>[];
  twofactorauthentication?: string;
  logintryid?: number;
  error?: string;
  accessToken: string;
  idToken: string;
}

export class OauthTokens {
  constructor(readonly accessToken: string, readonly idToken: string) {}

  getIdTokenEmail() {
    const idToken = new OauthIdToken(this.idToken);
    return idToken.getTokenEmail();
  }
}

export class OauthIdToken {
  private payload: any;

  constructor(private readonly idToken: string) {
    const jwtHelper = new JwtHelperService();
    this.payload = jwtHelper.decodeToken(idToken);
  }

  isValid(): boolean {
    return !!this.idToken && (!this.getTokenExpDate() || new Date() < this.getTokenExpDate());
  }

  getCreatedAt(): Date {
    return this.payload && this.payload.iat ? new Date(Number(this.payload.iat) * 1000) : new Date();
  }

  getTokenIss(): string {
    if (this.payload && !this.payload.hasOwnProperty('iss')) {
      return null;
    }
    return this.payload.iss;
  }

  getTokenExpDate(): Date {
    if (this.payload && !this.payload.hasOwnProperty('exp')) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(this.payload.exp); // 'cause jwt token are set in seconds
    return date;
  }

  getTokenEmail(): string {
    if (this.payload && !this.payload.hasOwnProperty('email')) {
      return null;
    }
    return this.payload.email;
  }

  toString(): string {
    return !!this.idToken ? this.idToken : '';
  }
}
