import { KeyValue } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { AccessMode } from '../configs/access-mode';
import { Approver } from './approver';
import { IdValue } from './core/key-value-data';
import { Entity } from './entity';
import { ProductFamilyMailFrame } from './productfamily-mailtemplate';
import { Service } from './service';

export const REQUEST_STATE_NEW = 0;
export const REQUEST_STATE_COMPLETE = 200;

export const REQUEST_CLOSED_NO = 0;
export const REQUEST_CLOSED_YES = 1;

export class Request extends Entity {
  requestId: number;
  requestActiondate: Date;
  requestActionstate: string;
  requestActionstateId: number;
  requestActionremarks: string;
  requestReference: string;
  requestReferenceId: number;
  requestReferenceKind: string;
  requestRequested: Date;
  requestRequestor: string;
  requestState: string;
  requestStateId: number;
  requestKind: string;
  requestKindId: number;
  requestType: string;
  requestTypeId: number;
  requestTypeName: string;
  requestAction: string;
  requestActionId: number;
  requestReceiverDeskId: number;
  requestReceiverDesk: string;
  requestReceiverUserId: number;
  requestReceiverUser: string;
  requestNo: number;
  requestNofChats: number;
  requestSubject: string;
  requestEffectiveDate: Date;
  requestActivationDate: Date;
  requestDeactivationDate: Date;
  requestBillingStarts: Date;
  requestBillingEnds: Date;
  requestError: string;
  requestSource: string;
  requestChangesOn: string;
  requestAssignedRoleUsers: IdValue<number, string>[];
  requestAssignedRole: string;
  requestAssignedRoleId: number;
  requestAssignedUser: string;
  requestAssignedUserId: number;
  requestUniqueId: string;
  requestSyncError: string;
  requestSyncState: string;
  requestSyncStateId: number;
  requestSyncStateOptions: KeyValue<string, string>[];
  requestSyncUser: string;
  requestSyncUserId: number;
  requestSynced: Date;
  requestClosed: boolean;
  requestItemNumber: number;
  requestItemUsed: number;
  requestRemarks: string;
  requestApproveAllowed: boolean;
  requestExecuteAllowed: boolean;
  requestExecuteNeedExternalMail: boolean;
  requestMailframeIdService: number;
  requestMailframeProductfamilyId: number;
  requestMailframesExternal: ProductFamilyMailFrame[];
  requestMailframesInternal: ProductFamilyMailFrame[];
  requestNotification: string;
  requestNotificationId: number;
  requestNotificationSent: Date;
  requestAssetId: number;
  requestAsset: string;
}

export class RequestValidationResponse {
  requestId: number;
  requestName?: string;
  requestValid: boolean;
  requestHint: string;
  requestClosed: boolean;
  requestAccess: boolean;
  requestError?: string;
}

export class RequestCurrentFlowStep {
  requestflowstepApproverRemarks: Entity[];
  requestflowstepData: {
    marketdataorders: Service[];
  };
  requestflowstepId: number;
  requestflowstepRequest: string;
  requestflowstepRequestDate: Date;
  requestflowstepRequestId: number;
  requestflowstepRequestRemarks: string;
  requestflowstepRequestType: string;
  requestflowstepRequestTypeId: number;
}

export class RequestFlowStep {
  requestflowstepApproveAction: string;
  requestflowstepApproveActionId: number;
  requestflowstepApproverEntity: string;
  requestflowstepApproverEntityId: number;
  requestflowstepApproverKind: string;
  requestflowstepApproverKindId: number;
  requestflowstepApprovers: Approver;
  requestflowstepAssigned: Date;
  requestflowstepAssignedUser: string;
  requestflowstepAssignedUserEmail: string;
  requestflowstepAssignedUserId: number;
  requestflowstepDeclineAction: string;
  requestflowstepDeclineActionId: number;
  requestflowstepEnded: Date;
  requestflowstepExceptionRole: string;
  requestflowstepExceptionRoleId: number;
  requestflowstepFirstReminded: Date;
  requestflowstepId: number;
  requestflowstepNextId: number;
  requestflowstepPendingStatus: string;
  requestflowstepPendingStatusId: number;
  requestflowstepPendingStatusMailText: string;
  requestflowstepPrevId: number;
  requestflowstepReinitialized: Date;
  requestflowstepRemarks: string;
  requestflowstepReminder1: number;
  requestflowstepReminder2: number;
  requestflowstepRequest: string;
  requestflowstepRequestFlow: string;
  requestflowstepRequestFlowId: number;
  requestflowstepRequestId: number;
  requestflowstepSecondReminded: Date;
  requestflowstepStarted: Date;
  requestflowstepStatus: string;
  requestflowstepStatusId: number;
  requestflowstepTimeout: number;
  requestflowstepTimeoutAction: string;
  requestflowstepTimeoutActionId: number;
  requestflowstepUnassignAction: string;
  requestflowstepUnassignActionId: number;
}

export class RequestProductRequest {
  requestproductId: number;
  requestproductRequestId: number;
  requestproductAddProduct: boolean;
  requestproductAddService: boolean;
  requestproductCancel: boolean;
  requestproductMove: boolean;
  requestproductDeskId: number;
  requestproductDeskLocationId: number;
  requestproductLicenseId: number;
  requestproductIsshared: boolean;
  requestproductProductId: number;
  requestproductProductfamilyId: number;
  requestproductProductserviceId: number;
  requestproductIsmovewithdesk: boolean;
  requestproductIscancel: boolean;
  requestproductIsleaveatcostcentre: boolean;
  requestproductIswaitingroom: boolean;
  requestproductMovetodeskId: number;
  requestproductMovetocostcentreId: number;
  requestproductMovetoreportingcostcentreId: number;
  requestproductMovetolocationId: number;
  requestproductMovetoorganisationId: number;
  requestproductMovedeskCostcentreId: number;
  requestproductMovedeskLocationId: number;
  requestproductMovedeskOrganisationId: number;
  requestproductMovedeskReportingcostcentreId: number;
  requestproductMovedeskUserId: number;
  requestproductBillingaccountId: number;
  requestproductServices: RequestProductServiceNode[];
}

export class RequestSelectedProduct extends RequestProductRequest {
  requestproductCancelSelectedOption?: number;
  requestproductKind: number;
  requestproductDesk: string;
  requestproductLicense: string;
  requestproductProduct: string;
  requestproductProductfamilyId: number;
  requestproductProductfamily: string;
  requestproductProductserviceId: number;
  requestproductProductservice: string;
  requestproductIsMovetodesk?: boolean;
  requestproductMovetodesk: string;
  requestproductIsMovetocostcentre?: boolean;
  requestproductMovetocostcentre: string;
  requestproductMovetoreportingcostcentre: string;
  requestproductMovetolocation: string;
  requestproductMovetoorganisation: string;
  requestproductMovedeskCostcentre: string;
  requestproductMovedeskLocation: string;
  requestproductMovedeskOrganisation: string;
  requestproductMovedeskReportingcostcentre: string;
  requestproductMovedeskUser: string;
  requestproductFromdeskCostcentre: string;
  requestproductFromdeskLocation: string;
  requestproductFromdeskOrganisation: string;
  requestproductFromdeskReportingcostcentre: string;
  requestproductFromdeskUser: string;
  requestproductBillingaccount: string;
  requestproductServices: RequestProductServiceNode[];
  isMoveProductRequest?: boolean;
  isMoveServiceRequest?: boolean;
  isMoveDeskRequest?: boolean;
  isRequestproductServicesEmpty?: boolean;
  isShowDeskField?: boolean;
}

export class RequestProductServiceNode {
  id: number;
  deliveryitemId: number;
  deliveryitem: string;
  cancel: boolean;
  replaceDeliveryitemId: number;
  replaceDeliveryitem: string;
  billingStarts: any;
  billingEnds: any;
  billingaccount: string;
  billingaccountId: number;
  billingaccountChanged: boolean;
  billingaccountOriginal: string;
  billingaccountOriginalId: number;
  deleted: boolean;
  serviceIsbase: boolean;
  serviceIsproduct: boolean;
  nofAssets: number;
  nofFoc: number;
  trialPeriod: string;
  trialPeriodId: number;
  visible: boolean;
}

export class RequestProductReplacementRequest {
  requestproductId: number;
  requestproductLicenseId: number;
  requestproductProductserviceId: number;
}

export class RequestDetails {
  requestId: number;
}

export class RequestParams {
  public static FILTER_ACCESSMODE = 'accessmode';
  public static FILTER_ACTIONSTATE_IDS = 'filterActionstateIds';
  public static FILTER_CHANGEON_IDS = 'filterChangesonIds';
  public static FILTER_CLOSED_IDS = 'filterClosedIds';
  public static FILTER_KIND_IDS = 'filterKindIds';
  public static FILTER_PLATFORM_IDS = 'filterPlatformIds';
  public static FILTER_PRODUCTFAMILY_IDS = 'filterProductfamilyIds';
  public static FILTER_REQUESTSTATE_IDS = 'filterRequeststateIds';
  public static FILTER_SOURCE_IDS = 'filterSourceIds';
  public static FILTER_USER_IDS = 'filterUserIds';
  public static FILTER_ASSET_IDS = 'filterAssetIds';
}

export function getRequestRequestParams(request: PaginatedRequest, linkable: boolean): HttpParams {
  let params = PaginatedRequest.getBaseRequestParams(request);
  if (linkable) {
    params = params.append(RequestParams.FILTER_ACCESSMODE, AccessMode.link.toString());
  }
  return params;
}
