import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { FormFieldDataRequest, GenericFormField } from '../models/forms/form-field';
import { FormGridItem } from '../models/forms/form-grid';
import { FormWizard, FormWizardResponse } from '../models/forms/form-wizard';
import { FormWizardTreeNode } from '../models/forms/form-wizard-tree-node';
import { PaginatedRequest } from '../models/paginated-request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  getFormsGridData(request: PaginatedRequest): Observable<BaseResponse<FormGridItem[]>> {
    const m = this.getFormsGridData.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_GRID_ITEMS);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<FormGridItem[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  getFormsGridDataSpreadsheet(request: PaginatedRequest) {
    const m = this.getFormsGridDataSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_GRID_ITEMS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  createFormWizard(wizardName: string): Observable<BaseResponse<FormWizard>> {
    const m = this.createFormWizard.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD);
    LogService.info(this, m, LogService.POST + path, null);
    const params = new HttpParams().set('wizardName', wizardName);
    const retVal = this.http.post<BaseResponse<FormWizard>>(path, null, { params });

    return retVal;
  }

  cancelFormWizard(wizardId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.createFormWizard.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD);
    LogService.info(this, m, LogService.DELETE + path, null);
    const params = new HttpParams().set('wizardId', wizardId.toString());
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path, { params });

    return retVal;
  }

  getFormWizardStepData(wizardId: number, step: number): Observable<BaseResponse<GenericFormField[]>> {
    const m = this.getFormWizardStepData.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_STEP);
    LogService.info(this, m, LogService.GET + path, null);
    const params = new HttpParams().set('wizardId', wizardId.toString()).set('step', step.toString());
    const retVal = this.http.get<BaseResponse<GenericFormField[]>>(path, { params });

    return retVal;
  }

  putFormWizardStepData(
    wizardId: number,
    step: number,
    request: FormFieldDataRequest[],
    isApply?: boolean
  ): Observable<BaseResponse<FormWizardResponse>> {
    const m = this.getFormWizardStepData.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_STEP);
    LogService.info(this, m, LogService.PUT + path, null);
    let params = new HttpParams().set('wizardId', wizardId.toString()).set('step', step.toString());
    if (isApply) {
      params = params.append('applyOnly', 'true');
    }
    const retVal = this.http.put<BaseResponse<FormWizardResponse>>(path, request, { params });

    return retVal;
  }

  commitFormData(wizardId: number): Observable<BaseResponse<FormWizardResponse>> {
    const m = this.commitFormData.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_COMMIT);
    LogService.info(this, m, LogService.POST + path, null);
    let params = new HttpParams().set('wizardId', wizardId.toString());
    const retVal = this.http.post<BaseResponse<FormWizardResponse>>(path, null, { params });

    return retVal;
  }

  getValidationStatus(wizardId: number): Observable<BaseResponse<FormWizardResponse>> {
    const m = this.getValidationStatus.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_VALIDATION);
    const params = new HttpParams().set('wizardId', wizardId.toString());
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.PARAMS, params);
    const retVal = this.http.get<BaseResponse<FormWizardResponse>>(path, {
      params,
    });
    return retVal;
  }

  getFinishStatus(wizardId: number): Observable<BaseResponse<FormWizardResponse>> {
    const m = this.getFinishStatus.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_WRITE);
    const params = new HttpParams().set('wizardId', wizardId.toString());
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.PARAMS, params);
    const retVal = this.http.get<BaseResponse<FormWizardResponse>>(path, {
      params,
    });
    return retVal;
  }

  getFormWizardNodes(
    request: PaginatedRequest,
    formwizardId?: number,
    formwizardStepId?: number
  ): Observable<BaseResponse<FormWizardTreeNode[]>> {
    const m = this.getFormWizardNodes.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_NODES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'formwizardId', formwizardId);
    LogService.info(this, m, 'formwizardstepId', formwizardStepId);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = formwizardId != null ? params.append('formwizardId', formwizardId.toString()) : params;
    params = formwizardStepId != null ? params.append('formwizardstepId', formwizardStepId.toString()) : params;
    const retVal = this.http.get<BaseResponse<FormWizardTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  getFormWizardNodesSpreadsheet(request: PaginatedRequest, formwizardId?: number, formwizardStepId?: number) {
    const m = this.getFormWizardNodesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Forms.FORMS_WIZARD_NODES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = formwizardId != null ? params.append('formwizardId', formwizardId.toString()) : params;
    params = formwizardStepId != null ? params.append('formwizardstepId', formwizardStepId.toString()) : params;
    return this.http
      .get(path, {
        params,
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  constructor(private http: HttpClient) {}
}
