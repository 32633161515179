import { KeyValue } from '@angular/common';
import { KeyValueAddon2 } from './core/key-value-data';
import { Entity } from './entity';

export class Property extends Entity {
  propertyEntityKind: string;
  propertyHasAccessRights: boolean;
  propertyId: number;
  propertyIsForm: boolean;
  propertyIsGeneric: boolean;
  propertyIsGlobalSearch: boolean;
  propertyName: string;
  propertyUseDocuments: boolean;
  propertyUseCollaboration: boolean;
}

export class PropertyColumn extends Entity {
  propertycolumnColumn: string;
  propertycolumnForeignEntity: string;
  propertycolumnForeignProperty: string;
  propertycolumnForeignPropertyId: number;
  propertycolumnId: number;
  propertycolumnName: string;
  propertycolumnOption: string;
  propertycolumnOptions: KeyValue<string, string>[];
  propertycolumnProperty: string;
  propertycolumnPropertyId: number;
  propertycolumnType: string;
  propertycolumnScale: number;
  propertycolumnPrecision: number;
}

export class PropertyParticulars {
  propertyId: number;
  propertySearchElements: PropertySearchElement[];
  propertySearchsql: string;
  translations: KeyValueAddon2<string, string, string>[];
}

export class PropertySearchElement extends Entity {
  propertysearchelementColumn: string;
  propertysearchelementElement: string;
  propertysearchelementId: number;
  propertysearchelementName: string;
  propertysearchelementProperty: string;
  propertysearchelementPropertyColumn: string;
  propertysearchelementPropertyColumnId: number;
  propertysearchelementPropertyId: number;
  propertysearchelementTableAlias: string;
  propertysearchelementTranslations: KeyValueAddon2<string, string, string>[];
  deleted: boolean;
}

export class PropertyParticularsRequest {
  propertySearchElements: PropertySearchElementRequest[];
  propertySearchsql: string;
}

export class PropertySearchElementRequest {
  deleted: boolean;
  propertysearchelementId: number;
  propertysearchelementPropertyColumnId: number;
  propertysearchelementPropertyId: number;
  propertysearchelementTableAlias: string;
  propertysearchelementTranslations: KeyValue<string, string>[];
}

export class PropertyColumnParams {
  public static FILTER_PROPERTY_IDS = 'filterPropertyIds';
  public static FILTER_TYPE_IDS = 'filterTypeIds';
}
