<div
  class="base-form-field form-field-inputfield"
  [ngClass]="[
    (formFieldData.formfieldEntityType === 'string' &&
      formFieldData.formfieldEntityPrecision != null &&
      formFieldData.formfieldEntityPrecision > 1) ||
    formFieldData.formfieldEntityType === 'mailtemplate'
      ? 'multiline'
      : '',
    !formFieldData.formfieldLabelShow ? 'justify-center' : ''
  ]"
  (click)="onItemClick()"
>
  <div class="label-container" *ngIf="formFieldData.formfieldLabelShow">
    <ng-container *ngIf="formFieldData.formfieldLabelDisplay">
      <span class="label" showIfTruncated matTooltip="{{ formFieldData.formfieldTextname | translate }}"
        >{{ formFieldData.formfieldTextname | translate }}
      </span>
      <span *ngIf="formFieldData.formfieldRequired" class="requiredField">*</span>
      <span
        class="tooltip"
        *ngIf="formFieldData.formfieldInfotextname"
        matTooltip="{{ parseEntityTooltip('\n', '&emsp;', formFieldData.formfieldInfotextname | translate) }}"
        [matTooltipClass]="'fo-mat-tooltip tree-tooltip'"
        ><mat-icon class="entityTooltipIcon">info</mat-icon></span
      >
    </ng-container>
    <ng-container *ngIf="!formFieldData.formfieldLabelDisplay">
      <span class="label">&nbsp;</span>
    </ng-container>
  </div>
  <div class="value-container">
    <span class="value">
      <span [ngSwitch]="true">
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'string' && formFieldData.formfieldEntityPrecision !== 3">
          <mat-form-field
            appearance="outline"
            class="form-field-input reset-outline-hint"
            [ngClass]="formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : ''"
          >
            <input
              *ngIf="formFieldData.formfieldEntityPrecision == null || formFieldData.formfieldEntityPrecision === 1"
              type="text"
              matInput
              [(ngModel)]="formFieldData.formfieldEntityText"
              [disabled]="true"
            />
            <textarea
              *ngIf="formFieldData.formfieldEntityPrecision != null && formFieldData.formfieldEntityPrecision === 2"
              type="text"
              matInput
              [(ngModel)]="formFieldData.formfieldEntityText"
              cdkTextareaAutosize
              [disabled]="true"
            ></textarea>
            <button *ngIf="formFieldData.formfieldEntityText" mat-icon-button class="reset-filter" matSuffix [disabled]="true">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'string' && formFieldData.formfieldEntityPrecision === 3">
          <ckeditor
            #ckeditor
            [editor]="Editor"
            [(ngModel)]="formFieldData.formfieldEntityText"
            [disabled]="true"
            (ready)="onCkeditorReady()"
            [config]="config"
          ></ckeditor>
        </ng-container>
        <ng-container
          *ngSwitchCase="
            formFieldData.formfieldEntityType === 'integer' ||
            formFieldData.formfieldEntityType === 'bigint' ||
            formFieldData.formfieldEntityType === 'money' ||
            formFieldData.formfieldEntityType === 'cost' ||
            formFieldData.formfieldEntityType === 'factor' ||
            formFieldData.formfieldEntityType === 'percentage'
          "
        >
          <mat-form-field
            appearance="outline"
            class="form-field-input reset-outline-hint"
            [ngClass]="formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : ''"
          >
            <input type="number" matInput [(ngModel)]="formFieldData.formfieldEntityValue" [disabled]="true" />
            <button *ngIf="formFieldData.formfieldEntityValue" mat-icon-button class="reset-filter" matSuffix [disabled]="true">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </ng-container>
        <ng-container
          *ngSwitchCase="
            formFieldData.formfieldEntityType === 'date' ||
            formFieldData.formfieldEntityType === 'datetime' ||
            formFieldData.formfieldEntityType === 'time'
          "
        >
          <p-calendar
            rPCalendarMask
            #pcalendarDatepicker
            [ngClass]="formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : ''"
            [disabled]="true"
            [(ngModel)]="formFieldData.formfieldEntityText"
            [disabled]="true"
            [showIcon]="true"
            [placeholder]="getDatePlaceholder()"
            [dateFormat]="getDateRegionalFormat()"
            appendTo="body"
            ><p-footer>
              <div class="p-datepicker-buttonbar">
                <button
                  class="p-button-text"
                  type="button"
                  (click)="calendarOnTodayButtonClick($event, pcalendarDatepicker)"
                  pButton
                  pRipple
                >
                  {{ 'label_today' | translate }}
                </button>
                <button class="p-button-text" type="button" (click)="pcalendarDatepicker.hideOverlay()" pButton pRipple>
                  {{ 'label_close' | translate }}
                </button>
              </div>
            </p-footer>
          </p-calendar>
        </ng-container>
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'option'">
          <mat-form-field
            appearance="outline"
            *ngIf="formFieldData.formfieldDisplayType === FormFieldInputfieldOptionDisplayOption.COMBOBOX"
            class="form-field-input reset-outline-hint"
            [ngClass]="formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : ''"
          >
            <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
            <button mat-icon-button matSuffix [disabled]="true">
              <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
            </button>
          </mat-form-field>
          <mat-radio-group
            *ngIf="formFieldData.formfieldDisplayType === FormFieldInputfieldOptionDisplayOption.RADIO"
            [(value)]="formFieldData.formfieldEntityValue"
          >
            <mat-radio-button *ngFor="let opt of InputFieldOptionOptions" [value]="castToInt(opt.key)">{{ opt.value }}</mat-radio-button>
          </mat-radio-group>
        </ng-container>
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'foreign'">
          <mat-form-field
            appearance="outline"
            class="form-field-input reset-outline-hint"
            [ngClass]="formFieldData.formfieldEntityUnit ? 'mat-form-field-with-suffix' : ''"
          >
            <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
            <button [disabled]="true" mat-icon-button matSuffix class="reset-filter">
              <mat-icon>search</mat-icon>
            </button>
            <button *ngIf="formFieldData.formfieldEntityText" mat-icon-button class="reset-filter" matSuffix [disabled]="true">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'boolean'">
          <mat-slide-toggle
            *ngIf="formFieldData.formfieldDisplayType === FormFieldInputfieldBooleanDisplayOption.TOGGLE"
            color="primary"
            [checked]="formFieldData.formfieldEntityValue != null && formFieldData.formfieldEntityValue != 0"
            [disabled]="true"
          >
          </mat-slide-toggle>
          <mat-checkbox
            *ngIf="formFieldData.formfieldDisplayType === FormFieldInputfieldBooleanDisplayOption.CHECKBOX"
            [checked]="formFieldData.formfieldEntityValue != null && formFieldData.formfieldEntityValue != 0"
            (click)="readOnlyCheckbox()"
          >
          </mat-checkbox>
        </ng-container>
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'image'">
          <span class="image-input">
            <img *ngIf="formFieldData.formfieldEntityText" [src]="transform(formFieldData.formfieldEntityText)" />
            <span class="truncate-text" *ngIf="!formFieldData.formfieldEntityText">{{ 'label_no_image' | translate }}</span>
            <div class="image-buttons">
              <button mat-icon-button class="small-icon" [disabled]="true"><mat-icon>search</mat-icon> /></button>
              <button mat-icon-button class="small-icon" [disabled]="true">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </span>
        </ng-container>
        <!-- <ng-container *ngSwitchCase="'bytes'">bytes</ng-container> NOT IMPLEMENTED YET -->
        <ng-container *ngSwitchCase="formFieldData.formfieldEntityType === 'color'">
          <div class="color-picker-readonly" [ngStyle]="{ 'background-color': formFieldData.formfieldEntityText }"></div>
        </ng-container>
        <ng-container *ngIf="formFieldData.formfieldEntityType === 'mailtemplate'">
          <div class="form-field-mailtemplate">
            <div class="mailtemplate-row">
              <div class="base-form-field form-field-inputfield onequarter">
                <div class="label-container">
                  <span class="label">{{ 'mailbody_languageid' | translate }}</span>
                </div>
                <div class="value-container">
                  <span class="value">
                    <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
                      <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
                    </mat-form-field>
                  </span>
                </div>
              </div>
              <div class="base-form-field form-field-inputfield onequarter" style="padding: 0 0.5rem">
                <div class="label-container">
                  <span class="label">{{ 'mailbody_stage' | translate }}</span>
                </div>
                <div class="value-container">
                  <span class="value">
                    <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
                      <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
                    </mat-form-field>
                  </span>
                </div>
              </div>
              <div class="base-form-field form-field-inputfield onequarter">&nbsp;</div>
              <div class="base-form-field form-field-inputfield onequarter">
                <div class="label-container">
                  <span class="label">{{ 'mailbody_template' | translate }}</span>
                </div>
                <div class="value-container">
                  <span class="value">
                    <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
                      <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
                    </mat-form-field>
                  </span>
                </div>
              </div>
            </div>
            <div class="mailtemplate-row">
              <div class="base-form-field form-field-inputfield">
                <div class="label-container">
                  <span class="label">{{ 'mailbody_subject' | translate }}</span>
                  <span class="requiredField">*</span>
                </div>
                <div class="value-container">
                  <span class="value">
                    <mat-form-field appearance="outline" class="form-field-input reset-outline-hint">
                      <input type="text" matInput [(ngModel)]="formFieldData.formfieldEntityText" [disabled]="true" />
                    </mat-form-field>
                  </span>
                </div>
              </div>
            </div>
            <div class="mailtemplate-editor-row">
              <div class="base-form-field form-field-inputfield multiline">
                <div class="label-container">
                  <span class="label">{{ 'mailbody_body' | translate }}</span>
                  <span class="requiredField">*</span>
                </div>
                <div class="value-container">
                  <span class="value"
                    ><ckeditor
                      #ckeditor
                      [editor]="Editor"
                      [(ngModel)]="formFieldData.formfieldEntityText"
                      [disabled]="true"
                      [config]="config"
                    ></ckeditor>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <span class="truncate-text" *ngSwitchDefault showIfTruncated [matTooltip]="formFieldData.formfieldEntityText">
          {{ formFieldData.formfieldEntityText }}
        </span>
      </span>
    </span>
  </div>
  <button class="remove-button" (click)="remove(); $event.stopPropagation()" mat-icon-button *ngIf="isEditMode">
    <mat-icon>close</mat-icon>
  </button>
</div>
