<div
  #gridsterContainer
  id="generic-form-container"
  [ngClass]="isWizardCss ? 'wizard-container' : ''"
  (resized)="gridsterContainerResized($event)"
>
  <gridster class="fo-gridster-view" [options]="options" *ngIf="options">
    <ng-container *ngFor="let item of genericFormFieldItems; let index = index">
      <gridster-item
        *ngIf="item.isHiddenItemToKeep()"
        [attr.aria-label]="'Hidden Item to keep ' + item.formfieldId"
        [item]="item"
        class="separator-foreground"
        [ngClass]="[
          item.formfieldRoundTopLeft ? 'round-top-left' : '',
          item.formfieldRoundTopRight ? 'round-top-right' : '',
          item.formfieldRoundBottomLeft ? 'round-bottom-left' : '',
          item.formfieldRoundBottomRight ? 'round-bottom-right' : ''
        ]"
      >
        <app-form-field-separator [formFieldData]="item"></app-form-field-separator>
      </gridster-item>
      <gridster-item
        *ngIf="!item.isHidedField()"
        [item]="item"
        [ngSwitch]="item.formfieldKindId"
        [ngClass]="[
          item.formfieldKindId === FormFieldConfigurationKind.SEPARATOR && !item.formfieldLabelShow
            ? 'separator-background'
            : 'separator-foreground',
          item.formfieldRoundTopLeft ? 'round-top-left' : '',
          item.formfieldRoundTopRight ? 'round-top-right' : '',
          item.formfieldRoundBottomLeft ? 'round-bottom-left' : '',
          item.formfieldRoundBottomRight ? 'round-bottom-right' : ''
        ]"
      >
        <app-form-field-separator *ngSwitchCase="FormFieldConfigurationKind.SEPARATOR" [formFieldData]="item"></app-form-field-separator>
        <app-form-field-text
          *ngSwitchCase="FormFieldConfigurationKind.TEXT"
          [formFieldData]="item"
          [isViewMode]="isViewMode"
        ></app-form-field-text>
        <app-form-field-search
          *ngSwitchCase="FormFieldConfigurationKind.SEARCH"
          [saveSubj]="saveSubj"
          [formFieldData]="item"
          (changeValueEvEm)="onSearchChangedValue(index)"
        ></app-form-field-search>
        <app-form-field-inputfield
          *ngSwitchCase="FormFieldConfigurationKind.INPUT_FIELD"
          [entityKind]="entityKind"
          [entityId]="entityId"
          [saveSubj]="saveSubj"
          [validationSubj]="validationSubj"
          [mailpreviewSubj]="mailpreviewSubj"
          [formFieldData]="item"
          [isViewMode]="isViewMode"
          [isDirtyEnabled]="isDirtyEnabled"
          [showForeignDetailsButton]="showForeignDetailsButton"
          [foreignFilterEntityKind]="foreignFilterEntityKind"
          [foreignFilterEntityId]="foreignFilterEntityId"
          (changeValueEvEm)="onInputFieldChangedValue(index)"
          (autoUpdateEvEm)="onAutoUpdateInputFieldChangedValue(index)"
          (loseFocusEvEm)="validateUniquenessFieldValue(index)"
          [rowHeight]="rowHeight"
        ></app-form-field-inputfield>
        <app-form-field-inputfield
          *ngSwitchCase="FormFieldConfigurationKind.ENTITY_FIELD"
          [entityKind]="entityKind"
          [entityId]="entityId"
          [saveSubj]="saveSubj"
          [validationSubj]="validationSubj"
          [mailpreviewSubj]="mailpreviewSubj"
          [formFieldData]="item"
          [isViewMode]="isViewMode"
          [isDirtyEnabled]="isDirtyEnabled"
          [showForeignDetailsButton]="showForeignDetailsButton"
          [foreignFilterEntityKind]="foreignFilterEntityKind"
          [foreignFilterEntityId]="foreignFilterEntityId"
          (changeValueEvEm)="onInputFieldChangedValue(index)"
          (autoUpdateEvEm)="onAutoUpdateInputFieldChangedValue(index)"
          (loseFocusEvEm)="validateUniquenessFieldValue(index)"
        ></app-form-field-inputfield>
        <app-form-field-step-control
          *ngSwitchCase="FormFieldConfigurationKind.STEP_CONTROL"
          [saveSubj]="saveSubj"
          [formFieldData]="item"
          [isViewMode]="isViewMode"
          [isDirtyEnabled]="isDirtyEnabled"
          (changeValueEvEm)="onInputFieldChangedValue(index)"
          (loseFocusEvEm)="validateUniquenessFieldValue(index)"
        ></app-form-field-step-control>
        <app-form-field-chips
          *ngSwitchCase="FormFieldConfigurationKind.CHIPS"
          [saveSubj]="saveSubj"
          [formFieldData]="item"
          [isViewMode]="isViewMode"
          [isDirtyEnabled]="isDirtyEnabled"
          (changeValueEvEm)="onInputFieldChangedValue(index)"
          (loseFocusEvEm)="validateUniquenessFieldValue(index)"
        ></app-form-field-chips>
        <app-form-field-mailtemplate
          *ngSwitchCase="FormFieldConfigurationKind.MAILTEMPLATE"
          [entityKind]="entityKind"
          [saveSubj]="saveSubj"
          [mailpreviewSubj]="mailpreviewSubj"
          [formFieldData]="item"
          [isViewMode]="isViewMode"
          [isDirtyEnabled]="isDirtyEnabled"
          (changeValueEvEm)="onInputFieldChangedValue(index)"
          (loseFocusEvEm)="validateUniquenessFieldValue(index)"
        ></app-form-field-mailtemplate>
      </gridster-item>
    </ng-container>
  </gridster>
</div>
