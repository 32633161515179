<div [ngClass]="isMenuDisabled ? 'toolbar-disabled' : null"></div>
<div
  *ngIf="!isServerUnreachableError"
  class="toolbar-container"
  [ngStyle]="{ 'background-color': navbarSettings && navbarSettings.backgroundColor ? navbarSettings.backgroundColor : '' }"
>
  <div class="left-wrapper">
    <button
      *ngIf="isAuthenticated && navbarSettings?.settingAllowUseCases"
      class="usecases-button"
      color="primary"
      [disabled]="isMenuDisabled"
      mat-flat-button
      (click)="goToUsecases()"
    >
      {{ 'label_use_cases' | translate }}
    </button>
    <app-global-date-grid-filter-panel
      *ngIf="isAuthenticated || isTenantAdminAuthenticated"
      [isDisabled]="isMenuDisabled"
    ></app-global-date-grid-filter-panel>
    <global-search-bar *ngIf="isAuthenticated" [isDisabled]="isMenuDisabled"></global-search-bar>
  </div>

  <div class="right-wrapper">
    <ng-container *ngIf="notificationCount.countTaricsEntitlement && notificationCount.countTaricsEntitlement.length > 0">
      <app-tarics-send-entitlements
        [taricsCount]="notificationCount.countTaricsEntitlement"
        [isDisabled]="isMenuDisabled"
      ></app-tarics-send-entitlements>
    </ng-container>
    <div class="help-chat-container" *ngIf="!Environment.production && isAuthenticated">
      <button
        class="help-chat-button"
        color="warn"
        mat-icon-button
        matTooltip="{{ 'label_help_chat_tooltip' | translate }}"
        [matTooltipClass]="'fo-mat-tooltip'"
        (click)="goToHelpChat()"
      >
        <mat-icon>contact_support</mat-icon>
      </button>
    </div>
    <div class="notification-container" *ngIf="isAuthenticated && navbarSettings?.settingInboxEnabled && authUser?.adminrights">
      <button
        class="notification-button"
        color="primary"
        [disabled]="isMenuDisabled"
        [matBadge]="notificationCount.countEmail > 0 ? (notificationCount.countEmail < 100 ? notificationCount.countEmail : '99+') : null"
        matBadgeColor="warn"
        matBadgeSize="medium"
        [matBadgeOverlap]="notificationCount.countEmail > 0"
        mat-icon-button
        (click)="goToIncomingMails()"
      >
        <mat-icon>email</mat-icon>
      </button>
    </div>
    <div class="notification-container" *ngIf="isAuthenticated">
      <button
        class="notification-button"
        color="primary"
        [disabled]="isMenuDisabled"
        [matBadge]="
          notificationCount.countNotification > 0
            ? notificationCount.countNotification < 100
              ? notificationCount.countNotification
              : '99+'
            : null
        "
        matBadgeColor="warn"
        matBadgeSize="medium"
        [matBadgeOverlap]="notificationCount.countNotification > 0"
        mat-icon-button
        (click)="goToNotifications()"
      >
        <mat-icon>notifications</mat-icon>
      </button>
    </div>
    <div class="error-warning-container" *ngIf="isTenantAdminAuthenticated && isErrorOccurred">
      <ng-container *ngIf="!isExceptionErrorFileLoading; else errorSpreadsheetLoading">
        <button class="spreadsheet-button" color="warn" mat-icon-button (click)="loadExceptionErrorFile()" [disabled]="isMenuDisabled">
          <mat-icon>warning</mat-icon>
        </button>
      </ng-container>
    </div>
    <div class="actions-wrapper">
      <div
        *ngIf="isAuthenticated || isTenantAdminAuthenticated"
        class="logged-user"
        [matMenuTriggerFor]="isMenuDisabled ? null : profileMenu"
        [ngClass]="isMenuDisabled ? 'disabled' : ''"
      >
        <img
          *ngIf="navbarSettings && navbarSettings.tenantImage"
          class="tenant-image"
          [src]="transform(navbarSettings.tenantImage)"
          alt="BST"
        />
        <span *ngIf="navbarSettings && !navbarSettings.tenantImage && navbarSettings.description" class="tenant-name">{{
          navbarSettings.description
        }}</span>
        <div *ngIf="navbarSettings && !navbarSettings.tenantImage && !navbarSettings.description" class="user-image-wrapper">
          <app-preload-image
            class="user-image"
            [ratio]="{ w: 1, h: 1 }"
            [src]="'../../../../assets/imgs/photo_placeholder.jpg'"
            alt="profile image"
            title="profile image"
          ></app-preload-image>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isServerUnreachableError" class="toolbar-container server-unreachable-toolbar">
  <div class="error-wrapper">
    <img [src]="'../../../../assets/imgs/NoConnection.png'" alt="Ooops!" />
    <span class="ooops">Ooops!</span>
    <span class="error-message"
      >The database is not accessible. <br />
      Please wait a few minutes.</span
    >
    <button mat-flat-button class="try-again" (click)="reload()">Try Again</button>
  </div>
</div>

<mat-menu class="profile-menu" #profileMenu="matMenu" xPosition="before" overlapTrigger="false">
  <ng-container *ngIf="isAuthenticated || isTenantAdminAuthenticated">
    <div mat-menu-item [disableRipple]="true" class="username">{{ authUser.fullname }}</div>
    <mat-divider></mat-divider>
  </ng-container>
  <button mat-menu-item (click)="viewAsUser()" *ngIf="authUser && navbarSettings?.viewAsUserEnabled && !isTenantAdminAuthenticated">
    {{ 'topnavbar_menu_viewasuser' | translate }}
  </button>
  <button mat-menu-item routerLink="/pages/profile/settings" *ngIf="!isTenantAdminAuthenticated">
    {{ 'topnavbar_menu_profile' | translate }}
  </button>
  <button mat-menu-item (click)="helpOverlay()" *ngIf="authUser && !isTenantAdminAuthenticated">
    {{ 'topnavbar_menu_help' | translate }}
  </button>
  <button mat-menu-item routerLink="/pages/news" *ngIf="newsSettings?.settingNewsEnabled && !isTenantAdminAuthenticated">
    {{ 'topnavbar_menu_news' | translate }}
  </button>
  <button
    mat-menu-item
    routerLink="/pages/tenantadministration/news"
    *ngIf="newsSettings?.settingNewsEnabled && isTenantAdminAuthenticated"
  >
    {{ 'topnavbar_menu_news' | translate }}
  </button>
  <button mat-menu-item (click)="configurationOverlay()" *ngIf="authUser && !isTenantAdminAuthenticated">
    {{ 'topnavbar_menu_configuration' | translate }}
  </button>
  <button mat-menu-item routerLink="/pages/system-errors" *ngIf="authUser && !isTenantAdminAuthenticated">
    {{ 'topnavbar_menu_systemerrors' | translate }}
  </button>
  <button mat-menu-item (click)="logout()">
    {{ 'topnavbar_menu_logout' | translate }}
  </button>
</mat-menu>

<ng-template #errorSpreadsheetLoading>
  <div class="spinner-with-icon-container">
    <mat-spinner diameter="24"></mat-spinner>
    <mat-icon color="warn">warning</mat-icon>
  </div>
</ng-template>
