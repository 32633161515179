import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AccessMode } from 'src/app/configs/access-mode';
import { Entities } from 'src/app/configs/entities';
import { EntityRecord, EntitySelection } from 'src/app/models/entity';
import { ForeignEntity } from 'src/app/models/foreign-entity';
import { FormFieldChipsOptionDisplayOption } from 'src/app/models/forms/form-configuration-option';
import { GenericFormFieldItem, GenericFormFieldRequiredType } from 'src/app/models/forms/form-field';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { GlobalDateFilterStoreAction } from 'src/app/root-store/global-date-filter-store';
import { CoreDataService } from 'src/app/services/core-data.service';
import {
  GenericEntityWizardModalDialogComponent,
  GenericEntityWizardModalDialogData,
} from '../../../generic-entity-wizard-modal-dialog/generic-entity-wizard-modal-dialog.component';
import { BaseFormFieldComponent } from '../base-form-field/base-form-field-component';

@Component({
  selector: 'app-form-field-chips',
  templateUrl: './form-field-chips.component.html',
  styleUrls: ['./form-field-chips.component.scss'],
})
export class FormFieldChipsComponent extends BaseFormFieldComponent implements OnInit, OnDestroy {
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('optionSelect') optionSelectInput: ElementRef<HTMLInputElement>;
  @ViewChild('chipListTable') chipListTable: ElementRef<HTMLInputElement>;
  originalFormFieldData: GenericFormFieldItem;
  foreignEntityOptions: ForeignEntity[] = [];
  filteredEntityOptions: KeyValue<string, string>[] = [];
  actualSelection: EntitySelection[];
  currentSelectionSubj: BehaviorSubject<EntitySelection[]> = new BehaviorSubject<EntitySelection[]>(null);
  filter: string;
  // Create
  confirmCloseDialog: MatDialogRef<any, any>;
  wizardName: string;
  tempUserInputString: string;

  multiselectionDialogRef: MatDialogRef<any>;
  observer: ResizeObserver;

  showMenuItem: boolean;
  showDescription: boolean;
  showInfo: boolean;

  displayedColumnsTable = ['entity_name', 'menu_item', 'entity_description', 'entity_info', 'entity_remove'];

  constructor(
    protected router: Router,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    protected coreDataService: CoreDataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.originalFormFieldData = JSON.parse(JSON.stringify(this.formFieldData));
    this.filteredEntityOptions = this.isForeignEntityOption() ? null : this.formFieldData.formfieldEntityOptions;
    if (this.saveSubj) {
      this.subscribe(this.saveSubj, () => (this.isSavePressed = true));
    }
    if (this.isEditWithMultiselection()) {
      this.actualSelection =
        this.formFieldData.formfieldEntityRelations != null
          ? this.formFieldData.formfieldEntityRelations.map((ffer) => {
              return {
                ...new EntitySelection(),
                entityId: ffer.entityId,
                entityName: ffer.entityName,
                entityKind: ffer.entityKind,
                deleted: ffer.entityDeleted,
              };
            })
          : [];
      this.currentSelectionSubj.next(this.actualSelection);
    }
    this.cdRef.detectChanges();
    this.showMenuItem = this.formFieldData.formfieldEntityKindOther === Entities.CONTRACT;
    this.observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        // console.log('width', entry.contentRect.width);
        this.showDescription = entry.contentRect.width >= 600;
        this.showInfo = entry.contentRect.width >= 900;
      });
    });
    if (this.chipListTable) {
      this.observer.observe(this.chipListTable.nativeElement);
    }
  }

  ngOnDestroy() {
    if (this.chipListTable) {
      this.observer.unobserve(this.chipListTable.nativeElement);
    }
  }

  get availabledEntityRelations() {
    return this.formFieldData.formfieldEntityRelations ? this.formFieldData.formfieldEntityRelations.filter((er) => !er.entityDeleted) : [];
  }

  checkSelectedForeignOption() {
    this.tempUserInputString = this.formFieldData.formfieldEntityText;
    if (!this.foreignEntityOptions || !this.foreignEntityOptions.find((fe) => fe.entityName === this.filter)) {
      this.chipInput.nativeElement.value = null;
      this.filter = null;
    }
  }

  checkSelectedOption() {
    if (!this.filteredEntityOptions || !this.filteredEntityOptions.find((fe) => fe.value === this.filter)) {
      this.optionSelectInput.nativeElement.value = null;
      this.filter = null;
      this.filteredEntityOptions = this.formFieldData.formfieldEntityOptions;
    }
  }

  filterOptions(filter: string) {
    if (filter) {
      this.filteredEntityOptions = this.formFieldData.formfieldEntityOptions
        ? this.formFieldData.formfieldEntityOptions.filter((kv) => kv.value.toLowerCase().includes(filter.toLowerCase()))
        : [];
    } else {
      this.filteredEntityOptions = this.formFieldData.formfieldEntityOptions;
    }
  }
  loadDefaultForeignOptions($event) {
    $event.target.select();
    document.body.classList.add('waiting-mouse-cursor');
    const apiPath = this.isTenantAdmin
      ? `api/tenantadministration/entities/${this.formFieldData.formfieldEntityKindOther}?accessmode=${AccessMode.link}`
      : `api/entities/${this.formFieldData.formfieldEntityKindOther}?accessmode=${AccessMode.link}`;
    const request = { ...new PaginatedRequest(), pageSize: 10, orderBy: 'entityName' };
    this.subscribe(this.coreDataService.getForeignEntity(apiPath, request), ({ data }) => {
      if (
        this.formFieldData.formfieldEntityText == null ||
        this.formFieldData.formfieldEntityText === '' ||
        this.formFieldData.formfieldEntityValue == null
      ) {
        this.foreignEntityOptions = data;
      } else {
        this.foreignEntityOptions = [
          {
            ...new ForeignEntity(),
            entityId: this.formFieldData.formfieldEntityValue,
            entityName: this.formFieldData.formfieldEntityText,
          },
          ...data.filter((d) => d.entityId !== this.formFieldData.formfieldEntityValue),
        ];
      }
      document.body.classList.remove('waiting-mouse-cursor');
    });
  }

  openDialog(ref: TemplateRef<any>) {
    this.actualSelection =
      this.formFieldData.formfieldEntityRelations != null
        ? this.formFieldData.formfieldEntityRelations.map((ffer) => {
            return {
              ...new EntitySelection(),
              entityId: ffer.entityId,
              entityName: ffer.entityName,
              entityKind: ffer.entityKind,
              deleted: ffer.entityDeleted,
            };
          })
        : [];
    this.currentSelectionSubj.next(this.actualSelection);
    this.multiselectionDialogRef = this.dialog.open(ref, {
      autoFocus: false,
      width: '80vw',
      height: '90vh',
      panelClass: 'chips-multiselection-dialog',
      disableClose: true,
    });
    // this.dialog
    //   .open(EntityModalComponent, {
    //     width: '60vw',
    //     data: { labelName: this.formFieldData.formfieldTextname, kind: this.formFieldData.formfieldEntityKindOther, linkableFlag: true },
    //   })
    //   .afterClosed()
    //   .subscribe((entity) => {
    //     if (entity) {
    //       this.foreignEntityOptions = [entity as ForeignEntity];
    //       this.onForeignOptionSelectionChanged(null, entity);
    //     }
    //   });
  }

  closeDialog() {
    this.multiselectionDialogRef.close();
  }

  isForeignEntityOption() {
    return (
      this.formFieldData &&
      this.formFieldData.formfieldEntityKindOther &&
      this.formFieldData.formfieldEntityKindOther !== 'entity_types' &&
      this.formFieldData.formfieldEntityKindOther !== 'entity_options'
    );
  }

  saveSelection() {
    this.cdRef.detectChanges();
    // DO SAVE
    this.formFieldData.formfieldEntityRelations = this.actualSelection.map((as) => {
      return {
        ...new EntityRecord(),
        entityId: as.entityId,
        entityName: as.entityName,
        entityKind: as.entityKind,
        entityDeleted: as.deleted,
      };
    });
    if (this.chipInput) {
      this.chipInput.nativeElement.value = null;
    }
    if (this.optionSelectInput) {
      this.optionSelectInput.nativeElement.value = null;
    }
    this.filter = null;
    this.changeValueEvEm.emit();
    this.loseFocusEvEm.emit();
    this.closeDialog();
  }

  addToSelection(rows: EntitySelection[]) {
    rows.forEach((row) => {
      const index: number = this.actualSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        this.actualSelection[index].deleted = false;
      } else {
        this.actualSelection.push(row);
      }
    });
    this.currentSelectionSubj.next(this.actualSelection);
    if (this.isEditWithMultiselection()) {
      this.formFieldData.formfieldEntityRelations = this.actualSelection.map((as) => {
        return {
          ...new EntityRecord(),
          entityId: as.entityId,
          entityName: as.entityName,
          entityKind: as.entityKind,
          entityDeleted: as.deleted,
        };
      });
      this.changeValueEvEm.emit();
    }
  }

  removeFromSelection(rows: EntitySelection[]) {
    rows.forEach((row) => {
      const index: number = this.actualSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        // Check if Item is present
        const elem =
          this.formFieldData.formfieldEntityRelations != null
            ? this.formFieldData.formfieldEntityRelations.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          this.actualSelection[index].deleted = true;
        } else {
          this.actualSelection.splice(index, 1);
        }
      }
    });
    this.currentSelectionSubj.next(this.actualSelection);
    if (this.isEditWithMultiselection()) {
      this.formFieldData.formfieldEntityRelations = this.actualSelection.map((as) => {
        return {
          ...new EntityRecord(),
          entityId: as.entityId,
          entityName: as.entityName,
          entityKind: as.entityKind,
          entityDeleted: as.deleted,
        };
      });
      this.changeValueEvEm.emit();
    }
  }

  clearSelection() {
    if (this.actualSelection.length > 0) {
      this.actualSelection.forEach((row, index) => {
        // Check if Item is present
        let elem =
          this.formFieldData.formfieldEntityRelations != null
            ? this.formFieldData.formfieldEntityRelations.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          row.deleted = true;
        }
      });
    }
    this.actualSelection = this.actualSelection.filter((row) => row.deleted);
    this.currentSelectionSubj.next(this.actualSelection);
    if (this.isEditWithMultiselection()) {
      this.formFieldData.formfieldEntityRelations = this.actualSelection.map((as) => {
        return {
          ...new EntityRecord(),
          entityId: as.entityId,
          entityName: as.entityName,
          entityKind: as.entityKind,
          entityDeleted: as.deleted,
        };
      });
      this.changeValueEvEm.emit();
    }
  }

  removeChip(item: EntityRecord) {
    const index = this.originalFormFieldData.formfieldEntityRelations
      ? this.originalFormFieldData.formfieldEntityRelations.findIndex((i) => i.entityId === item.entityId)
      : -1;

    if (index < 0) {
      // Removed a chip not yet stored
      const i = this.formFieldData.formfieldEntityRelations.findIndex((i) => i.entityId === item.entityId);
      this.formFieldData.formfieldEntityRelations.splice(i, 1);
    } else {
      item.entityDeleted = true;
    }
    this.changeValueEvEm.emit();
  }

  /**  */
  createForeignEntity() {
    this.wizardName = this.formFieldData.formfieldEntityKindOther;
    const openedDialogs = this.dialog.openDialogs.length;
    const dialogRef = this.dialog.open(GenericEntityWizardModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'generic-entity-wizard-modal-dialog',
      disableClose: true,

      data: {
        wizardName: this.wizardName,
        inputValue: this.tempUserInputString,
        isDocumentsTabEnabled: this.isDocumentEnabled,
      } as GenericEntityWizardModalDialogData,
    });
    if (openedDialogs > 0) {
      const elemRect = this.dialog.openDialogs[openedDialogs - 1]._containerInstance['_elementRef'].nativeElement.getBoundingClientRect();
      const top = elemRect.top + 10;
      const left = elemRect.left + 10;
      dialogRef.updatePosition({ top: `${top}px`, left: `${left}px` });
    }
    this.subscribe(dialogRef.afterClosed(), (entity) => {
      if (entity) {
        if (this.formFieldData.formfieldEntityRelations == null) {
          this.formFieldData.formfieldEntityRelations = [];
        }
        this.formFieldData.formfieldEntityRelations.push({
          entityId: entity.entityId,
          entityName: entity.entityName,
          entityKind: entity.entityKind,
          entityDeleted: false,
          entityDescription: entity.entityDescription,
        });
        if (this.chipInput) {
          this.chipInput.nativeElement.value = null;
        }
        if (this.optionSelectInput) {
          this.optionSelectInput.nativeElement.value = null;
        }
        this.filter = null;
        this.changeValueEvEm.emit();
        this.loseFocusEvEm.emit();
      }
    });
  }

  isDirtyChips() {
    return (
      JSON.stringify(this.originalFormFieldData.formfieldEntityRelations) !== JSON.stringify(this.formFieldData.formfieldEntityRelations)
    );
  }

  isEditWithMultiselection() {
    return (
      this.formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.MULTISELECTION_CHIPS ||
      this.formFieldData.formfieldDisplayType === FormFieldChipsOptionDisplayOption.MULTISELECTION_LIST
    );
  }

  jumpToContract(row: EntityRecord) {
    this.store.dispatch(GlobalDateFilterStoreAction.saveGlobalDateFilterStatus());
    const urlTree = this.router.createUrlTree([`pages/contracts`]);
    urlTree.queryParams[Entities.CONTRACT] = row.entityId;
    const url = this.router.serializeUrl(urlTree);
    window.open(url, '_blank');
  }

  get GenericFormFieldRequiredType() {
    return GenericFormFieldRequiredType;
  }
  get FormFieldChipsOptionDisplayOption() {
    return FormFieldChipsOptionDisplayOption;
  }
}
